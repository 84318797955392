
export class FileModel {

    constructor(file) { //link is any object that gets passed in and has these properties

        if (file === undefined) {
            this.fileName = "";
            this.file = null;
            this.subFolder = "";

        } else {
            this.fileName = file.fileName;
            this.file = file.file;
            this.subFolder = file.subFolder;
        }
    }

}
