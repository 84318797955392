import React from 'react';
import { Input } from 'reactstrap';
import RequestDetail from './RequestDetail';

export default function RequestDetailDatePicker(props) {
    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail} >
            <Input
                placeholder={props.RequestTypeDetail.placeholder}
                type="date"
                style={{ width: 'fit-content' }}
            />
        </RequestDetail>
    );
}