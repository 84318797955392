import React, { useEffect, useState } from 'react';
import { axios, Button, getNowMountainTime } from 'react-mimg';
import { Modal, ModalBody, ModalFooter, ModalHeader, Alert, Input } from 'reactstrap';
import { createComment } from '../../../../functions/formHelpers/genericRequestFormHelper';
import { getCurrentReferralBonusTotal, getCurrentSigningBonusTotal } from '../../../../functions/formHelpers/referralBonusFormHelper';
import { getEmployeeSecurity } from '../../../../functions/permissions';
import styles from './updateBonusAmountsModal.module.scss';

export default function UpdateBonusAmounts(props) {
    let security = getEmployeeSecurity()

    const [showModal, setShowModal] = useState(false);
    const [bonusDetailView, setBonusDetailView] = useState();
    const hasReferralBonus = props.genericRequestFormModel?.requestType?.requestType1?.toLowerCase().includes('referral');
    const hasSigningBonus = props.genericRequestFormModel?.requestType?.requestType1?.toLowerCase().includes('signing');
    const [currentReferralBonusTotal, setCurrentReferralBonusTotal] = useState();
    const [currentSigningBonusTotal, setCurrentSigningBonusTotal] = useState();
    const [newReferralBonusTotal, setNewReferralBonusTotal] = useState(0)
    const [newSigningBonusTotal, setNewSigningBonusTotal] = useState(0)
    const [referralBonusDefaultComment, setReferralBonusDefaultComment] = useState();
    const [signingBonusDefaultComment, setSigningBonusDefaultComment] = useState();
    const [referralBonusUpdateComment, setReferralBonusUpdateComment] = useState('');
    const [signingBonusUpdateComment, setSigningBonusUpdateComment] = useState('');
    const [submittingReferralBonus, setSubmittingReferralBonus] = useState(false);
    const [submittingSigningBonus, setSubmittingSigningBonus] = useState(false);
    const [modalAlert, setModalAlert] = useState({ color: "info", message: "Use this function to update the total referral or total signing bonus associated with this request" });


    useEffect(() => {
        if (showModal) {
            axios.get('api/payroll/GetSingleReferralBonusDetailView?genericRequestFormId=' + props.genericRequestFormModel.id).then((res) => {
                setBonusDetailView(res.data);
            })

            let currentTotal = getCurrentReferralBonusTotal(props.genericRequestFormModel);
            if (currentTotal !== newReferralBonusTotal) {
                setCurrentReferralBonusTotal(currentTotal)
            }

            let currentSigningTotal = getCurrentSigningBonusTotal(props.genericRequestFormModel);
            if (currentSigningTotal !== newSigningBonusTotal) {
                setCurrentSigningBonusTotal(currentSigningTotal);
            }
        }

    }, [props.genericRequestFormModel,showModal])

    function updateReferralBonus() { //need to set both a new GenericRequestFormResponse, and a GenericRequestFormComment to commemorate the old bonus. Should generate the comment as we go.
        setSubmittingReferralBonus(true);
        let validationMsg = getUpdateReferralBonusMessage();
        if (validationMsg === "") {
            let genericRequestFormModel = props.genericRequestFormModel;

            let genericRequestComments = genericRequestFormModel.genericRequestFormComments.filter(x => x.id !== 0)
            let genericRequestFormComment = createComment(security.empID, referralBonusDefaultComment + referralBonusUpdateComment)
            genericRequestComments.push(genericRequestFormComment);
            genericRequestFormModel.genericRequestFormComments = genericRequestComments;

            let genericResponses = genericRequestFormModel.genericRequestResponses.filter(x => x.id !== 0);

            let genericRequestResponse = {}
            genericRequestResponse.id = 0;
            genericRequestResponse.formId = genericRequestFormModel.id;
            genericRequestResponse.responseType = "UpdateTotalReferralBonus";
            genericRequestResponse.responsePrompt = "Update Total Referral Bonus";
            genericRequestResponse.responseValue = newReferralBonusTotal;
            genericRequestResponse.responseValue2 = "Old Amt: " + currentSigningBonusTotal;
            genericRequestResponse.credentialAddedByUserId = security.empID;
            genericRequestResponse.credentialAddedByUserName = security.employeeFullName;
            genericRequestResponse.credentialAddedDateTime = getNowMountainTime();
            genericResponses.push(genericRequestResponse)

            genericRequestFormModel.genericRequestResponses = genericResponses;

            axios.put('api/GenericRequestForm/UpdateRequest', genericRequestFormModel).then((res) => {
                let genericRequestFormModel = res.data;
                props.onSave(genericRequestFormModel)
                setModalAlert({ color: "success", message: "The Total Referral Bonus Amount was updated successfully. No notifications are automatically sent with this step.  If the Bonus Determination function has already been used, please return to that page to update as necessary. If you made a mistake and need to update again, please first refresh the page." })
                //setSubmittingReferralBonus(false) //don't run this, so that way it will lock the user out after the first successful response.
            })
        } else {
            setModalAlert({ color: "danger", message: validationMsg })
            setSubmittingReferralBonus(false);
        }
    }

    function getUpdateReferralBonusMessage() {
        let msg = ""
        let firstPortionAlreadyPaid = bonusDetailView.firstPortionStatus === "Paid";
        if (firstPortionAlreadyPaid) {//can still change the total if the 2nd portion is not paid, but the new amount cannot be less than the 2nd portion.
            let firstPortionAmount = bonusDetailView.firstPortionAmount;
            let secondPortionAlreadyPaid = bonusDetailView.secondPortionStatus === "Paid";

            if (secondPortionAlreadyPaid) {
                msg += "Both the first and second portions of this bonus have been marked as paid. You cannot update the total amount of this bonus. "
            } else {
                let secondPortionAmount = bonusDetailView.secondPortionAlreadyPaid;
                if (newReferralBonusTotal < firstPortionAmount) {
                    msg += "The new referral bonus amount entered is less than the first portion amount of $" + firstPortionAmount + ", which has already been paid. "
                } else if (newReferralBonusTotal < (firstPortionAmount + secondPortionAmount)) {
                    //this is ok, but it creates an imbalance between the total referral bonus and 1st + 2nd parts.  But there is already logic to prevent those from being paid/setup, so it has to be allowed from one of the two screens.
                }
            }

        } else {
            //if the first portion has not been paid yet, we can still do whatever
        }

        if (newReferralBonusTotal === 0) {
            //I guess this is still ok, in case there is a signing bonus but the referral needs to be cancelled.
        }

        if (!referralBonusUpdateComment || referralBonusUpdateComment?.length < 0) {
            msg += "You must enter a comment of at least 10 characters regarding why the amount is changing. "
        }

        return msg;
    }

    useEffect(() => {
        setReferralBonusDefaultComment("Updated Total Referral Bonus Amount from " + currentReferralBonusTotal + " to " + newReferralBonusTotal + ".");
    }, [newReferralBonusTotal, currentReferralBonusTotal])

    function updateSigningBonus() { //need to set both a new GenericRequestFormResponse, and a GenericRequestFormComment to commemorate the old bonus. Should generate the comment as we go.
        setSubmittingSigningBonus(true);
        let validationMsg = getUpdateSigningBonusMessage();
        if (validationMsg === "") {
            let genericRequestFormModel = props.genericRequestFormModel;

            let genericRequestComments = genericRequestFormModel.genericRequestFormComments.filter(x => x.id !== 0)
            let genericRequestFormComment = createComment(security.empID, signingBonusDefaultComment + " " + signingBonusDefaultComment)
            genericRequestComments.push(genericRequestFormComment);
            genericRequestFormModel.genericRequestFormComments = genericRequestComments;

            let genericResponses = genericRequestFormModel.genericRequestResponses.filter(x => x.id !== 0);

            let genericRequestResponse = {}
            genericRequestResponse.id = 0;
            genericRequestResponse.formId = genericRequestFormModel.id;
            genericRequestResponse.responseType = "UpdateTotalSigningBonus";
            genericRequestResponse.responsePrompt = "Update Total Signing Bonus";
            genericRequestResponse.responseValue = newSigningBonusTotal;
            genericRequestResponse.responseValue2 = "Old Amt: " + currentSigningBonusTotal;
            genericRequestResponse.credentialAddedByUserId = security.empID;
            genericRequestResponse.credentialAddedByUserName = security.employeeFullName;
            genericRequestResponse.credentialAddedDateTime = getNowMountainTime();
            genericResponses.push(genericRequestResponse)

            genericRequestFormModel.genericRequestResponses = genericResponses;

            axios.put('api/GenericRequestForm/UpdateRequest', genericRequestFormModel).then((res) => {
                let genericRequestFormModel = res.data;
                props.onSave(genericRequestFormModel)
                setModalAlert({ color: "success", message: "The Total Signing Bonus Amount was updated successfully. No notifications are automatically sent with this step.  If the Bonus Determination function has already been used, please return to that page to update as necessary. If you made a mistake and need to update again, please first refresh the page." })
                //setSubmittingReferralBonus(false) //don't run this, so that way it will lock the user out after the first successful response.
            })
        } else {
            setModalAlert({ color: "danger", message: validationMsg })
            setSubmittingSigningBonus(false);
        }
    }

    function getUpdateSigningBonusMessage() {
        let msg = ""

        let signingBonusAlreadyPaid = bonusDetailView.signingBonusStatus === "Paid"
        if (signingBonusAlreadyPaid) {
            msg += "The signing bonus is already marked as Paid.  You cannot update the signing bonus at this time. "
        }

        if (!signingBonusUpdateComment|| signingBonusUpdateComment?.length < 0) {
            msg += "You must enter a comment of at least 10 characters regarding why the amount is changing. "
        }
        return msg;
    }


    useEffect(() => {
        setSigningBonusDefaultComment("Updated Total Signing Bonus Amount from " + currentSigningBonusTotal + " to " + newSigningBonusTotal + ".");
    }, [newSigningBonusTotal, currentSigningBonusTotal])

    return (
        <div>
            <Button onClick={() => setShowModal(!showModal)}>Update Bonus Totals</Button>
            <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Update Bonus Totals</ModalHeader>

                <ModalBody>

                    {modalAlert &&
                        <Alert color={modalAlert.color}>{modalAlert.message}</Alert>
                    }

                    {hasReferralBonus
                        ? <div className={styles.itemWrapper}>
                            <div className={`${styles.flexRow} ${styles.headers}`}>
                                <div className={styles.flex1}></div>
                                <div className={styles.flex2}>Current Amount</div>
                                <div className={styles.flex2}>New Amount</div>
                            </div>
                            <div className={styles.flexRow}>
                                <div className={styles.flex1}>Current Referral Bonus:</div>

                                <Input className={styles.flex2}
                                    disabled={true}
                                    type="number"
                                    value={currentReferralBonusTotal}
                                    placeholder="0.00" />

                                <Input className={styles.flex2}
                                    disabled={submittingReferralBonus}
                                    type="number"
                                    value={newReferralBonusTotal}
                                    onChange={(e) => setNewReferralBonusTotal(e.target.value)}
                                    placeholder="$0.00" />
                            </div>

                            <div className={styles.flexRow}>
                                <div className={styles.flex1}>
                                    Default Comment
                                </div>
                                <div className={styles.flex4}>
                                    <span>{referralBonusDefaultComment}</span>
                                </div>
                            </div>
                            <div className={styles.flexRow}>
                                <div className={`${styles.flex1}`}>Additional Comments</div>
                                <Input className={styles.flex4}
                                    disabled={submittingReferralBonus}
                                    type="textarea"
                                    rows={3}
                                    value={referralBonusUpdateComment}
                                    onChange={(e) => setReferralBonusUpdateComment(e.target.value)}
                                    placeholder="Why is the bonus amount being changed?" />
                            </div>

                            <div className={`${styles.flexRow} ${styles.floatRight}`}>
                                <Button onClick={() => updateReferralBonus()} disabled={submittingReferralBonus}>Update Total Referral Bonus Amount</Button>
                            </div>

                        </div>
                        : <div className={styles.flexRow}>There is no referral bonus associated with this form</div>
                    }

                    {hasSigningBonus
                        ? <div className={styles.itemWrapper}>
                            <div className={`${styles.flexRow} ${styles.headers}`}>
                                <div className={styles.flex1}></div>
                                <div className={styles.flex2}>Current Amount</div>
                                <div className={styles.flex2}>New Amount</div>
                            </div>

                            <div className={styles.flexRow}>
                                <div className={styles.flex1}>Current Signing Bonus:</div>

                                <Input className={styles.flex2}
                                    disabled={true}
                                    type="text"
                                    value={currentSigningBonusTotal}
                                    placeholder="$0.00" />

                                <Input className={styles.flex2}
                                    disabled={submittingSigningBonus}
                                    type="text"
                                    value={newSigningBonusTotal}
                                    onChange={(e) => setNewSigningBonusTotal(e.target.value)}
                                    placeholder="$0.00" />
                            </div>

                            <div className={styles.flexRow}>
                                <div className={styles.flex1}>
                                    Default Comment
                                </div>
                                <div className={styles.flex4}>
                                    <span>{signingBonusDefaultComment}</span>
                                </div>
                            </div>
                            <div className={styles.flexRow}>
                                <div className={`${styles.flex1}`}>Additional Comments</div>
                                <Input className={styles.flex4}
                                    disabled={submittingSigningBonus}
                                    type="textarea"
                                    rows={3}
                                    value={signingBonusUpdateComment}
                                    onChange={(e) => setSigningBonusUpdateComment(e.target.value)}
                                    placeholder="Why is the bonus amount being changed?" />
                            </div>

                            <div className={`${styles.flexRow} ${styles.floatRight}`}>
                                <Button onClick={() => updateSigningBonus()} disabled={submittingSigningBonus}>Update Total Signing Bonus Amount</Button>
                            </div>
                        </div>
                        : <div className={styles.flexRow}>There is no signing bonus associated with this form</div>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button transparent onClick={() => setShowModal(!showModal)}>Close</Button>{"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}