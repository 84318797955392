import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { getNowMountainTime, axios } from 'react-mimg';
import { getEmployeeSecurity } from '../../functions/permissions';


export default function NoAccessPage(props) {
    const location = useLocation();
    const user = getEmployeeSecurity();

    useEffect(() => {
        const logPageView = async () => {

            let view = {
                userId: user?.userId,
                fullUrl: window.location.href,
                propertyEmployeesId: user?.empID,
                path: location?.pathname,
                queryString: location?.search,
            };

            await axios.post('api/PageViewLog/LogNoAccessView', view);
        };

        logPageView();
    }, []);


    return (
        <Alert color="danger">
            {!props.hideDefaultMessage &&
                <>
                    <p>You do not have access to this page or functionality. Please submit an IT Service Request if you believe you should have access</p>
                    <p><a href="https://mimg.us/ITRequestForm.aspx?Type=AddForm&on=0" target="_blank" rel="noreferrer">IT Service Request</a></p>
                </>
            }

            {props.messages && props.messages.length > 0 && <div>
                <b>Messages:</b> <br />
                {props.messages.map((m, index) => {
                    return <p key={index}>{m}</p>
                })}
            </div>
            }
        </Alert>

    )
}
