export function percent(decimal) {
    if (decimal === 0) {
        return ""
    } else {
        let valueFormat = Intl.NumberFormat("en-GB", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(decimal);
        return valueFormat
    }
}

export function percentWithZeros(decimal) {
    return Intl.NumberFormat("en-GB", {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(decimal);
}

export function currency(amount) {
    if (amount === 0) {
        return 0
    } else {
        let valueFormat = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0
        }).format(amount)
        return valueFormat
    }
}

export function currencyWithZeros(amount) {
    if (amount === 0) {
        return 0.00;
    } else {
        let valueFormat = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }).format(amount)
        return valueFormat
    }
}

export function formatDateForInput(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function getISODate(dateString) {
    var date = new Date(dateString);
    if (isValidDate(date)) {
        return date.toISOString().split('T')[0]
    }
    return null;
}

export function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
}