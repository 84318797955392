import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import styles from './mimgAlert.module.scss'

export class MimgAlert extends Component {
    constructor(props) {
        super(props);

        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    componentDidUpdate = () => {
        window.scrollTo(0, 0);
    }

    onDismiss() {
        this.props.onDismiss();
    }

    render() {
        if (this.props.alertInfo && this.props.alertInfo.AlertText !== "") {
            return (

                <Alert dismissable="true" id="alert" className="alert" isOpen={true} color={this.props.alertInfo.AlertColor}>
                    {this.props.alertInfo.AlertText.split("\n").map((str, index) => <p key={index} className={this.props.alertTypeClass}>{str}</p>)}
                    {this.props.alertInfo.AlertLinks &&
                        this.props.alertInfo.AlertLinks.map((p, index) => {
                            return <span key={index}><a className={styles.alertLink} href={p.url} target="_blank" rel="noreferrer">{p.linkDesc}</a><br /></span>
                        })
                    }

                </Alert>
            );
        } else {
            return <></>;
        }
    }
}
