
//this is used to create an thisect posted to the genericrequesttypecontroller via a post request as the filters are all optional and it is easier to handle all this at the controller level
export class GenericRequestFormSearchObject {

    constructor(relatedToFormName) { 

        this.relatedToFormName = relatedToFormName;
        this.requestId = null
        
        this.fromDate = null;
        this.toDate = null;
        this.requestTypeIds = [];
        this.searchPropertyIds = [];
        this.requestStatuses = []; //this is a string array, others are integers
        this.requestAdminIds = [];
        this.includeMimgTix = true;
        
    }

}
