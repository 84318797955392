import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import { axios, Button } from 'react-mimg';
import styles from './UserMaintFilters.module.scss';

export default function UserMaintFilters(props) {
    const [searchFilter, setSearchFilter] = useState();
    const [propertyFilter, setPropertyFilter] = useState();
    const [phoneFilter, setPhoneFilter] = useState();
    const [positionFilter, setPositionFilter] = useState();
    const users = props.users;
    const properties = props.properties;
    const positionOptions = props.positionOptions;

    useEffect(() => {
        var filteredUsers = users;
        if (searchFilter) {
            filteredUsers = filteredUsers?.filter(x => (x.firstName + ' ' + x.lastName)?.toLowerCase().includes(searchFilter?.toLowerCase()) || x.email?.toLowerCase().includes(searchFilter?.toLowerCase()) || x.userId === parseInt(searchFilter) || x.propertyEmployeesId === parseInt(searchFilter))
        };
        if (propertyFilter) {
            filteredUsers = filteredUsers?.filter(x => (x.siteId === propertyFilter.value))
        }
        if (positionFilter) {
            filteredUsers = filteredUsers?.filter(x => (x.position === positionFilter.value))
        }
        if (phoneFilter) {
            const cleanedPhoneFilter = phoneFilter.replace(/\D/g, '');
            filteredUsers = filteredUsers?.filter(x => {
                const numericPhone = x.phone.replace(/\D/g, '');
                const numericCellPhone = x.cellPhone.replace(/\D/g, '');
                return numericPhone.includes(cleanedPhoneFilter) || numericCellPhone.includes(cleanedPhoneFilter);
            });
        }

        props.setDisplayUsers(filteredUsers);

    }, [searchFilter, propertyFilter, positionFilter, phoneFilter]);

    

    return (
        <><div className={styles.inputRow}>
            <div className={styles.input}>
                <Input type="text" placeholder="Search by Name, Email, UserId or PropertyEmployeesId" onKeyUp={(e) => setSearchFilter(e.target.value)} />
            </div>
            <div className={styles.input}>
                <Input type="text" placeholder="Search by Phone Number" onKeyUp={(e) => setPhoneFilter(e.target.value)} />
            </div>
        </div>
            <div className={styles.inputRow}>
                <Select className={styles.filter}
                    value={propertyFilter ? { label: propertyFilter.label, value: propertyFilter.value } : null}
                    options={properties ? properties.sort((a, b) => a.label.localeCompare(b.label)) : null}
                    placeholder='Property'
                    isClearable={true}
                    onChange={(option) => setPropertyFilter(option)} />
                <Select className={styles.filter}
                    value={positionFilter ? { label: positionFilter.label, value: positionFilter.value } : null}
                    options={positionOptions}
                    placeholder='Position'
                    isClearable={true}
                    onChange={(option) => setPositionFilter(option)} />
            </div></>
    )
}