import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import styles from './linkStyles.module.scss';

export class AddNewFormLink extends Component {

    render() {
        return (
            <a href={this.props.linkToUrl}>
                <div className={styles.linkDiv} >
                    <FontAwesomeIcon icon={faPlusCircle} />
                </div>
            </a>
        );
    }
}