
export class CustomAlertLink {

    constructor(url, linkDesc) { //link is any object that gets passed in and has these properties
        if (url !== null && url !== undefined) {
            this.url = url;
            this.linkDesc = linkDesc;

        } else {
            this.url = "";
            this.linkDesc = "";

        }
      
    }

}
