
export function getCurrentReferralBonusTotal(genericRequestFormModel) {
    let submittedAmount = parseInt(genericRequestFormModel?.genericRequestFormDetails?.filter(x => x.detail.questionLabel.toLowerCase().includes("referral bonus amount"))[0]?.response.replace(',',''))
    let updates = genericRequestFormModel.genericRequestResponses.filter(x => x.responseType === "UpdateTotalReferralBonus")
    if (updates.length > 0) {
        updates.sort((a, b) => { return b.id - a.id })
        return updates[0].responseValue;
    } else {
        return submittedAmount;
    }
}

export function getCurrentSigningBonusTotal(genericRequestFormModel) {
    let submittedAmount = parseInt(genericRequestFormModel?.genericRequestFormDetails?.filter(x => x.detail.questionLabel.toLowerCase().includes("signing bonus amount"))[0]?.response.replace(',',''))
    let updates = genericRequestFormModel.genericRequestResponses.filter(x => x.responseType === "UpdateTotalSigningBonus")
    if (updates.length > 0) {
        updates.sort((a, b) => { return b.id - a.id })
        return updates[0].responseValue;
    } else {
        return submittedAmount;
    }

}
