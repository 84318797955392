import React, { useState } from 'react';
import { Button, axios, getNowMountainTime } from 'react-mimg';
import { Form, Modal, ModalBody, ModalHeader, Alert, Label, Input } from 'reactstrap';
import { createComment, getNextStepInfo } from '../../../../functions/formHelpers/genericRequestFormHelper';
import { getEmployeeSecurity } from '../../../../functions/permissions';
import { GenericRequestFormDocumentModel } from '../../../../models/genericrequestforms/GenericRequestFormDocumentModel';
import ClipLoader from "react-spinners/ClipLoader";
import styles from './UploadMvrModal.module.scss';
import { useEffect } from 'react';


export default function UploadMvr(props) {

    let security = getEmployeeSecurity()

    const [showModal, setShowModal] = useState();
    const [modalAlert, setModalAlert] = useState({
        color: "info",message: "HR's is responsible for gathering the Motor Vehicle Report and uploading here.  The MVR will be sent to Legal for review, and Legal will determine if the driver is accepted or not. The documents loaded to this form are only available to admins. If any other attachments need to be added, use the 'Add Attachment' button" });
    const isUpdateRequest = props.genericRequestFormModel.requestTypeId === process.env.REACT_APP_REQUEST_TYPE_UPDATE_LICENSE;
    const [employeeIsAlreadyInDriversList, setEmployeeIsAlreadyInDriversList] = useState(false);
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        if (showModal) {
            if (isUpdateRequest && parseInt(props.genericRequestFormModel.id) > 0) {
                axios.post('api/DriversLicense/CheckIfEmployeeIsAlreadyInDriversList', props.genericRequestFormModel).then(r => {
                    setEmployeeIsAlreadyInDriversList(r.data);
                    if (r.data !== "") {
                        setModalAlert({ color: "danger", message: r.data + ". Please use the 'Reject' button to notify the submitter of this form." })
                    }
                })
            }
        }
    }, [props.showModal, props.genericRequestFormModel])

    function onSendToLegal(e) {
        e.preventDefault();
        setProcessing(true);
        let genericRequestForm = JSON.parse(JSON.stringify(props.genericRequestFormModel))
        let file = e.target.fileUpload.files[0];
        let mvrDate = e.target.mvrDate.value;
        let fileName = getFixedFileName(fileUpload.files[0])
        
        let fileFormData = new FormData();
        fileFormData.append("formFile", file)
        fileFormData.append("fileName", fileName)
        fileFormData.append("subFolderName", "Drivers")
        fileFormData.append("formId", genericRequestForm.id);

        axios.post('api/file/PostEncryptedFile', fileFormData).then((response) => {
            if (response.status === 201) { //the file was successfully saved to Azure storage.  Now we need to write info about the document (and comment if added) to the GenericRequestFormDocument and GenericRequestFormComment tables

                let documentModel = new GenericRequestFormDocumentModel();
                documentModel.docType = "Drivers";
                documentModel.docDescription = "Motor Vehicle Record - " + new Date(mvrDate.replace(/-/, '/').replace(/-/, '/')).toLocaleDateString();
                documentModel.fileDirectory = "Drivers";
                documentModel.docDate = mvrDate;
                documentModel.createDateTime = getNowMountainTime();
                documentModel.fileName = fileName
                documentModel.uploadedByEmpID = security.empID;
                documentModel.uploadedByName = security.employeeFullName;
                documentModel.isByRequester = (parseInt(security.empID) === genericRequestForm.requesterEmpId);
                documentModel.originalFileName = fileUpload.files[0].name;

                genericRequestForm.genericRequestFormDocuments.push(documentModel);

                //Add a comment that will at the very least show up in the comment box to denote that someone from HR did this.     
                let mvrComment = e.target.mvrComment.value;
                if (mvrComment && mvrComment !== '') {
                    let genericRequestComment = createComment(security.empID, mvrComment)
                    genericRequestForm.genericRequestFormComments.push(genericRequestComment);
                }

                //Add a GenericRequestResponse object. This is used to store the DateOfMvr that is then shown on the finalize modal to confirm. This value is not saved to the EmployeeDrivers table, rather that value comes from the date confirmed in the ApproveDriverModal
                let response = {};
                response.formId = genericRequestForm.id;
                response.responseType = "DateOfMvr";
                response.responsePrompt = "Date of Mvr";
                response.responseValue = mvrDate;
                response.credentialAddedByUserId = security.empID;
                response.credentialAddedByUserName = security.employeeFullName;
                response.credentialAddedDateTime = getNowMountainTime();

                genericRequestForm.genericRequestResponses.push(response);

                let requestTypeId = genericRequestForm.requestTypeId;
                let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
                let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Upload MVR')[0].id;
                genericRequestForm.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);
                genericRequestForm.requestStatus = 'Pending Approval'

                axios.put('api/GenericRequestForm/UpdateRequest?updateAction=addMotorVehicleInfo', genericRequestForm).then((res) => {
                    axios.post(`api/file/AddDocumentIdToBlob?fileName=${fileName}&formId=${genericRequestForm.id}`) //this should run fine in the background, and doesn't need awaited

                    let genericRequestFormModel = res.data;
                    let pageAlert = {AlertText: "The Motor Vehicle Report was uploaded to the form, and a notification was sent to Legal. If you have additional documents, use the 'Add Attachment' button to upload them. ", AlertColor: "success"}

                    props.onSave(genericRequestFormModel, pageAlert, "Motor Vehicle Report")
                    setShowModal(false);
                    setProcessing(false);
                }).catch((error) => {
                    setModalAlert({ color: "danger", message: "The document was saved, but there was an error associating the Motor Vehicle Report with this form. Error " + error })
                    setProcessing(false)
                })

            } else {
                setModalAlert({ color: "danger", message: "There was an issue saving the document.  Please reload the page and try again.  If the issue persists, please submit an IT Support Request" })
                setProcessing(false);
            }
        })

    }


    function getFixedFileName(file) {
        let ext = file.name.split('.').pop();
        let formId = props.genericRequestFormModel.id.toString();
        let sixDigitFormId = formId.padStart(6, '0');
        let fileTime = Date.now();

        return `grf${sixDigitFormId}-${fileTime}.${ext}`;
    }

    return (
        <div>

            <Button onClick={() => setShowModal(true)}>Upload MVR</Button>

            <Modal size="lg" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Upload Motor Vehicle Report</ModalHeader>

                <ModalBody>
                    {modalAlert &&
                        <Alert color={modalAlert.color}>{modalAlert.message}</Alert>
                    }

                    <Form onSubmit={onSendToLegal}>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="fileUpload">Add the Date of the MVR</Label>
                            <Input disabled={isUpdateRequest || employeeIsAlreadyInDriversList} type="date" name="mvrDate" id="mvrDate" required defaultValue={new Date().toISOString().substring(0, 10)} />
                        </div>

                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="fileUpload">Choose File Containing the MVR to upload (only admins will be able to see this)</Label>
                            <Input disabled={isUpdateRequest || employeeIsAlreadyInDriversList} type="file" name="fileUpload" id="fileUpload" required />
                        </div>

                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="mvrComment">Comments (optional, any comments will be visible to anyone that can access the form)</Label>
                            <Input disabled={isUpdateRequest || employeeIsAlreadyInDriversList} type="textarea" name="mvrComment" id="mvrComment" defaultValue="Uploaded Motor Vehicle Report. " />
                        </div>
                        <div className={styles.buttonsOnRight}>
                            <Button transparent onClick={props.toggle}>Close</Button> {"  "}
                            <Button type="submit" disabled={processing || (!isUpdateRequest && employeeIsAlreadyInDriversList)} className={styles.submitButton}> 
                                {processing
                                    ? <div><ClipLoader size={25} /></div>
                                    : <>Send to Legal</>
                                }
                                </Button> {"  "}                        
                        </div>
                    </Form>
                </ModalBody>

            </Modal>
        </div>

    );
}