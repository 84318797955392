import React, { Component } from 'react';
import { AddNewFormLink } from './navigation/AddNewFormLink';
import styles from '../layout/pageTitle.module.scss';
import { BackToListLink } from './navigation/BackToListLink';

export class PageTitle extends Component {


    render() {
        return (
            <div className={`${styles.wrapper} row-flex-container mimgPadTop10`}>
                <div className="flex-3">
                    <h3 className="featurette-heading mimgPageTitle">{this.props.PageTitle}</h3>
                    {this.props.instructionsUrl &&
                        <a className={styles.instructionsLink} target="blank" href={this.props.instructionsUrl}>Page Instructions</a> 
                    }
                </div>
                

                {this.props.addFormUrl  &&
                    <span><AddNewFormLink className="flex-1" linkToUrl={this.props.addFormUrl} />&nbsp;</span>
                }

                {this.props.goToListUrl &&
                    <span><BackToListLink className="flex-1" linkToUrl={this.props.goToListUrl} />&nbsp;</span>
                }

                {this.props.notMobileOptimized && 
                    <span className={styles.notOptimizedText}>This page is not optimized for mobile screens</span>
                }
            </div>

        );
    }
}
