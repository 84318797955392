import React, { useState, useEffect } from 'react';
import { getMenuPermissions, getUnauthorizedToViewPageAlertMessage, isDeveloper, getFormAccess } from '../../../functions/permissions';
import { axios, logError, ErrorLogModel } from 'react-mimg';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { GenericRequestFormSearchObject } from '../../../models/genericrequestforms/GenericRequestFormSearchObject';
import { RequestListItem } from './RequestListItem';
import RequestListFilter from './RequestListFilter';
import validator from 'validator';
import { MimgAlert } from '../../layout/MimgAlert';
import { CustomAlertLink } from '../../../models/CustomAlertLink';
import { updateProfileUrl } from '../../profiles/EmployeeProfile/employeeProfileConfig';

import FileSaver from 'file-saver';
import NoAccessPage from '../../../pages/admin/NoAccessPage';

export default function GenericRequestFormList(props) {

    const [requestForms, setRequestForms] = useState();
    const [searchAlert, setSearchAlert] = useState({ AlertColor: "info", AlertText: "Showing requests based on the default request statuses. To view other requests, please use the filters, which will search across all requests. If you cannot find a form using the status filters, please submit an IT Support Ticket" });
    const [allowMimgTix, setAllowMimgTix] = useState();
    const [includeMimgPropsByDefault, setIncludeMimgPropsByDefault] = useState();
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatusOptions, setSelectedStatusOptions] = useState([]);
    const [allSteps, setAllSteps] = useState([])
    const [propertyOptions, setPropertyOptions] = useState([]);

    const filteredRequestTypes = props.requestTypes.filter(rt =>
        rt.requestTypePermissions.length === 0 || props.isAdmin || isDeveloper() ||
        rt.requestTypePermissions.map(permission => permission.position.position).includes(props.security.employeePosition));

    const requestTypeOptions = filteredRequestTypes.map(r => ({ value: r.id, label: r.requestType1 }))
    const assigneeOptions = getAssigneeOptions(props.availableAssignees);


    useEffect(() => {
        //limit the list to just the person's associated or home property.
        let filteredPropertyList = [];
        let corporateSiteIds = [176, 231, 99999]
        let allowMimgTix = false;
        let includeMimgPropsByDefault = true;
        if (corporateSiteIds.includes(props.security.employeeSiteID)) {
            allowMimgTix = true;
        }

        if (props.isAdmin) {
            filteredPropertyList = props.propertyList;
        } else if (props.security.propertyAssociations.length > 0) { //see their associated properties, plus their home site (usually MI home office)
            let siteIds = props.security.propertyAssociations.map(x => x.siteId);
            filteredPropertyList = props.propertyList.filter(x => siteIds.includes(x.siteId) || x.siteId === props.security.employeeSiteID);
            includeMimgPropsByDefault = false
        } else { //default.  If a corporate employee, sees all, if a site employee sees their site only
            let siteId = props.security.employeeSiteId;
            if (corporateSiteIds.includes(siteId)) {
                filteredPropertyList = props.propertyList;
            } else {
                filteredPropertyList = props.propertyList.filter(x => x.siteId === siteId);
            }
        }

        setPropertyOptions(getPropertyOptions(filteredPropertyList));
        setAllowMimgTix(allowMimgTix);
        setIncludeMimgPropsByDefault(includeMimgPropsByDefault);
        getDefaultFormsList(includeMimgPropsByDefault)

    }, [props.isAdmin, props.security, props.propertyList])

    function getAssigneeOptions(availableAssignees){
        let options = availableAssignees.map(pe => {
            var obj = {};
            obj.value = pe.propertyEmployeesId;
            obj.label = pe.firstName + " " + pe.lastName;
            return obj;
        })

        return options;
    }

    function getPropertyOptions(propertyList) {
        let propertyOptions = propertyList.map(p => {
            var obj = {};
            obj.value = p.siteId;
            obj.label = p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')';

            return obj;
        })

        return propertyOptions;
    }

    function getStatusOptions(statuses) {
        let statusOptions = statuses.map(s => {
            var obj = {};
            obj.value = s.stepName;
            obj.label = s.stepName;

            return obj;
        })

        return statusOptions;
    }

    function gatherFormElements() {
        //define elements
        let dateRequestsFrom = document.getElementById("dateRequestsFrom");
        let dateRequestsTo = document.getElementById("dateRequestsTo");
        let selectRequestType = document.getElementById("selectRequestType");
        let selectRequestStatus = document.getElementById("selectRequestStatus");
        let selectProps = document.getElementById("selectProps");
        let selectAssignee = document.getElementById("selectAssignee");
        let txtRequestId = document.getElementById("txtRequestId");
        let switchIncMimgForms = document.getElementById("switchIncMimgTix");

        ////controller takes this object
        let searchObj = new GenericRequestFormSearchObject(props.formType);
        if (validator.isDate(dateRequestsFrom.value)) { searchObj.fromDate = dateRequestsFrom.value; };
        if (validator.isDate(dateRequestsTo.value)) { searchObj.toDate = dateRequestsTo.value; };
        if (validator.isInt(txtRequestId.value, { min: 1, max: 999999 })) { searchObj.requestId = txtRequestId.value };

        let searchPropertyIds = Array.from(selectProps.children[3].children).map(p => parseInt(p.value));
        if (!isNaN(searchPropertyIds[0])) {
            searchObj.searchPropertyIds = searchPropertyIds;
        } else {
            searchObj.searchPropertyIds = propertyOptions.map(x => x.value);
        }

        let requestTypeIds = Array.from(selectRequestType.children[3].children).map(p => parseInt(p.value));
        if (isNaN(requestTypeIds[0]))
            searchObj.requestTypeIds = filteredRequestTypes.map(x => x.id);
        else
            searchObj.requestTypeIds = requestTypeIds;

        let requestStatuses = Array.from(selectRequestStatus.children[3].children).map(p => p.value);
        if (requestStatuses[0] !== "") {
            searchObj.requestStatuses = allSteps.filter(step => requestStatuses.includes(step.stepName))
                .map(x => x.id);
        }

        let requestAdminIds = Array.from(selectAssignee.children[3].children).map(p => parseInt(p.value));
        if (!isNaN(requestAdminIds[0])) { searchObj.requestAdminIds = requestAdminIds; };

        searchObj.formType = props.formType;
        searchObj.includeMimgTix = switchIncMimgForms.checked;

        return searchObj;
    }

    function searchForms() {
        let searchObj = gatherFormElements();

        axios.post("api/GenericRequestForm/SearchRequests", searchObj).then((response) => {
            if (response.data.length >= 0) {
                setSearchAlert({ AlertColor: "info", AlertText: response.data.length + " forms found for the given search conditions the last time the Submit Filters button was pressed. To see all forms, click the Clear Filters button. To filter the list again, press the Submit Filters button"})
            } else {
                setSearchAlert({ AlertColor: "danger", AlertText: "No data found for the given search condition.  Please try changing the filters and searching again." });
            }
            setRequestForms(response.data)
        })
    }

    function searchFormsToCSV(closeWindow = true) {
        let searchObj = gatherFormElements();
        if (searchObj.requestTypeIds.length === 0) {
            setSearchAlert({AlertColor: "danger", AlertText: "\nPlease select at least one Request Type" })
            return;
        }
        searchObj.empId = props.security.empID;
        axios.post("api/GenericRequestForm/SearchRequestsToExport", searchObj).then((response) => {
            if (response.data) {
                let blob = new Blob([response.data], { type: "text/csv" });
                let fileName = searchObj.relatedToFormName + '_' + response.headers['content-disposition'].replace('*', '').replace(/; /g, '').split("filename=")[1]
                FileSaver.saveAs(blob, fileName);
                if (closeWindow !== false) { window.close() }
            } else {
                setSearchAlert({ AlertColor: "danger", AlertText: "Issue downloading export file. For assistance, please put in an IT Service Request form and paste in this message" })
            }
        });
    }

    function clearFilters() {
        setSearchAlert({ AlertColor: "info", AlertText: "Showing requests based on the default request statuses. To view other requests, please use the filters, which will search across all requests. If you cannot find a form using the status filters, please submit an IT Support Ticket" })
        getDefaultFormsList(includeMimgPropsByDefault);
    }


    function uniqueByName(items) {
        const seen = new Map();
        items.forEach(item => {
            if (!seen.has(item.stepName)) {
                seen.set(item.stepName, item);
            }
        });
        return Array.from(seen.values());
    }

    function getDefaultFormsList(includeMimgTixByDefault) {

        const uniqueIds = [
            ...new Set(
                props.requestTypes
                    .map(item => item.approvalWorkflow)
                    .filter(workflow => workflow != null)
            )
        ];

        const url = `api/GenericRequestForm/GetRequestStatusOptions?ids=${uniqueIds.join(',')}`;
        axios.get(url).then(response => {
            setAllSteps(response.data);

            const statusOptions = uniqueByName(response.data
                .filter(item => item.allowFilteringBy === true)
                .map(item => ({ id: item.id, stepName: item.stepName, sortOrder: item.sortOrder }))
            ).sort((a, b) => a.sortOrder - b.sortOrder);

            const defaultStatuses = uniqueByName(response.data
                .filter(item => item.filteringByDefault === true)
                .map(item => ({ id: item.id, stepName: item.stepName, sortOrder: item.sortOrder }))
            ).sort((a, b) => a.sortOrder - b.sortOrder);

            setStatusOptions(getStatusOptions(statusOptions))
            setSelectedStatusOptions(getStatusOptions(defaultStatuses))

            let searchObj = new GenericRequestFormSearchObject(props.formType);
            searchObj.searchPropertyIds = propertyOptions.map(x => x.value);
            searchObj.includeMimgTix = includeMimgTixByDefault;
            searchObj.requestTypeIds = filteredRequestTypes.map(x => x.id);

            if (defaultStatuses.length > 0) {
                searchObj.requestStatuses = response.data.filter(step =>
                    defaultStatuses.some(option => option.stepName === step.stepName)
                )
                    .map(x => x.id);
            }

            axios.post("api/GenericRequestForm/SearchRequests", searchObj).then((response) => {
                setRequestForms(response.data)
            }).catch((error) => {
                logError(new ErrorLogModel(error, "componentDidMount", "Error getting list of requests data from api/GenericRequestForm/GetRequestFormByName?formName=" + props.formType));

                let pageAlert = new CustomAlertModel();
                pageAlert.alertInfo = "There was an error loading the request forms.";
                pageAlert.alertColor = "danger";

                props.onUpdatePageAlert(pageAlert);
            })
        })
    }

    function getStepName(id) {
        const step = allSteps.find(step => step.id === id);

        return step ? step.stepName : null;
    }


    if (!requestForms || !props.security) {
        return <div>Loading...</div>;
    }
    if (requestTypeOptions.length === 0) {
        const links = [
            new CustomAlertLink(updateProfileUrl(true, props.security.empID), "You may try to reset your permissions by clicking this link, then looking for the Update My Permissions button near the top of the page.Then, reload this page."),
        ]
        return (
            <MimgAlert alertInfo={getUnauthorizedToViewPageAlertMessage(links)} />
        )
    }

    return (
        (this?.props?.formType === 'ITSetup' && !props.security.isITadmin) ? <NoAccessPage /> :
            <>
                <RequestListFilter
                    assigneeOptions={assigneeOptions}
                    propertyOptions={propertyOptions}
                    requestTypeOptions={requestTypeOptions}
                    statusOptions={statusOptions}
                    selectedStatusOptions={selectedStatusOptions}
                    clearFilters={clearFilters}
                    searchForms={searchForms}
                    searchFormsToCSV={searchFormsToCSV}
                    isAdmin={props.isAdmin}
                    formType={props.formType}
                    includeMimgPropsByDefault={includeMimgPropsByDefault}
                    allowMimgTix={allowMimgTix}
                />

                {searchAlert.AlertText !== "" &&
                    <MimgAlert alertInfo={searchAlert} />
                }

                {requestForms.map(p => {
                    return <div key={p.id}> <RequestListItem stepName={getStepName(p?.currentWorkflowStep)} requestInfo={p} propertyList={props.propertyList} formType={props.formType} /></div>
                })
                }
            </>
    )
}
