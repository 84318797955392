import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import Select from 'react-select'
import RequestDetail from './RequestDetail';
import styles from './RequestDetailEmailGroupPicker.module.scss';

export default function RequestDetailUnitAvailability(props) {
    const [unitAvailability, setUnitAvailability] = useState([]);
    const [propUnitList, setPropUnitList] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);

    useEffect(() => {
        axios.get("api/aptproperties/GetYardiUnitAvailability")
            .then((response) => {
                let allUnits = response.data.sort((a, b) => a.unitTypeDescription > b.unitTypeDescription ? 1 : -1)
                let allOptions = []
                allUnits.map(u => {
                    allOptions.push({ label: u.unitTypeDescription + " (" + u.unitTypeCode + ") - " + u.availableUnits + " out of " + u.totalUnits + " available" , value: u.unitTypeCode, siteId: u.empWebsiteId})
                })
                setUnitAvailability(allOptions)
            })
    }, [])

    useEffect(() => {

        let selectedProperty = props.selectedProperties[0]
        if (unitAvailability && unitAvailability.length > 0) {
            let unitsList = unitAvailability.filter(x => x.siteId == selectedProperty.siteId)
            setPropUnitList(unitsList)
        }
    }, [props.selectedProperties])


    function handleChange(options) {
        setSelectedUnits(options)
    }

    if (!propUnitList || !selectedUnits)
        return <div>Loading...</div>

    return (
        <div>
            <h6 className={styles.warning}>If you do not see a unit you want to include, add that to the Additional Information box at the bottom of the form.</h6>
            <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
                <Select
                    isClearable
                    isMulti
                    placeholder={props.RequestTypeDetail.placeholder}
                    options={propUnitList}
                    onChange={handleChange}
                    value={selectedUnits}
                    name='unitAvailabilityPicker' />
            </RequestDetail>
            
        </div>
    );
}
