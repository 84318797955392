import React, { useEffect, useState } from 'react'; 
import styles from './ITHelpdeskProcessing.module.scss';
import EmailAddressExistanceChecker from './EmailAddressExistanceChecker';
import YardiOneAccountAdd from './YardiOneAccountAdd';
import YardiSpecifySystems from './YardiSpecifySystems';
import YardiPayleaseAccountInfo from './YardiPayleaseAccountInfo';

export default function YardiProcessing(props) {
    const [email, setEmail] = useState("")
    const [user, setUser] = useState();
    const [emp, setEmp] = useState();
    const [selectedSystemOptions, setSelectedSystemOptions] = useState([]);

    useEffect(() => {
        let details = props.genericRequestFormModel.genericRequestFormDetails;
        let emailAddress = details.filter(x => x.detail.defaultDataPropName === "employeeEmail")[0]?.response
        setEmail(emailAddress);        
    }, [props.genericRequestFormModel.genericRequestFormDetails])

    useEffect(() => {
        let emp = props.genericRequestFormModel.genericRequestFormEmployees[0].propertyEmployees;
        if (emp) {
            setEmp(emp)
        }
    },[props.genericRequestFormModel.genericRequestFormModel])


    return (
        <div>
            <div className={styles.questionRow}>

                <EmailAddressExistanceChecker defaultEmail={email}
                    setEmail={setEmail}
                    setUser={setUser}
                    emp={emp}
                    genericRequestFormModel={props.genericRequestFormModel}
                    disabled={true} />
                    
            </div>

            {user
                ? <>
                    <YardiOneAccountAdd genericRequestFormModel={props.genericRequestFormModel} email={email} emp={emp} onAddComment={props.onAddComment} setModalAlert={props.setModalAlert} onAddCredential={props.onAddCredential} />
                    <YardiSpecifySystems genericRequestFormModel={props.genericRequestFormModel} setSelectedSystemOptions={setSelectedSystemOptions} onAddCredential={props.onAddCredential} onAddComment={props.onAddComment} />


                    {selectedSystemOptions?.filter(x => x.label === 'Paylease').length > 0 &&
                        <YardiPayleaseAccountInfo genericRequestFormModel={props.genericRequestFormModel} onAddCredential={props.onAddCredential} onAddComment={props.onAddComment} />
                    }
                 </>
                : <h5 className={`${styles.header} ${styles.marginTop10}`}>The email account does not exist and you will be unable to proceed with the setup at this time</h5>
            }



        </div>


    );
}