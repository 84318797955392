import React, { useState, useEffect } from 'react';
import { axios, Button, getNowMountainTime } from 'react-mimg';
import { Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import { createComment, getNextStepInfo } from '../../../../functions/formHelpers/genericRequestFormHelper';
import { getCurrentReferralBonusTotal, getCurrentSigningBonusTotal } from '../../../../functions/formHelpers/referralBonusFormHelper';
import { getEmployeeSecurity } from '../../../../functions/permissions';
import ReferralBonusDetail from './ReferralBonusDetail';
import styles from './referralBonusInfoModal.module.scss';

export default function ReferralBonusInfo(props) {
    let security = getEmployeeSecurity()

    const isBonusAdmin = security.isBonusAdmin;
    const [showModal, setShowModal] = useState(false);
    const [modalAlert, setModalAlert] = useState();
    const [submissionAlert, setSubmissionAlert] = useState();
    const [referralFirstPortion, setReferralFirstPortion] = useState(0);
    const [referralSecondPortion, setReferralSecondPortion] = useState(0);
    const [totalReferralAmount, setTotalReferralAmount] = useState(0);
    const [signingBonus, setSigningBonus] = useState();
    const [referralEmployeeCard, setReferralEmployeeCard] = useState()
    const [signingEmployeeCard, setSigningEmployeeCard] = useState()
    const [availablePayDates, setAvailablePayDates] = useState([])
    const hasReferralBonus = props.genericRequestFormModel?.requestType?.requestType1?.toLowerCase().includes('referral');
    const hasSigningBonus = props.genericRequestFormModel?.requestType?.requestType1?.toLowerCase().includes('signing');
    const [totalReferralBonusAmount, setTotalReferralBonusAmount] = useState();
    const [totalSigningBonusAmount, setTotalSigningBonusAmount] = useState();

    useEffect(() => {
        if (props.genericRequestFormModel.requestComplete === true) {
            setModalAlert({ color: "info", message: "This bonus request has been marked as complete. The below should be a reference as to what was paid" })
        } else if (!isBonusAdmin) {
            setModalAlert({ color: "info", message: "A status of 'Submitted' or a blank date means that Payroll has not yet reviewed/scheduled this bonus. A status of 'Scheduled' means that payroll is preparing to pay the bonus on the pay date nearest the inputted date. Please use the commenting functionality of this form to notify payroll of any needed changes or requests" })
        }
    }, [])

    useEffect(() => {
        setTotalReferralBonusAmount(getCurrentReferralBonusTotal(props.genericRequestFormModel))
        setTotalSigningBonusAmount(getCurrentSigningBonusTotal(props.genericRequestFormModel));
    }, [props.genericRequestformModel, props.genericRequestFormModel.genericRequestResponses])

    useEffect(() => {
        if (showModal) {
            let payDates = [];
            let payDate = new Date("02/23/2024");
            for (let i = 0; i < 500; i++) {
                payDates.push(payDate);
                payDate = payDate.addDays(14);
            }

            setAvailablePayDates(payDates) //this functionality is only for determining the approximate date for which someone will be paid.  Pay date will always be on a Friday except when it's a holiday, but I think it's fine as this is only used to prefill a value which can be overridden.
            axios.get(`api/payroll/GetReferralAndSigningBonuses?genericRequestId=${props.genericRequestFormModel.id}`).then(response => {

                axios.get(`api/PropertyEmployees/GetEmployeeCardByPrismId?prismId=${props.genericRequestFormModel?.genericRequestFormEmployees[1]?.prismId}`).then(response => { //nothing depends on this in the startup
                    setReferralEmployeeCard(response.data)
                })
                axios.get(`api/PropertyEmployees/GetEmployeeCardByPrismId?prismId=${props.genericRequestFormModel?.genericRequestFormEmployees[0]?.prismId}`).then(signResponse => { //we need to be able to get the pay date based on the new employee's start date, so get this first'
                    setSigningEmployeeCard(signResponse.data);

                    let ref1 = response.data?.filter(x => x.bonusType === 'Referral Portion 1')
                    if (ref1.length > 0) {
                        setReferralFirstPortion(ref1[0])
                    } else {
                        if (hasReferralBonus) {
                            var newReferral = {};
                            newReferral.genericRequestFormId = props.genericRequestFormModel.id;
                            newReferral.bonusType = "Referral Portion 1";
                            newReferral.prismId = props.genericRequestFormModel?.genericRequestFormEmployees[1]?.prismId;
                            newReferral.employeeName = props.genericRequestFormModel?.genericRequestFormEmployees[1]?.employeeName;
                            newReferral.propertyEmployeesId = props.genericRequestFormModel?.genericRequestFormEmployees[1]?.propertyEmployeesId
                            newReferral.amount = getReferralFirstPortionDefaultAmount(getCurrentReferralBonusTotal(props.genericRequestFormModel));
                            newReferral.status = "Submitted";
                            newReferral.payDate = getFirstEligiblePayDate(30, signResponse?.data?.lastHireDate, payDates);
                            newReferral.referralAndSigningBonusDetails = [];

                            setReferralFirstPortion(newReferral)
                        }
                    }

                    let ref2 = response.data?.filter(x => x.bonusType === 'Referral Portion 2')
                    if (ref2.length > 0) {
                        setReferralSecondPortion(ref2[0])
                    } else {
                        if (hasReferralBonus) {
                            var newReferral = {};
                            newReferral.genericRequestFormId = props.genericRequestFormModel.id;
                            newReferral.bonusType = "Referral Portion 2";
                            newReferral.prismId = props.genericRequestFormModel?.genericRequestFormEmployees[1]?.prismId;
                            newReferral.employeeName = props.genericRequestFormModel?.genericRequestFormEmployees[1]?.employeeName;
                            newReferral.propertyEmployeesId = props.genericRequestFormModel?.genericRequestFormEmployees[1]?.propertyEmployeesId
                            newReferral.amount = getReferralSecondPortionDefaultAmount(getCurrentReferralBonusTotal(props.genericRequestFormModel));
                            newReferral.status = "Submitted";
                            newReferral.payDate = getFirstEligiblePayDate(90, signResponse?.data?.lastHireDate, payDates);
                            newReferral.referralAndSigningBonusDetails = [];
                            setReferralSecondPortion(newReferral)
                        }
                    }

                    var signing = response.data.filter(x => x.bonusType === 'Signing');
                    if (signing.length > 0) {
                        setSigningBonus(signing[0])
                    } else {
                        if (hasSigningBonus) {
                            var newSigning = {};
                            newSigning.genericRequestFormId = props.genericRequestFormModel.id;
                            newSigning.bonusType = "Signing";
                            newSigning.prismId = props.genericRequestFormModel?.genericRequestFormEmployees[0]?.prismId;
                            newSigning.employeeName = props.genericRequestFormModel?.genericRequestFormEmployees[0]?.employeeName;
                            newSigning.propertyEmployeesId = props.genericRequestFormModel?.genericRequestFormEmployees[0]?.propertyEmployeesId
                            newSigning.amount = getCurrentSigningBonusTotal(props.genericRequestFormModel);
                            newSigning.payDate = getFirstEligiblePayDate(90, signResponse?.data?.lastHireDate, payDates)
                            newSigning.status = "Submitted";
                            newSigning.referralAndSigningBonusDetails = [];
                            setSigningBonus(newSigning)
                        }
                    }
                })
            })

        }
    }, [showModal, props.genericRequestFormModel])


    useEffect(() => {
        setTotalReferralAmount(parseInt(referralFirstPortion.amount) + parseInt(referralSecondPortion.amount));
    }, [referralFirstPortion, referralSecondPortion])

    function getReferralFirstPortionDefaultAmount(totalReferralBonus) {
        //1st portion is 1/3 of the total (rounded up to the nearest $10 for some reason)
        return Math.round((totalReferralBonus / 3) / 10) * 10
    }

    function getReferralSecondPortionDefaultAmount(totalReferralBonus) {
        //2nd portion is 2/3 of the total, paid by the property.  
        let firstPortion = getReferralFirstPortionDefaultAmount(totalReferralBonus);
        return totalReferralBonus - firstPortion;
    }

    function getFirstEligiblePayDate(daysAfterHire, newEmpStartDate, payDates) { //1st portion of referral bonus can be paid at this point
        let hireDate = new Date(newEmpStartDate);
        let eligibleDate = hireDate.addDays(daysAfterHire)
        return payDates.filter(x => x >= eligibleDate)[0]
    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    function onUpdateBonus(bonus) {
        if (bonus?.bonusType === "Referral Portion 1") {
            setReferralFirstPortion(bonus);
        } else if (bonus?.bonusType === "Referral Portion 2") {
            setReferralSecondPortion(bonus);
        } else if (bonus?.bonusType === "Signing") {
            setSigningBonus(bonus);
        }
    }

    function markAsScheduled() {
        let validationMessage = getValidationMessage();
        if (validationMessage === "") {
            setSubmissionAlert()
            let genericRequestForm = props.genericRequestFormModel;
            let genericRequestComment = createComment(security.empID, "Payments Scheduled.  Press the 'Bonus Determination' button for more info")
            genericRequestForm.genericRequestFormComments.push(genericRequestComment);

            let requestTypeId = genericRequestForm.requestTypeId;
            let workflow = props.requestTypes?.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Bonus Processing Info')[0].id;
            genericRequestForm.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);
            genericRequestForm.requestStatus = 'Payouts Scheduled';
            genericRequestForm.requestStatusUpdatedByEmpId = security.empID;
            genericRequestForm.requestStatusUpdatedByEmpName = security.employeeFullName;
            genericRequestForm.requestStatusUpdatedDateTime = getNowMountainTime();
            genericRequestForm.requestStatusUpdatedByPosition = security.employeePosition;

            axios.put('api/GenericRequestForm/UpdateRequest', genericRequestForm).then((res) => {
                let genericRequestFormModel = res.data;
                let pageAlert = { AlertText: "The bonus was successfully marked as scheduled for payment. " }
                props.onSave(genericRequestFormModel, pageAlert, "Payouts Scheduled");
                setShowModal(false);
            })
        } else {
            setSubmissionAlert({ color: "danger", message: validationMessage });
        }
    }

    function getValidationMessage() {
        let msg = "";


        if (hasReferralBonus) { //need to have a first and a second portion, and amounts need to match to the total amount
            if (!referralFirstPortion || referralFirstPortion === 0 || referralFirstPortion.status === "Submitted")
                msg += "First Portion of Referral Bonus Has Not Been Properly Set. The bonus cannot be marked as scheduled if the status is set to 'Submitted'. "

            if (!referralSecondPortion || referralSecondPortion === 0 || referralSecondPortion.status === "Submitted")
                msg += "Second Portion of Referral Bonus has not been properly set. The bonus cannot be marked as scheduled if the status is set to 'Submitted'. If there is no second portion, mark as 'Not Applicable'. ";

            if (referralFirstPortion?.amount > 0 && referralSecondPortion?.amount > 0) {
                if (referralFirstPortion?.amount + referralSecondPortion?.amount !== parseInt(totalReferralBonusAmount))
                    msg += "The total referral bonus between the first and second portion does not equal the total referral bonus."
            }
        }

        if (hasSigningBonus) {
            if (!signingBonus || signingBonus === 0 || signingBonus.status === "Submitted")
                msg += "Signing Bonus has not been properly set.The bonus cannot be marked as scheduled if the status is set to 'Submitted'. If there is no signing bonus, mark as 'Not Applicable' "

            if (signingBonus && signingBonus?.amount !== parseInt(totalSigningBonusAmount))
                msg += "The total signing bonus amount entered does not match the amount to be paid out"
        }

        return msg;

    }

    return (
        <div>

            <Button onClick={() => setShowModal(true)}>Bonus Determination</Button>

            <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Referral Bonus Processing</ModalHeader>

                <ModalBody>
                    {modalAlert &&
                        <Alert color={modalAlert.color}>{modalAlert.message}</Alert>
                    }
                    {hasReferralBonus && referralFirstPortion && referralSecondPortion && referralEmployeeCard && signingEmployeeCard &&
                        <div className={styles.bonusWrapper}>
                            <h4>Referral Bonus</h4>
                            <div className={styles.flexRow}>
                                <div className={styles.flex3}>
                                    Employee Name: <span className={styles.employeeName}>{referralEmployeeCard?.employeeName}</span><br />
                                    PrismId: {referralEmployeeCard?.prismId}<br />
                                    Home Property: {referralEmployeeCard?.homeProperty}<br />
                                    Job Title: {referralEmployeeCard?.officialJobTitle}<br />
                                    Total Amount: {totalReferralBonusAmount}
                                    {totalReferralAmount !== parseInt(totalReferralBonusAmount) &&
                                        <span className={styles.warning}> -- 1st + 2nd Bonus Amounts Do Not Match Total Requested {totalReferralAmount}</span>
                                    }<br />

                                </div>
                                <div className={styles.flex3}>
                                    New Employee Name: <span className={styles.employeeName}>{signingEmployeeCard?.employeeName}</span><br />
                                    PrismId: {signingEmployeeCard?.prismId}<br />
                                    Home Property: {signingEmployeeCard?.homeProperty}<br />
                                    Job Title: {signingEmployeeCard?.officialJobTitle}<br />
                                    Total Amount: {totalSigningBonusAmount}<br />

                                </div>
                                <div className={styles.flex1}>
                                    <b>Dates are for the New Hire</b><br />
                                    Hire Date: {new Date(signingEmployeeCard?.lastHireDate).toLocaleDateString()}<br />
                                    30 Days:  {new Date(signingEmployeeCard?.lastHireDate).addDays(30).toLocaleDateString()}<br />
                                    60 Days: {new Date(signingEmployeeCard?.lastHireDate).addDays(60).toLocaleDateString()}<br />
                                    90 Days: {new Date(signingEmployeeCard?.lastHireDate).addDays(90).toLocaleDateString()}<br />
                                </div>
                            </div>
                            <div className={styles.flexRow}>
                                <div className={styles.flex1}>
                                    <ReferralBonusDetail bonus={referralFirstPortion} employeeCard={signingEmployeeCard} header="First Portion" propertyList={props.propertyList} onUpdateBonus={onUpdateBonus} genericRequestFormModel={props.genericRequestFormModel} />
                                </div>
                                <div className={styles.flex1}>
                                    <ReferralBonusDetail bonus={referralSecondPortion} employeeCard={signingEmployeeCard} header="Second Portion" propertyList={props.propertyList} onUpdateBonus={onUpdateBonus} genericRequestFormModel={props.genericRequestFormModel} />
                                </div>
                            </div>
                        </div>
                    }

                    {hasSigningBonus && signingBonus && signingEmployeeCard &&
                        <div className={styles.bonusWrapper}>
                            <h4>Signing Bonus</h4>
                            <div className={styles.flexRow}>
                                <div className={styles.flex3}>
                                    Employee Name: <span className={styles.employeeName}>{signingEmployeeCard?.employeeName}</span><br />
                                    PrismId: {signingEmployeeCard?.prismId}<br />
                                    Home Property: {signingEmployeeCard?.homeProperty}<br />
                                    Job Title: {signingEmployeeCard?.officialJobTitle}<br />
                                    Total Amount: {totalSigningBonusAmount}<br />
                                </div>
                                <div className={styles.flex1}>
                                    <b>Dates are for the New Hire</b>
                                    Hire Date: {new Date(signingEmployeeCard?.lastHireDate).toLocaleDateString()}<br />
                                    30 Days:  {new Date(signingEmployeeCard?.lastHireDate).addDays(30).toLocaleDateString()}<br />
                                    60 Days: {new Date(signingEmployeeCard?.lastHireDate).addDays(60).toLocaleDateString()}<br />
                                    90 Days: {new Date(signingEmployeeCard?.lastHireDate).addDays(90).toLocaleDateString()}<br />
                                </div>
                            </div>
                            <ReferralBonusDetail bonus={signingBonus} employeeCard={signingEmployeeCard} header="Signing Bonus" propertyList={props.propertyList} onUpdateBonus={onUpdateBonus} genericRequestFormModel={props.genericRequestFormModel} />
                        </div>
                    }
                </ModalBody >

                <ModalFooter>
                    {submissionAlert &&
                        <Alert color={submissionAlert.color}>{submissionAlert.message}</Alert>
                    }
                    <Button transparent onClick={() => setShowModal(false)}>Close</Button>
                    {isBonusAdmin && !props.genericRequestFormModel.requestComplete && !props.genericRequestFormModel.requestRejected &&
                        <Button onClick={() => markAsScheduled()}>Mark Scheduled</Button>
                    }
                </ModalFooter>
            </Modal >
        </div >

    );
}