import React, { useState, useRef } from 'react';
import { axios, Button } from 'react-mimg';
import { Alert, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';
import styles from '../actionbuttons/itsetup/sendtodepartmentinfo/ITHelpdeskProcessing.module.scss';
import RequestRichInput from './RequestRichInput';
import { uploadImage } from './UploadImage';

export default function SentExternally(props) {
    const [showModal, setShowModal] = useState(false);
    const [modalAlert, setModalAlert] = useState({ AlertColor: "info", AlertText: "Use this functionality to note that the ticket was escalated to a 3rd party. Note that nothing is sent to the third party automatically and you will need to communicate the issue with them outside of this system." });
    const [externalVendorName, setExternalVendorName] = useState(props.genericRequestFormModel.relatedToFormName === 'Yardi' ? 'Yardi' : 'Paychex');
    const [comment, setComment] = useState('');
    const [processing, setProcessing] = useState(false);
    const quillRef = useRef();

    async function markSentToVendor() {
        setProcessing(true)
        let genericRequestFormModel = props.genericRequestFormModel;

        let updatedComment = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : comment;

        const imageRegex = /<img[^>]+src="([^">]+)"/g;
        const images = [];
        let match;
        while ((match = imageRegex.exec(updatedComment)) !== null) {
            images.push(match[1]);
        }

        for (let i = 0; i < images.length; i++) {
            const imageUrl = images[i];
            const response = await uploadImage(imageUrl, genericRequestFormModel.id);
            updatedComment = updatedComment.replace(imageUrl, response.data.url);
        }

        let newComment = createComment(genericRequestFormModel.requesterEmpId, `<b>Request Sent To ${externalVendorName}.</b><br />` + updatedComment);
        genericRequestFormModel.genericRequestFormComments.push(newComment);
        genericRequestFormModel.requestStatus = props.genericRequestFormModel.relatedToFormName === 'Yardi' ? "Sent To Yardi" : "Sent to External Vendor";

        let requestTypeId = genericRequestFormModel.requestTypeId;
        let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0]?.approvalWorkflowNavigation;
        let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Sent to External Vendor')[0].id;
        genericRequestFormModel.currentWorkflowStep = currentStepId;

        axios.put("api/GenericRequestForm/UpdateRequest?updateAction=SentToYardi", genericRequestFormModel).then((response) => {
            genericRequestFormModel = response.data;

            let pageAlert = {}
            pageAlert.AlertColor = "success";
            pageAlert.AlertText = `The request was successfully marked as having been sent to ${externalVendorName}.`;

            props.onSave(genericRequestFormModel, pageAlert, `Sent To ${externalVendorName}`);
            setProcessing(false);
            setShowModal(false);

        }).catch((error) => {
            let modalAlert = {};
            modalAlert.AlertColor = "danger";
            modalAlert.AlertText = `Error marking the request as having been sent to ${externalVendorName}.`;
            setModalAlert(modalAlert);
            setProcessing(false);
        });
    }

    return (
        <div>
            <Button onClick={() => setShowModal(true)}>Mark Sent To External Vendor</Button>
            <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)} size={props.formPreferences.richTextComments ? "lg" : undefined}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Mark as Sent to Yardi</ModalHeader>
                <ModalBody>
                    {modalAlert &&
                        <Alert color={modalAlert.AlertColor}>{modalAlert.AlertText}</Alert>
                    }
                    <Form>
                        <FormGroup>
                            <Label for="ddlSentToWho">Select 3rd Party - if not found, mark as Other External Vendor and note name in the comment below</Label>
                            <Input type="select" value={externalVendorName} onChange={(e) => setExternalVendorName(e.target.value)} disabled={props.genericRequestFormModel.relatedToFormName === 'Yardi' }>
                                <option id="yardi" value="Yardi">Yardi</option>
                                <option id="paychex" value="Paychex">Paychex</option>
                                <option id="ukg" value="UKG">UKG</option>
                                <option id="other" value="Other External Vendor">Other External Vendor (name in comments)</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="textAreaNewComment">Comment (optional):</Label>
                            {props.formPreferences.richTextComments ? (
                                <RequestRichInput r={quillRef} />
                            ) : (
                                <Input className={styles.commentBox} type="textarea" id="textAreaNewComment" name="textAreaNewComment" value={comment} onChange={(e) => setComment(e.target.value)} />
                            )}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button transparent onClick={() => setShowModal(false)}>Close</Button>
                    <Button onClick={markSentToVendor} disabled={processing}>
                        {processing
                            ? <span>Processing</span>
                            : <span>Mark Sent to Vendor</span>
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
