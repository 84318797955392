import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import CreatableSelect from 'react-select/creatable'
import RequestDetail from './RequestDetail';

export default function RequestDetailJobDisplayNamePicker (props) {

    const [selectedOption, setSelectedOption] = useState()
    const [jobList, setJobList] = useState([])
    const [jobOptions, setJobOptions] = useState([])

    useEffect(() => {
        axios.get("api/MonarchPositionsByDepartment/GetActiveJobTitles")
            .then((response) => {
                let options = []
                let jobTitles = response.data.filter(job => job.isObsolete === false).sort((a, b) => a.displayName > b.displayName ? 1 : -1);
                jobTitles.forEach(job => {
                    if (!options.find(({ value }) => value === job.displayName)) {
                        options.push({ value: job.displayName, label: job.displayName })
                    }
                })
                setJobOptions(options);
            })
    }, [props.selectedProperties])


    function handleChange(option) {
        setSelectedOption(option)
    }

    if (!jobOptions)
        return <div>Loading...</div>

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
            <CreatableSelect
                isClearable
                placeholder={props.RequestTypeDetail.placeholder}
                options={jobOptions}
                onChange={handleChange}
                value={selectedOption}
                name='jobPicker'
            />
        </RequestDetail>
    );
}
