import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import CreatableSelect from 'react-select/creatable'
import RequestDetail from './RequestDetail';

export default function RequestDetailEmployeePositionGroupPicker (props) {

    const [selectedOption, setSelectedOption] = useState()
    const [jobOptions, setJobOptions] = useState([])

    useEffect(() => {
        axios.get("api/EmployeePosition/GetEmployeePositions")
            .then((response) => {
                let options = []
                let jobTitles = response.data.sort((a, b) => a.position > b.position ? 1 : -1);
                jobTitles.forEach(job => {
                    options.push({ value: job.position, label: job.position })
                })                
                setJobOptions(options);
            })
    }, [])


    function handleChange(option) {
        setSelectedOption(option)
    }

    if (!jobOptions)
        return <div>Loading...</div>

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
            <CreatableSelect
                isClearable
                placeholder={props.RequestTypeDetail.placeholder}
                options={jobOptions}
                onChange={handleChange}
                value={selectedOption}
                name='jobPicker'
            />
        </RequestDetail>
    );
}
