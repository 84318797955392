import React, { useState, useEffect } from 'react';
import { axios, Button, ErrorLogModel, logError } from 'react-mimg';
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { MimgAlert } from '../../layout/MimgAlert';
import validator from 'validator';
import styles from './RequestModals.module.scss';


export default function WhoAreTheAdminsModal(props) {

    const [modalAlert, setModalAlert] = useState();
    const [adminList, setAdminList] = useState([]);
    const [distinctAdmins, setDistinctAdmins] = useState([])

    useEffect(() => {
        if (props.isOpen) {
            axios.get('api/RequestTypes/GetAllAdminsForRequestType?requestTypeId=' + props.requestType?.id).then(response => {
                setAdminList(response.data)

                let distinctAdmins = []
                for (let i = 0; i < response.data.length; i++) {
                    let item = response.data[i]
                    if (distinctAdmins.filter(x => x.propertyEmployeesId === item.propertyEmployeesId).length === 0) {
                        let newObj = {}
                        newObj.propertyEmployeesId = item.propertyEmployeesId;
                        newObj.empName = item.firstName + ' ' + item.lastName;
                        newObj.empPosition = item.position;
                        newObj.isRequestTypeAdmin = response.data.filter(x => x.propertyEmployeesId === item.propertyEmployeesId && x.adminType === "Request Type Admin").length > 0;
                        newObj.isAssignedRequests = response.data.filter(x => x.propertyEmployeesId === item.propertyEmployeesId && x.adminType === "Assigned Requests").length > 0;
                        newObj.deptAreFormAdmins = response.data.filter(x => x.propertyEmployeesId === item.propertyEmployeesId && x.adminType === "Form Admin Group Member").length > 0;

                        distinctAdmins.push(newObj);
                    }
                }

                setDistinctAdmins(distinctAdmins);
            })
        }
    }, [props.isOpen])

    return (
        <div>
            <Modal size="lg" isOpen={props.isOpen} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}>{props.requestType?.requestType1} Admin Info</ModalHeader>

                {modalAlert &&
                    <MimgAlert alertInfo={modalAlert} />
                }

                <ModalBody>
                    Those listed are the various "Admins" or "Assignees" for the request type.  In most cases, any of these people can take admin actions and see 'Admin Only' comments for this request
                    <div className={`${styles.flexRow} ${styles.headers}`}>
                        <div className={styles.flex2}>Employee Name</div>
                        <div className={styles.flex2}>Position Group</div>
                        <div className={styles.flex1}>Request Type Admin</div>
                        <div className={styles.flex1}>Assigned Requests</div>
                        <div className={styles.flex1}>Position All Are Admins</div>
                    </div>
                    <div className={styles.distinctAdminList}>
                        {distinctAdmins && distinctAdmins.map((a, index) => {
                            return <div key={index} className={styles.flexRow}>
                                <div className={styles.flex2}>{a.empName}</div>
                                <div className={styles.flex2}>{a.empPosition}</div>
                                <div className={`${styles.flex1} ${styles.textAlignMiddle}`}>{a.isRequestTypeAdmin ? 'x' : ''}</div>
                                <div className={`${styles.flex1} ${styles.textAlignMiddle}`}>{a.isAssignedRequests ? 'x' : ''}</div>
                                <div className={`${styles.flex1} ${styles.textAlignMiddle}`}>{a.deptAreFormAdmins ? 'x' : ''}</div>
                            </div>
                        })}
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={props.toggle}>Done</Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}