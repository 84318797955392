import React from "react";
import { Link } from 'react-router-dom'
import GenericCard from "../../Cards/GenericCard";
import styles from './requestCardForHistory.module.scss';

export default function RequestCardForHistory(props) {
    let request = props.request;

    return (
        <GenericCard header={request.title} headerLink={request.link} target="_blank">
            <div>
                {props.genericRequestFormModel.id === request.id &&
                    <span className={styles.currentRequest}>Current Request<br /></span>
                }
                <b>Request for: </b>{request.requestFor}<br />
                <b>Requested by: </b><br />
                <span><Link to={'/profiles/employee?id=' + request.requestedBy.propertyEmployeesId} target="_blank" rel="noopener noreferrer">
                    {request.requestedBy.employeeName}
                </Link> on {new Date(request.requestDateTime).toLocaleDateString()}</span><br />
                <b>Status: </b>{request.status} <br />
                <b>Last Action By: </b>{request.lastActionBy}<br />
                <span dangerouslySetInnerHTML={{ __html: request.keyDetails }}></span>
            </div>
        </GenericCard>
    )
}