

export class GenericRequestFormDetailsModel {

    constructor() { //link is any object that gets passed in and has these properties

        this.formId = 0;
        this.detailId = 0;
        this.response = "";
        this.isEncrypted = false;
    }

}
