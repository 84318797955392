import React, { useEffect, useState } from 'react';
import { Button, axios, getNowMountainTime } from 'react-mimg';
import { Form, Modal, ModalBody, ModalHeader, Alert, Label, Input } from 'reactstrap';
import { createComment, getNextStepInfo } from '../../../../functions/formHelpers/genericRequestFormHelper';
import { getEmployeeSecurity } from '../../../../functions/permissions';
import styles from './UploadMvrModal.module.scss';
import ClipLoader from "react-spinners/ClipLoader";
import ToggleSwitch from '../../../profiles/PropertyProfile/Maintenance/ToggleSwitch';

export default function RemoveDriver(props) {

    const security = getEmployeeSecurity();
    const [showModal, setShowModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [sendEmailToEpic, setSendEmailToEpic] = useState(true);
    const [defaultEmailBody, setDefaultEmailBody] = useState("");
    const [modalAlert, setModalAlert] = useState({
        color: "info", message: "ONLY USE THIS FORM IF YOU ARE CONFIRMING THAT THE EMPLOYEE WILL NO LONGER BE AUTHORIZED TO DRIVE MONARCH VEHICLES. If the person will continue to be authorized, please close this popout and use the 'REJECT' button."
    });

    useEffect(() => {
        if (showModal) {
            axios.get('api/PropertyEmployees/GetSingleEmployeeCard?PropertyEmployeesId=' + props.genericRequestFormModel?.genericRequestFormEmployees[0]?.propertyEmployeesId).then(response => {
                axios.get('api/DriversLicense/GetDriverByPropertyEmployeesId?PropertyEmployeesId=' + props.genericRequestFormModel?.genericRequestFormEmployees[0]?.propertyEmployeesId).then(response2 => {
                    setDefaultEmailBody(getDefaultEmailBody(response.data, response2.data))
                })
            })
        }
    }, [showModal])

    function getDefaultEmailBody(employeeCard, driverRecord) {
        let reasonDetail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_REASON_REMOVAL))[0];

        return `Monarch Investment has removed a driver from our authorized drivers list. The driver below is no longer authorized to drive Monarch vehicles.  This is not necessarily the result of an employee termination.

Driver Name (Per License): ${employeeCard?.employeeName} 
Job Title: ${employeeCard?.title}
Home Property: ${employeeCard?.homeProperty}
License Number: ${driverRecord?.licenseNumber}
Date Of Birth: ${new Date(employeeCard?.birthdate).toLocaleDateString()}

This email was sent from a Driver Removal request form entered on the Employee Website`
    }

    function onCompleteRequest(e) {
        e.preventDefault();
        setProcessing(true);

        let genericRequestFormModel = JSON.parse(JSON.stringify(props.genericRequestFormModel));
        let deauthorizeDriverRequestModel = {};
        deauthorizeDriverRequestModel.requestForm = genericRequestFormModel;
        deauthorizeDriverRequestModel.notes = e.target.deauthDriverComment.value;
        deauthorizeDriverRequestModel.sendEmailToEpic = sendEmailToEpic;
        deauthorizeDriverRequestModel.emailToEpicBody = e.target.removeDriverEmailToEpic?.value;

        //api call to add the driver to the drivers table list, which should also copy the used MVR and Drivers License documents to the EmployeeDocuments table.
        axios.post('api/DriversLicense/DeauthorizeEmployeeDriver', deauthorizeDriverRequestModel).then(response => {
            genericRequestFormModel.genericRequestFormComments.push(createComment(security.empID, e.target.deauthDriverComment.value));

            let requestTypeId = genericRequestFormModel.requestTypeId;
            let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Remove Driver')[0].id;
            genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);
            genericRequestFormModel.requestStatus = 'Completed'
            genericRequestFormModel.requestComplete = true;
            genericRequestFormModel.requestCompletedByEmpId = security.empID;
            genericRequestFormModel.requestCompletedByEmpName = security.employeeFullName;
            genericRequestFormModel.requestCompletedDateTime = getNowMountainTime();
            genericRequestFormModel.requestCompletedByEmpPosition = security.employeePosition;

            axios.put('api/GenericRequestForm/UpdateRequest?updateAction=deauthDriver', genericRequestFormModel).then((res) => {
                let genericRequestForm = res.data;
                let pageAlert = { AlertText: "The request to deauthorize the driver from driving Monarch vehicles has been completed and the employee has been removed from the list of approved drivers. A notification was sent to the requester.", AlertColor: "success" }

                props.onSave(genericRequestForm, pageAlert, "Remove Driver")
                setShowModal(false);
                setProcessing(false);
            })
        })
    }

    return (
        <div>

            <Button onClick={() => setShowModal(true)}>Remove Driver</Button>
            <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Confirm Driver Deauthorization</ModalHeader>

                <ModalBody>
                    {modalAlert &&
                        <Alert color={modalAlert.color}>{modalAlert.message}</Alert>
                    }
                    <Form onSubmit={onCompleteRequest}>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="deauthDriverComment">Additional Comments Beyond Reason for Removal (optional, any comments will be visible to anyone that can access the form)</Label>
                            <Input type="textarea" name="deauthDriverComment" id="deauthDriverComment" placeholder="Add any additional info beyond the reason supplied by the form submittor" defaultValue="Driver removal has been confirmed. " />
                        </div>
                        <div className={styles.marginBetweenQuestions}>
                            <ToggleSwitch className={styles.prompt} label="Send Email To EPIC" checked={sendEmailToEpic} onChange={() => setSendEmailToEpic(!sendEmailToEpic)} />
                            {sendEmailToEpic &&
                                <Input type="textarea" rows="10" name="removeDriverEmailToEpic" id="removeDriverEmailToEpic" defaultValue={defaultEmailBody} placeholder="This text will be sent to Epic in an email" />
                            }
                        </div>
                        <div className={styles.buttonsOnRight}>
                            <Button transparent onClick={() => setShowModal(false)}>Close</Button> {"  "}
                            <Button type="submit" disabled={processing} className={styles.submitButton}>
                                {processing
                                    ? <div><ClipLoader size={25} /></div>
                                    : <>Deauthorize Driver</>
                                }
                            </Button> {"  "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}