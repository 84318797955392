import 'bootstrap/dist/css/bootstrap.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './styles/global.scss';
import './styles/Flex.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { unregister } from './registerServiceWorker';
import { ErrorBoundary } from './pages/ErrorHandling/ErrorBoundary'
import PageView from './PageView'
import { CookiesProvider } from 'react-cookie';
import { AxiosInterceptor } from 'react-mimg';
import App from './App';
import Login from './pages/Login/Login';
import ResetPassword from './pages/Login/ResetPassword';
import Register from './pages/Login/Register';
import { AuthProvider } from './hooks/useAuth';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ErrorBoundary>
            <CookiesProvider >
                <AxiosInterceptor>
                    <AuthProvider>
                        <PageView />
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/resetPassword" element={<ResetPassword />} />
                            <Route path="/register" element={<Register />} />
                            <Route path='*' element={<App />} />
                        </Routes>
                    </AuthProvider>
                </AxiosInterceptor >
            </CookiesProvider>
        </ErrorBoundary>
    </BrowserRouter >,
    rootElement);

unregister();


