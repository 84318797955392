/* eslint-disable no-eval*/
import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap'
import RequestSubmitButton from './RequestSubmitButton';
import flexStyles from '../../../styles/flex.module.scss';
import styles from './GenericRequestForm.module.scss';
import Assign from '../actionbuttons/Assign';
import RequestInfoNeeded from '../actionbuttons/RequestInfoNeeded';
import RequestComment from '../actionbuttons/RequestComment';
import ApproveDriver from '../actionbuttons/drivers/ApproveDriver';
import ApproveDriversLicenseUpdate from '../actionbuttons/drivers/ApproveDriversLicenseUpdate';
import UploadMvr from '../actionbuttons/drivers/UploadMvr';
import RemoveDriver from '../actionbuttons/drivers/RemoveDriver';
import ReferralBonusInfo from '../actionbuttons/payroll/ReferralBonusInfo';
import RequestRejection from '../actionbuttons/RequestRejection';
import UpdateBonusAmounts from '../actionbuttons/payroll/UpdateBonusAmounts';
import SendToDepartment from '../actionbuttons/itsetup/SendToDepartment';
import SentExternaly from '../actionbuttons/SentExternally';
import ViewEmails from '../actionbuttons/ViewEmails';
import AddRequestCredential from '../actionbuttons/AddRequestCredential';
import RequestCompletion from '../actionbuttons/RequestCompletion';
import RequestApproval from '../actionbuttons/RequestApproval';
import RequestDocument from '../actionbuttons/RequestDocument';
import RequestReadyForReview from '../actionbuttons/RequestReadyForReview';
import RequestReadyForApproval from '../actionbuttons/RequestReadyForApproval';
import { getEmployeeSecurity, isDeveloper } from '../../../functions/permissions';
import SentExternally from '../actionbuttons/SentExternally';

export default function RequestFormActionButtons(props) {

    const security = getEmployeeSecurity();
    const workflow = props.selectedRequestType?.approvalWorkflowNavigation;
    const [actionButtonAvailability, setActionButtonAvailability] = useState(false);

    useEffect(() => {
        let buttonAvailability = {};
        let currentWorkflowStep = getCurrentWorkflowStep();

        let employeeList = props.availableAssignees.map(pe => pe.propertyEmployeesId);
        let isAdmin = props.isAdmin || employeeList.includes(parseInt(security.empID)) || //this probably needs to be done in the corporate services one
            (props.filteredRequestTypes && [].concat(...props.filteredRequestTypes?.map(x => x.requestTypeAdmins))?.map(admin => admin.adminEmpId.toString()).includes(security.empID));
        //anyone who is an admin for ANY requestType will be considered an assignee - this is not great design but we need to rewrite a lot to make it scalable between requestTypes


        if (props.genericRequestFormModel.requestStatus === "Completed") {
            buttonAvailability.canAddComment = getShowCommentOrDocumentButton(isAdmin, workflow.allowCommentsAfterCompletion)
            buttonAvailability.canAddAttachment = getShowCommentOrDocumentButton(isAdmin, workflow.allowDocumentsAfterCompletion)
        } else if (props.genericRequestFormModel.requestStatus === "Rejected") {
            buttonAvailability.canAddComment = getShowCommentOrDocumentButton(isAdmin, workflow.allowCommentsAfterRejection)
            buttonAvailability.canAddAttachment = getShowCommentOrDocumentButton(isAdmin, workflow.allowDocumentsAfterRejection)
        } else {
            //Always show the button for the current step.  These must match to RequestWorkflowSteps.AssociatedButton
            buttonAvailability = setButtonAvailabilityByName(buttonAvailability, currentWorkflowStep, isAdmin)

            //if the person is an admin and we want downstream buttons to also show, do that
            let remainingSteps = workflow.requestWorkflowSteps.filter(x => x.sortOrder > currentWorkflowStep.sortOrder); //these should already be sorted when they come out of the controller
            if (isAdmin && currentWorkflowStep?.showDownstreamButtons) {
                for (let i = 0; i < remainingSteps.length; i++) {
                    buttonAvailability = setButtonAvailabilityByName(buttonAvailability, remainingSteps[i], isAdmin);
                }
            }

            //any previously completed steps marked as showUntilFinalized can reappear here
            for (let j = 0; j < workflow.requestWorkflowSteps.length; j++) {
                let step = workflow.requestWorkflowSteps[j];
                if (step.showUntilFinalized && step.sortOrder <= currentWorkflowStep.sortOrder) {
                    buttonAvailability = setButtonAvailabilityByName(buttonAvailability, workflow.requestWorkflowSteps[j], isAdmin);
                }
            }

            //For the non-workflow buttons (Comment, Attachment, and Reject), show or hide those buttons based on flags associated in RequestWorkflowSteps
            buttonAvailability.canAddComment = currentWorkflowStep.showCommentButton;
            buttonAvailability.canAddAttachment = currentWorkflowStep.showAttachDocsButton;

            //allow admins to complete the form at any time
            let completeSteps = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === "Complete");
            if (isAdmin && currentWorkflowStep.associatedButtonName !== "Submit" && completeSteps.length > 0) {
                buttonAvailability.canCompleteForm = true;
            }

            let readyForReviewSteps = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === "Ready For Review");
            // For Mark Ready For Review, not sure why it was done that way for VendorSetup, but for all other types, it should only show during the Ready For Review step
            if (isAdmin && props.formType.toLowerCase() === "VendorSetup".toLowerCase() && currentWorkflowStep.associatedButtonName !== "Submit" && props.genericRequestFormModel.requestStatus !== "Complete" && props.genericRequestFormModel.requestStatus !== "Submit Data" && readyForReviewSteps !== null) {
                buttonAvailability.canMarkReadyForReview = true;
            }
            if (isAdmin && props.formType.toLowerCase() !== "VendorSetup".toLowerCase() && currentWorkflowStep.associatedButtonName === 'Ready for Review') {
                buttonAvailability.canMarkReadyForReview = true;
            }

            //Show Reject Button
            if (isAdmin && currentWorkflowStep.showRejectButton) {
                buttonAvailability.canRejectForm = true;
            }

            //If formId is 0, then the form hasn't yet been submitted and these buttons should not be available.
            if (props.genericRequestFormModel.id === 0) {
                buttonAvailability.canAddAttachment = false;
                buttonAvailability.canAddComment = false;
                buttonAvailability.canRejectForm = false;
            }

            if (props.genericRequestFormModel.relatedToFormName === "Payroll" && props.genericRequestFormModel.requestComplete === true) {
                buttonAvailability.showBonusProcessingModal = true;
            }

            if (props.genericRequestFormModel.relatedToFormName === "Payroll" && props.genericRequestFormModel.requestRejected === true) {
                buttonAvailability.showBonusProcessingModal = false;
            }
        }

        if (props.formPreferences?.hideActionButtonsUntilAssignment && !props.genericRequestFormModel.assignedToByName && isAdmin) { //admins cannot see the downhill steps until after the request has been assigned to someone
            let showRegardless = ['showActionButtons', 'canAssignRequest', 'canRejectForm', 'canViewEmailList', 'canSubmitForm', 'canApproveForm'];
            for (const action in buttonAvailability) {
                if (!showRegardless.includes(action)) {
                    buttonAvailability[action] = false;
                }
            }

            if (buttonAvailability['canSubmitForm'] !== true) {
                if (!props.genericRequestFormModel.requestComplete && !props.genericRequestFormModel.requestRejected) {
                    buttonAvailability.showMustAssignAlert = true;
                }
            }

        }

        //Emails
        buttonAvailability.canViewEmailList = (props.genericRequestFormModel.emailsSents.length > 0)
        let showActionButtons = false;
        for (var property in buttonAvailability) {
            if (buttonAvailability[property] === true) {
                showActionButtons = true;
            }
        }

        buttonAvailability.showActionButtons = showActionButtons;

        if (props.selectedRequestType?.instructionsOnlyRequestType) {  //these are special requests that link to another form. 
            buttonAvailability.showActionButtons = false;
        }

        setActionButtonAvailability(buttonAvailability)

    }, [props.genericRequestFormModel, workflow, props.selectedRequestType])


    function setButtonAvailabilityByName(buttonAvailability, workflowStep, isAdmin) {
        //Always show the button for the current step.  These must match to RequestWorkflowSteps.AssociatedButton
        //if they are an admin, then they can see all buttons, if they are not an admin, then they can only see the 
        let passedAdminCheck = isAdmin;

        if (props.isAdminOverride) {
            passedAdminCheck = props.isAdminOverride;
        }

        if (!passedAdminCheck) {
            passedAdminCheck = !workflowStep.adminOnly;
        }

        //do not delete these, they are used for the condition evaluation on some forms.
        let userIsDeveloper = isDeveloper(); //Don't delete
        let userIsRequestTypeAdmin = props.selectedRequestType?.requestTypeAdmins.map(x => x.adminEmpId).some(i => security.empID.includes(i)); //Don't delete

        switch (workflowStep?.associatedButtonName) {
            case "Submit":
                buttonAvailability.canSubmitForm = true && passedAdminCheck;
                break;
            case "Approve":
                let eligibleToApprove = props.formType === 'Marketing' ? false : isAdmin;
                if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                    for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                        let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                        if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                            eligibleToApprove = true;
                            break;
                        }
                    }
                }
                buttonAvailability.canApproveForm = true && eligibleToApprove;
                buttonAvailability.canRejectForm = true && eligibleToApprove;
                break;
            case "Assign":
                buttonAvailability.canAssignRequest = true && passedAdminCheck;
                break;
            case "Complete":
                buttonAvailability.canCompleteForm = true && passedAdminCheck;
                break;
            case "Need More Info":
                buttonAvailability.canRequestMoreInfo = true && passedAdminCheck;
                break;
            case "Ready For Review":
                buttonAvailability.canMarkReadyForReview = true && passedAdminCheck;
                break;
            case "Ready for Approval":
                if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                    for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                        let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                        if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                            eligibleToApprove = true;
                            break;
                        }
                    }
                }

                buttonAvailability.canMarkReadyForApproval = true && eligibleToApprove;
                buttonAvailability.canRejectForm = true && eligibleToApprove;
                break;

            case "Add Credential":
                buttonAvailability.canAddCredentials = true && passedAdminCheck;
                break;

            case "Send to Yardi Support": //internal Yardi support on IT Setup Form.
                if (buttonAvailability.canAssignToYardi = true && passedAdminCheck) {
                    if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                        for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                            let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                            if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                                eligibleToApprove = true;
                                break;
                            }
                        }
                    }
                }

                buttonAvailability.canAssignToYardi = eligibleToApprove && passedAdminCheck;

                break;

            case "Send to Helpdesk":
                if (buttonAvailability.canAssignToHelpdesk = true && passedAdminCheck) {
                    if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                        for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                            let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                            if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                                eligibleToApprove = true;
                                break;
                            }
                        }
                    }
                }

                buttonAvailability.canAssignToHelpdesk = eligibleToApprove && passedAdminCheck;
                break;

            case "Send to Network Team":
                if (buttonAvailability.canAssignToNetworking = true && passedAdminCheck) {
                    if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                        for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                            let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                            if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                                eligibleToApprove = true;
                                break;
                            }
                        }
                    }
                }

                buttonAvailability.canAssignToNetworking = eligibleToApprove && passedAdminCheck;
                break;

            case "Sent to External Vendor": //originally for external Yardi Support, later added ability to do this from the HR form
                if (passedAdminCheck) {
                    if (props.genericRequestFormModel.relatedToFormName === 'Yardi') {
                        buttonAvailability.canMarkSentExternally = true;
                    } else if (props.genericRequestFormModel.relatedToFormName === 'HR') {
                        buttonAvailability.canMarkSentExternally = true;
                    }
                }

                break;

            case "Upload MVR":
                if (passedAdminCheck) {
                    if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                        for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                            let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                            if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                                eligibleToApprove = true;
                                break;
                            }
                        }
                    }
                }

                buttonAvailability.canUploadMvr = eligibleToApprove && passedAdminCheck;
                break;
            case "Update Bonus Totals":
                if (passedAdminCheck)
                    buttonAvailability.canUpdateBonusTotals = true;

                break;

            case "Approve Driver":
                if (passedAdminCheck) {
                    if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                        for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                            let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                            if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                                eligibleToApprove = true;
                                break;
                            }
                        }
                    }
                }
                buttonAvailability.canApproveDriver = eligibleToApprove && passedAdminCheck;
                break;

            case "Remove Driver":
                if (buttonAvailability.canRemoveDriver = true && passedAdminCheck) {
                    if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                        for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                            let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                            if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                                eligibleToApprove = true;
                                break;
                            }
                        }
                    }
                }
                buttonAvailability.canRemoveDriver = eligibleToApprove && passedAdminCheck;
                break;

            case "Approve Drivers License Update":
                if (buttonAvailability.canApproveDriversLicenseUpdate = true && passedAdminCheck) {
                    if (workflowStep.requestWorkflowStepApprovalConditions.length > 0) {
                        for (let c = 0; c < workflowStep.requestWorkflowStepApprovalConditions.length; c++) {
                            let condition = workflowStep.requestWorkflowStepApprovalConditions[c];
                            if (eval(condition.conditionsAsValidJavascriptBoolean.replaceAll('this.props.security', 'props.security').replaceAll('props.security', 'security').replaceAll('this.props.', 'props.'))) {
                                eligibleToApprove = true;
                                break;
                            }
                        }
                    }
                }
                buttonAvailability.canApproveDriversLicenseUpdate = eligibleToApprove && passedAdminCheck;
                break;


            case "Bonus Processing Info":
                buttonAvailability.showBonusProcessingModal = true;
                break;
            default:
                break;
        }

        return buttonAvailability;
    }

    function getShowCommentOrDocumentButton(isAdmin, value) {
        if (value === "Admin Only" && isAdmin)
            return true;

        if (value === "All Users")
            return true;

        return false;
    }

    function getCurrentWorkflowStep() {
        let currentStep = workflow.requestWorkflowSteps.filter(x => x.id === props.genericRequestFormModel.currentWorkflowStep)[0];
        if (props.genericRequestFormModel.requestStatus === "Rejected") {
            currentStep = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === "Reject")[0];
        }

        if (!currentStep) {
            currentStep = workflow.requestWorkflowSteps[0]; //default is the first step by sortOrder
        }

        return currentStep;
    }

    return (
        <div>
            {
                actionButtonAvailability.showActionButtons
                    ? <div id="actionButtons" className={`${flexStyles.rowFlexContainer} ${flexStyles.paddingTop10}`}>
                        <h4 className={styles.sectionHeader}>Request Actions</h4>

                        <div className={flexStyles.rowFlexContainer}>
                            {actionButtonAvailability.canSubmitForm &&
                                <RequestSubmitButton
                                    onClick={props.onSubmitButtonClick}
                                    security={security}
                                    requestTypes={props.requestTypes}
                                    requestProperties={props.requestProperties}
                                    formType={props.formType}
                                    showValidationError={props.showValidationError}
                                    formPreferences={props.formPreferences}
                                    quillRef={props.quillRef}
                                    employeePhone={props.employeePhone}
                                    actuallyForEmpCard={props.actuallyForEmpCard}>Submit</RequestSubmitButton>
                            }

                            {actionButtonAvailability.canApproveForm &&
                                <RequestApproval
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    availableAssignees={props.availableAssignees}
                                    requestTypes={props.requestTypes}
                                    onSave={props.onSaveWithEmails}
                                    security={security}
                                    formPreferences={props.formPreferences}
                                    propertyList={props.propertyList} />
                            }
                            {actionButtonAvailability?.canAssignRequest &&
                                <Assign
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    availableAssignees={props.availableAssignees}
                                    requestTypes={props.requestTypes}
                                    onSave={props.onSaveWithEmails}
                                    formPreferences={props.formPreferences}
                                    updateGenericRequestFormModel={props.updateGenericRequestFormModel} />
                            }
                            {actionButtonAvailability.canRequestMoreInfo &&
                                <RequestInfoNeeded
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails}
                                    requestTypes={props.requestTypes}
                                    security={security}
                                    formPreferences={props.formPreferences} />
                            }
                            {actionButtonAvailability.canMarkReadyForApproval &&
                                <RequestReadyForApproval
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    workflow={workflow}
                                    onSave={props.onSaveWithEmails}
                                />
                            }
                            {actionButtonAvailability.canAddCredentials &&
                                <AddRequestCredential
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails} />
                            }
                            {actionButtonAvailability.canAssignToHelpdesk &&
                                <SendToDepartment
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    propertyList={props.propertyList}
                                    onSave={props.onSaveWithoutEmails}
                                    requestTypes={props.requestTypes}
                                    assignToDepartment="Helpdesk"
                                    requestProperties={props.requestProperties} />
                            }
                            {actionButtonAvailability.canAssignToYardi &&
                                <SendToDepartment
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    propertyList={props.propertyList}
                                    onSave={props.onSaveWithoutEmails}
                                    requestTypes={props.requestTypes}
                                    assignToDepartment="Yardi Support"
                                    requestProperties={props.requestProperties} />
                            }
                            {actionButtonAvailability.canAssignToNetworking &&
                                <SendToDepartment
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    propertyList={props.propertyList}
                                    onSave={props.onSaveWithoutEmails}
                                    requestTypes={props.requestTypes}
                                    assignToDepartment="Network Team"
                                    requestProperties={props.requestProperties} />
                            }
                            {actionButtonAvailability.canMarkSentExternally &&
                                <SentExternally
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails}
                                    requestTypes={props.requestTypes}
                                    formPreferences={props.formPreferences}
                                    security={security} />

                            }
                            {actionButtonAvailability.canAddComment &&
                                <RequestComment
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    requestTypes={props.requestTypes}
                                    onSave={props.onSaveWithEmails}
                                    onSaveWithoutEmails={props.updateGenericRequestFormModel}
                                    security={security}
                                    isAdmin={props.isAdmin}
                                    propertyList={props.propertyList}
                                    belongsToGroup={props.belongsToGroup}
                                    formPreferences={props.formPreferences} />
                            }
                            {actionButtonAvailability.canAddAttachment &&
                                <RequestDocument
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    requestTypes={props.requestTypes}
                                    onSave={props.onSaveWithEmails}
                                    onSaveWithoutEmails={props.updateGenericRequestFormModel}
                                    security={security}
                                    requestTypes={props.requestTypes}
                                    documentSubfolder={props.formType}
                                />

                            }
                            {actionButtonAvailability.canUploadMvr &&
                                <UploadMvr genericRequestFormModel={props.genericRequestFormModel}
                                    requestTypes={props.requestTypes}
                                    onSave={props.onSaveWithEmails} />
                            }
                            {actionButtonAvailability.canApproveDriver &&
                                <ApproveDriver genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails}
                                    requestTypes={props.requestTypes} />
                            }
                            {actionButtonAvailability.canRemoveDriver &&
                                <RemoveDriver
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails}
                                    requestTypes={props.requestTypes} />
                            }
                            {actionButtonAvailability.canApproveDriversLicenseUpdate &&
                                <ApproveDriversLicenseUpdate
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails}
                                    requestTypes={props.requestTypes} />
                            }
                            {actionButtonAvailability.canMarkReadyForReview &&
                                <RequestReadyForReview
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails}
                                    requestTypes={props.requestTypes}
                                    security={security}
                                    formPreferences={props.formPreferences}
                                    workflow={workflow}
                                />
                            }
                            {actionButtonAvailability.canUpdateBonusTotals &&
                                <UpdateBonusAmounts
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    requestTypes={props.requestTypes}
                                    onSave={props.onSaveWithEmails} />
                            }
                            {actionButtonAvailability.showBonusProcessingModal &&
                                <ReferralBonusInfo
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    requestTypes={props.requestTypes}
                                    onSave={props.onSaveWithEmails}
                                    propertyList={props.propertyList.filter(x => x.divisionAvailablePayroll === true)} />
                            }
                            {actionButtonAvailability.canCompleteForm &&
                                <RequestCompletion
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails}
                                    requestTypes={props.requestTypes}
                                    security={security}
                                    formPreferences={props.formPreferences}
                                    workflow={workflow} />
                            }
                            {actionButtonAvailability.canRejectForm &&
                                <RequestRejection
                                    genericRequestFormModel={props.genericRequestFormModel}
                                    onSave={props.onSaveWithEmails}
                                    requestTypes={props.requestTypes}
                                    security={security}
                                    formPreferences={props.formPreferences}
                                    propertyList={props.propertyList} />
                            }
                            {actionButtonAvailability.canViewEmailList &&
                                <ViewEmails genericRequestFormModel={props.genericRequestFormModel} />
                            }

                            {actionButtonAvailability?.showMustAssignAlert &&
                                <Alert color="danger">This request type requires that the ticket be assigned to someone before any other actions can be taken.</Alert>
                            }

                        </div>
                    </div>

                    : <></>
            }
        </div>
    );
}
