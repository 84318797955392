
export class CustomAlertModel {

    constructor() { //link is any object that gets passed in and has these properties
        this.AlertText = "";
        this.AlertColor = "";
        this.AlertLinks = null;
    }

}
