import React, { useEffect, useState } from 'react';
import { Button, axios, getNowMountainTime } from 'react-mimg';
import { Form, Modal, ModalBody, ModalHeader, Alert, Label, Input } from 'reactstrap';
import { createComment, getNextStepInfo } from '../../../../functions/formHelpers/genericRequestFormHelper';
import { getEmployeeSecurity } from '../../../../functions/permissions';
import styles from './UploadMvrModal.module.scss';
import ClipLoader from "react-spinners/ClipLoader";
import ToggleSwitch from '../../../profiles/PropertyProfile/Maintenance/ToggleSwitch';

var CryptoJS = require("crypto-js");

export default function ApproveDriver(props) {

    const security = getEmployeeSecurity();
    const [employeeIsAlreadyInDriversList, setEmployeeIsAlreadyInDriversList] = useState(false);
    const [mvrDate, setMvrDate] = useState();
    const [processing, setProcessing] = useState(false);
    const [licenseExpDate, setLicenseExpDate] = useState();
    const [sendEmailToEpic, setSendEmailToEpic] = useState(true);
    const [defaultEmailBody, setDefaultEmailBody] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalAlert, setModalAlert] = useState({
        color: "info", message: "DO NOT USE THIS IF THE EMPLOYEE IS NOT AUTHORIZED TO DRIVE MIMG VEHICLES. INSTEAD, CLOSE THIS AND USE THE REJECT BUTTON! If the MVR has been approved and the driver is now authorized to drive MIMG vehicles, press 'Approve', which will add this person to the list of assigned drivers"
    });

    useEffect(() => {
        if (showModal) {
            if (props.genericRequestFormModel.id > 0) {
                let response = props.genericRequestFormModel.genericRequestResponses[0];
                if (response) {
                    setMvrDate(response.responseValue)
                }

                let detail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_EXP_DATE_ADD))[0];
                if (detail) {
                    setLicenseExpDate((new Date(detail.response)).toISOString().split('T')[0]);
                }
            }

            axios.post('api/DriversLicense/CheckIfEmployeeIsAlreadyInDriversList', props.genericRequestFormModel).then(r => {
                setEmployeeIsAlreadyInDriversList(r.data);
                if (r.data !== "") {
                    setModalAlert({ color: "danger", message: r.data + ". Please use the 'Reject' button to notify the submitter of this form." })
                }
            })

            axios.get('api/PropertyEmployees/GetSingleEmployeeCard?PropertyEmployeesId=' + props.genericRequestFormModel?.genericRequestFormEmployees[0]?.propertyEmployeesId).then(response => {
                setDefaultEmailBody(getDefaultEmailBody(response.data))
            })
        }
    }, [showModal, props.genericRequestFormModel])

    function onCompleteRequest(e) {
        e.preventDefault();
        setProcessing(true);

        let genericRequestFormModel = JSON.parse(JSON.stringify(props.genericRequestFormModel));
        let approveRequestModel = {};
        approveRequestModel.requestForm = genericRequestFormModel;
        approveRequestModel.licenseExpDate = e.target.licenseExpDate.value;
        approveRequestModel.dateOfMvr = e.target.mvrDate.value;
        approveRequestModel.notes = e.target.approveDriverComment.value;
        approveRequestModel.licenseNumber = getDecryptedDriversLicenseNumber(genericRequestFormModel);
        approveRequestModel.privateNotes = e.target.approveDriverPrivateComment.value;
        approveRequestModel.emailToEpicBody = sendEmailToEpic ? e.target.approveDriverEmailToEpic.value : "";
        approveRequestModel.sendEmailToEpic = sendEmailToEpic;

        //api call to add the driver to the drivers table list, which should also copy the used MVR and Drivers License documents to the EmployeeDocuments table.
        axios.post('api/DriversLicense/ApproveDriver', approveRequestModel).then(response => {
            genericRequestFormModel.genericRequestFormComments.push(createComment(security.empID, e.target.approveDriverComment.value));

            let requestTypeId = genericRequestFormModel.requestTypeId;
            let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Approve Driver')[0].id;
            genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);
            genericRequestFormModel.requestStatus = 'Completed'
            genericRequestFormModel.requestComplete = true;
            genericRequestFormModel.requestCompletedByEmpId = security.empID;
            genericRequestFormModel.requestCompletedByEmpName = security.employeeFullName;
            genericRequestFormModel.requestCompletedDateTime = getNowMountainTime();
            genericRequestFormModel.requestCompletedByEmpPosition = security.employeePosition;

            axios.put('api/GenericRequestForm/UpdateRequest?updateAction=approveDriver', genericRequestFormModel).then((res) => {
                let genericRequestForm = res.data;
                let pageAlert = { AlertText: "The new driver has been marked as approved and added to the list of approved drivers. A notification was sent to the requester.", AlertColor: "success" }

                props.onSave(genericRequestForm, pageAlert, "Approve Driver")
                setShowModal(false);
                setProcessing(false);
            })
        })
    }

    function getDecryptedDriversLicenseNumber(genericRequestFormModel) { //the grf system encrypts/decrypts the details at the client.  The drivers system does it on the backend, so as the Drivers system is the target system, we need to decrypt here (keys not shared)
        let detail = genericRequestFormModel.genericRequestFormDetails.filter(x => x.detail.questionLabel.toLowerCase() === "Drivers License Number" || x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_NUMBER_ADD))[0]
        let key = process.env.REACT_APP_GENERIC_REQUESTS_CLIENT_SIDE_KEY + detail?.detailId

        var bytes = CryptoJS.AES.decrypt(detail?.response, key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    function getDefaultEmailBody(employeeCard) {
        let nameDetail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_NAME_ADD))[0];
        let stateDetail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_STATE_ADD))[0];
        let dobDetail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_DOB_ADD))[0];

        return `Monarch Investment has approved a new driver to drive company vehicles

Driver Name (Per License): ${nameDetail?.response} 
Driver Name (Per Employee Record): ${employeeCard?.employeeName} 
Job Title: ${employeeCard?.title}
Home Property: ${employeeCard.homeProperty}
License State: ${stateDetail?.response}
License Number: ${getDecryptedDriversLicenseNumber(props.genericRequestFormModel)}
DOB: ${dobDetail?.response}`
    }

    return (
        <div>
            <Button onClick={() => setShowModal(true)}>Approve Driver</Button>

            <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Approve Driver</ModalHeader>

                <ModalBody>
                    {modalAlert &&
                        <Alert color={modalAlert.color}>{modalAlert.message}</Alert>
                    }
                    <Form onSubmit={onCompleteRequest}>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="licenseExpDate">Confirm License Exp Date</Label>
                            <Input required disabled={employeeIsAlreadyInDriversList} type="date" name="licenseExpDate" id="licenseExpDate" defaultValue={licenseExpDate} />
                        </div>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="approveDriverComment">Confirm MVR Date</Label>
                            <Input required disabled={employeeIsAlreadyInDriversList} type="date" name="mvrDate" id="mvrDate" defaultValue={mvrDate} />
                        </div>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="approveDriverComment">Public Notes (optional, any comments will be visible to anyone that can access the form)</Label>
                            <Input type="textarea" disabled={employeeIsAlreadyInDriversList} name="approveDriverComment" id="approveDriverComment" defaultValue="" placeholder="Add any details that can be seen by anyone that has access to this drivers info (their managers)" />
                        </div>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="approveDriverPrivateComment">Private Notes (optional, only visible to Drivers admins)</Label>
                            <Input type="textarea" disabled={employeeIsAlreadyInDriversList} name="approveDriverPrivateComment" id="approveDriverPrivateComment" defaultValue="" placeholder="Add any details that should only be visible to Monarch's driver admins" />
                        </div>
                        <div className={styles.marginBetweenQuestions}>
                            <ToggleSwitch className={styles.prompt} label="Send Email To EPIC" checked={sendEmailToEpic} onChange={() => setSendEmailToEpic(!sendEmailToEpic)} />
                            {sendEmailToEpic &&
                                <Input type="textarea" rows="10" disabled={employeeIsAlreadyInDriversList} name="approveDriverEmailToEpic" id="approveDriverEmailToEpic" defaultValue={defaultEmailBody} placeholder="This text will be sent to Epic in an email" />
                            }
                        </div>
                        <div className={styles.buttonsOnRight}>
                            <Button transparent onClick={() => setShowModal(false)}>Close</Button> {"  "}
                            <Button type="submit" disabled={processing || employeeIsAlreadyInDriversList} className={styles.submitButton}>
                                {processing
                                    ? <div><ClipLoader size={25} /></div>
                                    : <>Approve Driver</>
                                }
                            </Button> {"  "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}