import React, { useState } from 'react';
import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup } from 'reactstrap';
import { Button, axios, getNowMountainTime } from 'react-mimg';
import { useEffect } from 'react';
import styles from './changeRequestTypeModal.module.scss';
import validator from 'validator';
import { getEmployeeSecurity } from '../../../functions/permissions';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';
import ChangeRequestTypeEmail from './ChangeRequestTypeEmail';

export default function ChangeRequestTypeModal(props) {
    const security = getEmployeeSecurity();
    const [alert, setAlert] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [processing, setProcessing] = useState(false)
    const [additionalComment, setAdditionalComment] = useState('');
    const [emailToRequester, setEmailToRequester] = useState({ send: false, to: props.genericRequestFormModel.requesterEmail, body: '', genericRequestFormId: props.genericRequestFormModel.id, subject: "Request Form Request Type Changed - XRef: " + props.genericRequestFormModel.id });
    const [emailToAdmins, setEmailToAdmins] = useState({ send: false, to: '', body: '', genericRequestFormId: props.genericRequestFormModel.id, subject: "Request Form Request Type Changed - XRef: " + props.genericRequestFormModel.id });
    const [emailToRegional, setEmailToRegional] = useState({ send: false, to: '', body: '', genericRequestFormId: props.genericRequestFormModel.id, subject: "Request Form Request Type Needs Approval - XRef: " + props.genericRequestFormModel.id });
    const [requestGroups, setRequestGroups] = useState([]);
    const [requestTypes, setRequestTypes] = useState([]);
    const [requestTypesInGroup, setRequestTypesInGroup] = useState([]);
    const [requestWorkflows, setRequestWorkflows] = useState([]);
    const [availableWorkflows, setAvailableWorkflows] = useState([])
    const [selectedWorkflow, setSelectedWorkflow] = useState([])
    const [initialRequestType, setInitialRequestType] = useState();
    const [initialRequestWorkflow, setInitialRequestWorkflow] = useState();
    const [initialWorkflowStep, setInitialWorkflowStep] = useState();
    const [workflowSteps, setWorkflowSteps] = useState([]);
    const [selectedWorkflowStep, setSelectedWorkflowStep] = useState();
    const [selectedRequestGroup, setSelectedRequestGroup] = useState('');
    const [selectedRequestType, setSelectedRequestType] = useState();
    const [newFormUrl, setNewFormUrl] = useState(window.location.href)
    const requestAlreadyCompleted = (props.genericRequestFormModel.requestComplete === true || props.genericRequestFormModel.requestRejected === true)

    useEffect(() => {
        let messages = ['Admins may use this functionality to change to a new Request Type within the same form group, or to assign to a different department. Upon reclassification, the questions already answered by the submitter will not change, so in some cases it may be better to reject the form and request that the submitter resubmit with the correct type. If you wish to send emails associated with this change, check the switch controls at the bottom of the form']

        if (requestAlreadyCompleted) {
            messages.push("The request has already been completed or rejected.  At this point, you may only update the 'Request Type' unless the ticket is reopened");
        }

        setAlert({ color: "info", messages: messages })
    }, [])

    useEffect(() => {
        if (props.isOpen && (requestTypes.length === 0 || !requestWorkflows.length === 0)) {
            axios.get('api/requestworkflow/getworkflows').then(res => {
                setRequestWorkflows(res.data);
                setInitialRequestWorkflow(res.data.filter(x => x.id === props.genericRequestFormModel.workflowId)[0])
                setSelectedWorkflow(res.data.filter(x => x.id === props.genericRequestFormModel.workflowId)[0])

                let canReassignTo = props.canReclassifyTo ? props.canReclassifyTo : [];
                canReassignTo.push(props.requestTypes[0]?.belongsToGroup);

                let qs = ""
                let propName = "canReassignTo";
                let sep = ""
                canReassignTo.forEach(x => {
                    qs += `${sep}${propName}=${x}`
                    sep = "&"
                })

                axios.get('api/RequestTypes/GetAvailableRequestTypesForReassignment?' + qs).then(res => {
                    setRequestTypes(res.data.filter(x => !x.inactive));
                    let initialRequestType = res.data.filter(x => x.requestTypeId === props.genericRequestFormModel.requestTypeId)[0]
                    setInitialRequestType(res.data.filter(x => x.requestTypeId === props.genericRequestFormModel.requestTypeId)[0])
                    let requestGroups = [...new Set(res.data.map(x => x.requestGroupName))]
                    requestGroups.sort((a, b) => {
                        if (a < b) {
                            return - 1
                        } else if (a > b) {
                            return 1;
                        }
                        return 0;
                    })

                    setRequestGroups(requestGroups)
                    setSelectedRequestGroup(initialRequestType?.requestGroupName)
                    setIsLoading(false);
                })
            })
        }
    }, [props.isOpen])

    useEffect(() => {
        if (selectedWorkflowStep?.stepName === "Pending Approval" && emailToRegional?.to === '') {
            let siteIds = props.genericRequestFormModel.genericRequestFormProperties.map(x => x.siteId);
            let rmEmails = props.propertyList.filter(x => siteIds.includes(x.siteId)).map(x => x.rmemp?.email);
            updateEmailToRegional('to', rmEmails.join(','), 'send', true);
        } else {
            updateEmailToRegional('to', '', 'send', false);
        }

    }, [selectedWorkflowStep])
    
    useEffect(() => {
        let typesInGroup = requestTypes.filter(x => x.requestGroupName === selectedRequestGroup);
        setRequestTypesInGroup(typesInGroup.sort((a, b) => {
            if (a.requestTypeName < b.requestTypeName) {
                return -1;
            } else if (a.requestTypeName > b.requestTypeName) {
                return 1;
            }
            return 0;
        }))

        let selRequestType = requestTypes.filter(x => x.requestTypeId === props.genericRequestFormModel.requestTypeId)[0];
        setSelectedRequestType(selectedRequestGroup === initialRequestType?.requestGroupName ? selRequestType : typesInGroup[0])

        let workflows = [...new Set(typesInGroup.map(x => x.workflowId))]
        let workflowsInGroup = requestWorkflows.filter(x => workflows.includes(x.id));
        setAvailableWorkflows(workflowsInGroup);

        updateEmailToAdmins("send", selectedRequestGroup !== initialRequestType?.requestGroupName)

    }, [selectedRequestGroup])

    useEffect(() => {
        //take on the workflow of the selected request type.
        if (requestWorkflows && selectedRequestType) {
            let selWorkflow = requestWorkflows.filter(x => x.id === selectedRequestType.workflowId)[0];
            setSelectedWorkflow(selWorkflow)

            //workflows should usually all have the last three steps being Comment, Document, Reject, and the step before that is the completion step, or hopefully is in all cases. We don't want to allow going to the Complete step here.  So show all the steps besides the last 4 based on sort order, and see how it goes. 
            let steps = selWorkflow.requestWorkflowSteps;
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('submit'));
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('comment'));
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('document'));
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('complete'));
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('reject'));

            setWorkflowSteps(steps);
            if (steps.length > 0) {
                let initialStep = steps.filter(x => x.id === props.genericRequestFormModel.currentWorkflowStep)[0]
                if (!initialWorkflowStep) //only run this the first time through, otherwise it gets overwritten when we change the form type
                    setInitialWorkflowStep(initialStep);

                if (!selectedWorkflowStep) {
                    setSelectedWorkflowStep(steps.filter(x => x.id === props.genericRequestFormModel.currentWorkflowStep)[0]);
                } else {
                    if (steps?.filter(x => x.id === initialStep?.id).length > 0) {
                        setSelectedWorkflowStep(initialStep) //this is probably the most likely case, where the step stays the same within the same workflow.
                    } else {
                        let matchingNameStep = steps?.filter(x => x.stepName === initialWorkflowStep?.stepName)[0]
                        if (matchingNameStep) {
                            setSelectedWorkflowStep(matchingNameStep)
                        } else {
                            setSelectedWorkflowStep(steps[0]);
                        }
                    }
                }

                if (selectedRequestType.relatedToFormName === 'IT') {
                    updateEmailToAdmins('to', 'helpdesk.team@monarchinvestment.com');
                } else if (selectedRequestType.relatedToFormName === 'Yardi') {
                    updateEmailToAdmins('to', 'yardi.team@monarchinvestment.com')
                } else {
                    axios.get('api/RequestTypes/GetRequestTypeSummary/' + selectedRequestType.requestTypeId).then(response => {
                        updateEmailToAdmins('to', response.data.requestTypeAdmins.map(x => x.adminEmp.email).join(','))
                    })
                }
            }
        }

        if (selectedRequestType?.relatedToFormName !== undefined && selectedRequestType?.relatedToFormName !== null) {
            setNewFormUrl(getRedirectUrl(false))
        }

    }, [selectedRequestType, requestWorkflows])

    function updateSelectedRequestType(requestTypeId) {
        setSelectedRequestType(requestTypes.filter(x => x.requestTypeId === parseInt(requestTypeId))[0]);
    }

    function updateSelectedWorkflowStep(stepId) {
        setSelectedWorkflowStep(workflowSteps.filter(x => x.id === parseInt(stepId))[0]);
    }

    function getDefaultComment() {
        let s = "The following items were changed:"
        if (initialRequestType?.requestTypeId !== selectedRequestType?.requestTypeId)
            s += `<br /><b>Request Type</b> - From: <i>${initialRequestType?.requestTypeName}</i> to <b>${selectedRequestType?.requestTypeName}</b>`;

        if (initialRequestType?.requestGroupName !== selectedRequestGroup)
            s = s + `<br /><b>Form Type</b> - From: <i>${initialRequestType?.requestGroupName}</i> to <b>${selectedRequestGroup}</b>`

        if (initialRequestWorkflow?.id !== selectedWorkflow?.id)
            s = s + `<br /><b>Workflow Name</b> - From : <i>${initialRequestWorkflow.workflowName}</i> to <b>${selectedWorkflow.workflowName}</b>`

        if (initialWorkflowStep?.stepName !== selectedWorkflowStep?.stepName)
            s = s + `<br /><b>Workflow Step</b> - From: <i>${initialWorkflowStep?.stepName}</i> to <b>${selectedWorkflowStep?.stepName}</b>`;

        return s;
    }


    function getDefaultEmailBody() {

        let s = `An admin (${security.employeeFullName}) has made changes to a request form for the following request: [FormUrl].\n\n`

        s += "[DefaultCommentsFromAbove]"
        s += `\n\n\[AdditionalCommentsFromAbove]`;

        return s;
    }

    function updateEmailToAdmins(property, value) {
        let emailCopy = JSON.parse(JSON.stringify(emailToAdmins))
        emailCopy[property] = value;
        setEmailToAdmins(emailCopy);
    }

    function updateEmailToRegional(property, value) {
        let emailCopy = JSON.parse(JSON.stringify(emailToRegional))
        emailCopy[property] = value;
        setEmailToRegional(emailCopy);
    }

    function updateEmailToRegional(property1, value1, property2, value2) {
        let emailCopy = JSON.parse(JSON.stringify(emailToRegional))
        emailCopy[property1] = value1;
        emailCopy[property2] = value2;
        setEmailToRegional(emailCopy);
    }

    function submitUpdate() {
        //validate that something has actually changed
        let validationMessages = validateUpdate();
        if (validationMessages.length === 0) {
            setProcessing(true)
            let genericRequestFormCopy = JSON.parse(JSON.stringify(props.genericRequestFormModel));
            genericRequestFormCopy.requestTypeInfoChangedByEmpId = security.empID;
            genericRequestFormCopy.requestTypeInfoChangedByEmpName = security.employeeFullName;
            genericRequestFormCopy.requestTypeInfoChangedDateTime = getNowMountainTime();

            if (initialRequestType?.requestTypeId !== selectedRequestType.requestTypeId) {
                if (!genericRequestFormCopy.originalRequestTypeId) { //if the originalRequestTypeId is not null, it is not the first time it is being changed.  In order to be able to access the request form details (needed to change the property details), we need to only keep the originalRequestTypeId, and not the most recent if changed more than 1x.  That info will be kept in the comments anyways if ever needed. However, we cannot change this on subsequent changes, as that will break the permissions if the request is reassigned to a different form
                    genericRequestFormCopy.originalRequestTypeId = genericRequestFormCopy.requestTypeId
                }

                if (selectedWorkflowStep?.stepName.toLowerCase() === 'needs assignment') {
                    genericRequestFormCopy.assignedToEmpId = null;
                    genericRequestFormCopy.assignedToName = null;
                    genericRequestFormCopy.assignedToDateTime = null;
                    genericRequestFormCopy.assignedToByEmpId = null;
                    genericRequestFormCopy.assignedToByName = null;
                };

                genericRequestFormCopy.previousRequestTypeId = genericRequestFormCopy.requestTypeId;
                genericRequestFormCopy.requestTypeId = selectedRequestType?.requestTypeId;
                genericRequestFormCopy.requestType = null;
                genericRequestFormCopy.requestStatus = selectedWorkflowStep?.stepName;
            }

            if (!requestAlreadyCompleted) {
                if (initialRequestType?.requestGroupName !== selectedRequestGroup) { //for each of the items that can change, we only want to update/change if it does change. Feel it may be easier to troubleshoot if there are some nulls in there
                    genericRequestFormCopy.previousRelatedToFormName = genericRequestFormCopy.relatedToFormName;
                    genericRequestFormCopy.relatedToFormName = selectedRequestType?.relatedToFormName;

                    //clear out any assignment info (it may stay at the assigned step, but don't think that matters for getting the ticket to show in the queue properly.)
                    genericRequestFormCopy.assignedToEmpId = null;
                    genericRequestFormCopy.assignedToName = null;
                    genericRequestFormCopy.assignedToDateTime = null;
                    genericRequestFormCopy.assignedToByEmpId = null;
                    genericRequestFormCopy.assignedToByName = null;

                }

                if (initialRequestWorkflow?.workflowName !== selectedWorkflow?.workflowName) {
                    genericRequestFormCopy.previousWorkflowId = genericRequestFormCopy.workflowId;
                    genericRequestFormCopy.workflowId = selectedWorkflow.id;
                }

                if (initialWorkflowStep?.id !== selectedWorkflowStep?.id) {
                    genericRequestFormCopy.previousWorkflowStep = genericRequestFormCopy.currentWorkflowStep;
                    genericRequestFormCopy.currentWorkflowStep = selectedWorkflowStep?.id;
                }

                genericRequestFormCopy.requestStatus = selectedWorkflowStep?.stepName;

            }
            //add comment
            let commentText = getDefaultComment();
            if (additionalComment !== '')
                commentText += `<br /><b><u>Additional Comment</u></b><br />${additionalComment}`
            genericRequestFormCopy.genericRequestFormComments.push(createComment(security.empID, commentText))

            //post here to update the GRF. Use existing function
            axios.put('api/GenericRequestForm/UpdateRequest?updateAction=ChangeRequestType', genericRequestFormCopy).then((response) => {
                //SINCE I AM GOING TO SEND THE EMAIL AFTER UPDATING THE FORM, WE WANT TO BE SURE THAT THEY WILL WORK, SO WILL NEED TO UPDATE THE VALIDATION FUNCTION ACCORDINGLY TO MAKE SURE EVERYTHING SEEMS VALID
                let emailsToSend = []
                if (emailToAdmins.send) {
                    let emailCopy = JSON.parse(JSON.stringify(emailToAdmins))
                    emailCopy.body = emailCopy.body.replace('[FormUrl]', window.location.origin + window.location.pathname);
                    emailCopy.body = emailCopy.body.replace('[DefaultCommentsFromAbove]', "<br /><br />" + getDefaultComment())
                    emailCopy.body = emailCopy.body.replace('[AdditionalCommentsFromAbove]', "<br /><br />" + additionalComment + "<br /><br />")
                    emailsToSend.push(emailCopy)
                }

                if (emailToRequester.send) {
                    let emailCopy = JSON.parse(JSON.stringify(emailToRequester))
                    emailCopy.body = emailCopy.body.replace('[FormUrl]', window.location.origin + window.location.pathname);
                    emailCopy.body = emailCopy.body.replace('[DefaultCommentsFromAbove]', "<br /><br />" + getDefaultComment())
                    emailCopy.body = emailCopy.body.replace('[AdditionalCommentsFromAbove]', "<br /><br />" + additionalComment + "<br /><br />")
                    emailsToSend.push(emailCopy)
                }

                if (emailToRegional.send) {
                    let emailCopy = JSON.parse(JSON.stringify(emailToRegional))
                    emailCopy.body = emailCopy.body.replace('[FormUrl]', window.location.origin + window.location.pathname);
                    emailCopy.body = emailCopy.body.replace('[DefaultCommentsFromAbove]', "<br /><br />" + getDefaultComment())
                    emailCopy.body = emailCopy.body.replace('[AdditionalCommentsFromAbove]', "<br /><br />" + additionalComment + "<br /><br />")
                    emailsToSend.push(emailCopy)
                }

                if (emailsToSend.length > 0) {
                    axios.post('api/GenericRequestForm/SendGenericRequestFormReclassificationEmails', emailsToSend).then(res => {
                        if (res.data.isSuccessful) {
                            window.location = getRedirectUrl(true, true) //redirect form no matter what, either to a new url, or to the existing url.  This will force everything to reload which seems necessary to get it all to work properly. 

                        } else {
                            setAlert({ color: "danger", messages: ['Updating the ticket was successful, but there was an error sending one or more emails', res.data.errorMessage] })
                            setProcessing(false);
                        }
                    })
                } else {
                    window.location = getRedirectUrl(true, false)
                }
            })

        } else {
            setAlert({ color: "danger", messages: validationMessages })
            setProcessing(false)
        }
    }

    function getRedirectUrl(justChanged, emailsWereSent) {
        let baseUrl = `requests/${selectedRequestType?.relatedToFormName}/${props.genericRequestFormModel.id}`;
        if (justChanged) {
            if (emailsWereSent) {
                return baseUrl + "?justChanged=1&emailsWereSent=1";
            } else {
                return baseUrl + "?justChanged=1";
            }
        } else {
            return baseUrl;
        }
    }

    function validateUpdate() {
        let messages = [];
        let somethingChanged = false;
        if (initialRequestType?.requestGroupName !== selectedRequestGroup)
            somethingChanged = true;

        if (initialRequestType?.requestTypeId !== selectedRequestType.requestTypeId)
            somethingChanged = true;

        if (initialRequestWorkflow?.workflowName !== selectedWorkflow?.workflowName)
            somethingChanged = true;

        if (initialWorkflowStep?.stepName !== selectedWorkflowStep?.stepName)
            somethingChanged = true;

        if (!somethingChanged)
            messages.push("None of the 'New Values' selected appear to have changed. Something must change in order to submit the form");

        if (emailToAdmins.send) {
            let toEmails = emailToAdmins.to.split(',')
            if (toEmails.length === 0 || toEmails[0] === '') {
                messages.push('No email recipients are selected for the email to admins')
            } else {
                for (let i = 0; i < toEmails.length; i++) {
                    if (!validator.isEmail(toEmails[i]))
                        messages.push(toEmails[i] + ' is not a valid email address')
                }
            }
        }

        if (emailToRequester.send) {
            let toEmails = emailToRequester.to.split(',')
            if (toEmails.length === 0 || toEmails[0] === '') {
                messages.push('No email recipients are selected for the email to requester')
            } else {
                for (let i = 0; i < toEmails.length; i++) {
                    if (!validator.isEmail(toEmails[i]))
                        messages.push(toEmails[i] + ' is not a valid email address')
                }
            }
        }

        return messages;
    }

    function onUpdateEmail(emailType, emailObj) {
        if (emailType === "Admins")
            setEmailToAdmins(emailObj);

        if (emailType === "Requester")
            setEmailToRequester(emailObj);

        if (emailType === "Regional")
            setEmailToRegional(emailObj);

    }

    return (
        <div>
            <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle}>
                {/*<ModalHeader toggle={props.toggle}>Change Request Type</ModalHeader>*/}

                <ModalBody>
                    {alert && alert.messages?.length > 0 &&
                        <Alert toggle={() => setAlert()} color={alert.color}>{alert.messages.map((m, index) => {
                            return <p key={index}><b>{m}</b></p>
                        })}
                            <a href="https://mimg.us/gotolink.aspx?id=12264" target="_blank" rel="noreferrer">Download Instructions Document</a>
                        </Alert>
                    }

                    <div className={styles.sectionWrapper}>
                        {isLoading ? <>Loading</>
                            : <><div className={`${styles.flexRow} ${styles.headers}`}>
                                <div className={styles.flex1}></div>
                                <div className={styles.flex1}>Current</div>
                                <div className={styles.flex1}>New Value</div>
                            </div>

                                <div className={styles.flexRow}>
                                    <div className={`${styles.flex1}`}>Request Group/Form Name</div>
                                    <div className={styles.flex1}>{initialRequestType?.requestGroupName}</div>
                                    <div className={styles.flex1}>
                                        <Input type="select" value={selectedRequestGroup} onChange={(e) => setSelectedRequestGroup(e.target.value)} disabled={requestAlreadyCompleted}>
                                            {requestGroups.map((g, i) => {
                                                return <option key={i}>{g}</option>
                                            })}
                                        </Input>
                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>Request Type</div>
                                    <div className={styles.flex1}>{initialRequestType?.requestTypeName}</div>
                                    <div className={styles.flex1}>
                                        <Input type="select" value={selectedRequestType?.requestTypeId} onChange={(e) => updateSelectedRequestType(e.target.value)}>
                                            {requestTypesInGroup.map((t, i) => {
                                                return <option key={i} value={t.requestTypeId}>{t.requestTypeName}</option>
                                            })}
                                        </Input>
                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>Request Workflow</div>
                                    <div className={styles.flex1}>{initialRequestWorkflow?.workflowName}</div>
                                    <div className={styles.flex1}>
                                        {selectedWorkflow?.workflowName}
                                    </div>
                                </div>


                                {!requestAlreadyCompleted && initialRequestWorkflow?.workflowName !== selectedWorkflow?.workflowName &&
                                    <div className={styles.flexRow}>
                                        <Alert color="danger">
                                            {workflowSteps.length === 0 &&
                                                <b>The currently selected request type does not have any workflow steps which support changing the request to this type. Please try another request type. <br /><br /></b>
                                            }
                                            <b>CHANGING THE REQUEST TYPE WILL ALSO CHANGE THE WORKFLOW ASSOCIATED TO THIS TICKET, WHICH MAY AFFECT VARIOUS EMAILS AND WORKFLOW STEPS.  PLEASE REVIEW THE WORKFLOW STEP BELOW TO FIND THE PROPER STEP TO PROCEED TO. BY DEFAULT, THE SYSTEM WILL SELECT A STEP WITH THE SAME NAME AS THE CURRENT WORKFLOW STEP, BUT THAT IS NOT ALWAYS POSSIBLE
                                            </b>
                                        </Alert>
                                    </div>
                                }

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>Workflow Step</div>
                                    <div className={styles.flex1}>{initialWorkflowStep?.stepName}</div>
                                    <div className={styles.flex1}>
                                        <Input type="select" value={selectedWorkflowStep?.id} onChange={(e) => updateSelectedWorkflowStep(e.target.value)} disabled={requestAlreadyCompleted}>
                                            {workflowSteps.map((s, i) => {
                                                return <option key={i} value={s.id}>{s.stepName}</option>
                                            })}
                                        </Input>
                                    </div>
                                </div>
                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        Default Comments
                                    </div>
                                    <div className={`${styles.flex5} ${styles.changesComment}`} dangerouslySetInnerHTML={{ __html: getDefaultComment() }}>

                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        Additional Comments (optional)
                                    </div>
                                    <div className={styles.flex5}>
                                        <Input type="textarea" rows={3} value={additionalComment} onChange={(e) => setAdditionalComment(e.target.value)} placeholder="Enter any additional comments regarding the change" />
                                    </div>
                                </div>

                                {selectedWorkflowStep?.stepName === "Pending Approval" &&
                                    <div className={styles.flexRow}>
                                        <div className={`${styles.flex1} ${styles.sectionWrapper}`}>
                                            <ChangeRequestTypeEmail email={emailToRegional} onUpdateEmail={onUpdateEmail} sendToLabel="Regional" defaultEmailBody={getDefaultEmailBody()} required={selectedRequestGroup !== initialRequestType?.requestGroupName} />
                                        </div>
                                    </div>
                                }

                                <div className={styles.flexRow}>
                                    <div className={`${styles.flex1} ${styles.sectionWrapper}`}>
                                        <ChangeRequestTypeEmail email={emailToAdmins} onUpdateEmail={onUpdateEmail} sendToLabel="Admins" defaultEmailBody={getDefaultEmailBody()} required={selectedRequestGroup !== initialRequestType?.requestGroupName} />
                                    </div>
                                    <div className={`${styles.flex1} ${styles.sectionWrapper}`}>
                                        <ChangeRequestTypeEmail email={emailToRequester} onUpdateEmail={onUpdateEmail} sendToLabel="Requester" defaultEmailBody={getDefaultEmailBody()} />
                                    </div>
                                </div>

                            </>
                        }

                    </div>



                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={props.toggle}>Cancel</Button> {"  "}
                    <Button onClick={() => submitUpdate()} disabled={processing || workflowSteps.length === 0}>Update Request Type</Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}
