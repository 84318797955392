import React, { useEffect, useState } from 'react'
import { Alert, Container, Form, FormGroup, FormText, Input, Label } from 'reactstrap'
import styles from './resetPassword.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { axios, Button } from 'react-mimg';
import logo from '../../images/mimgLogo3.png';
import Required from '../../components/layout/Required';

export default function ResetPassword() {
    const [user, setUser] = useState({});
    const [searchParams] = useSearchParams();
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        var token = searchParams.get('token');
        axios.get('api/login/getResetUser/' + token)
            .then(res => setUser(res.data))
    }, [searchParams])

    function updatePassword(e) {
        e.preventDefault();
        var data = {
            propertyEmployeesId: user.propertyEmployeesId,
            existingUserId: user.userId,
            UserName: user.username,
            Password: password1
        }
        axios.post('api/login/changepassword', data)
            .then(res => {
                if(res.data.isSuccessful)
                    navigate('/login')
                else
                    setError(res.data.errorMessage)
            })
            .catch(() => setError('Unexpected error when updating the password. Please try again.'))
    }

    return (
        <Container className={styles.container}>
            <div className={styles.wrapper}>
                <img className={styles.logo} src={logo} />
                <h5>Reset password for {user.username}</h5>
                <Form>
                    <FormGroup>
                        <Label>New password <Required /></Label>
                        <Input required type='password' value={password1} onChange={e => setPassword1(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Confirm new password <Required /></Label>
                        <Input required type='password' value={password2} onChange={e => setPassword2(e.target.value)} />
                        {password1.length > 0 && password2.length > 0 && password1 !== password2 &&
                            <FormText color="muted">
                                Passwords do not match.
                            </FormText>}
                    </FormGroup>
                    <Button className={styles.button} onClick={updatePassword}>Update password</Button>
                </Form>
                <Alert color='danger' isOpen={error.length > 0} toggle={() => setError('')} >{error}</Alert >
            </div>
        </Container>
    )
}
