/* eslint-disable no-eval*/
import validator from 'validator';
import { getEmployeeSecurity } from '../permissions';
import { axios } from 'react-mimg';

export function getRecipientEmailAddresses(genericRequestFormModel, emailData, recipientField, propertyList, requestTypes) {
    var recipients = [];
    var positions = [];

    let requestProps = genericRequestFormModel.genericRequestFormProperties.map(x => x.siteId);

    if (emailData.emailRequester === recipientField)
        recipients.push(genericRequestFormModel.requesterEmail);

    if (emailData.emailAssignee === recipientField)
        recipients.push(genericRequestFormModel.assignedToEmail)

    if (emailData.emailAdmins === recipientField)
        recipients = recipients.concat(getAdminEmails(genericRequestFormModel,requestTypes));

    if (emailData.emailAssetManager === recipientField) {
        recipients.push(getPropertyPositionEmails(requestProps, "Asset Manager", propertyList));
        positions.push("Asset Manager");
    }
    if (emailData.emailAnalysts === recipientField) {
        recipients.push(getPropertyPositionEmails(requestProps, "Analyst",propertyList));
        positions.push("Analyst");
    }
    if (emailData.emailRegionalManager === recipientField) {
        recipients.push(getPropertyPositionEmails(requestProps, "Regional Manager", propertyList));
        positions.push("Regional Manager");
    }

    if (emailData.emailPropertyManager === recipientField) {
        recipients.push(getPropertyPositionEmails(requestProps, "Property Manager",propertyList));
        positions.push("Property Manager");
    }
    if (emailData.emailRecruiter === recipientField) {
        recipients.push(getPropertyPositionEmails(requestProps, "Recruiting",propertyList));
        positions.push("Recruiting");
    }
    if (emailData.emailLms === recipientField) {
        recipients.push(getPropertyPositionEmails(requestProps, "Marketing",propertyList));
        positions.push("Marketing");
    }
    if (recipientField.toLowerCase() === "to" && emailData.otherEmailTo) {
        emailData.otherEmailTo = emailData.otherEmailTo.replaceAll(';', ',').replaceAll(' ', '');
        let emails = emailData.otherEmailTo.split(',');
        for (let e = 0; e < emails.length; e++) {
            if (validator.isEmail(emails[e])) {
                recipients.push(emails[e]);
            }
        }
    }

    if (recipientField.toLowerCase() === 'cc' && genericRequestFormModel.relatedToFormName === 'HR') {
        let hrWorkflowSubmitStepEmailIds = process.env.REACT_APP_HR_SUBMISSION_EMAIL_IDS.split(',');
        if (hrWorkflowSubmitStepEmailIds.includes(emailData.id.toString())) {
            let requestType = requestTypes?.filter(x => x.id === genericRequestFormModel.requestTypeId)[0];
            if (requestType?.alwaysCcRegionalOnSubmission) {
                recipients.push(getPropertyPositionEmails(requestProps, "Regional Manager", propertyList));
                positions.push("Regional Manager");
            }
        }
    }

    if (recipients.length > 0 && recipients.flat().length === 0) {
        let positionString = Array.from(positions.values()).join("/")
        recipients = recipients.concat(getAdminEmails(genericRequestFormModel, requestTypes));
        emailData.emailBody = `<b>This email is supposed to go to ${positionString}, but there is currently no ${positionString} assigned to this property, so is being sent to the Admins instead.</b></br></br>` + emailData.emailBody
    }
    return recipients;
}


function getPropertyPositionEmails(requestProps, position, propertyList) {
    let empEmails;
    if (requestProps.length > 0) {
        let propEmpData;
        if (position === 'Asset Manager' || position === 'Analyst') {
            propEmpData = propertyList.filter(x => requestProps.includes(x.siteId) && x.siteId !== 176 && x.siteId !== 231).map(p => p.propertyEmployeeBasics);
        } else {
            propEmpData = propertyList.filter(x => requestProps.includes(x.siteId) && x.siteId).map(p => p.propertyEmployeeBasics);
        }

        let empData = [];
        for (let i = 0; i < propEmpData.length; i++) {
            for (let j = 0; j < propEmpData[i].length; j++) {
                empData.push(propEmpData[i][j]);
            }
        }
        empEmails = empData.filter(x => x.propertyPosition === position).map(e => e.email);
    } else {
        empEmails = "";
    }

    return empEmails;
}


function getAdminEmails(genericRequestFormModel, requestTypes) {
    let requestType = requestTypes?.find(x => x.id === genericRequestFormModel.requestTypeId);
    let adminEmails = requestType.notificationEmails ? requestType.notificationEmails.emailAddresses.split(',') : [];
    return adminEmails;
}

export async function replaceStringPlaceholders(s, genericRequestFormModel, propertyList, selectedRequestType, formType, currentWorkflowStep){
    s = s.replace("@FORMID", genericRequestFormModel.id);
    //s = s.replace("@FORMTYPE", genericRequestFormModel.relatedToFormName);  //difficulty with this field not having spacing
    s = s.replace("@REQUESTTYPE", selectedRequestType.requestType1);
    s = s.replace("@LINKTOFORM", `To view the form, please visit <a href="https://mimg.us/v2/requests/${formType}/${genericRequestFormModel.id}">https://mimg.us/v2/requests/${formType}/${genericRequestFormModel.id}</a>`);
    s = s.replace("@APPROVALINFO", `Approved By: ${genericRequestFormModel.approvedByEmpName} - ${new Date(genericRequestFormModel.approvedDateTime).toLocaleDateString()}`);
    s = s.replace("@ASSIGNMENTINFO", `Assigned By ${genericRequestFormModel.assignedToByName} - ${new Date(genericRequestFormModel.assignedToDateTime).toLocaleDateString()}`);
    s = s.replace("@COMPLETIONINFO", `Marked Completed By: ${genericRequestFormModel.requestCompletedByEmpName} - ${new Date(genericRequestFormModel.requestCompletedDateTime).toLocaleDateString()}`);
    s = s.replace("@REJECTIONINFO", `Rejected By: ${genericRequestFormModel.rejectedByEmpName} - ${new Date(genericRequestFormModel.requestStatusUpdatedDateTime).toLocaleDateString()}`);
    s = s.replace("@YARDIVENDORCODE", `Yardi Vendor Code: ${genericRequestFormModel.externalReferenceId}`);
    s = s.replace("@NEXTSTEP", currentWorkflowStep.stepName);

    if (s.includes("@SUBMISSIONINFO")) {
        
        if (genericRequestFormModel.submittedOnBehalfOfUser) {
            s = s.replace("@SUBMISSIONINFO", `This form was submitted on behalf of ${genericRequestFormModel.requesterName} by ${genericRequestFormModel.submittedOnBehalfOfUserByEmpName} - ${new Date(genericRequestFormModel.requestDateTime).toLocaleDateString()}.`);
        } else {
            s = s.replace("@SUBMISSIONINFO", `Submitted By: ${genericRequestFormModel.requesterName} - ${new Date(genericRequestFormModel.requestDateTime).toLocaleDateString()}`);
        }
    }
    

    if (s.includes("@ADDITIONALDETAILS")) {
        if (genericRequestFormModel.additionalDetails) {
            s = s.replace("@ADDITIONALDETAILS", genericRequestFormModel.additionalDetails);
        } else {
            s = s.replace("@ADDITIONALDETAILS", "");
        }
    }

    if (s.includes("@PROPERTY")) {
        let propList;
        if (genericRequestFormModel.genericRequestFormProperties.length === 1) {
            let siteId = genericRequestFormModel.genericRequestFormProperties[0].siteId;
            let list = propertyList.filter(x => x.siteId === siteId)[0];
            if (list) {
                propList = propertyList.filter(x => x.siteId === siteId)[0].propertyName;
            } else {
                propList = "Unknown Property"
            }

        } else {
            propList = "Multiple Properties";
        }

        s = s.replace("@PROPERTY", propList);
    }

    if (s.includes("@EMPLOYEEINFO")) {
        let empList;
        if (genericRequestFormModel.genericRequestFormEmployees.length === 1 || selectedRequestType.belongsToGroup === 'ITSetup' || selectedRequestType.belongsToGroup === 'Payroll') {
            let emp = genericRequestFormModel.genericRequestFormEmployees[0].employeeName;
            empList = emp;
        } else if (genericRequestFormModel.genericRequestFormEmployees.length >= 1) {
            empList = "Multiple Employees";
        } else {
            empList = "No Employee Found for this Form";
        }

        s = s.replace("@EMPLOYEEINFO", empList);
    }

    //COMMENT INFO
    if (s.includes("@COMMENTINFO")) {
        genericRequestFormModel.genericRequestFormComments.sort(function (a, b) { //order to get newest comment first
            let x = a.id;
            let y = b.id;
            if (x > y) { return -1; }
            if (x < y) { return 1; }
            return 0;
        });

        let comment = genericRequestFormModel.genericRequestFormComments[0];
        s = s.replace("@COMMENTINFO", `Comment By ${comment.commenterName} - ${comment.comment}`);
    }

    //DOCUMENT INFO
    if (s.includes("@DOCUMENTINFO")) {
        genericRequestFormModel.genericRequestFormDocuments.sort(function (a, b) { //order to get newest document first
            let x = a.id;
            let y = b.id;
            if (x > y) { return -1; }
            if (x < y) { return 1; }
            return 0;
        });

        let document = genericRequestFormModel.genericRequestFormDocuments[0];
        s = s.replace("@DOCUMENTINFO", `Document Added By ${document.uploadedByName} - Description: ${document.docDescription} // FileName: ${document.fileName}`);
    }

    if (s.includes("@FINALEMAILPASSWORD")) {
        let credential = genericRequestFormModel.genericRequestResponses.filter(x => x.responseType === "Final Email Password")[0];
        if (credential) {
            let credentialInfo = "Username: " + credential.responseValue + "; Password: " + credential.responseValue2;
            s = s.replace("@FINALEMAILPASSWORD", credentialInfo);
        } else {
            s = s.replace("@FINALEMAILPASSWORD", "The email password was not successfully reset for this employee");
        }
    }

    if (s.includes("@KEYDETAILS")) {
        await axios.get('api/GenericRequestForm/GetKeyDetailsText?GenericRequestFormId=' + genericRequestFormModel.id).then(response => {
            s = s.replace("@KEYDETAILS", response.data);
        });
    }

    return s;
}


export function finalizeEmailList(emailData, recipients, sendEmails) {
    let emails = "";
    let sep = "";

    let security = getEmployeeSecurity();
    let allRecipients = recipients.flat();
    let index = allRecipients.indexOf(security.employeeEmail);
    if (index > - 1 && !emailData.sendToCurrentEmp) {
        allRecipients.splice(index, 1);
    }

    for (var i = 0; i < allRecipients.length; i++) {
        if (allRecipients[i] !== null) {
            if (sendEmails) {
                emails += sep + allRecipients[i];
            } else {
                emails += sep + "xyz_" + allRecipients[i];
            }
            sep = ",";
        }
    }

    emails = emails === "," ? "" : emails;
    return emails;
}
