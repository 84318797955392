import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import RequestDetail from './RequestDetail';

export default function RequestDetailTextArea(props) {
    //needs to be a controlled component so it updates when defualt value updates (e.g. someone changes the property)
    const [value, setValue] = useState(props.defaultValue ? props.defaultValue : '');

    useEffect(() => {
        setValue(props.defaultValue ? props.defaultValue : '')
    }, [props.defaultValue])

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail} >
            <Input
                rows="5"
                type="textarea"
                placeholder={props.RequestTypeDetail.placeHolderText}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </RequestDetail>
    );
}
