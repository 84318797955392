import { axios } from 'react-mimg';

export async function uploadImage(imageUrl, formId) {
    const base64Data = imageUrl.split(',')[1];
    const contentType = imageUrl.match(/data:(.*?);base64,/)[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: contentType });

    const formData = new FormData();
    formData.append('file', file, 'image.png');
    formData.append('FormId', formId);
    const response = await axios.post('api/GenericRequestForm/UploadCommentImage', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response;
}
