import { isValidDate } from "./validation";

export function getDate(dateString) {
    return new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
}

export function getShortDate(dateString) {
    return new Date(dateString).toLocaleDateString('en-US')
}

export function getISODate(dateString) {
    var date = new Date(dateString);
    if (isValidDate(date)) {
        return date.toISOString().split('T')[0]
    }
    return null;
}

export function getTime(dateString) {
    return new Date(dateString).toLocaleTimeString('en-US')
}

export function getCalendarDate(inputDate) {
    return new Date(...inputDate.split('-').map((v, i) => v - (i === 1 ? 1 : 0)))
}