import React, { useState, useEffect } from 'react';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Label } from 'reactstrap';
import { Button, axios, getNowMountainTime } from 'react-mimg';
import styles from './requestReminderModal.module.scss';
import validator from 'validator';
import { getEmployeeSecurity } from '../../../functions/permissions';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';
import RequestAutoClose from '../formelements/RequestAutoClose';

export default function RequestReminderModal(props) {
    const security = getEmployeeSecurity();
    const [alert, setAlert] = useState(getInitialAlert());
    const [processing, setProcessing] = useState(false)
    const [requestType, setRequestType] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [associatedEmployees, setAssociatedEmployees] = useState();
    const [additionalComment, setAdditionalComment] = useState('');
    const [autoCloseDate, setAutoCloseDate] = useState(props.genericRequestFormModel.autoCloseTicketAfterDateTime !== null ? props.genericRequestFormModel.autoCloseTicketAfterDateTime : '');
    const [emailsToObject, setEmailsToObject] = useState({ requester: true, regionalmanager: false, assistantregionalmanager: false, assetmanager: false, analysts: false });
    const [emailToRecipients, setEmailToRecipients] = useState(props.genericRequestFormModel.requesterEmail);
    const [emailCcRecipients, setEmailCcRecipients] = useState('');
    const formUrl = window.location.href

    useEffect(() => {
        if (props.isOpen) {
            axios.get('api/requesttypes/GetRequestTypeSummary/' + props.genericRequestFormModel.requestTypeId).then((response) => {
                setRequestType(response.data)
            })

            //get the information about the properties to get the RM/AM names, but that will hopefully already be available. Not sure about Analyst
            let siteIds = props.genericRequestFormModel.genericRequestFormProperties.map(x => x.siteId);
            let employees = []
            let sites = props.propertyList.filter(x => siteIds.includes(x.siteId));
            sites.forEach(x => {
                x.propertyEmployeeBasics.forEach(y => {
                    if (employees.filter(z => z.id === y.propertyEmployeesId).length === 0) {
                        let emp = { id: y.propertyEmployeesId, name: y.employeeName, email: y.email, position: y.position }
                        employees.push(emp)
                    }
                })
            })

            setAssociatedEmployees(employees);
            setIsLoading(false);
        }
    }, [props.isOpen])


    function getInitialAlert() {
        let alert = { color: "info" };
        let messages = ['Admins may use this functionality to select recipients and send a reminder email related to the processing of this form.  Selected "Email To" boxes may be edited to modify the recipients of an email prior to sending. Multiple email addresses should be separated by a comma.'];
        let otherMessage = `${props.genericRequestFormModel.numberOfRemindersSent ? props.genericRequestFormModel.numberOfRemindersSent : 0 } reminder(s) already sent.`;
        if (props.genericRequestFormModel.mostRecentReminderSentDateTime) {
            otherMessage += ` Most recent reminder sent ${new Date(props.genericRequestFormModel.mostRecentReminderSentDateTime).toLocaleDateString()} by ${props.genericRequestFormModel.mostRecentReminderSentByEmpName}.`;
        }
        messages.push(otherMessage)

        alert.messages = messages;
        return alert;
    }

    function getDefaultComment() {
        let reminderNumber = 1;
        if (props.genericRequestFormModel.numberOfRemindersSent) {
            reminderNumber = props.genericRequestFormModel.numberOfRemindersSent + 1;
        }
        let comment = `A request admin has marked your request form as needing your feedback. This email is serving as reminder #${reminderNumber} for this request.`;
        if (autoCloseDate) {
            comment += ` ${getAutocloseCommentText()}`;
        }

        comment += ` To view the form, please visit <a href='${formUrl}'>${formUrl}</a>.`;

        return comment;
    }

    function getAutocloseCommentText() {
        let selectedDate = new Date(autoCloseDate);
        var userTimezoneOffset = selectedDate.getTimezoneOffset() * 60000;
        selectedDate = new Date(selectedDate.getTime() + userTimezoneOffset)
        return ` <b>The request will be automatically closed at 8:00 PM MT on ${selectedDate.toLocaleDateString()} if no response is received.</b>`;
    }

    function onHandleAutoCloseDate(event) {
        setAutoCloseDate(event.target.value)
    }

    function updateEmailsToObject(property, value) {
        let objCopy = JSON.parse(JSON.stringify(emailsToObject));
        objCopy[property] = value;

        if (property === 'requester') {
            if (value === false) {
                setEmailToRecipients('');
            } else {
                setEmailToRecipients(props.genericRequestFormModel.requesterEmail);
            }
        } else { //try to get the list of people by position from the array, then add/remove them from the list of people as needed.  At the end, replace any double commas that may now exist
            let matchingPosition = property.replaceAll(',,', ',').toLowerCase();
            let matchedEmps = associatedEmployees.filter(x => x.position.replaceAll(' ', '').toLowerCase() === matchingPosition);

            let newEmailCc = JSON.parse(JSON.stringify(emailCcRecipients));
            if (value === false) {
                for (var i = 0; i < matchedEmps.length; i++) {
                    newEmailCc = newEmailCc.replaceAll(matchedEmps[i].email + ',', '');
                    newEmailCc = newEmailCc.replaceAll(matchedEmps[i].email, '');
                }
            } else { //add them
                for (var i = 0; i < matchedEmps.length; i++) {
                    if (newEmailCc === '') {
                        newEmailCc += `${matchedEmps[i].email}`;
                    } else {
                        newEmailCc += `,${matchedEmps[i].email}`;
                    }
                }
            }

            newEmailCc = newEmailCc.replaceAll(',,', ',').replaceAll(' ', '')
            setEmailCcRecipients(newEmailCc);
        }

        setEmailsToObject(objCopy);
    }

    function submitUpdate() {
        //validate that something has actually changed
        let validationMessages = validateUpdate();
        if (validationMessages.length === 0) {
            setProcessing(true)

            let genericRequestFormCopy = JSON.parse(JSON.stringify(props.genericRequestFormModel));
            let reminderNumber = 1;
            if (props.genericRequestFormModel.numberOfRemindersSent) {
                reminderNumber = props.genericRequestFormModel.numberOfRemindersSent + 1;
            }

            genericRequestFormCopy.numberOfRemindersSent = reminderNumber;
            genericRequestFormCopy.mostRecentReminderSentDateTime = getNowMountainTime();
            genericRequestFormCopy.mostRecentReminderSentByEmpName = security.employeeFullName;
            genericRequestFormCopy.mostRecentReminderSentByEmpId = security.empID;

            let commentText = "Reminder email sent!";
            if (autoCloseDate !== '') {
                genericRequestFormCopy.autoCloseTicketAfterDateTime = autoCloseDate;
                genericRequestFormCopy.autoCloseSetByEmpId = parseInt(getEmployeeSecurity().empID);
            }

            //add comment
            if (additionalComment !== '')
                commentText += `<br /><b><u>Additional Comment</u></b><br />${additionalComment}`
            genericRequestFormCopy.genericRequestFormComments.push(createComment(security.empID, commentText))

            //post here to update the GRF. Use existing function
            axios.put('api/GenericRequestForm/UpdateRequest?updateAction=ChangeRequestType', genericRequestFormCopy).then((response) => {
                //SINCE THE EMAIL IS Sent AFTER UPDATING THE FORM, WE WANT TO BE SURE THAT THEY WILL WORK, SO WILL NEED TO UPDATE THE VALIDATION FUNCTION ACCORDINGLY TO MAKE SURE EVERYTHING SEEMS VALID
                let email = {};
                email.to = emailToRecipients;
                email.cc = emailCcRecipients;
                email.subject = `Action Needed - ${requestType.relatedToFormName} ${requestType.requestType1} Request Form Reminder`;
                email.genericRequestFormId = genericRequestFormCopy.id;
                let body = getDefaultComment();
                if (additionalComment !== '')
                    body += `<br /><br /><b><u>Additional Comment</u></b><br />${additionalComment}`

                email.body = body;
                
                axios.post('api/GenericRequestForm/SendGenericRequestReminderEmail', email).then(res => {
                    if (res.data.isSuccessful) {
                        props.onUpdateGenericRequestFormModel(res.data.data, true);
                        props.onUpdatePageAlert({ AlertColor: "success", AlertText: "The reminder email was sent successfully!" });
                        props.toggle();
                    } else {
                        setAlert({ color: "danger", messages: ['There was an error sending sending the reminder email', res.data.errorMessage] })
                        setProcessing(false);
                    }
                })
            })

        } else {
            setAlert({ color: "danger", messages: validationMessages })
            setProcessing(false)
        }
    }

    function validateUpdate() {
        let messages = [];

        //If an auto close date was submitted correctly, make sure that it is in the future
        let selectedDate = new Date(autoCloseDate);
        var userTimezoneOffset = selectedDate.getTimezoneOffset() * 60000;
        selectedDate = new Date(selectedDate.getTime() + userTimezoneOffset)
        let tomorrow = addDays(new Date(new Date().setHours(0, 0, 0, 0)), 1);

        if (selectedDate < tomorrow)
            messages.push("Auto Close Date must be at least one day in the future");

        //email must have at least 1 recipient, and validate that they are actually email addresses
        let allRecipients = [];
        emailToRecipients.split(',').map(x => {
            allRecipients.push(x);
        })

        emailCcRecipients.split(',').map(x => {
            allRecipients.push(x);
        })

        allRecipients = allRecipients.filter(x => x !== '');

        if (allRecipients.length === 0)
            messages.push("No recipients were selected to send the email to.  You must have at least 1 recipient in the 'TO' or 'CC' field");

        for (let i = 0; i < allRecipients.length; i++) {
            if (!validator.isEmail(allRecipients[i]))
                messages.push(`${allRecipients[i]} is not a valid email. Emails should be separated with a comma`)
        }

        return messages;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }


    return (
        <div>
            <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle}>
                {/*<ModalHeader toggle={props.toggle}>Send Request Reminder</ModalHeader>*/}
                <ModalBody>
                    {alert && alert.messages?.length > 0 &&
                        <Alert toggle={() => setAlert()} color={alert.color}>{alert.messages.map((m, index) => {
                            return <p key={index}><b>{m}</b></p>
                        })}
                        </Alert>
                    }

                    <div className={styles.sectionWrapper}>
                        {isLoading ? <>Loading</>
                            : <>
                                {props.formPreferences.allowAutoClose &&
                                    <div className={styles.sectionWrapper}>
                                        <RequestAutoClose onHandleAutoCloseDate={onHandleAutoCloseDate} autoCloseDate={autoCloseDate} rowClassName={styles.flexRow} labelClassName={styles.flex1} inputClassName={styles.flex1} />
                                    </div>
                                }

                                <div className={`${styles.sectionWrapper} ${styles.flexRow}`}>
                                    <div className={styles.flex1}>
                                        <FormGroup switch>
                                            <Input type="switch" checked={emailsToObject.requester} onChange={(e) => updateEmailsToObject('requester', e.target.checked)} />
                                            <Label check>Email Requester?</Label>
                                        </FormGroup>
                                        <FormGroup switch>
                                            <Input type="switch" checked={emailsToObject.regionalmanager} onChange={(e) => updateEmailsToObject('regionalmanager', e.target.checked)} />
                                            <Label check>CC RM?</Label>
                                        </FormGroup>
                                        <FormGroup switch>
                                            <Input type="switch" checked={emailsToObject.assistantregionalmanager} onChange={(e) => updateEmailsToObject('assistantregionalmanager', e.target.checked)} />
                                            <Label check>CC ARM?</Label>
                                        </FormGroup>
                                        <FormGroup switch>
                                            <Input type="switch" checked={emailsToObject.assetmanager} onChange={(e) => updateEmailsToObject('assetmanager', e.target.checked)} />
                                            <Label check>CC AM?</Label>
                                        </FormGroup>
                                        <FormGroup switch>
                                            <Input type="switch" checked={emailsToObject.analyst} onChange={(e) => updateEmailsToObject('analyst', e.target.checked)} />
                                            <Label check>CC Analysts?</Label>
                                        </FormGroup>
                                    </div>
                                    <div className={`${styles.flex2} ${styles.spaceBetween}`}>
                                        Email 'TO' Recipients: <br />
                                        <Input type="textarea" value={emailToRecipients} onChange={(e) => setEmailToRecipients(e.target.value)} rows="3" placeholder="Enter all email addresses of people who should receive this reminder. Separate multiple email addresses with a comma." />
                                    </div>
                                    <div className={`${styles.flex2} ${styles.spaceBetween}`}>
                                        Email 'CC' Recipients: <br />
                                        <Input type="textarea" value={emailCcRecipients} onChange={(e) => setEmailCcRecipients(e.target.value)} rows="3" placeholder="Enter all email addresses of people who should be cc'd on this reminder. Separate multiple email addresses with a comma." />
                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        Default Comments
                                    </div>
                                    <div className={`${styles.flex4} ${styles.changesComment}`} dangerouslySetInnerHTML={{ __html: getDefaultComment() }}></div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        Additional Comments <i>(optional, will be added to the email and form comments)</i>
                                    </div>
                                    <div className={styles.flex4}>
                                        <Input type="textarea" rows={3} value={additionalComment} onChange={(e) => setAdditionalComment(e.target.value)} placeholder="Enter any other additional details regarding what you need from the requester" />
                                    </div>
                                </div>
                            </>
                        }

                    </div>

                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={props.toggle}>Cancel</Button> {"  "}
                    <Button onClick={() => submitUpdate()} disabled={processing}>Send Reminder</Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}
