import React from "react";
import styles from './genericCard.module.scss'

export default function GenericCard(props) {
    return (
        <div className={props.isActive ? `${styles.active} ${styles.wrapper}` : styles.wrapper}>
            {props.header && <div className={styles.header}>
                {props.headerLink ?
                    <a href={props.headerLink} onClick={props.onHeaderLinkClick} target={props.target ? props.target : "_self"}><h6>{props.header.toUpperCase()}</h6></a> :
                    <h6>{props.header.toUpperCase()}</h6>}
            </div>}
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    )
}