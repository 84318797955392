import React, { useEffect, useState } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';
import styles from './changeRequestTypeModal.module.scss';


export default function ChangeRequestTypeEmail(props) {
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        if (!props.email.body || props.email.body === '') {
            updateEmail("body",props.defaultEmailBody)
        }
    },[props.defaultEmailBody, props.email])

    function updateEmail(property, value) {
        let mail = JSON.parse(JSON.stringify(props.email));
        mail[property] = value

        props.onUpdateEmail(props.sendToLabel, mail)
    }

    function getLabel() {
        if (props.sendToLabel === "Admins") {
            return "New Request Type Admins";
        } else if (props.sendToLabel === "Regional") {
            return "Regional Manager (you may edit the email address or add multiple emails using a comma)";
        } else {
            return props.sendToLabel;
        }
    }

    return (<>
        <div className={styles.flexRow}>
            <FormGroup switch>
                <Input id={"switchMe_" + props.sendToLabel} disabled={props.required} type="switch" checked={props.email.send} onChange={(e) => updateEmail("send", e.target.checked)}></Input>
                <Label for={"switchMe_" + props.sendToLabel}>Send Email to {getLabel()}</Label>
            </FormGroup>
        </div>

        <div className={styles.flexRow}>
            <span className={styles.flex1}>To:</span>
            <Input className={styles.flex5} value={props.email.to} onChange={(e) => updateEmail("to", e.target.value)} />
        </div>

        <div className={styles.flexRow}>
            <span>
                By default, the email body will be the default comment, any additional comment, and some basic information along with a link to the new form.<br />
                <span onClick={() => setShowPreview(!showPreview)} className={styles.link}>
                    {showPreview
                        ? <>Hide this text</>
                        : <>Preview/edit this text</>
                    }
                </span>
            </span>
        </div>

        {showPreview &&
            <div className={styles.flexRow}>
                <Input type="textarea" rows="8" value={props.email.body} onChange={(e) => updateEmail("body", e.target.value)} />
            </div>
        }
    </>
    );
}
