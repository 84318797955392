import React from 'react';
import styles from './GenericRequestForm.module.scss';
import { Input } from 'reactstrap';

export default function RequestSingleCredential(props) {

    return (
        <div className={`${styles.credential} card card-body bg-light `}>
            <span className={styles.credentialName}>{props.credential.responsePrompt}</span>
            <div className={styles.infoLine}>
                Username: <br />
                <Input className={styles.disabledInput} type="text" value={props.credential.responseValue} disabled />
            </div>
            <div className={styles.infoLine}>
                Password: <br />
                <Input className={styles.disabledInput} type="text" value={props.credential.responseValue2} disabled />
            </div>
            <div className={styles.infoLine}>
                <span className={styles.credentialAddedByInfo}>Added by: {props.credential.credentialAddedByUserName} - {new Date(props.credential.credentialAddedDateTime).toLocaleDateString()} {new Date(props.credential.credentialAddedDateTime).toLocaleTimeString()}</span>
            </div>
        </div>
    )
}