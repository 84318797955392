import React, { useCallback, useState } from "react";
import { SelectPropertiesModal } from "../modals/SelectPropertiesModal";
import styles from './requestPropertyInfo.module.scss'
import { Button } from 'react-mimg'
import Select from 'react-select'
import { Label, FormGroup } from 'reactstrap'
import { getEmployeeSecurity } from "../../../functions/permissions";
import QuestionWrapper from "./QuestionWrapper";
import LightBox from "../../layout/LightBox";

export default function RequestPropertyInfo(props) {
    const [showPropertySelectModal, setShowPropertySelectModal] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState(props.requestProperties[0]);

    const options = useCallback(() => {
        let security = getEmployeeSecurity();
        let associatedPropertiesIds = security.propertyAssociations.map(x => x.siteId);
        let associatedProperties = props.properties.filter(x => associatedPropertiesIds.includes(x.siteId)).sort((a, b) => a.propertyName < b.propertyName ? -1 : 1);
        let theRest = props.properties.filter(x => !associatedPropertiesIds.includes(x.siteId)).sort((a, b) => a.propertyName < b.propertyName ? -1 : 1);
        let sortedProperties = associatedProperties.concat(theRest)
        return sortedProperties.map(p => (
            {
                value: p.siteId,
                label: p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'
            }))
    }, [props.properties]);

    function onSaveSelectedProperties(requestProperties, selectedProperties) {
        props.onSaveSelectedProperties && props.onSaveSelectedProperties(requestProperties, selectedProperties)
    }

    function onSelectChange(option) {
        let property = props.properties.filter(p => p.siteId === option.value)
        setSelectedProperty(property[0]);
        onSaveSelectedProperties(property, property)
    }

    if (!props.properties || props.requestProperties.length === 0)
        return null;

    return (
        <div className={styles.wrapper}>
            <h4 className={styles.header}>Request Property Info</h4>
            {props.isNewRequest
                ? <>{props.allowMultiPropertySelect
                    ? <QuestionWrapper>
                        <Button onClick={() => setShowPropertySelectModal(true)}>Change Properties</Button>
                        <LightBox>
                            {props.requestProperties.map(p => {
                                return <div key={p.siteId}>{p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'}</div>
                            })}
                        </LightBox>
                    </QuestionWrapper>
                    : <QuestionWrapper showThirdElement={false}>
                        <div>Request Property
                        </div>
                        <FormGroup>
                            <Select
                                autoFocus
                                name='propertySingleSelect'
                                options={options()}
                                onChange={onSelectChange}
                                defaultValue={options().find(x => x.value === selectedProperty?.siteId)}
                            />
                        </FormGroup>
                        {selectedProperty && <FormGroup>
                            <LightBox>
                                <div><b>Property:</b> {selectedProperty.propertyName + ' (' + selectedProperty.yardiCode + ')'}</div>
                                <div><b>Address:</b> {selectedProperty.address + ', ' + selectedProperty.city + ', ' + selectedProperty.state + ' ' + selectedProperty.zip} </div>
                                <div><b>Acquisition Date:</b> {new Date(selectedProperty.startDate).toLocaleDateString()} </div>
                                <div><b>Asset Manager:</b> {selectedProperty.amemp ? selectedProperty.amemp.employeeName : 'No AM for Property'} </div>
                                <div><b>Regional Manager:</b> {selectedProperty.rmemp ? selectedProperty.rmemp.employeeName : 'No RM for Property'}</div>
                            </LightBox>
                        </FormGroup>}
                    </QuestionWrapper>
                }

                </>
                : <>
                    {props.requestProperties.length === 1
                        ? <QuestionWrapper>
                            <div>Request Properties
                                {props.isAdmin && !props.isNewRequest &&
                                    <><br />
                                        <span className={styles.changeLabel} onClick={() => props.onShowChangePropertiesModal(true)}>Change Properties</span>
                                    </>}
                            </div>
                            <FormGroup>
                                <LightBox>
                                    <div><b>Property:</b> {selectedProperty.propertyName + ' (' + selectedProperty.yardiCode + ')'}</div>
                                    <div><b>Address:</b> {selectedProperty.address + ', ' + selectedProperty.city + ', ' + selectedProperty.state + ' ' + selectedProperty.zip} </div>
                                    <div><b>Acquisition Date:</b> {new Date(selectedProperty.startDate).toLocaleDateString()} </div>
                                    <div><b>Asset Manager:</b> {selectedProperty.amemp ? selectedProperty.amemp.employeeName : 'No AM for Property'} </div>
                                    <div><b>Regional Manager:</b> {selectedProperty.rmemp ? selectedProperty.rmemp.employeeName : 'No RM for Property'}</div>
                                </LightBox>
                            </FormGroup>

                        </QuestionWrapper>
                        : <QuestionWrapper>
                            <div>Request Properties
                                {props.isAdmin && !props.isNewRequest &&
                                    <><br />
                                        <span className={styles.changeLabel} onClick={() => props.onShowChangePropertiesModal(true)}>Change Properties</span>
                                    </>}
                            </div>

                            <LightBox>
                                {props.requestProperties.map(p => {
                                    return <div key={p.siteId}>{p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'}</div>
                                })}
                            </LightBox>

                        </QuestionWrapper>
                    }
                </>
            }
            <SelectPropertiesModal
                allProps={props.properties}
                open={showPropertySelectModal}
                toggle={() => setShowPropertySelectModal(false)}
                onSave={onSaveSelectedProperties}
            />
        </div>

    )
}

