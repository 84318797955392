import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Label, FormGroup } from 'reactstrap';
import { Button, axios } from 'react-mimg';
import Select from 'react-select';
import styles from './requestListFilter.module.scss';

export default function RequestListFilter(props)  {
    const [selectedAssignees, setSelectedAssignees] = useState(0);
    const [selectedProperties, setSelectedProperties] = useState(0);
    const [selectedRequestType, setSelectedRequestType] = useState(0);
    const [selectedRequestStatus, setSelectedRequestStatus] = useState(props.selectedStatusOptions);
    const [requestTypeOptions, setRequestTypeOptions] = useState(props.requestTypeOptions);
    const [includeMimgProps, setIncludeMimgProps] = useState(props.includeMimgPropsByDefault);

    useEffect(() => {
        console.log(props.includeMimgPropsByDefault);
        console.log(props.allowMimgTix);
        axios.get(`api/requestTypes/GetAllInCategory?Category=${props.formType}&ExcludeInactives=false`).then((response) => {
            const options = response.data.map(x => ({
                label: x.requestType1,
                value: x.id
            }));
            setRequestTypeOptions(options);
        });
    }, [props.formType, props.includeMimgPropsByDefault, props.allowMimgTix]);

    const clearFilters = () => {
        document.getElementById("dateRequestsFrom").value = "";
        document.getElementById("dateRequestsTo").value = "";
        document.getElementById("txtRequestId").value = "";

        setSelectedRequestType(0);
        setSelectedRequestStatus(0);
        setSelectedProperties(0);
        setSelectedAssignees(0);
        setIncludeMimgProps(props.includeMimgPropsByDefault);

        props.clearFilters();
    };

    return (
        <Row xs={1} sm={1} md={4}>
            <Col>
                <FormGroup className="form-row">
                    <Label className="col-md-4 col-form-label" htmlFor="dateRequestsFrom">From:&nbsp;&nbsp;&nbsp;</Label>
                    <Input id="dateRequestsFrom" className="form-control col-md-8" name="dateRequestsFrom" type="date" placeholder="Requests From Date" />
                </FormGroup>
                <FormGroup className="form-row">
                    <Label className="col-md-4 col-form-label" htmlFor="dateRequestsTo">To:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Label>
                    <Input id="dateRequestsTo" className="form-control col-md-8" name="dateRequestsTo" type="date" placeholder="Requests To Date" />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Select id="selectRequestType" name="selectRequestType" autoFocus isMulti closeMenuOnSelect={false} placeholder="Request Type" options={requestTypeOptions} value={selectedRequestType} onChange={e => setSelectedRequestType(e.value)} />
                </FormGroup>
                <FormGroup>
                    <Select id="selectRequestStatus" isMulti closeMenuOnSelect={false} autoFocus name="selectRequestStatus" type="select" placeholder="Step" options={props.statusOptions} value={selectedRequestStatus} onChange={e => setSelectedRequestStatus(e.value)} />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Select id="selectProps" name="selectProps" closeMenuOnSelect={false} isMulti autoFocus placeholder="Property" options={props.propertyOptions} value={selectedProperties} onChange={e => setSelectedProperties(e.value)} />
                </FormGroup>
                <FormGroup>
                    <Select id="selectAssignee" name="selectAssignee" closeMenuOnSelect={false} isMulti autoFocus placeholder="Assigned to" options={props.assigneeOptions} value={selectedAssignees} onChange={e => setSelectedAssignees(e.value)} />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <div className={styles.flexRow}>
                        <div className={props.allowMimgTix ? styles.flex1 : styles.hidden}>
                            <FormGroup switch>
                                <Input id="switchIncMimgTix" type="switch" role="switch" checked={includeMimgProps} onChange={e => setIncludeMimgProps(e.target.checked)} />
                                MIMG Tix
                            </FormGroup>
                        </div>
                        <div className={styles.flex1}>
                            <Input id="txtRequestId" name="txtRequestId" type="text" placeholder="ID" />
                        </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Button className={styles.button} id="btnSubmitFilters" onClick={props.searchForms}>Submit Filters</Button>
                    <Button className={styles.button} id="btnClearFilters" red={true} onClick={clearFilters}>Clear Filters</Button>
                    {props.isAdmin &&
                        <Button className={styles.button} id="btnSubmitFiltersToCSV" red={false} onClick={props.searchFormsToCSV}>Export</Button>
                    }
                </FormGroup>
            </Col>
        </Row>
    );
};
