import React, { useState, useEffect } from "react";
import RequestFormTemplate from "../../components/requestforms/RequestFormTemplate";
import { getShortDate } from "../../functions/time";
import { Alert } from "reactstrap";

export default function CorporateServicesV2(props) {
    const params = new URLSearchParams(window.location.search)
    const [showForm, setShowForm] = useState(params.get("RequestType") == process.env.REACT_APP_REQUEST_TYPE_FIRE_SURVEY ? false : true);
    const [fireMonth, setFireMonth] = useState(null);
    const [message, setMessage] = useState();
    const formPreferences = {};
    formPreferences["allowReclassification"] = true;
    formPreferences["canReclassifyTo"] = ["Yardi"];
    formPreferences["allowSubmitOnBehalfOf"] = true;
    formPreferences["richTextComments"] = true;
    formPreferences["allowRichTextAdditionalDetails"] = true;
    formPreferences["alwaysHonorPropsDotShowForm"] = true;

    useEffect(() => {
        
        let p = params.get("requestDetailKey1")

        if (p) {
            setFireMonth(p);
            let today = new Date();
            let year = parseInt(p.split('-')[1])
            let month = parseInt(p.split('-')[0])
            let thirdMonday = new Date(year, month - 1, 1 + ((8 - new Date(year, month - 1, 1).getDay()) % 7) + 14);
            let tempDate = new Date(thirdMonday)
            let deadline = new Date(tempDate.setDate(tempDate.getDate() + 14));

            if (today > deadline) {
                setShowForm(false);
                setMessage(`The ${p} Monthly Exterior Fire Inspection form is no longer available because the deadline for submission has passed. The deadline was ${getShortDate(deadline)}`)
            } else if (today < thirdMonday) {
                setShowForm(false);
                setMessage(`The ${p} Monthly Exterior Fire Inspection form has not yet opened for submissions. This form will be open ${getShortDate(thirdMonday)}`)
            } else {
                setShowForm(true);
            }
        }
    }, [])

    useEffect(() => {
    
    },[showForm])

    function alertSpecialInfo() {
        if (!showForm && message) {
            return (
                <Alert color="warning">{message}</Alert>
            )
        }
    }

    return <RequestFormTemplate
        department='Corporate Services'
        group='Corporate Services'
        pageTitle='Corporate Services Request Form'
        formPreferences={formPreferences}
        sendEmails={true}
        requestDetailKey1={fireMonth}
        showForm={showForm}
        specialAlertContent={alertSpecialInfo}
    />

}