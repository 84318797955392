import React, { useEffect, useState } from 'react';
import { axios, Button } from 'react-mimg';
import { itGroupOptions } from '../../../requestTypeConfig';
import Select from 'react-select'
import { TailSpin } from "react-loader-spinner";
import styles from './outlookGroupSelection.module.scss'

export default function OutlookGroupSelection(props) {
    const [allGroups, setAllGroups] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [allGroupOptions, setAllGroupOptions] = useState([]);
    const [suggestedGroupOptions, setSuggestedGroupOptions] = useState([]);
    const [requestedGroupOptions, setRequestedGroupOptions] = useState([]);
    const [selectedGroupOptions, setSelectedGroupOptions] = useState([]);
    const [memberGroupList, setMemberGroupList] = useState('');
    const [syncingGroups, setSyncingGroups] = useState(false);
    const [lastSyncedComment, setLastSyncedComment] = useState();

    useEffect(() => {
        setAllGroups(props.allGroups.filter(x => props.groupTypes.includes(x.groupTypeName)));
    }, [props.allGroups])

    useEffect(() => {
        setUserGroups(props.userExistingGroups.filter(x => props.groupTypes.includes(x.groupTypeName)));
    }, [props.userExistingGroups])

    useEffect(() => {
        let allOptions = [];
        allGroups.map(x => {
            allOptions.push({ label: x.displayName, value: x.id });
        })

        setAllGroupOptions(sortArray(allOptions));

    }, [allGroups])

    useEffect(() => {
        let userOptions = [];
        if (userGroups?.length > 0) {
            userGroups?.map(x => {
                userOptions.push({ label: x.displayName, value: x.id })
            })
        }

        if (suggestedGroupOptions?.length > 0) {
            suggestedGroupOptions?.map(x => {
                let opt = x;
                if (userOptions.filter(y => y.label === x.label).length === 0) {
                    userOptions.push(opt)
                }
            })
        }

        if (requestedGroupOptions?.length > 0) {
            requestedGroupOptions?.map(x => {
                let opt = x;
                if (userOptions.filter(y => y.label === x.label).length === 0) {
                    userOptions.push(opt)
                }
            })
        }

        setSelectedGroupOptions(sortArray(userOptions));

    }, [props.itGroupName, userGroups, props.user, suggestedGroupOptions, requestedGroupOptions])

    useEffect(() => {
        getSuggestedGroups();
        getRequestedGroups();
    }, [allGroups])

    useEffect(() => {
        let matchedComments = props.genericRequestFormModel.genericRequestFormComments.filter(x => x.comment.toLowerCase().includes(('synced ' + props.selectionType).toLowerCase()));

        matchedComments.sort(function (a, b) {
            return (a.dateTimeCreated - b.dateTimeCreated)
        })

        if (matchedComments.length > 0) {
            setLastSyncedComment(matchedComments[0]);
        }
    }, [props.genericRequestFormModel])


    function getRequestedGroups() {
        let requestedGroups = [];
        let possibleMatches = allGroups.filter(x => props.groupTypes.includes(x.groupTypeName));

        //get the detail that had the requested groups, and see if it is in the existing list
        let detail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detail.responseControlType === 'RequestDetailEmailGroupPicker')[0];
        if (detail) {
            let groups = detail.response.split(';');
            groups.forEach(x => {
                let matches = possibleMatches.filter(y => y.displayName.toLowerCase() === x.toLowerCase());
                matches.forEach(z => {
                    requestedGroups.push({ label: z.displayName, value: z.id });
                })
            })
        }

        setRequestedGroupOptions(requestedGroups)
    }

    function getSuggestedGroups() {
        let suggestedGroups = [];
        let possibleMatches = allGroups.filter(x => props.groupTypes.includes(x.groupTypeName));
        let itGroup = itGroupOptions.filter(x => x.label === props.itGroupName)[0];

        if (props.selectionType === 'Security Groups') {
            let pm = itGroupOptions.filter(x => x.label === props.itGroupName)[0];
            if (pm) {
                suggestedGroups.push({ label: pm.defaultSsoGroupName, value: pm.defaultSsoGroupId });
            }
        }

        if (props.selectionType === 'Email Groups') {
            //this may require calling an API, in which case, we need to wait for the response
            if (props.emp.position.toLowerCase().includes('maint')) {
                let maintGroup = props.allGroups.filter(x => x.id === "9cd565bc-3ffe-4fba-9f41-4b8bc3ee144a")[0] //"All Maintenance Users - DL"
                if (maintGroup) {
                    suggestedGroups.push({ label: maintGroup.displayName, value: maintGroup.id });
                }
            }

            if (props.emp.position === "Leasing/Other Office Staff") {
                let leasingGroup = props.allGroups.filter(x => x.id === "615fcae7-b2ad-4994-a78b-ea68b2a54534")[0] //"All Leasing Agents - DL"
                if (leasingGroup) {
                    suggestedGroups.push({ label: leasingGroup.displayName, value: leasingGroup.id });
                }
            }
        }

        if (props.selectionType === 'Sharepoint Groups') {
            let propertySiteIds = props.genericRequestFormModel.genericRequestFormProperties.map(x => x.siteId);
            let propertyYardiCodes = props.propertyList.filter(x => propertySiteIds.includes(x.siteId)).map(x => x.yardiCode);

            //append any yardi codes that are part of the portfolio here, or need to have some sort of mapping
            //there are issues like the folder being called 'SP_OH-Ncorp Kushner 3', can it be renamed to say ohio3llc at the end? That will make it a bit easier to 

            propertyYardiCodes.forEach(x => {
                let matchedFolders = possibleMatches.filter(y => y.displayName?.toLowerCase().includes(x?.toLowerCase()));
                matchedFolders.forEach(z => { //make sure there is a full word in each string that matches the property code - to avoid in cases like property 'MI' including 'Emily Mertz' and 'RWMI'
                    let parts = z.displayName?.toLowerCase().replace(' ', '_').split('_');
                    if (parts.includes(x.toLowerCase())) {
                        if (z.displayName?.toLowerCase().includes('corp')) {
                            if (itGroup?.corpFolders) {
                                suggestedGroups.push({ label: z.displayName, value: z.id });
                            }
                        } else {
                            suggestedGroups.push({ label: z.displayName, value: z.id });
                        }
                    }
                })

            })

            //groups with department name in it
            let deptFolders = possibleMatches.filter(x => x.displayName.toLowerCase().includes(itGroup?.label.toLowerCase()))
            deptFolders.map(x => {
                suggestedGroups.push({ label: x.displayName, value: x.id })
            })

            let getsAmFolderPositions = ['Analyst', 'Asset Manager'] //ex "Ryan Murphy" folder
            let getsAmRegionalFolderPositions = ['Regional Manager'] //ex "Ryan Murphy Regionals" folder
            //sites should not get any of these folders
            let assetManagersForSites = [... new Set(props.requestPropertyInfo.filter(x => x.amemp?.employeeName !== undefined).map(x => x.amemp?.employeeName))];
            assetManagersForSites.forEach(x => {
                let matchedFolders = possibleMatches.filter(y => y.displayName?.toLowerCase().includes(x?.toLowerCase()));
                matchedFolders.map(y => {
                    if (y.displayName?.toLowerCase().includes('regional')) {
                        if (getsAmRegionalFolderPositions.includes(itGroup.label)) {
                            suggestedGroups.push({ label: y.displayName, value: y.id });
                        }
                    } else {
                        if (getsAmFolderPositions.includes(itGroup.label)) {
                            suggestedGroups.push({ label: y.displayName, value: y.id });
                        }
                    }
                })
            })

        }

        if (props.selectionType === 'Microsoft 365 Groups') {

        }

        setSuggestedGroupOptions(sortArray(suggestedGroups));

    }

    function sortArray(options) {
        options.sort((a, b) => {
            const nameA = a.label.toUpperCase(); // ignore upper and lowercase
            const nameB = b.label.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        })

        return options;

    }

    useEffect(() => {
        let s = "";
        if (userGroups?.length > 0) {
            userGroups.map(x => {
                s += x.displayName + ", ";
            })
            setMemberGroupList(s.substr(0, s.length - 2));
        } else {
            setMemberGroupList("No Current Group Membership")
        }
    }, [userGroups])

    function syncGroups() {
        let searchObj = {};
        let selectedIds = selectedGroupOptions.map(x => x.value);
        searchObj.emailGroups = allGroups.filter(x => selectedIds.includes(x.id));
        searchObj.propertyEmployeesId = props.emp.propertyEmployeesId;
        searchObj.email = props.user?.mail;
        searchObj.groupTypes = props.groupTypes;

        setSyncingGroups(true);

        axios.post('api/ITSetup/SyncUserGroups', searchObj).then((response) => {
            setSyncingGroups(false);
            let h = ['Synced ' + props.selectionType + ' Groups'];

            if (response.data.data.addedEmailGroups.length > 0) {
                h.push("Added the following groups:" + response.data.data.addedEmailGroups.map(x => { return x.displayName + '; ' }))
            } else {
                h.push("No email groups were added.");
            }

            if (response.data.data.removedEmailGroups.length > 0) {
                h.push("Removed the following groups:" + response.data.data.removedEmailGroups.map(x => { return x.displayName + '; ' }));
            } else {
                h.push("No email groups were removed.");
            }

            if (response.data.data.unchangedEmailGroups.length > 0) {
                h.push("The user was already a member of the following groups, and is still a member: " + response.data.data.unchangedEmailGroups.map(x => { return x.displayName + '; ' }));
            }

            props.onAddComment(h, true)
            props.onRefreshUserExistingGroups();

        })
    }

    return (
        <div className={styles.wrapper}>
            <span className={styles.header}>{props.header}</span>
            <div className={styles.flexRow}>
                <div className={styles.flex1}>
                    <div>
                        <span className={styles.currentMemberGroupLabel}>Current Member Groups:</span><br />
                        {memberGroupList && memberGroupList.split(', ').map((s, index) => {
                            return <div key={index}>{s}</div>
                        })}
                    </div>
                    <br />
                    <div>
                        <span className={styles.currentMemberGroupLabel}>Requested Member Groups:</span><br />
                        {requestedGroupOptions.length > 0
                            ? <>{
                                requestedGroupOptions.map((x, index) => {
                                    return <div key={index}>
                                        {x.label}
                                    </div>
                                })}</>
                            : <>{(props.selectionType === 'Sharepoint Groups' || props.selectionType === 'Security Groups')
                                ? <>Users cannot request this group type</>
                                : <>None</>
                            }

                            </>}
                    </div>
                </div>

                <div className={styles.flex1}>
                    <span className={styles.currentMemberGroupLabel}>Suggested Member Groups:</span><br />
                    {suggestedGroupOptions.length > 0 && suggestedGroupOptions.map((x, index) => {
                        return <div key={index}>
                            {x.label}
                        </div>
                    })
                    }
                </div>
                <div className={styles.flex2}>
                    {props.groupTypes[0] !== "Distribution groups" &&
                        <Select
                            isMulti
                            isClearable
                            options={allGroupOptions}
                            onChange={setSelectedGroupOptions}
                            value={selectedGroupOptions}
                            name='groupOptionsPicker' />
                    }

                    <div className={styles.flexRow}>
                        {lastSyncedComment
                            ? <span className={`${styles.lastSyncedMsg} ${styles.flex1} ${styles.marginTop10}`}>Last Synced {new Date(lastSyncedComment.dateTimeCreated).toLocaleDateString()} {new Date(lastSyncedComment.dateTimeCreated).toLocaleTimeString()} by {lastSyncedComment.commenterName}</span>
                            : <span className={`${styles.lastSyncedMsg} ${styles.flex1} ${styles.marginTop10}`}>
                                {props.groupTypes[0] !== "Distribution groups"
                                    ? <>This function has not been used on this form!</>
                                    : <>This functionality can no longer be used on this website.  Please use the Microsoft Online Portal Instead</>
                                }
                                </span>
                        }



                        {props.groupTypes[0] !== "Distribution groups" &&
                            <Button className={styles.floatRight} onClick={syncGroups} disabled={syncingGroups}>
                                Sync Groups
                            </Button>
                        }



                        {syncingGroups
                            ? <TailSpin width="50"
                                color='#d59038'
                                arialLabel="Syncing" />
                            : <></>
                        }

                    </div>


                </div>
            </div>
        </div>

    );
}