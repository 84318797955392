import React from 'react';
import flexStyles from '../../../styles/flex.module.scss';
import styles from './GenericRequestForm.module.scss';
import { axios } from 'react-mimg';
import { getEmployeeSecurity } from '../../../functions/permissions';

export default function RequestAttachments(props) {

    const security = getEmployeeSecurity();

    function onAttachmentClicked(event) {

        let id = event.target.id.replace("bigScreenLink_", "").replace("smallScreenLink_", "");
        let fileName = event.target.getAttribute("data-filename");
        let fileList = props.attachmentList;
        let file = fileList.find(x => x.id === parseInt(id));

        axios({
            url: `api/file/getencryptedfile?Id=${file.id}&fileName=${file.fileName}&formId=${file.formId}&fileDirectory=${props.formType}`,
            method: "get",
            responseType: 'blob',
        }).then((response) => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        })
    }


    return (
        <div className={flexStyles.paddingTopBot10}>
            <h4 className={styles.sectionHeader}>Request Attachments</h4>
            <div className={flexStyles.rowFlexContainer80}>
                <div className="card card-body bg-light">
                    <div className="d-none d-sm-inline">
                        {props.attachmentList &&
                            <div className={flexStyles.rowFlexContainer}>
                                <div className={flexStyles.flexColumnHeader2}>Date Added</div>
                                <div className={flexStyles.flexColumnHeader2}>Added By</div>
                                <div className={flexStyles.flexColumnHeader4}>Original File Name</div>
                                <div className={flexStyles.flexColumnHeader4}>File Description/Download Link</div>
                            </div>
                        }

                        {props.attachmentList &&
                            props.attachmentList.map(p => (
                                <div key={p.id} className={flexStyles.rowFlexContainer}>
                                    <div className={flexStyles.flex2}>{new Date(p.docDate).toLocaleDateString() + " " + new Date(p.docDate).toLocaleTimeString()}</div>
                                    <div className={flexStyles.flex2}>{p.uploadedByName}</div>
                                    <div className={flexStyles.flex4}>{p.originalFileName}</div>
                                    <div className={flexStyles.flex4}>
                                        {props.formType === "Drivers" ? (
                                            security?.isDriverAdmin ? (
                                                <span id={"bigScreenLink_" + p.id} data-filename={p.originalFileName} className={styles.linkText} onClick={onAttachmentClicked}>{p.docDescription}</span>
                                            ) : (
                                                <div> Must be Driver Admin to download file </div>
                                            )
                                        ) : <span id={"bigScreenLink_" + p.id} data-filename={p.originalFileName} className={styles.linkText} onClick={onAttachmentClicked}>{p.docDescription}</span>}
                                    </div>
                                </div>
                            ))
                        }
                        {!props.attachmentList &&
                            <div>There are no documents for this request yet</div>
                        }
                    </div>
                    <div className="d-block d-sm-none">
                        {props.attachmentList
                            ? props.attachmentList.map(p => {
                                return <div key={p.id} className={`row ${flexStyles.marginTop10}`}>
                                    <span><span className={styles.boldUnderline}>Added By:</span> {p.uploadedByName}</span>
                                    <span><span className={styles.bold}>Date:</span> {new Date(p.docDate).toLocaleDateString() + ' ' + new Date(p.docDate).toLocaleTimeString()}</span>
                                    <span><span className={styles.bold}>Name:</span> {p.originalFileName}</span>
                                    {props.formType === "Drivers" ? (
                                        security?.isDriverAdmin ? (
                                            <span><span id={"smallScreenLink" + p.id} data-filename={p.originalFileName} className={styles.linkText} onClick={onAttachmentClicked}>{p.docDescription}</span></span>
                                        ) : (
                                            <div> Must be Driver Admin to download file </div>
                                        )
                                    ) : <span><span id={"smallScreenLink" + p.id} data-filename={p.originalFileName} className={styles.linkText} onClick={onAttachmentClicked}>{p.docDescription}</span></span>}
                                </div>
                            })
                            : <div>There are no documents for this request yet</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

