import React, { useEffect, useState } from 'react';
import { axios, Button } from 'react-mimg';
import { Input } from 'reactstrap';
import styles from './ITHelpdeskProcessing.module.scss';
import RequestSingleCredential from '../../../formelements/RequestSingleCredential';

export default function YardiPayleaseAccountInfo(props) {

    const [existingCredential, setExistingCredential] = useState();
    const [payleaseUserName, setPayleaseUserName] = useState("");
    const [payleasePassword, setPayleasePassword] = useState('Password123!');

    useEffect(() => {
        let emailAddress = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detail.defaultDataPropName === "employeeEmail")[0]?.response
        setPayleaseUserName(emailAddress)
        setExistingCredential(props.genericRequestFormModel.genericRequestResponses.filter(x => x.responsePrompt === 'Paylease Login')[0]);
    }, [props.genericRequestFormModel])

    function addPayleaseCredential() {
        let credential = {};
        credential.responsePrompt = "Paylease Login"
        credential.responseValue = payleaseUserName;
        credential.responseValue2 = payleasePassword;

        axios.post(`api/GenericRequestForm/AddGenericRequestCredential?formId=${props.genericRequestFormModel.id}`, credential).then((response) => {
            let pageAlert = { AlertColor: "success", AlertText: "" };
            props.onAddCredential(response.data, pageAlert, "Credential");
            props.onAddComment([`Successfully marked Paylease Login as created for ${payleaseUserName}`], true);

        }).catch((error) => {
            props.setModalAlert({ color: "danger", message: "Error saving request credential. Error " + error })
        })
    }

    return (
        <div className={styles.questionWrapper}>
            <div className={`${styles.questionRow} ${styles.flexRow}`}>
                <div className={styles.flex2}>
                    <h5 className={styles.header}>Step 3 (optional) Setup Paylease Account</h5>
                </div>

                <div className={styles.flex2}>
                    <div className={styles.questionRow}>
                        {existingCredential
                            ? <RequestSingleCredential credential={existingCredential} />
                            : <><b>Paylease UserName</b>
                                <Input type="text" value={payleaseUserName} placeholder="Paylease Username" onChange={(e) => setPayleaseUserName(e.target.value)} />
                                <b>Paylease Password</b>
                                <Input type="text" value={payleasePassword} placeholder="Paylease Default Password" onChange={(e) => setPayleasePassword(e.target.value)} />
                                <Button onClick={addPayleaseCredential}>Add Paylease Credential</Button>
                            </>
                        }
                    </div>

                </div>
            </div>



        </div>



    );
}