/* eslint-disable no-eval*/
import { getNowMountainTime } from 'react-mimg';
import { getEmployeeSecurity } from '../permissions';

export function getIsCorporateServicesAdmin(requestTypes, security) {
    let adminObjects = requestTypes.data.filter(x => x.relatedToFormName === "CorporateServicesRequests").map(rt => rt.requestTypeAdmins);
    let adminList = [];
    for (let i = 0; i < adminObjects.length; i++) {
        let l = adminObjects[i];
        for (let j = 0; j < l.length; l++) {
            adminList.push(l[j].adminEmpId);
        }
    }

    return adminList.includes(parseInt(security.empID));
}

export function createComment(requesterEmpId, comment) {
    let security = getEmployeeSecurity();
    return {
        empId: parseInt(security.empID),
        dateTimeCreated: getNowMountainTime(),
        commenterName: security.employeeFullName,
        isByRequester: (requesterEmpId === parseInt(security.empID)),
        comment: comment.replaceAll('\n','<br />'),
        adminOnly: false
    }
}

export function createDepartmentSpecificComment(requesterEmpId, comment, department, isArray) {
    let security = getEmployeeSecurity();

    let c = comment;
    if (isArray) {
        c = "";
        comment.map(x => {
            c += x + "\\n";
        })
    }
    return {
        empId: parseInt(security.empID),
        dateTimeCreated: getNowMountainTime(),
        commenterName: security.employeeFullName,
        isByRequester: (requesterEmpId === parseInt(security.empID)),
        comment: c,
        specificDepartment: department,
        commentIsArray: isArray
    }
}

/*Email Notifications*/
export function getAdminEmails(genericRequestFormModel, requestTypes) {
    let adminEmails = requestTypes.filter(x => x.id === genericRequestFormModel.requestTypeId)[0].notificationEmails.emailAddresses;
    //    adminEmails += ',CorporateServices@MonarchInvestment.com';

    return adminEmails;
}


export function getPropertyNames(genericRequestFormModel, propertyList) {
    let properties = propertyList.filter(x => genericRequestFormModel.genericRequestFormProperties.map(x => x.siteId).includes(x.siteId));

    let propNames = "";
    let sep = ""
    if (properties.length > 1) {
        for (let p = 0; p < properties.length; p++) {
            propNames = propNames + sep + properties[p].propertyName;
            sep = "<br />"; //this will go in an html string
        }
        return propNames;
    } else {

        return properties[0].propertyName;
    }
}

export function getPropertyName(genericRequestFormModel, propertyList) {
    let properties = propertyList.filter(x => genericRequestFormModel.genericRequestFormProperties.map(x => x.siteId).includes(x.siteId));

    let propNames = "";
    let sep = ""
    if (properties.length > 1) {
        for (let p = 0; p < properties.length; p++) {
            propNames = propNames + sep + properties[p].propertyName;
            sep = "<br />"; //this will go in an html string
        }
        return propNames;
    } else {

        return properties[0].propertyName;
    }
}

export function getNextStepInfo(workflow, currentStepId) {
    // eslint-disable-next-line no-unused-vars
    let security = getEmployeeSecurity(); //DO NOT REMOVE, allows for eval functions to use security
    let nextStepId;
    //let currentStepId = genericRequestFormModel.currentWorkflowStep; //current step needs to be passed in by the existing step, to account for some steps like assignment being able to happen multiple times
    let currentStep = workflow.requestWorkflowSteps.filter(x => x.id === currentStepId)[0];
    if (!currentStep) {
        currentStep = workflow.requestWorkflowSteps[0];
        currentStepId = currentStep.id;
    }

    nextStepId = currentStep.defaultNextStep; //this is reset below if any other conditions exist and are met
    let conditions = workflow.requestWorkflowSteps.filter(x => x.id === currentStepId)[0].requestWorkflowStepConditions;
    for (let i = 0; i < conditions.length; i++) {
        if (eval(conditions[i].conditionsAsValidJavascriptBoolean)) {  //EVAL may be considered unsafe. However, as the page where this is entered is different from where it is stored, and only web developers will initially be able to add conditions, we determined it was safe enough. (Curtis Pottle 11-18-2021)
            nextStepId = conditions[i].nextStepId;
            break; //if any conditions are met, then act and exit the loop. this means more complex stuff would need some thought put into the sort order especially if things need to be included/excluded dynamically
        }
    }

    return nextStepId;
}

