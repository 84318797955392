import React from 'react';
import flexStyles from '../../../styles/flex.module.scss';
import styles from './GenericRequestForm.module.scss';
import RequestSingleCredential from './RequestSingleCredential';

export default function RequestCredentials(props) {

    return (
        <div className={flexStyles.paddingTop10}>
            <h4 className={styles.sectionHeader}>Associated Credentials (Passwords are temporary only)</h4>
            <div>
                <div className={styles.flexRow}>
                    {props.credentials.map((r, index) => {
                        return <RequestSingleCredential key={index} credential={r} />
                    })}
                </div>
            </div>
        </div>
    );
}
