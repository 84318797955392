import { getMIMGUrl } from 'react-mimg'
import { getEmployeeSecurity } from '../../../functions/permissions';

export const updateProfileUrl = function (isSelf, empId) {
    const security = getEmployeeSecurity();

    if (security.isProfileAdmin)
        return getMIMGUrl() + "v2/Profiles/Employee?tab=Edit%20Profile&ID=" + empId;

    if (isSelf)
        return "profiles/employee?id=" + empId + "&tab=edit%20profile";
   

    else return "";
}