import React, { useEffect, useState } from 'react';
import { axios, Button } from 'react-mimg';
import { Input } from 'reactstrap';
import validator from 'validator';
import RequestSingleCredential from '../../../formelements/RequestSingleCredential';
import styles from './ITHelpdeskProcessing.module.scss';

export default function YardiOneAccountAdd(props) {

    const [existingCredential, setExistingCredential] = useState();
    const [existingYardiUserId, setExistingYardiUserId] = useState('');
    const [newYardiPersonCode, setNewYardiPersonCode] = useState('');

    useEffect(() => {
        setExistingCredential(props.genericRequestFormModel.genericRequestResponses.filter(x => x.responsePrompt === 'Yardi One Login')[0]);
    }, [props.genericRequestFormModel])

    useEffect(() => {
        if (props.emp?.yardiPersonId && props.emp?.propertyEmployees?.yardiPersonId !== '') {
            setExistingYardiUserId(props.emp?.yardiPersonId);
        }
    },[props.emp])

    function onAddYardiOneAccount() {
        if (!validator.isLength(newYardiPersonCode, { min: 0, max: 8 }) || validator.isEmail(newYardiPersonCode)) {
            props.setModalAlert({ AlertColor: "danger", AlertText: "Yardi Person ID must be 8 or fewer characters and cannot be an email. Example: cpottle" })
            return;
        }
        if (newYardiPersonCode !== '') {
            let cred = {};
            cred.responseType = "Credential"
            cred.responsePrompt = "Yardi One Login"
            cred.responseValue = props.email;
            cred.responseValue2 = "Uses Email Password";

            axios.post(`api/GenericRequestForm/AddGenericRequestResponse?formId=${props.genericRequestFormModel.id}`, cred).then((response) => {
                let pageAlert = { AlertColor: "success", AlertText: "" };
                props.onAddCredential(response.data, pageAlert, "Credential");
                props.onAddComment([`Marked Yardi One Username as created for ${props.email}`], true);

                axios.get(`api/ITSetup/UpdatePropertyEmployeesYardiPersonId?propertyEmployeesId=${props.emp?.propertyEmployeesId}&yardiPersonId=${newYardiPersonCode}`);

            }).catch((error) => {
                props.setModalAlert({ AlertColor: "danger", AlertText: "Error saving request credential. Error " + error })
            })
        }

    }

    return (
        <div className={styles.questionWrapper}>
            {!existingCredential && existingYardiUserId !== '' &&
                <div className={styles.questionRow}>
                    <h5 className={styles.warning}>There is already a Yardi User Id mapped to this employee.  Matched value: {existingYardiUserId}</h5>
                </div>   
            }

            <div className={`${styles.questionRow} ${styles.flexRow}`}>
                <div className={styles.flex2}>
                    <h5 className={`${styles.header}`}>Step 1: Mark that Yardi One Access was setup for this user</h5>
                    {existingYardiUserId !== '' &&
                        <b><i>Existing Yardi Person Id: {existingYardiUserId}</i></b>
                    }
                </div>
                
                <div className={styles.flex2}>
                    {existingCredential
                        ? <RequestSingleCredential credential={existingCredential} />
                        : <div>
                            <Input className={styles.responseButton} type="text" value={newYardiPersonCode} onChange={(e) => setNewYardiPersonCode(e.target.value)} placeholder="Enter the Yardi Code for this employee" />
                            <Button className={styles.responseButton} onClick={onAddYardiOneAccount}>Yardi One Access was Completed</Button>
                        </div>
                    }
                </div>
            </div>
        </div>

    );
}