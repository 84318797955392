import React, { useEffect, useState } from 'react';
import { axios, Button } from 'react-mimg';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import { officeLicenseTypes } from '../../../requestTypeConfig';
import Select from 'react-select'
import { TailSpin } from "react-loader-spinner";

export default function CreateOutlookUserModal(props) {
    const [emp, setEmp] = useState(props.emp);
    const [subscribedSkus, setSubscribedSkus] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [stateOptions, setStateOptions] = useState();
    const [selectedState, setSelectedState] = useState();
    const [licenseTypeOptions, setLicenseTypeOptions] = useState([])
    const [tempPassword, setTempPassword] = useState('Temp123!')
    const [selectedLicenseTypeOptions, setSelectedLicenseTypeOptions] = useState([]);
    const [modalAlert, setModalAlert] = useState();

    useEffect(() => {
        if (props.isOpen) {
            //get licenses available to our company
            axios.get('api/ITSetup/GetSubscribedSkus').then((response) => {
                setSubscribedSkus(response.data);

                let typeData = [];
                let selected = [];
                response.data.map(d => {
                    let skuPartNumber = d.skuPartNumber;
                    let matchingData = officeLicenseTypes.filter(x => x.label === skuPartNumber)[0];
                    if (matchingData?.show) {
                        let option = { label: matchingData.displayName + ' (' + (d.prepaidUnits.enabled - d.consumedUnits) + ' of ' + d.prepaidUnits.enabled + ' licenses available)', value: d.skuId }
                        typeData.push(option)
                        if (skuPartNumber === 'EXCHANGESTANDARD') {
                            selected.push(option);
                        }
                    }
                })

                setLicenseTypeOptions(typeData);
                setSelectedLicenseTypeOptions(selected);
            })
        }
    }, [props.isOpen])

    useEffect(() => {
        axios.get("api/state/getstates").then((response) => {
            let defaultOption = { value: "", label: "" };
            let options = [defaultOption];
            response.data.forEach(s => {
                options.push({ value: s.stateAbbr, label: s.state1 + " - " + s.stateAbbr });
            })

            setStateOptions(options);

            //get states
            let propertyStates = [... new Set(props.requestPropertyInfo.map(x => x.state))];
            console.log(propertyStates)
            let statesWithCount = [];
            propertyStates.forEach(y => {
                let state = {};
                state.stateAbbr = y;
                state.propCount = props.requestPropertyInfo.filter(x => x.state === y).length
                statesWithCount.push(state);
            })

            //get state with most properties (probably defaults to alphabetical or first selected, if not, unsure, who cares)
            statesWithCount.sort((a, b) => {
                return b.propCount - a.propCount;
            })

            let firstState = statesWithCount[0];
            let selectedOption = options.filter(x => x.value === firstState.stateAbbr)[0]

            if (selectedOption) {
                setSelectedState(selectedOption);
            }
            
        })
    }, [])

    function onCreateUser() {
        setIsProcessing(true);
        let obj = {};
        obj.email = props.email;
        obj.firstName = props.emp.firstName;
        obj.lastName = props.emp.lastName;
        obj.displayName = obj.firstName + ' ' + obj.lastName;
        obj.licenseSkus = selectedLicenseTypeOptions.map(x => x.value);
        obj.stateCode = selectedState ? selectedState.value : null;
        obj.tempPassword = tempPassword;
        obj.mobilePhone = props.cellPhone;

        axios.post(`api/ITSetup/CreateOutlookUser`, obj).then((response) => {
            if (response.data.isSuccessful) {
                props.onSetUser(response.data.data);
                setModalAlert();
                props.onUpdateFormAlert({ color: "success", message: "Successfully created user. Please proceed to add to the appropriate SSO Group" })
                onAddEmailAccount()
                let addedLicenseNames = selectedLicenseTypeOptions.map(x => x.label + '; ');

                props.onAddComment([`Successfully created new Outlook User ${props.email}`, `Assigned license(s) ${addedLicenseNames}`], true);
                props.toggle();
            } else {
                setModalAlert({ color: "danger", message: response.data.errorMessage });
            }

            setIsProcessing(false);
        })
    }

    function onAddEmailAccount() {
        let credential = {};
        credential.responsePrompt = "Email Login"
        credential.responseValue = props.email;
        credential.responseValue2 = tempPassword;

        axios.post(`api/GenericRequestForm/AddGenericRequestCredential?formId=${props.genericRequestFormModel.id}`, credential).then((response) => {
            let pageAlert = { AlertColor: "success", AlertText: "" };
            props.onAddCredential(response.data, pageAlert, "Credential");
        }).catch((error) => {
            setModalAlert({ color: "danger", message: "Error saving request credential. Error " + error })
        })
    }

    return (
        <div>
            <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}>Create Email Address {props.email} for {props.emp?.firstName} {props.emp?.lastName}</ModalHeader>
                <ModalBody>
                    {modalAlert &&
                        <Alert color={modalAlert.color}>{modalAlert.message}</Alert>
                    }
                    First Name: {emp?.firstName} <br />
                    Last Name: {emp?.lastName} <br />
                    Display Name: {emp?.firstName} {emp?.lastName} <br />
                    Email: {props.email} <br /><br />
                    <div>
                        <b>Initial Password</b>
                        <Input type="text" placeholder="Temp Password" value={tempPassword} onChange={(e) => setTempPassword(e.target.value)} />
                    </div><br />

                    <div>
                        <b>User Phone Number For MFA</b>
                        <Input type="text" placeholder="Cell Phone For MFA" value={props.cellPhone} onChange={(e) => setUserPhone(e.target.value)} />
                    </div><br />

                    <div>
                        <b>Select Licenses</b>
                        <Select
                            isMulti
                            isClearable
                            options={licenseTypeOptions}
                            onChange={setSelectedLicenseTypeOptions}
                            value={selectedLicenseTypeOptions}
                            name='licensePicker' />
                    </div> <br />

                    <div>
                        <b>Select State - <i>If user has properties in more than 1 state, select the state with the most properties, or leave blank.</i></b>
                        <Select
                            placeholder={"Select Primary State"}
                            options={stateOptions}
                            onChange={setSelectedState}
                            value={selectedState}
                            name='statePicker'
                        />
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button transparent onClick={props.toggle}>Close</Button>
                    {isProcessing
                        ? <TailSpin width="50"
                            color='#d59038'
                            arialLabel="Syncing" />
                        : <Button enabled={isProcessing} onClick={() => onCreateUser()}>Create</Button>
                    }
                </ModalFooter>
            </Modal>

        </div>


    );
}