

export class GenericRequestFormDocumentModel {

    constructor() { //link is any object that gets passed in and has these properties

        this.formId = 0;
        this.docType = "";
        this.docDate = null;
        this.docDescription = "";
        this.fileDirectory = "";
        this.fileName = "";
        this.createDateTime = null;
        this.originalFileName = null;
        this.uploadedByEmpID = 0;
        this.uploadedByName = "";
        this.isByRequester = 0;
    }

}
