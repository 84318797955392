import React, { Component } from 'react';
import { Input, UncontrolledPopover, PopoverHeader, PopoverBody, Label } from 'reactstrap';
import LightBox from '../../layout/LightBox';
import Required from '../../layout/Required';
import QuestionWrapper from '../formelements/QuestionWrapper';
import RequestDetail from './RequestDetail';
import styles from './RequestDetailAssociatedDocuments.module.scss';

export class RequestDetailAssociatedDocuments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showPopover: false,
        }
    }

    togglePopover = () => {
        this.setState({ showPopover: !this.state.showPopover })
    }


    render() {
        return (
            <QuestionWrapper>
                <Label to='documentSelect'>{this.props.RequestTypeDetail.questionLabel}</Label>
                <LightBox id='documentSelect'>
                    <h6>Please provide the documents listed. Documents with a {<Required />} are required to submit the form. If you have more documents than there is room for here, you will generally be able add additional documents after submitting the form. Maximum document size: 45 MB.</h6>
                    
                    <QuestionWrapper>
                        <div className={styles.header}>Document Name</div>
                        <div className={styles.header}>Uploaded Filename</div>
                    </QuestionWrapper>

                    {this.props.RequestTypeDetail.requestTypeDetailAssociatedDocuments.filter(x => x.isActive).sort((a, b) => a.sortOrder - b.sortOrder < 0 ? -1 : 1).map(d => {
                        return (
                            <RequestDetail key={d.id} RequestTypeDetail={
                                {
                                    id: 'requestTypeAssociatedDocument',
                                    isRequired: d.isRequired,
                                    questionLabel: d.documentDescription,
                                    linkUrl: d.linkUrl
                                }}>
                                <Input className="requestTypeAssociatedDocument" type="file" id={"fileUpload_" + d.id} />
                                {(d.documentDescription.toLowerCase() === "Certificate of Insurance".toLowerCase() || d.documentDescription.includes("COI")) &&
                                    <>
                                        <div id={"tooltip_" + d.id} className={styles.tooltipIdentifier} onClick={this.togglePopover}>COI Requirements</div>
                                        <UncontrolledPopover
                                            className='documentPopover'
                                            isOpen={this.state.showPopover}
                                            trigger="legacy"
                                            toggle={this.togglePopover}
                                            target={"tooltip_" + d.id}
                                            placement="right">
                                            <PopoverHeader className={styles.popoverHeader}>COI Requirements</PopoverHeader>
                                            <PopoverBody className={styles.popoverBody}>
                                                All COI's need the following: In addition to being a certificate holder, Monarch must be named additionally insured for General Liability as well as adding Monarch and address or property as a certificate holder.<br /><br />
                                                All COI's need to have the below information included in the Additional Remarks: <br /><br />
                                                "Monarch Investment and Management Group, LLC and [Property Legal name] are included as additional insured with respect to the foregoing Commercial General Liability Policy, and coverage is Primary and Non-contributory."
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </>
                                }
                            </RequestDetail>
                        )
                    })}
                </LightBox>
            </QuestionWrapper>
        );
    }
}
