import React, { useState } from 'react'
import { axios, Button } from 'react-mimg';
import { Alert, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { isEmailValid } from '../../functions/validation';

export default function ForgotUsernameOrPasswordModal(props) {
    const USERNAME = 'username';
    const PASSWORD = 'password';
    const [email, setEmail] = useState('');
    const [selected, setSelected] = useState(USERNAME);
    const [error, setError] = useState('');

    function onResetPasswordClick() {
        if (isEmailValid(email)) {
            axios.post('api/login/resetUser?email=' + email)
                .then(() => props.toggle())
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        setError(error.response.data)
                    }
                    else {
                        setError('Unexpected error sending the email. Please try again.');
                    }
                })
        }
        else {
            setError('Please submit a valid email address.')
        }
    }

    function onSendUsername() {
        if (isEmailValid(email)) {
            axios.post('api/login/sendUsername?email=' + email)
                .then(() => props.toggle())
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        setError(error.response.data)
                    }
                    else {
                        setError('Unexpected error sending the email. Please try again.');
                    }
                })
        }
        else {
            setError('Please submit a valid email address.')
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>Forgot Username or Password?</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>I forgot my:</Label>
                    <FormGroup check>
                        <Input type='checkbox' checked={selected === USERNAME} onChange={() => setSelected(USERNAME)} />Username
                    </FormGroup>
                    <FormGroup check>
                        <Input type='checkbox' checked={selected === PASSWORD} onChange={() => setSelected(PASSWORD)} />Password
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <Label for='email'>Email Address:</Label>
                    <Input id='email' type='text' value={email} onChange={e => setEmail(e.target.value)} />
                    <FormText>This can either be your @monarchinvestment.com or your personal email address (provided at the time of hire).</FormText>
                </FormGroup>
                <Alert color='danger' isOpen={error.length > 0} toggle={() => setError('')}>{error}</Alert >
                {selected === USERNAME && <Button onClick={onSendUsername}>Email Username</Button>}
                {selected === PASSWORD && <Button onClick={onResetPasswordClick}>Email Reset Password Link</Button>}
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}
