import React, { useState } from 'react'
import { ActionLink, Button, Link } from 'react-mimg'
import { UncontrolledAlert, Alert, Form, FormGroup, Input, Label } from 'reactstrap'
import { PageTitle } from '../../components/layout/PageTitle'
import styles from './login.module.scss'
import image from '../../images/handsBanner.webp'
import logo from '../../images/mimgLogo3.png'
import Required from '../../components/layout/Required'
import { useNavigate } from 'react-router-dom'
import ForgotUsernameOrPasswordModal from '../../components/login/ForgotUsernameOrPasswordModal'
import useAuth from '../../hooks/useAuth'
import { useEffect } from 'react'

export default function Login() {
    const [showResetModal, setShowResetModal] = useState(false);
    const navigate = useNavigate();
    const { signIn, error, clearError, isAuthenticated, signOut } = useAuth();

    useEffect(() => {
        signOut();
    },[])

    function onSubmit(e) {
        e.preventDefault();
        signIn(e.target.username.value, e.target.password.value)
        .then(() => {
            navigate("/");
        })
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.loginWrapper}>
                <div className={styles.login}>
                    <img className={styles.logo} src={logo} />
                    <PageTitle PageTitle="Log In" />
                    <Form onSubmit={onSubmit} className={styles.form}>
                        <FormGroup>
                            <Label for="username">Username (not your email) <Required /></Label>
                            <Input required type='text' id='username' name='username' />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password <Required /></Label>
                            <Input required type='password' id='password' name='password' />
                        </FormGroup>
                        <Alert color='danger' isOpen={error.length > 0} toggle={() => clearError()} >{error}</Alert >
                        <div className={styles.buttons}>
                            <Button type='submit'>Login</Button>
                        </div>
                    </Form>
                    <FormGroup>
                        <h6>What if <ActionLink href="" title="I forgot my username or password?" onClick={() => setShowResetModal(true)} /></h6>
                        <h6><Link href="register" title="Register" /> if you don&apos;t have an account.</h6>
                        <ForgotUsernameOrPasswordModal isOpen={showResetModal} toggle={() => setShowResetModal(!showResetModal)} />
                    </FormGroup>
                    <div className={styles.links}>
                        <h6>Quick Links:</h6>
                        <Link href='https://identity.enterprise.spendclarity.visa.com/login?signin=a749aaff4661366c9d03ac1cd73777b3#!/login' title='Credit Card (Visa)' />
                        <Link href='https://authenticate.visionlms.com/idp/profile/SAML2/Redirect/SSO;jsessionid=E4206214DF8693950779BDDBFDB6D39E?execution=e1s1' title='Monarch University-Grace Hill' />
                        <Link href='https://portal.oasisassistant.com/' title='Paychex (Oasis/Prism)' />
                        <Link href='https://peliton.taserver.com/site/asp/login.asp' title='Time America' />
                        <Link href='https://monarch28439.yardione.com/' title='Yardi One (Voyager, CRM, Rent Cafe, etc)' />
                    </div>
                </div>
            </div>
            <div className={styles.imageWrapper}>
                <img className={styles.image} src={image} />
            </div>
        </div >
    )
}
