import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { PageTitle } from "../../components/layout/PageTitle";
import { MimgAlert } from "../../components/layout/MimgAlert";
import { getEmployeeSecurity, isDeveloper } from "../../functions/permissions";
import { CustomAlertLink } from "../../models/CustomAlertLink";
import { getUnauthorizedToViewPageAlertMessage } from "../../functions/permissions";
import GenericRequestForm from "../../components/requestforms/formelements/GenericRequestForm";
import { axios } from 'react-mimg'
import { GenericRequestFormModel } from "../../models/genericrequestforms/GenericRequestFormModel";
import GenericRequestFormList from "../../components/requestforms/requestlist/GenericRequestFormList";
import { updateProfileUrl } from "../profiles/EmployeeProfile/employeeProfileConfig";
import { useQuery } from '../../functions/routing';
import { Alert } from "reactstrap";

export default function RequestFormTemplate(props) {
    const action = useParams().action;
    const [alert, setAlert] = useState();
    const [availableAssignees, setAvailableAssignees] = useState([]);
    const [requestTypes, setRequestTypes] = useState([]);
    const [properties, setProperties] = useState([]);
    const [formDetails, setFormDetails] = useState(new GenericRequestFormModel());
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [selectedEmployee2, setSelectedEmployee2] = useState();
    const [selectedSites, setSelectedSites] = useState();
    const [canAccessForm, setCanAccessForm] = useState(false);
    const [defaultValues, setDefaultValues] = useState();
    const [propertyEmployeesId, setPropertyEmployeesId] = useState();
    const formType = props.group.replaceAll(/\s+/g, '');
    const notFound = "Could not find form";
    const pageTitle = props.pageTitle;
    const goToListUrl = 'requests/' + formType.toLowerCase() + '/list';
    const addFormUrl = 'requests/' + formType.toLowerCase() + '/add';
    const security = getEmployeeSecurity();
    const query = useQuery();
    const [showForm, setShowForm] = useState();

    const isUserAdmin = useCallback(() => {
        const isAssignee = availableAssignees.map(x => x.propertyEmployeesId).includes(parseInt(security.empID));
        const isRequestTypeAdmin = [].concat(...requestTypes.map(x => x.requestTypeAdmins))?.map(admin => admin.adminEmpId.toString()).includes(security.empID);
        const isFormAdmin = security.canEditForms.includes(props.group);

        return isAssignee || isRequestTypeAdmin || isFormAdmin || isDeveloper();
    }, [availableAssignees, security, requestTypes, props.group])

    useEffect(() => {

        if (props.showForm === undefined || formDetails.id > 0) {
            setShowForm(true)
        } else if (parseInt(query.get("requesttype"))) {
            if (props.formPreferences.alwaysHonorPropsDotShowForm) {  /*There is logic that needs to be completed before the form is shown (IT Form Requires a Phone Number), but conflicted with existing behavior so added this formPreference which overrides the previous behavior */
                setShowForm(props.showForm);
            } else {
                setShowForm(true);
            }
        } else {
            setShowForm(props.showForm)
        }

    }, [props.showForm, formDetails.id])


    useEffect(() => {
        const predefinedRequestTypeId = parseInt(query.get("requesttype"));
        const propertyEmployeesId = parseInt(query.get("propertyemployeesid"))
        const justChanged = query.get("justchanged");
        const emailsWereSent = query.get("emailsweresent")
        const excludeUnsearchables = predefinedRequestTypeId || parseInt(action) ? "false" : "true"

        axios.get('api/requestTypes/GetAllInCategory?Category=' + formType + '&ExcludeInactives=false' + '&ExcludeUnsearchables=' + excludeUnsearchables)
            .then((res) => {
                if (res.data.length === 0) {
                    setAlert({ AlertColor: "danger", AlertText: "No request types were found for this page.  Either you do not have access to view any of the request types, or the department in charge of this form does not currently have any available request types.  Please contact the department in charge of this form with questions." });
                }

                if (parseInt(propertyEmployeesId) > 0) { //control must be of type 'Employee Info (From Query String)' for this to work
                    setPropertyEmployeesId(propertyEmployeesId);
                }

                if (parseInt(predefinedRequestTypeId) > 0) {
                    let singleRequestType = res.data.filter(x => x.id === predefinedRequestTypeId);
                    if (singleRequestType.length === 1) {
                        setRequestTypes(singleRequestType);
                        let newDetails = new GenericRequestFormModel();
                        newDetails.requestTypeId = predefinedRequestTypeId;
                        setFormDetails(newDetails);

                    } else {
                        setRequestTypes(res.data);
                        props.getRequestTypes ? props.getRequestTypes(res.data) : null;
                    }
                } else {
                    setRequestTypes(res.data);
                    props.getRequestTypes ? props.getRequestTypes(res.data) : null;
                }

                if (justChanged === '1') {
                    if (emailsWereSent === '1') {
                        setAlert({ AlertColor: "success", AlertText: "Successfully reclassified and reloaded this ticket. The requested emails were sent. See the associated comment for detail" })
                    } else {
                        setAlert({ AlertColor: "success", AlertText: "Successfully reclassified and reloaded this ticket. No emails were sent along with this message. See the associated comment for detail" })
                    }

                }

            })

    }, [formType, query])

    useEffect(() => {
        setPropertyEmployeesId(selectedEmployee?.propertyEmployeesId);
    }, [selectedEmployee])

    useEffect(() => {
        const department = props.department.replaceAll(/\s+/g, ''); //remove white spaces
        axios.get('api/PropertyEmployees/GetEmployeesInDepartmentGroup?departmentGroup=' + department)
            .then((response) => {
                setAvailableAssignees(response.data)
            })
    }, [props.department])

    useEffect(() => {
        let formId = parseInt(action)
        if (formId) {
            var propsToInclude = formDetails.genericRequestFormProperties?.map(x => x.siteId)

            var alwaysIncludeMimgOnForms = ['payroll'];
            if (alwaysIncludeMimgOnForms.includes(formType.toLowerCase())) {
                propsToInclude.push(176);

                formDetails.genericRequestFormEmployees?.map(x => {
                    propsToInclude.push(x.propertyEmployees.siteId)
                })
            }

            var siteIds = propsToInclude.join('&siteIds=');
            if (siteIds.length > 0) {
                axios.get("api/aptproperties/GetFilteredListPropertiesWithManagersAndCityMetroAreas?siteIds=" + siteIds)
                    .then((response) => {
                        setProperties(response.data)
                    })
            }
        } else {
            axios.get("api/aptproperties/GetActiveAndPendingPropertiesWithManagersAndCityMetroAreas")
                .then((response) => {
                    setProperties(response.data)
                })
        }
    }, [formDetails.genericRequestFormProperties])

    useEffect(() => {
        let formId = parseInt(action);
        if (formId > 0) {
            axios.get('api/GenericRequestForm/GetRequestByIdAndType?Id=' + formId + '&formName=' + formType)
                .then((response) => {
                    if (response.data) {
                        if (formType.toLowerCase() === response.data.previousRelatedToFormName?.toLowerCase() && (response.data.relatedToFormName?.toLowerCase() !== response.data.previousRelatedToFormName?.toLowerCase())) { //the form was previously of a different type, so we should redirect to that, but only if we are on the wrong (old) url
                            window.location = window.location.href.toLowerCase().replace(response.data.previousRelatedToFormName.toLowerCase(), response.data.relatedToFormName.toLowerCase())
                        } else {
                            setFormDetails(response.data)
                        }

                    }
                    else setFormDetails(notFound)
                })
        }
    }, [action, formType])

    useEffect(() => {
        let defaultVals = [];
        if (selectedSites && selectedSites.length > 0) {
            let selectedSite = selectedSites[0];
            defaultVals.push({ "defaultDataPropName": "propertyName", "value": selectedSite.propertyName });
            defaultVals.push({ "defaultDataPropName": "propertyUnitCount", "value": selectedSite.nbrUnits });
            defaultVals.push({ "defaultDataPropName": "propertyAddress", "value": selectedSite.address });
            defaultVals.push({ "defaultDataPropName": "propertyFullAddress", "value": selectedSite.address + ' ' + selectedSite.city + ', ' + selectedSite.state + ' ' + selectedSite.zip });
            defaultVals.push({ "defaultDataPropName": "propertyCity", "value": selectedSite.city });
            defaultVals.push({ "defaultDataPropName": "propertyState", "value": selectedSite.state });
            defaultVals.push({ "defaultDataPropName": "propertyMetro", "value": selectedSite.mapGroup ? selectedSite.mapGroup.description : '' });
            defaultVals.push({ "defaultDataPropName": "propertyZip", "value": selectedSite.zip });
            defaultVals.push({ "defaultDataPropName": "propertyUrl", "value": selectedSite.url });
            defaultVals.push({ "defaultDataPropName": "propertyAM", "value": selectedSite.amemp?.employeeName });
            defaultVals.push({ "defaultDataPropName": "propertyRM", "value": selectedSite.rmemp?.employeeName });
            defaultVals.push({ "defaultDataPropName": "propertyPM", "value": selectedSite.propertyEmployeeBasics.find(x => x.position === 'Property Manager')?.employeeName });
            defaultVals.push({ "defaultDataPropName": "propertyAcquisitionDate", "value": new Date(selectedSite.startDate).toLocaleDateString() });
        }
        defaultVals.push({ "defaultDataPropName": "employeePosition", "value": selectedEmployee ? selectedEmployee.position : '' });
        defaultVals.push({ "defaultDataPropName": "employeeHireDate", "value": selectedEmployee?.employeeCard?.lastHireDate ? new Date(selectedEmployee.employeeCard.lastHireDate)?.toLocaleDateString() : '' });
        defaultVals.push({ "defaultDataPropName": "employeeJobTitle", "value": selectedEmployee?.employeeCard?.officialJobTitle ? selectedEmployee?.employeeCard.officialJobTitle : '' });
        defaultVals.push({ "defaultDataPropName": "employeePrismId", "value": selectedEmployee?.employeeCard?.prismId ? selectedEmployee?.employeeCard.prismId : '' });
        defaultVals.push({ "defaultDataPropName": "employeeDateofBirth", "value": selectedEmployee?.employeeCard?.birthdate ? new Date(selectedEmployee?.employeeCard?.birthdate).toLocaleDateString() : ' ' });
        defaultVals.push({ "defaultDataPropName": "employeeEmail", "value": selectedEmployee?.email ? selectedEmployee.email : '' });
        defaultVals.push({ "defaultDataPropName": "employeePosition2", "value": selectedEmployee2 ? selectedEmployee2.position : '' });
        defaultVals.push({ "defaultDataPropName": "employeeHireDate2", "value": selectedEmployee2?.employeeCard?.lastHireDate ? new Date(selectedEmployee2.employeeCard.lastHireDate)?.toLocaleDateString() : '' });
        defaultVals.push({ "defaultDataPropName": "employee2PrismId", "value": selectedEmployee2?.employeeCard?.prismId ? selectedEmployee2.employeeCard?.prismId : '' });

        defaultVals.push({ "defaultDataPropName": "employeeEmail2", "value": selectedEmployee2?.email ? selectedEmployee2.email : '' });
        defaultVals.push({ "defaultDataPropName": "employeeJobTitle2", "value": selectedEmployee2?.employeeCard?.officialJobTitle ? selectedEmployee2?.employeeCard.officialJobTitle : '' });
        defaultVals.push({ "defaultDataPropName": "propertyEmployeesId", value: selectedEmployee?.propertyEmployeesId ? selectedEmployee.propertyEmployeesId : 0 })
        defaultVals.push({ "defaultDataPropName": "propertyEmployeesId2", value: selectedEmployee2?.propertyEmployeesId ? selectedEmployee2.propertyEmployeesId : 0 })
        defaultVals.push({ "defaultDataPropName": "employeeCellPhone", value: selectedEmployee?.cellPhone ? selectedEmployee?.cellPhone : 0 })
        defaultVals.push({ "defaultDataPropName": "employeeCellPhone2", value: selectedEmployee2?.cellPhone ? selectedEmployee2?.cellPhone : 0 })
        defaultVals.push({ "defaultDataPropName": "referralBonus", value: selectedEmployee && props.getReferralBonusAmountByPosition ? props.getReferralBonusAmountByPosition(selectedEmployee?.position) : 0 })
        defaultVals.push({ "defaultDataPropName": "signingBonus", value: selectedEmployee && props.getSigningBonusAmountByPosition ? props.getSigningBonusAmountByPosition(selectedEmployee?.position) : 0 })
        defaultVals.push({ "defaultDataPropName": "vendorname", value: props.selectedVendor && props.selectedVendor?.vendorName });
        defaultVals.push({ "defaultDataPropName": "vendorCode", value: props.selectedVendor && props.selectedVendor?.vendorCode });
        defaultVals.push({ "defaultDataPropName": "vendoraddress", value: props.selectedVendor && props.selectedVendor?.address });
        defaultVals.push({ "defaultDataPropName": "vendorcity", value: props.selectedVendor && props.selectedVendor?.city });
        defaultVals.push({ "defaultDataPropName": "vendorstate", value: props.selectedVendor && props.selectedVendor?.state });
        defaultVals.push({ "defaultDataPropName": "vendorzip", value: props.selectedVendor && props.selectedVendor?.zip });
        defaultVals.push({ "defaultDataPropName": "vendorphone", value: props.selectedVendor && props.selectedVendor?.phone });
        defaultVals.push({ "defaultDataPropName": "vendoremail", value: props.selectedVendor && props.selectedVendor?.email });
        defaultVals.push({ "defaultDataPropName": "vendorein", value: props.selectedVendor && props.selectedVendor?.vendorEin });

        setDefaultValues(defaultVals);
    }, [selectedSites, selectedEmployee, selectedEmployee2, propertyEmployeesId])


    function onUpdatePageAlert(alert) {
        setAlert(alert)
    }

    function updateOuterForm(form, alert) {
        setAlert(alert)
        setFormDetails(form)
    }

    function onRequestTypeChange(requestType) {
        let form = new GenericRequestFormModel();
        form.requestType = requestType;
        setFormDetails(form);
    }

    function onSelectSite(sites) {
        setSelectedSites(sites);
        setSelectedEmployee(null);
        setSelectedEmployee2(null);
    }

    useEffect(() => {
        if (security && formDetails.requestType) {
            let userProperties = security.propertyAssociations.map(site => site.siteId);
            if (security.employeeSiteID === 99999) {
                userProperties.push(176);
            }
            userProperties.push(security.employeeSiteID);
            let requestProperties = formDetails.genericRequestFormProperties.map(site => site.siteId);
            let isAssociatedToProperty = userProperties.some(x => requestProperties.indexOf(x) >= 0);

            let hasAccessToRequestType = formDetails.requestType.requestTypePermissions.length === 0 ||
                formDetails.requestType.requestTypePermissions.map(permission => permission.position.position).includes(security.employeePosition);

            let isSubmitter = formDetails.requesterEmpId.toString() === security.empID;
            let isAssociatedEmployee = formDetails.genericRequestFormEmployees.map(x => x.propertyEmployeesId).includes(parseInt(security.empID));

            let hasAccess = (isAssociatedToProperty && hasAccessToRequestType) || isUserAdmin() || isSubmitter;

            if (formType === 'HR') {
                if (isSubmitter) {
                    hasAccess = true;
                }
                else if (isAssociatedEmployee) {
                    hasAccess = true;  //may want them to later, but safer to be off by default
                } else {
                    let myEmpsIds = props.myEmployees.map(x => x.propertyEmployeesId);
                    let formEmpsIds = formDetails.genericRequestFormEmployees.map(x => x.propertyEmployeesId);
                    formEmpsIds.push(formDetails.requesterEmpId);
                    let hasAccessToAtLeastOneEmp = false;
                    for (var i = 0; i < formEmpsIds.length; i++) {
                        if (myEmpsIds.includes(formEmpsIds[i])) {
                            hasAccessToAtLeastOneEmp = true;
                        }
                    }
                    if (hasAccessToAtLeastOneEmp || isUserAdmin()) {
                        hasAccess = true;
                    } else {
                        hasAccess = false;
                        let formSiteIds = formDetails.genericRequestFormProperties.map(x => x.siteId);
                        let empAssociations = security.propertyAssociations.filter(x => (x.positionSortOrder === 99 || x.positionSortOrder <= 4)).map(x => x.siteId);

                        for (var i = 0; i < formSiteIds.length; i++) {
                            if (empAssociations.includes(formSiteIds[i])) {
                                hasAccess = true;
                            }
                        }
                    }
                }
            }
            if (hasAccess === true) { //situation 1 - already has access based on the existing checks that don't require an api call'
                setCanAccessForm(true);
            } else {
                if (formType === 'ITSetup') { //check if they have access by virtue of being a hiring manager, but only for ITSetup forms
                    if (isAssociatedEmployee) {
                        setCanAccessForm(true)
                    } else {
                        axios.get(`api/ItSetup/GetSystemAccessRequestHiringManagersForItSetupForm?formid=${formDetails.id}`).then(response => {
                            if (response.data.map(x => x.propertyEmployeesId).includes(parseInt(security.empID))) {
                                setCanAccessForm(true);
                            } else {
                                setCanAccessForm(false);
                            }
                        })
                    }
                } else { //if this is not an ITSetup form for which they may be granted additional access, then no more checks, this should be false if hit.
                    setCanAccessForm(false)
                }
            }
        }
    }, [security, formDetails, isUserAdmin, props.myEmployees])

    function alertSpecialInfo() {
        if (formType === 'TeamRelations') {
            return (
                <Alert color="warning">
                    <div>Boot Reimbursement Links</div>
                    <ul>
                        <li><a href="https://mimg.us/GoToLink.aspx?ID=11613" target="_blank" rel="noreferrer">Cabela's Mens Boot Item Numbers</a></li>
                        <li><a href="https://mimg.us/GoToLink.aspx?ID=11612" target="_blank" rel="noreferrer"> Cabela's Womens Boot Item Numbers</a></li>
                    </ul>
                </Alert>
            )
        }

        if (props.specialAlertContent) {
            if (['LicenseToOccupy', 'LTO'].includes(formType) && (formDetails.id > 0 || parseInt(query.get("requesttype")))) {
                return null
            } else {
                return props.specialAlertContent();
            }
        }
    }

    if (availableAssignees.length === 0 ||
        (requestTypes.length === 0 && !alert) ||
        properties.length === 0 ||
        (parseInt(action) > 0 && formDetails.id === 0)) {
        return <div>Loading...</div>
    }

    if (requestTypes.length === 0 && alert) {
        return <div>
            <PageTitle PageTitle={pageTitle} addFormUrl={props.addFormUrl === null ? null : addFormUrl} />
            {alert && <MimgAlert alertInfo={alert} />}
        </div>
    }

    if (action === "list") {
        return (
            <div>
                <PageTitle PageTitle={pageTitle} addFormUrl={props.addFormUrl === null ? null : addFormUrl} />
                {alert && <MimgAlert alertInfo={alert} />}
                <GenericRequestFormList
                    defaultRequestStatus={props.defaultRequestStatus}
                    security={security}
                    formType={formType}
                    propertyList={properties}
                    requestTypes={requestTypes}
                    availableAssignees={availableAssignees}
                    onUpdatePageAlert={onUpdatePageAlert}
                    formPreferences={props.formPreferences}
                    isAdmin={isUserAdmin()}
                />
            </div>
        )
    }
    else if (parseInt(action) > 0) {
        if ((!canAccessForm) || formDetails === notFound) {
            const links = [
                new CustomAlertLink(updateProfileUrl(true, security.empID), "You may try to reset your permissions by clicking this link, then looking for the Update My Permissions button near the top of the page. Then, reload this page."),
                new CustomAlertLink(goToListUrl, "Visit this page to view a list of requests (access required)")
            ]
            return (
                <div>
                    <PageTitle PageTitle={pageTitle} goToListUrl={goToListUrl} addFormUrl={props.addFormUrl === null ? null : addFormUrl} />
                    <MimgAlert alertInfo={getUnauthorizedToViewPageAlertMessage(links)} />
                </div>
            )
        }
        return (
            <div>
                <PageTitle PageTitle={pageTitle} goToListUrl={goToListUrl} addFormUrl={props.addFormUrl === null ? null : addFormUrl} />
                {alert && <MimgAlert alertInfo={alert} />}
                <div>{alertSpecialInfo()}</div>

                {showForm &&
                    <GenericRequestForm
                        security={security}
                        formId={action}
                        requestTypes={props.selectedType ? props.selectedType : requestTypes}
                        formType={formType}
                        propertyList={properties}
                        availableAssignees={availableAssignees}
                        defaultValueList={defaultValues}
                        genericRequestFormModel={formDetails}
                        updateOuterForm={updateOuterForm}
                        onUpdatePageAlert={onUpdatePageAlert}
                        sendEmails={props.sendEmails}
                        propertyEmployeesId={propertyEmployeesId}
                        isAdmin={isUserAdmin()}
                        formPreferences={props.formPreferences}
                        myEmployees={props.myEmployees}
                        requestDetailKey1={props.requestDetailKey1} />
                }
            </div>
        )
    }

    else //add
    {
        return (<div>
            <PageTitle PageTitle={pageTitle} goToListUrl={goToListUrl} addFormUrl={props.addFormUrl === null ? null : addFormUrl} />
            {alert && <MimgAlert alertInfo={alert} />}
            <div>{alertSpecialInfo()}</div>

            {showForm &&
                <GenericRequestForm
                    security={security}
                    requestTypes={props.selectedType ? props.selectedType : requestTypes}
                    formType={formType}
                    propertyList={properties}
                    availableAssignees={availableAssignees}
                    genericRequestFormModel={formDetails}
                    updateOuterForm={updateOuterForm}
                    onUpdatePageAlert={onUpdatePageAlert}
                    defaultValueList={defaultValues}
                    onSelectEmployee={emp => setSelectedEmployee(emp)}
                    onSelectEmployee2={emp => setSelectedEmployee2(emp)}
                    onSaveSelectedProperties={onSelectSite}
                    onRequestTypeChange={onRequestTypeChange}
                    sendEmails={props.sendEmails}
                    propertyEmployeesId={propertyEmployeesId}
                    employeePhone={props.employeePhone}
                    formPreferences={props.formPreferences}
                    isAdmin={isUserAdmin()}
                    myEmployees={props.myEmployees}
                    requestDetailKey1={props.requestDetailKey1} />
            }
        </div>)
    }
}