
export class GenericRequestFormPropertiesModel {

    constructor() { //link is any object that gets passed in and has these properties

        this.id = 0;
        this.formId = 0;
        this.siteId = 0;
      
    }

}
