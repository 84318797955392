import React from "react";
import styles from './toggleSwitch.module.scss'


export default function ToggleSwitch(props) {
    return (

        <label className={styles.toggleWrapper}>
            <input className={styles.toggleSwitchCheckbox} id={props.id ? props.id : ""} name={props.name ? props.name : ""} type="checkbox" checked={props.checked} value={props.value || 0} onChange={props.onChange} disabled={props.disabled} />
            <span className={styles.toggleSwitch}><span className={styles.toggleSwitchButton}></span></span>
            <span className={styles.label}>{props.label}</span>
        </label>

        )
}