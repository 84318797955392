import React, { useState, useEffect } from 'react';
import styles from './requestedPropertyInfo.module.scss';

export default function RequestedPropertyInfo(props) {
    //request property info should be filtered down from the result of the API call api/aptproperties/GetActiveAndPendingPropertiesWithManagersAndCityMetroAreas, based on the GenericRequestFormModel.GenericRequestFormProperties Result
    const [isForAllProps, setIsForAllProps] = useState(false);
    const [requestEmployee, setRequestEmployee] = useState();
    const [position, setPosition] = useState('');
    const [copyPermissionsFrom, setCopyPermissionsFrom] = useState();
    
    useEffect(() => {
        setRequestEmployee(props.genericRequestFormModel.genericRequestFormEmployees[0])
        setIsForAllProps(props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.response === 'Access To All Properties').length > 0);

        if (props.genericRequestFormModel.genericRequestFormEmployees.length === 2) {
            if (props.genericRequestFormModel?.genericRequestFormEmployees[1].propertyEmployees) {
                setCopyPermissionsFrom(props.genericRequestFormModel?.genericRequestFormEmployees[1]);
            }
        }
    },[props.genericRequestFormModel])

    useEffect(() => {
        if (requestEmployee?.propertyEmployees?.position) {
            setPosition(requestEmployee?.propertyEmployees?.position)
        } else {
            let detail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detail.questionLabel === "Employee Position Group")[0];
            if (detail) {
                setPosition(detail.response);
            }
        }
    },[requestEmployee])

    return (<div className={styles.wrapper}>

        <h5 className={styles.employeeInfo}>Employee Name: {requestEmployee?.employeeName}</h5>
        <h5 className={styles.employeeInfo}>Employee Position: {position}</h5>

        {copyPermissionsFrom && 
            <h5 className={`${styles.employeeInfo} ${styles.warning}`}>Copy Yardi Permissions From: {copyPermissionsFrom.propertyEmployees.firstName} {copyPermissionsFrom.propertyEmployees.lastName}</h5>
        }
        {isForAllProps
            ? <h5 className={styles.employeeInfo}>This request is for access to all properties</h5>
            : <div>
                <div className={styles.headerRow}>
                    <div className={styles.flex1}>Yardi Code</div>
                    <div className={styles.flex2}>Property Name</div>
                    <div className={styles.flex2}>City, State</div>
                    <div className={styles.flex2}>Property Phone</div>
                    <div className={styles.flex2}>Asset Manager</div>
                    <div className={styles.flex2}>Regional Manager</div>
                </div>

                {(!props.requestPropertyInfo || props.requestPropertyInfo.length === 0) &&
                    <span>No property info found for this request</span>
                }

                {props.requestPropertyInfo.map((x, index) => {
                    return <div key={index} className={styles.flexRow}>
                        <div className={styles.flex1}>{x.yardiCode}</div>
                        <div className={styles.flex2}>{x.propertyName}</div>
                        <div className={styles.flex2}>{x.city}, {x.state}</div>
                        <div className={styles.flex2}>{x.phone}</div>
                        <div className={styles.flex2}>{x.amemp?.employeeName}</div>
                        <div className={styles.flex2}>{x.rmemp?.employeeName}</div>
                    </div>
                })}
            </div>
        
        }
        </div>
    );
}