import React, { useState, useEffect } from 'react';
import { axios, Button, ErrorLogModel, getNowMountainTime, logError } from 'react-mimg';
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import validator from 'validator';
import { checkIfFileExtensionIsValid } from "../../../functions/validation";
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { EncryptedZipFileModel } from '../../../models/EncryptedZipFileModel';
import { GenericRequestFormDocumentModel } from "../../../models/genericrequestforms/GenericRequestFormDocumentModel";
import { MimgAlert } from '../../layout/MimgAlert';
import flexStyles from '../../../styles/flex.module.scss';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';

export default function RequestDocument(props) {
    const [modalAlert, setModalAlert] = useState(new CustomAlertModel());
    const [showModal, setShowModal] = useState(false);
    const [autoCloseDate, setAutoCloseDate] = useState(props.genericRequestFormModel.autoCloseTicketAfterDateTime !== null ? props.genericRequestFormModel.autoCloseTicketAfterDateTime : '');
    const formAlreadyCompleted = (props.genericRequestFormModel.requestComplete === true || props.genericRequestFormModel.requestRejected === true);
    const isRequestor = (props.genericRequestFormModel.requesterEmpId === parseInt(props.security.empID));
    const [processing, setProcessing] = useState(false)
    function toggle() { setShowModal(false) }
    function open() { setShowModal(true) }

    useEffect(() => {
        if (isRequestor && autoCloseDate) {
            let alert = new CustomAlertModel();
            alert.AlertColor = "info";
            alert.AlertText = "Auto close will be cancelled when you upload this document"
            setModalAlert(alert)
        }
    }, [])

    function saveDocument() {
        var genericRequestFormModel = props.genericRequestFormModel;
        var pageAlert = new CustomAlertModel();
        var alert = new CustomAlertModel();
        var documentSubfolder = props.documentSubfolder;

        //first save the file to the server, if it is successful then save the file info to the documents table
        //Save File
        let fileUpload = document.getElementById("fileUpload");
        let fileTime = Date.now();
        let fileName = getFixedFileName(fileUpload.files[0], fileTime)
        
        validateGenericRequestDocument(fileUpload.files[0], documentSubfolder, fileTime).then((validationMsg) => {
            if (validationMsg === '') {
                setProcessing(true);
                let file = new EncryptedZipFileModel();
                file.file = fileUpload.files[0];
                file.subFolderPath = documentSubfolder;
                file.fileName = fileName;
                file.formId = props.genericRequestFormModel.id;

                UploadFile(file).then((fileUploaded) => {
                    if (fileUploaded) {
                        let documentModel = new GenericRequestFormDocumentModel();
                        documentModel.docType = props.documentSubfolder;
                        documentModel.docDescription = document.getElementById("textDocumentDescription").value;
                        documentModel.fileDirectory = documentSubfolder;
                        documentModel.docDate = getNowMountainTime();
                        documentModel.createDateTime = getNowMountainTime();
                        documentModel.fileName = file.fileName;
                        documentModel.uploadedByEmpID = props.security.empID;
                        documentModel.uploadedByName = props.security.employeeFullName;
                        documentModel.isByRequester = (parseInt(props.security.empID) === genericRequestFormModel.requesterEmpId);
                        documentModel.originalFileName = fileUpload.files[0].name;

                        if (isRequestor && autoCloseDate) {
                            let autoCloseText = "<i>Document Uploaded which cancelled the scheduled Auto Close</i>";
                            let newComment = createComment(genericRequestFormModel.requesterEmpId, autoCloseText);
                            genericRequestFormModel.genericRequestFormComments.push(newComment);
                        }

                        if (genericRequestFormModel.genericRequestFormDocuments.filter(x => x.fileName === fileName).length === 0) {
                            genericRequestFormModel.genericRequestFormDocuments.push(documentModel);

                            if (autoCloseDate !== '') {
                                if (isRequestor && new Date().setHours(0, 0, 0, 0) <= new Date(genericRequestFormModel.autoCloseTicketAfterDateTime)) {
                                    genericRequestFormModel.autoCloseTicketAfterDateTime = null;
                                    setAutoCloseDate('')
                                }
                            }

                            axios.put("api/GenericRequestForm/UpdateRequest?updateAction=addDocument", genericRequestFormModel).then((response) => {
                                genericRequestFormModel = response.data;

                                pageAlert.AlertText = "The document was uploaded to the form";
                                pageAlert.AlertColor = "success";

                                if (formAlreadyCompleted) {
                                    let alert = {};
                                    alert.AlertColor = "warning"
                                    alert.AlertText = "The document was added successfully, and anyone with access to the form will be able to see it.  As the form is already marked as completed or rejected, no notification emails were sent!"
                                    props.onSaveWithoutEmails(response.data);
                                    setModalAlert(alert)
                                } else {
                                    props.onSave(genericRequestFormModel, pageAlert, "Document");
                                    toggle();
                                    setProcessing(false)
                                }

                                axios.post(`api/file/AddDocumentIdToBlob?fileName=${file.fileName}&formId=${file.formId}`)
                                
                            }).catch((error) => {
                                alert.AlertColor = "danger";
                                alert.AlertText = "Error saving new document";
                                setProcessing(false);                                
                                logError(new ErrorLogModel(error, "OnSaveDocumentButtonPressed", props.security.employeeFullName + " experienced an error adding a document to a GenericRequestForm (CorporateServices). The document was saved, but there was an issue adding the record to the GenericRequestFormDocuments table. FormId " + genericRequestFormModel.id));
                                setModalAlert(alert)
                            })
                        } else {
                            console.log("Avoided duplicate document upload for " + fileName);  //there was an issue with duplicate files being uploaded, if multiple actions were taken after the form was uploaded. 
                        }
                    }
                });
            } else {
                alert.AlertColor = "danger";
                alert.AlertText = validationMsg;
                setModalAlert(alert)
            }
        });
    }

    function getFixedFileName(file, fileTime) {
        let ext = file.name.split('.').pop();
        let formId = props.genericRequestFormModel.id.toString();
        let sixDigitFormId = formId.padStart(6, '0');

        return `grf${sixDigitFormId}-${fileTime}.${ext}`;
    }

    async function UploadFile(file) {
        let uploaded = false;
        const formData = new FormData();
        formData.append("formFile", file.file);
        formData.append("fileName", file.fileName);
        formData.append("subFolderName", file.subFolderPath);
        formData.append("formId", file.formId);

        await axios.post("api/file/PostEncryptedFile", formData)
            .then(response => {
                uploaded = true;
            })
            .catch(error => {
                logError(new ErrorLogModel(error, "UploadFile", props.security.employeeFullName + " The document was not uploaded to the server. Document Name: " + file.fileName + "; FormId: " + props.genericRequestFormModel.id));
                uploaded = false;
            })

        return uploaded;
    };

    async function validateGenericRequestDocument(file, subFolder, fileTime) {
        let msg = "";

        if (file !== null && file !== undefined) {
            let fileName = getFixedFileName(file, fileTime);
            let fileDesc = document.getElementById("textDocumentDescription").value;
            if (fileDesc === "" || !validator.isLength(fileDesc, { min: 0, max: 200 })) {
                msg = msg + "The file description is required and cannot be more than 200 characters.\n";
            }
            msg = msg + await checkIfFileExtensionIsValid(file);

        } else {
            msg = msg + "No File was Selected.  Please select a file to continue";
        }
        return msg;
    }

        return (
            <div>
                <Button onClick={open}>Add Attachment</Button>
                <Modal isOpen={showModal} size="lg" toggle={toggle}>
                    <ModalHeader toggle={toggle}>Add Document</ModalHeader>

                    {modalAlert.AlertText !== "" &&
                        <MimgAlert alertInfo={modalAlert} />
                    }

                    <ModalBody>
                        <Form>
                            <div className={`${flexStyles.rowFlexContainer} ${flexStyles.paddingTop10}`}>
                                <Label className={flexStyles.flex1} for="textDocumentDescription">Document Description:</Label>
                                <Input className={flexStyles.flex3} type="text" id="textDocumentDescription" name="textDocumentDescription" />
                            </div>
                            <div className={`${flexStyles.rowFlexContainer} ${flexStyles.paddingTop10}`}>
                                <Label className={flexStyles.flex1} for="fileUpload">Upload File</Label>
                                <Input className={flexStyles.flex3} type="file" name="fileUpload" id="fileUpload" />
                            </div>
                        </Form>
                    </ModalBody>

                    <ModalFooter>
                        <Button transparent onClick={toggle}>Cancel</Button> {"  "}
                        <Button onClick={saveDocument} disabled={processing}>
                            {processing
                                ?<span>Document Submitted</span>
                                :<span>Submit Document</span>
                            }
                            
                        </Button> {"  "}
                    </ModalFooter>
                </Modal>
            </div>

        );
    }

