import React, { useState, useEffect } from 'react';
import { axios } from "react-mimg";
import RequestFormTemplate from "../../components/requestforms/RequestFormTemplate";
import { getEmployeeSecurity } from '../../functions/permissions';

export default function PayrollRequestForm(props) {

    const formPreferences = {};
    formPreferences["hideRequestPropertySelection"] = false;
    formPreferences["allowSubmitOnBehalfOf"] = true;

    const [employeePositions, setEmployeePositions] = useState([]);
    const security = getEmployeeSecurity();
    const isBonusAdmin = security.isBonusAdmin;

    useEffect(() => {
        axios.get("api/employeePosition/GetEmployeePositions").then((response) => {
            setEmployeePositions(response.data)
        })
    },[])

    function getReferralBonusAmountByPosition(selectedEmployeePosition) {
        let def = employeePositions.find((x) => x.position == selectedEmployeePosition)?.referralBonusAmount;
        if (def === 0) { return 500; }

        return def;
    }

    function getSigningBonusAmountByPosition(selectedEmployeePosition) {
        let def = employeePositions.find((x) => x.position == selectedEmployeePosition)?.signingBonusAmount;
        if (def === 0) { return 500; }

        return def;
    }

    return (
        <div>
            <RequestFormTemplate
                department='Payroll'
                group='Payroll'
                pageTitle='Payroll Request Form'
                getReferralBonusAmountByPosition={getReferralBonusAmountByPosition}
                getSigningBonusAmountByPosition={getSigningBonusAmountByPosition}
                formPreferences={formPreferences}
                isAdminOverride={isBonusAdmin}
                sendEmails={true} />
        </div>

    )
}