import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import styles from './emailAddressExistanceChecker.module.scss';
import sharedStyles from './ITHelpdeskProcessing.module.scss';
import { axios, Button } from 'react-mimg';
import CreateOutlookUserModal from './CreateOutlookUserModal';

export default function EmailAddressExistanceChecker(props) {
    const [email, setEmail] = useState('');
    const [emailMsg, setEmailMsg] = useState('Checking if Mail User Exists');
    const [emailPassword, setEmailPassword] = useState('');
    const [canCreateUser, setCanCreateUser] = useState(false);
    const [showCreateUser, setShowCreateUser] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        if (!email)
            setEmail(props.defaultEmail);
    }, [props.defaultEmail])

    useEffect(() => {
        if (emailMsg === 'Mail User Exists') {
            //look at the credentials stored for this user, and see if we can display the most recent known temp password from the genericRequestDetailResponses data
            let emailCredential = props.genericRequestFormModel.genericRequestResponses.filter(x => x.responsePrompt === 'Email Login')[0]
            if (emailCredential) {
                if (email && email.toLowerCase() === emailCredential.responseValue.toLowerCase()) {
                    setEmailPassword(emailCredential.responseValue2)
                }
            }
        }
    }, [emailMsg])

    useEffect(() => {
        if (email) {
            setTimeout(() => {
                props.setEmail(email)
                if (email.toLowerCase().includes('@monarchinvestment.com')) {
                    axios.get(`api/ITSetup/GetMicrosoftGraphUserInfo?email=${email}&propertyEmployeesId=${props.emp.propertyEmployeesId}`).then((response) => { //need to also see if the email exists for someone else already
                        if (response.data.isSuccessful) {
                            props.setUser(response.data.data);
                            setEmailMsg('Mail User Exists');
                            setCanCreateUser(false);
                        } else {
                            props.setUser()
                            if (response.data.errorMessage.includes('Email Address is already in use')) {
                                setEmailMsg(response.data.errorMessage);
                                setCanCreateUser(false);
                            } else {
                                setEmailMsg('Does Not Exist');
                                setCanCreateUser(true);
                            }
                        }
                    })
                } else {
                    setEmailMsg('must end with @monarchinvestment.com')
                    setCanCreateUser(false);
                }
            }, 250)
        }

    }, [email])

    function onSetUser(user) {
        if (email.toLowerCase().includes('@monarchinvestment.com')) {
            axios.get(`api/ITSetup/GetMicrosoftGraphUserInfo?email=${email}&propertyEmployeesId=${props.emp.propertyEmployeesId}`).then((response) => { //need to also see if the email exists for someone else already
                if (response.data.isSuccessful) {
                    props.setUser(response.data.data);
                    setEmailMsg('Mail User Exists');
                    setCanCreateUser(false);
                } else {
                    props.setUser()
                    if (response.data.errorMessage.includes('Email Address is already in use')) {
                        setEmailMsg(response.data.errorMessage);
                        setCanCreateUser(false);
                    } else {
                        setEmailMsg('Does Not Exist');
                        setCanCreateUser(true);
                    }
                }
            })
        }
        props.setUser(user)
    }


    return (
        <div className={sharedStyles.questionWrapper}>
            <div className={styles.flexRow}>
                <span className={styles.header}>User Email: <span className={emailMsg !== 'Mail User Exists' ? `${styles.warning}` : ``}>{emailMsg}</span></span><br />
            </div>
            <div className={styles.flexRow}>
                <div className={styles.flex1}>
                    <Input disabled={props.disabled} value={email} onChange={(e) => { setEmail(e.target.value) }} /><br />
                </div>

                <div className={styles.flex1}>
                    {(canCreateUser && !props.disabled) &&
                        <Button className={sharedStyles.responseButton} onClick={() => setShowCreateUser(true)}>Create User</Button>
                    }
                </div>
                
                {emailPassword !== '' &&
                    <div className={styles.flex1}>
                        <b>Last Known Temp Email Password</b><br />
                        <Input value={emailPassword} disabled />
                    </div>
                }

            </div>

            {props.emp &&
                <CreateOutlookUserModal genericRequestFormModel={props.genericRequestFormModel} isOpen={showCreateUser} toggle={() => setShowCreateUser(!showCreateUser)} emp={props.emp} email={email} cellPhone={props.cellPhone} onAddComment={props.onAddComment} onSetUser={onSetUser} onUpdateFormAlert={props.onUpdateFormAlert} onAddCredential={props.onAddCredential} requestPropertyInfo={props.requestPropertyInfo} />
            }

        </div>

    );
}



