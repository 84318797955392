import { logError, ErrorLogModel,axios } from 'react-mimg';

export function getSearchData() {
    try {
        let refreshedTime = localStorage.getItem('searchRefreshedTime');
        
        if (refreshedTime && refreshedTime !== null) {
            if ((new Date().getTime() - (4 * 60 * 60 * 1000) > refreshedTime)) {//refresh if older than 4 hours old, so about 2x a day for most people
                axios.get('api/search/GetSearchObject').then(response => {
                    setSearchData(response.data);
                    return response.data;
                })
            } else {
                return localStorage.getItem('savedSearchResults');
            }
        } else {
            axios.get('api/search/GetSearchObject').then(response => {
                setSearchData(response.data);
                return response.data;
            })
        }

        return null;
    }
    catch (error) {
        logError(new ErrorLogModel(error, "getSearchData", "Cannot load saved search data from local storage."));
        localStorage.removeItem('searchRefreshedTime');
        localStorage.removeItem('savedSearchResults');
        return null;
    }
}


export function setSearchData(searchData) {
    searchData.refreshedDateTime = new Date().getTime();
    localStorage.setItem('searchRefreshedTime', searchData.refreshedDateTime) //separated this from the main object, figured it would be slightly faster since the object is small.
    localStorage.setItem('savedSearchResults', JSON.stringify(searchData));
}

export function hasSearchData() {
    var data = localStorage.getItem('savedSearchResults');
    if (!data) {
        return false;
    } else {
        return true;
    }
}
