import React, { useState, useRef } from 'react';
import { axios, Button, ErrorLogModel, logError, getNowMountainTime } from 'react-mimg';
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { createComment, getNextStepInfo } from '../../../functions/formHelpers/genericRequestFormHelper';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { MimgAlert } from '../../layout/MimgAlert';
import styles from '../modals/RequestModals.module.scss';
import RequestRichInput from './RequestRichInput';
import { uploadImage } from './UploadImage';

export default function RequestApproval(props) {
    const [modalAlert, setModalAlert] = useState(new CustomAlertModel());
    const [showModal, setShowModal] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [processing, setProcessing] = useState(false);
    const quillRef = useRef();

    function toggle() { setShowModal(false); }
    function open() { setShowModal(true); }

    async function onApproveRequest() {
        setProcessing(true)
        let genericRequestFormModel = props.genericRequestFormModel;
        let pageAlert = new CustomAlertModel();
        let alert = new CustomAlertModel();
        
        let updatedValue = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : commentText;

        const imageRegex = /<img[^>]+src="([^">]+)"/g;
        const images = [];
        let match;
        while ((match = imageRegex.exec(updatedValue)) !== null) {
            images.push(match[1]);
        }

        for (let i = 0; i < images.length; i++) {
            const imageUrl = images[i];
            const response = await uploadImage(imageUrl, genericRequestFormModel.id );
            updatedValue = updatedValue.replace(imageUrl, response.data.url);
        }

        let newComment = createComment(genericRequestFormModel.requesterEmpId, "Request Approved. " + updatedValue);
        genericRequestFormModel.genericRequestFormComments.push(newComment);
        genericRequestFormModel.requestStatus = "Approved";
        genericRequestFormModel.requestApproved = true;
        genericRequestFormModel.approvedByEmpId = props.security.empID;
        genericRequestFormModel.approvedByEmpName = props.security.employeeFullName;
        genericRequestFormModel.approvedDateTime = getNowMountainTime();

        let requestTypeId = genericRequestFormModel.requestTypeId;
        let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0]?.approvalWorkflowNavigation;
        let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Approve')[0].id;
        genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);

        let requestType = props.requestTypes.filter(x => x.id === requestTypeId)[0];
        if (requestType.automaticallyAssignToEmpId && requestType.automaticallyAssignToEmpId > 0) { //this is specified on the Request Type maintenance page, and only available if the request workflow has an associated step with button name "Assign"
            genericRequestFormModel.assignedToEmpId = requestType.automaticallyAssignToEmpId;
            genericRequestFormModel.assignedToName = requestType.automaticallyAssignToEmpName;
            genericRequestFormModel.assignedToDateTime = getNowMountainTime();
            genericRequestFormModel.assignedToByEmpId = 0;
            genericRequestFormModel.assignedToByName = 'Automatically Assigned On Request Submission';
            genericRequestFormModel.assignedToEmail = requestType.automaticallyAssignToEmpEmail;

            let currentStep = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Assign')?.sort((a, b) => { return a.sortOrder - b.sortOrder })[0];
            genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStep.id);
            genericRequestFormModel.requestStatus = "Assigned"; //not entirely 100% sure this will work, but requests "Assigned" from the Assign Modal also have this status
        }

        axios.put("api/GenericRequestForm/UpdateRequest?updateAction=approve", genericRequestFormModel).then((response) => {
            genericRequestFormModel = response.data;

            pageAlert.AlertColor = "success";
            pageAlert.AlertText = "The request was approved successfully.";

            props.onSave(genericRequestFormModel, pageAlert, "Approve");
            setProcessing(false)
            toggle();

        }).catch((error) => {

            alert.AlertColor = "danger";
            alert.AlertText = "Error approving request.";
            setModalAlert(alert);
            setProcessing(false);
            logError(new ErrorLogModel(error, "OnApproveRequest", props.security.employeeFullName + " experienced an error completing a GenericRequestForm (CorporateServices). FormId " + genericRequestFormModel.id));
        });
    }

    return (
        <div>
            <Button onClick={open}>Approve</Button>
            <Modal isOpen={showModal} toggle={toggle} size={props.formPreferences.richTextComments ? "lg" : undefined}>
                <ModalHeader toggle={toggle}>Approve Request</ModalHeader>

                {modalAlert.AlertText !== "" &&
                    <MimgAlert alertInfo={modalAlert} />
                }

                <ModalBody>
                    <h5>Current Action: Approve Request</h5>
                    <Form>
                        <Label for="textAreaNewComment">Comment:</Label>
                        {props.formPreferences.richTextComments ? (
                            <RequestRichInput r={quillRef} />
                        ) : (
                            <Input className={styles.commentBox} type="textarea" id="textAreaNewComment" name="textAreaNewComment" value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                        )}
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={toggle}>Cancel</Button> {"  "}
                    <Button onClick={onApproveRequest} disabled={processing}>
                        {processing
                            ? <span>Processing</span>
                            : <span>Approve Request</span>
                        }
                    </Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>
    );
}
