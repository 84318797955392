import React, { useState } from 'react';
import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup } from 'reactstrap';
import { Button, axios, getNowMountainTime } from 'react-mimg';
import { useEffect } from 'react';
import styles from './reopenRequestModal.module.scss';
import validator from 'validator';
import { getEmployeeSecurity } from '../../../functions/permissions';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';
import ChangeRequestTypeEmail from './ChangeRequestTypeEmail';

export default function ReopenRequestModal(props) {
    const security = getEmployeeSecurity();
    const emailText = props.formPreferences?.reopenDefaultSendToRequester ? "" : " No emails will be sent by default, so you must explicitly choose to send an email if necessary."; 
    const [alert, setAlert] = useState({ color: "info", messages: [`Admins may use this functionality to re-open a request that that has been Completed or Rejected.  You will be required to select a Workflow Status at which the process will pick up. ${emailText}`] });
    const [isLoading, setIsLoading] = useState(true);
    const [processing, setProcessing] = useState(false)
    const [confirmed, setConfirmed] = useState(false);
    const [workflow, setWorkflow] = useState();
    const [requestType, setRequestType] = useState();
    const [selectedWorkflowStep, setSelectedWorkflowStep] = useState();
    const [additionalComment, setAdditionalComment] = useState('');
    const [emailToRequester, setEmailToRequester] = useState({ send: props.formPreferences?.reopenDefaultSendToRequester ? props.formPreferences.reopenDefaultSendToRequester : false, to: props.genericRequestFormModel.requesterEmail, body: '', genericRequestFormId: props.genericRequestFormModel.id, subject: "Request Form Reopened - XRef: " + props.genericRequestFormModel.id });
    const [emailToAdmins, setEmailToAdmins] = useState({ send: false, to: '', body: '', genericRequestFormId: props.genericRequestFormModel.id, subject: "Request Form Reopened - XRef: " + props.genericRequestFormModel.id });
    const [workflowSteps, setWorkflowSteps] = useState([]);
    const formUrl = window.location.href

    useEffect(() => {
        if (props.isOpen) {
            axios.get('api/requestworkflow/getworkflowbyid?id=' + props.genericRequestFormModel.workflowId).then((response) => {
                setWorkflow(response.data);

                axios.get('api/requesttypes/GetRequestTypeSummary/' + props.genericRequestFormModel.requestTypeId).then((response) => {
                    setRequestType(response.data)
                    updateEmailToAdmins('to', response.data.requestTypeAdmins.map(x => x.adminEmp.email).join(','))
                })
            })
            setIsLoading(false)
        }
    }, [props.isOpen])


    useEffect(() => {
        //take on the workflow of the selected request type.
        if (workflow && requestType) {

            //workflows should usually all have the last three steps being Comment, Document, Reject, and the step before that is the completion step, or hopefully is in all cases. We don't want to allow going to the Complete step here.  So show all the steps besides the last 4 based on sort order, and see how it goes. 
            let steps = workflow.requestWorkflowSteps;
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('comment'));
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('document'));
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('complete'));
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('reject'));
            steps = steps.filter(x => !x.stepName.toLowerCase().includes('submit'));

            setWorkflowSteps(steps.sort((a, b) => { return a.sortOrder - b.sortOrder; }));
        }

    }, [requestType, workflow])

    function updateSelectedWorkflowStep(stepId) {
        setSelectedWorkflowStep(workflowSteps.filter(x => x.id === parseInt(stepId))[0]);
    }

    function getDefaultComment() {
        return "The request was reopened, into the workflow step: <b><u>" + selectedWorkflowStep?.stepName + "</u></b>.";
    }


    function getDefaultEmailBody() {

        let s = `An admin (${security.employeeFullName}) has reopened the ${props.formType} request form for the following request: [FormUrl].\n\n`

        s += "[DefaultCommentsFromAbove]"
        s += `\n\n\[AdditionalCommentsFromAbove]`;

        return s;
    }

    function submitUpdate() {
        //validate that something has actually changed
        let validationMessages = validateUpdate();
        if (validationMessages.length === 0) {
            setProcessing(true)
            let genericRequestFormCopy = JSON.parse(JSON.stringify(props.genericRequestFormModel));

            genericRequestFormCopy.originalWorkflowStep = genericRequestFormCopy.currentWorkflowStep;
            genericRequestFormCopy.currentWorkflowStep = selectedWorkflowStep?.id;
            genericRequestFormCopy.requestStatus = "Reopened";
            genericRequestFormCopy.reopenedByEmpId = security.empID;
            genericRequestFormCopy.reopenedByEmpName = security.employeeFullName;
            genericRequestFormCopy.reopenedDateTime = getNowMountainTime();

            //void Completed, Rejected, and AutoClose data
            genericRequestFormCopy.requestComplete = false;
            genericRequestFormCopy.requestCompletedByEmpId = null;
            genericRequestFormCopy.requestCompletedByEmpName = null;
            genericRequestFormCopy.requestCompletedDateTime = null;
            genericRequestFormCopy.requestCompletedByEmpPosition = null;
            genericRequestFormCopy.requestRejected = false;
            genericRequestFormCopy.rejectedByEmpId = null;
            genericRequestFormCopy.rejectedByEmpName = null;
            genericRequestFormCopy.rejectedByEmpPosition = null;
            genericRequestFormCopy.autoCloseTicketAfterDateTime = null;
            genericRequestFormCopy.ticketWasAutoClosed = null;
            genericRequestFormCopy.ticketAutoClosedDateTIme = null;
            genericRequestFormCopy.autoCloseSetByEmpId = null;

            //add comment
            let commentText = getDefaultComment();
            if (additionalComment !== '')
                commentText += `<br /><b><u>Additional Comment</u></b><br />${additionalComment}`
            genericRequestFormCopy.genericRequestFormComments.push(createComment(security.empID, commentText))

            //post here to update the GRF. Use existing function
            axios.put('api/GenericRequestForm/UpdateRequest?updateAction=ChangeRequestType', genericRequestFormCopy).then((response) => {
                //SINCE I AM GOING TO SEND THE EMAIL AFTER UPDATING THE FORM, WE WANT TO BE SURE THAT THEY WILL WORK, SO WILL NEED TO UPDATE THE VALIDATION FUNCTION ACCORDINGLY TO MAKE SURE EVERYTHING SEEMS VALID
                let emailsToSend = []
                if (emailToAdmins.send) {
                    let emailCopy = JSON.parse(JSON.stringify(emailToAdmins))
                    emailCopy.body = emailCopy.body.replace('[FormUrl]', formUrl);
                    emailCopy.body = emailCopy.body.replace('[DefaultCommentsFromAbove]', "<br /><br />" + getDefaultComment())
                    emailCopy.body = emailCopy.body.replace('[AdditionalCommentsFromAbove]', "<br /><br />" + additionalComment + "<br /><br />")
                    emailCopy.body = emailCopy.body.replace('\n', '<br />')
                    emailsToSend.push(emailCopy)
                }

                if (emailToRequester.send) {
                    let emailCopy = JSON.parse(JSON.stringify(emailToRequester))
                    emailCopy.body = emailCopy.body.replace('[FormUrl]', formUrl);
                    emailCopy.body = emailCopy.body.replace('[DefaultCommentsFromAbove]', "<br /><br />" + getDefaultComment())
                    emailCopy.body = emailCopy.body.replace('[AdditionalCommentsFromAbove]', "<br /><br />" + additionalComment + "<br /><br />")
                    emailCopy.body = emailCopy.body.replace('\n', '<br />')
                    emailsToSend.push(emailCopy)
                }

                if (emailsToSend.length > 0) {
                    axios.post('api/GenericRequestForm/SendGenericRequestFormReclassificationEmails', emailsToSend).then(res => {
                        if (res.data.isSuccessful) {
                            window.location = getRedirectUrl(true, true) //redirect form no matter what, either to a new url, or to the existing url.  This will force everything to reload which seems necessary to get it all to work properly. 

                        } else {
                            setAlert({ color: "danger", messages: ['Updating the ticket was successful, but there was an error sending one or more emails', res.data.errorMessage] })
                            setProcessing(false);
                        }
                    })
                } else {
                    window.location = getRedirectUrl(true, false)
                }
            })

        } else {
            setAlert({ color: "danger", messages: validationMessages })
            setProcessing(false)
        }
    }

    function getRedirectUrl(justChanged, emailsWereSent) {
        let baseUrl = `requests/${requestType?.relatedToFormName}/${props.genericRequestFormModel.id}`;
        if (justChanged) {
            if (emailsWereSent) {
                return baseUrl + "?justChanged=1&emailsWereSent=1";
            } else {
                return baseUrl + "?justChanged=1";
            }
        } else {
            return baseUrl;
        }
    }

    function validateUpdate() {
        let messages = [];

        if (!confirmed)
            messages.push('You must toggle the control to confirm that you understand that this will reopen the form')

        if (!selectedWorkflowStep)
            messages.push('You must specify which workflow step to which you wish to reopen the ticket')

        if (additionalComment === '')
            messages.push('The additional comments are required to specify why you are reopening this request')

        if (emailToAdmins.send) {
            let toEmails = emailToAdmins.to.split(',')
            if (toEmails.length === 0 || toEmails[0] === '') {
                messages.push('No email recipients are selected for the email to admins')
            } else {
                for (let i = 0; i < toEmails.length; i++) {
                    if (!validator.isEmail(toEmails[i]))
                        messages.push(toEmails[i] + ' is not a valid email address')
                }
            }
        }

        if (emailToRequester.send) {
            let toEmails = emailToRequester.to.split(',')
            if (toEmails.length === 0 || toEmails[0] === '') {
                messages.push('No email recipients are selected for the email to requester')
            } else {
                for (let i = 0; i < toEmails.length; i++) {
                    if (!validator.isEmail(toEmails[i]))
                        messages.push(toEmails[i] + ' is not a valid email address')
                }
            }
        }

        return messages;
    }

    function onUpdateEmail(emailType, emailObj) {
        if (emailType === "Admins")
            setEmailToAdmins(emailObj);

        if (emailType === "Requester")
            setEmailToRequester(emailObj);
    }

    function updateEmailToAdmins(property, value) {
        let emailCopy = JSON.parse(JSON.stringify(emailToAdmins))
        emailCopy[property] = value;
        setEmailToAdmins(emailCopy);
    }

    return (
        <div>
            <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle}>
                {/*<ModalHeader toggle={props.toggle}>Change Request Type</ModalHeader>*/}

                <ModalBody>
                    {alert && alert.messages?.length > 0 &&
                        <Alert toggle={() => setAlert()} color={alert.color}>{alert.messages.map((m, index) => {
                            return <p key={index}><b>{m}</b></p>
                        })}
                            <a href="https://mimg.us/gotolink.aspx?id=12262" target="_blank" rel="noreferrer">Download Instructions Document</a>
                        </Alert>
                    }

                    <div className={styles.sectionWrapper}>
                        {isLoading ? <>Loading</>
                            : <>
                                {workflowSteps.length === 0 && <Alert color="danger">The currently selected request type does not have any workflow steps which support reopening the ticket.</Alert>}

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>Request Type Name: </div>
                                    <div className={styles.flex5}>{requestType?.requestType1}</div>
                                </div>
                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>Reopen to Step:</div>
                                    <div className={styles.flex3}>
                                        <Input type="select" value={selectedWorkflowStep?.id} onChange={(e) => updateSelectedWorkflowStep(e.target.value)}>
                                            <option value={0}>--Select--</option>
                                            {workflowSteps.map((s, i) => {
                                                return <option key={i} value={s.id}>{s.stepName}</option>
                                            })}
                                        </Input>
                                    </div>
                                    <div className={styles.flex2}></div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}></div>
                                    <div className={styles.flex5}>
                                        <FormGroup check>
                                            <Input type="checkbox" id="confirmReopen" name="confirmReopen" checked={confirmed} onChange={(e) => setConfirmed(e.target.checked)} />
                                            <Label for="confirmReopen">Click here to confirm that you wish to Re-Open the request!</Label>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        Default Comments
                                    </div>
                                    <div className={`${styles.flex5} ${styles.changesComment}`} dangerouslySetInnerHTML={{ __html: getDefaultComment() }}>

                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        Additional Comments (required)
                                    </div>
                                    <div className={styles.flex5}>
                                        <Input type="textarea" rows={3} value={additionalComment} onChange={(e) => setAdditionalComment(e.target.value)} placeholder="Enter the reason for reopening the ticket" />
                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={`${styles.flex1} ${styles.sectionWrapper}`}>
                                        <ChangeRequestTypeEmail email={emailToAdmins} onUpdateEmail={onUpdateEmail} sendToLabel="Admins" defaultEmailBody={getDefaultEmailBody()} />
                                    </div>
                                    <div className={`${styles.flex1} ${styles.sectionWrapper}`}>
                                        <ChangeRequestTypeEmail email={emailToRequester} onUpdateEmail={onUpdateEmail} sendToLabel="Requester" defaultEmailBody={getDefaultEmailBody()} />
                                    </div>
                                </div>
                            </>
                        }

                    </div>

                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={props.toggle}>Cancel</Button> {"  "}
                    <Button onClick={() => submitUpdate()} disabled={processing || workflowSteps.length === 0}>Reopen Request</Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}
