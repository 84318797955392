import React from 'react';
import Editor from './Editor';
import styles from './RequestRichInput.module.scss'

export default function RequestRichInput(props) {

    return (
        <>
            <div className={styles.InputContainer}>
                <Editor ref={props.r} readonly={false} />
            </div>

            {!props.hideBlurb &&
                <div className={styles.InputBlurb}>
                    You may copy/paste images or screenshots, or drag an image file directly into this input.
                </div>
            }

        </>
    );
}
