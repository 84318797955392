import React, { useState, useRef } from 'react';
import { axios, Button, ErrorLogModel, logError } from 'react-mimg';
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import validator from 'validator';
import { createComment, getNextStepInfo } from '../../../functions/formHelpers/genericRequestFormHelper';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import styles from '../modals/RequestModals.module.scss';
import RequestRichInput from './RequestRichInput';
import { uploadImage } from './UploadImage';

export default function RequestReadyForReview(props) {
    const [modalAlert, setModalAlert] = useState();
    const [showModal, setShowModal] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [processing, setProcessing] = useState(false);
    const quillRef = useRef();

    function toggle() { setShowModal(false); }
    function open() { setShowModal(true); }

    async function onMarkReadyForReview() {
        let validationMsg = validateExternalReferenceId();
        let alert = new CustomAlertModel();
        if (validationMsg === "") {
            setProcessing(true);
            let genericRequestFormModel = props.genericRequestFormModel;
            let pageAlert = new CustomAlertModel();

            if (props.workflow.showExternalReference) {
                genericRequestFormModel.externalReferenceId = document.getElementById("txtExternalReference").value;
            }

            let requestTypeId = genericRequestFormModel.requestTypeId;
            let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Ready for Review')[0].id;
            let nextStepId = getNextStepInfo(workflow, currentStepId);
            genericRequestFormModel.currentWorkflowStep = nextStepId;
            let nextStepName = workflow.requestWorkflowSteps.filter(x => x.id === nextStepId)[0]?.stepName;
            genericRequestFormModel.requestStatus = nextStepName;

            let comments = genericRequestFormModel.genericRequestFormComments;
            let newComment = createComment(genericRequestFormModel.requesterEmpId, "<b>Request marked as ready for review by " + props.security.employeeFullName + "</b>");

            if (props.workflow.showExternalReference) {
                newComment.comment += `<br />Code: ${document.getElementById("txtExternalReference").value}`;
            }

            if (validator.isLength(newComment.comment, { min: 1, max: 1000 })) {
                let updatedValue = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : commentText;

                const imageRegex = /<img[^>]+src="([^">]+)"/g;
                const images = [];
                let match;
                while ((match = imageRegex.exec(updatedValue)) !== null) {
                    images.push(match[1]);
                }

                for (let i = 0; i < images.length; i++) {
                    const imageUrl = images[i];
                    const response = await uploadImage(imageUrl, genericRequestFormModel.id);
                    updatedValue = updatedValue.replace(imageUrl, response.data.url);
                }

                newComment.comment += "<br />Notes: " + updatedValue.replaceAll('\n', '<br />');
            }

            comments.push(newComment);

            axios.put("api/GenericRequestForm/UpdateRequest?updateAction=readyToReview", genericRequestFormModel).then((response) => {
                genericRequestFormModel = response.data;

                pageAlert.AlertText = "The request was successfully marked as ready to review."
                pageAlert.AlertColor = "success";

                props.onSave(genericRequestFormModel, pageAlert, "Ready For Review");
                setProcessing(false);
                toggle();
            }).catch((error) => {
                alert.AlertColor = "danger";
                alert.AlertText = "Error Marking Ready For Review";
                logError(new ErrorLogModel(error, "OnMarkReadyForReview", props.security.employeeFullName + " experienced an error marking a GenericRequestForm as Ready For Review. FormId " + genericRequestFormModel.id));
                setModalAlert(alert);
                setProcessing(false);
            });
        } else {
            let modalAlert = {};
            modalAlert.AlertColor = "danger";
            modalAlert.AlertText = "You must supply a valid response for the external reference id field";
            setModalAlert(modalAlert);
        }
    }

    function validateExternalReferenceId() {
        let msg = "";
        if (props.workflow.showExternalReference) {
            let textContent = document.getElementById("txtExternalReference").value;
            if (!validator.isLength(textContent, { min: 1, max: 25 })) {
                msg = `The external reference id ${props.workflow.externalReferenceLabel} is required and must be between 1 and 25 characters`;
            }
        }
        return msg;
    }

    return (
        <div>
            <Button onClick={open}>Mark Ready For Review</Button>
            <Modal isOpen={showModal} toggle={toggle} size={props.formPreferences.richTextComments ? "lg" : undefined}>
                <ModalHeader toggle={toggle}>Add Comment</ModalHeader>

                {modalAlert &&
                    <Alert color={modalAlert.AlertColor}>{modalAlert.AlertText}</Alert>
                }

                <ModalBody>
                    <h5>Current Action: Mark Ready For Review</h5>
                    <Form>
                        <Label for="textAreaNewComment">Comment (Optional):</Label>
                        {props.formPreferences.richTextComments ? (
                            <RequestRichInput r={quillRef} />
                        ) : (
                            <Input className={styles.commentBox} type="textarea" id="textAreaNewComment" name="textAreaNewComment" value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                        )}

                        {props.workflow.showExternalReference &&
                            <div className={styles.externalReferenceBox}>
                                <Label for="txtExternalReference">External Reference ID: {props.workflow.externalReferenceLabel}:</Label>
                                <Input type="text" id="txtExternalReference" name="txtExternalReference" defaultValue={props.genericRequestFormModel.externalReferenceId} placeholder={props.workflow.externalReferencePlaceholder} />
                            </div>
                        }
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={toggle}>Cancel</Button> {"  "}
                    <Button onClick={onMarkReadyForReview} disabled={processing}>
                        {processing
                            ? <span>Processing</span>
                            : <span>Mark Ready For Review</span>
                        }
                        </Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>
    );
}
