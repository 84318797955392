import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import Select from 'react-select';
import { Button } from 'react-mimg';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import flexStyles from '../../../styles/flex.module.scss';
import styles from '../formelements/GenericRequestForm.module.scss';


export class SelectPropertiesModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            groupBy: "All Properties",
            selectedProperties: this.props.selected ? this.props.selected : [],
            modalAlert: this.props.alert ? this.props.alert : new CustomAlertModel(),
            filterData: this.getPropertyFilterData(this.props.allProps),
        }
    }

    onSelectType = (event) => {
        console.log(event)
        this.setState({
            selectionType: event.target.id,
        });
    }

    changeGroupBy = (event) => {

        var groupBy = event.target.value;
        this.setState({
            groupBy: groupBy,
        })
    }

    changeGroupMember = (event) => {

        var groupMember = event.target.selectedOptions[0].id;
        this.setState({
            groupMember: groupMember,
        })
    }

    //do add only first
    UpdateSelectedProperties = (event) => {  //using propertylist here, having an issue getting the state variables
        var selectedProperties = this.state.selectedProperties; //start with this, then add anything that was selected
        if (event.target.id === "btnAddProps") {
            var lbAvailable = document.getElementById("multiSelectAvailableProps") //these are already selected, so keep them in the selection
            let selectedOptions = lbAvailable.selectedOptions;

            for (let i = 0; i < selectedOptions.length; i++) {
                let newProp = this.props.allProps.filter(x => x.siteId === parseInt(selectedOptions[i].id))[0];
                selectedProperties.push(newProp);
            }
        } else if (event.target.id === "btnRemoveProps") {
            let lbSelected = document.getElementById("multiSelectSelectedProps") //these are already selected, so keep them in the selection
            const selectedOptions = lbSelected.selectedOptions;

            for (let j = 0; j < selectedOptions.length; j++) {
                selectedProperties = selectedProperties.filter(x => x.siteId !== parseInt(selectedOptions[j].id));
            }
        }

        this.setState({
            selectedProperties: selectedProperties,
        })
    }

    onSelectSingleProperty() {
        var element = document.getElementById("selectSingleProp");
        this.props.OnUpdateSelectedProperties(element, this.props.allProps);
    }

    onSaveSelectedProperties = () => {
        let alert = new CustomAlertModel();
        let showSelectPropertyModal = false;
        let selectedProperties = this.state.selectedProperties;
        let requestProperties = this.state.selectedProperties;
        
        if (!this.state.selectionType) {
            showSelectPropertyModal = true;
            alert.AlertColor = "danger";
            alert.AlertText = "You must select a method by which you will select the properties";
        } else {
            if (this.state.selectionType === "radioSingleProperty") {
                let selectControl = document.getElementById("selectSingleProp");
                requestProperties = this.props.allProps.filter(x => x.siteId === parseInt(selectControl.children[3].value));
                showSelectPropertyModal = false;
            }
            else if (this.state.selectionType === "radioMultipleProperties") {
                requestProperties = selectedProperties.slice();
            }

            if (requestProperties.length > 0) {
                requestProperties = requestProperties.slice();
                selectedProperties = requestProperties.slice();
                showSelectPropertyModal = false;
            } else {
                alert.AlertColor = "danger";
                alert.AlertText = "No properties are selected.  Please select a property to continue";
                showSelectPropertyModal = true;
            }
        }

        if (showSelectPropertyModal) {
            this.setState({
                modalAlert: alert,
            })
        } else {
            this.props.onSave(requestProperties, selectedProperties); //only save if successful            

        }
        this.props.toggle();
    }

    getPropertyFilterData = (properties) => {

        //these are used for filtering data in the request form
        let states = [];
        let uniqueAms = [];
        let uniqueRms = [];
        let uniqueCmas = [];

        states = [...new Set(properties.map(data => data.state))];
        states.sort(function (a, b) { //order alphabetically
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
        });

        const cmas = properties.filter(x => x.mapGroup !== null).map(data => data.mapGroup);
        for (let cma of cmas) {
            if (!uniqueCmas.some(e => e.mapGroupId === cma.mapGroupId)) {
                uniqueCmas.push(cma);
            }
        }

        uniqueCmas.sort(function (a, b) { //order alphabetically
            let x = a.description;
            let y = b.description;
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });


        //had a lot of trouble filtering this to unique values, settled with looping using a dictionary but definitely there is a better way
        const ams = properties.filter(x => x.amemp !== null).map(data => data.amemp);
        for (let am of ams) {
            if (!uniqueAms.some(e => e.propertyEmployeesId === am.propertyEmployeesId)) {
                uniqueAms.push(am);
            }
        }

        uniqueAms.sort(function (a, b) { //order alphabetically
            let x = a.firstName + ' ' + a.lastName;
            let y = b.firstName + ' ' + b.lastName;
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });

        const rms = properties.filter(x => x.rmemp !== null).map(data => data.rmemp);
        for (let rm of rms) {
            if (!uniqueRms.some(e => e.propertyEmployeesId === rm.propertyEmployeesId)) {
                uniqueRms.push(rm);
            }
        }

        uniqueRms.sort(function (a, b) { //order alphabetically
            let x = a.firstName + ' ' + a.lastName;
            let y = b.firstName + ' ' + b.lastName;
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });

        //property options (for use as {value: 'a', label:'bad' }) per this https://react-select.com/home
        var options = properties.map(p => {
            var obj = {};
            obj.value = p.siteId;
            obj.label = p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')';

            return obj;
        })

        var filterData = {};
        filterData["state"] = states;
        filterData["assetManager"] = uniqueAms;
        filterData["regionalManager"] = uniqueRms;
        filterData["region"] = uniqueCmas;
        filterData["autoCompleteOptions"] = options;

        return filterData;
    }

    render() {
        const allprops = this.props.allProps;
        const selectedPropertyIds = this.state.selectedProperties.map(p => {
            return p.siteId;
        });

        const availableProps = allprops.filter(x => !selectedPropertyIds.includes(x.siteId));

        availableProps.sort(function (a, b) { //order alphabetically
            let x = a.propertyName.toLowerCase();
            let y = b.propertyName.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        })

        const selectedProps = this.state.selectedProperties;
        selectedProps.sort(function (a, b) { //order alphabetically
            let x = a.propertyName.toLowerCase();
            let y = b.propertyName.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        })
      
        const states = this.state.filterData["state"];

        return (
            <div>
                <Modal isOpen={this.props.open} size="lg" toggle={this.props.toggle}>
                    <ModalHeader toggle={this.props.toggle}>Select Properties</ModalHeader>
                    <ModalBody>
                        {this.state.modalAlert.AlertText !== "" &&
                            /*<MimgAlert alertInfo={this.state.modalAlert} />*/
                            <Alert>{this.state.modalAlert.AlertText}</Alert>
                        }

                        <Form>
                            <div className={flexStyles.rowFlexContainer}>
                                
                                    <div className={flexStyles.flexHalfIfSmall}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radioHowManyProps" id="radioSingleProperty" onChange={this.onSelectType} checked={this.state.selectionType === 'radioSingleProperty'} />{' '}
                                                The request is for a single property
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                            <Input type="radio" name="radioHowManyProps" id="radioMultipleProperties" onChange={this.onSelectType} checked={this.state.selectionType === 'radioMultipleProperties'} />{' '}
                                                The request is for multiple properties
                                            </Label>
                                        </FormGroup>
                                    </div>
                                
                                <div className={`${flexStyles.flexHalfIfSmall} ${flexStyles.paddingTop10}`}>
                                    {this.state.selectionType === "radioMultipleProperties" &&
                                        <>
                                            <FormGroup className={flexStyles.rowFlexContainer}>
                                                <Label className={flexStyles.flex1} for="groupPropsBy">Group By</Label>
                                                <Input className={flexStyles.flex2} type="select" name="groupPropsBy" id="groupPropsBy" onChange={this.changeGroupBy} >
                                                    <option>All Properties</option>
                                                    <option>State</option>
                                                    <option>Region</option>
                                                    <option>Asset Manager</option>
                                                    <option>Regional Manager</option>
                                                </Input>
                                            </FormGroup>
                                            <FormGroup className={flexStyles.rowFlexContainer}>
                                                <Label for="groupMembers" className={flexStyles.flex1}>Select Group Member</Label>
                                                <Input type="select" className={flexStyles.flex2} name="groupMembers" id="groupPropsBy" onChange={this.changeGroupMember} >
                                                    <option key="0" id="0">--Select A Place or Person--</option>
                                                    {this.state.groupBy === "State" &&
                                                        states.map(p => {
                                                            return <option key={p} id={p}>{p}</option>
                                                        })}

                                                    {this.state.groupBy === "Region" &&
                                                        this.state.filterData["region"].map(cma => {
                                                            return <option key={cma.mapGroupId} id={cma.mapGroupId}>{cma.description}</option>
                                                        })}

                                                    {this.state.groupBy === "Asset Manager" &&
                                                        this.state.filterData["assetManager"].map(am => {
                                                            return <option key={am.propertyEmployeesId} id={am.propertyEmployeesId}>{am.firstName + ' ' + am.lastName}</option>
                                                        })}

                                                    {this.state.groupBy === "Regional Manager" &&
                                                        this.state.filterData["regionalManager"].map(rm => {
                                                            return <option key={rm.propertyEmployeesId} id={rm.propertyEmployeesId}>{rm.firstName + ' ' + rm.lastName}</option>
                                                        })}
                                                </Input>
                                            </FormGroup>
                                        </>
                                    }
                                </div>
                            </div>

                            {this.state.selectionType === "radioSingleProperty" &&
                                <FormGroup className={flexStyles.paddingTop10}>
                                    <Label for="selectSingleProp">Begin typing a property name, city, state, or Yardi code, then click to select it</Label>
                                    <Select id="selectSingleProp" name="selectSingleProp" autoFocus placeholder="Begin typing a property name, city, state, or Yardi code, then click to select it" options={this.state.filterData["autoCompleteOptions"]} />
                                </FormGroup>
                            }

                            {this.state.selectionType === "radioMultipleProperties" &&
                                <div className={flexStyles.rowFlexContainer}>
                                    <div className={flexStyles.flexDoubleListbox}>
                                        <FormGroup>
                                            <Label for="multiSelectAvailableProps">Available Properties</Label>

                                            <Input type="select" className={styles.mimgSelectPropertyListbox} name="multiSelectAvailableProps" id="multiSelectAvailableProps" multiple>
                                                {this.state.groupBy === "All Properties" &&
                                                    availableProps.map(p => {
                                                        return <option key={p.siteId} id={p.siteId}>{p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'}</option>
                                                    })}

                                                {this.state.groupBy === "State" &&
                                                    availableProps.filter(x => x.state === this.state.groupMember).map(p => {
                                                        return <option key={p.siteId} id={p.siteId}>{p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'}</option>
                                                    })}

                                                {this.state.groupBy === "Region" &&
                                                    availableProps.filter(x => x.mapGroupId === parseInt(this.state.groupMember)).map(p => {
                                                        return <option key={p.siteId} id={p.siteId}>{p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'}</option>
                                                    })}

                                                {this.state.groupBy === "Asset Manager" &&
                                                    availableProps.filter(x => x.amEmpId === parseInt(this.state.groupMember)).map(p => {
                                                        return <option key={p.siteId} id={p.siteId}>{p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'}</option>
                                                    })}

                                                {this.state.groupBy === "Regional Manager" &&
                                                    availableProps.filter(x => x.rmEmpId === parseInt(this.state.groupMember)).map(p => {
                                                        return <option key={p.siteId} id={p.siteId}>{p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'}</option>
                                                    })}
                                            </Input>

                                        </FormGroup>
                                    </div>

                                    <div className={flexStyles.flexDoubleListboxButtons}>
                                        <div className={`${flexStyles.columnFlexContainer} ${flexStyles.flexJustifySpaceAround} ${flexStyles.flexNowrap} ${flexStyles.flexJustifyCenter}`}>
                                            <Button id="btnAddProps" outline color="info" onClick={this.UpdateSelectedProperties}>Add</Button><br />
                                            <Button id="btnRemoveProps" outline color="info" onClick={this.UpdateSelectedProperties}>Remove</Button>
                                        </div>
                                    </div>

                                    <div className={flexStyles.flexDoubleListbox}>
                                        <FormGroup>
                                            <Label for="multiSelectSelectedProps">Selected Properties</Label>
                                            <Input type="select" className={styles.mimgSelectPropertyListbox} name="multiSelectSelectedProps" id="multiSelectSelectedProps" multiple>
                                                {this.state.selectedProperties &&
                                                    selectedProps.map(p => {
                                                        return <option key={p.siteId} id={p.siteId}>{p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')'} </option>
                                                    })}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </div>
                            }
                        </Form>
                    </ModalBody>

                    <ModalFooter>
                        <Button transparent onClick={this.props.toggle}>Cancel</Button> {"  "}
                        <Button onClick={this.onSaveSelectedProperties}>Save</Button> {"  "}
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}
