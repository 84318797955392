import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import RequestDetail from './RequestDetail';
import CreatableSelect from 'react-select/creatable';

export default function RequestDetailEmployeeForSitePicker(props) {
    const [employeeList, setEmployeeList] = useState([])
    const [selectedOption, setSelectedOption] = useState()
    const [employeeOptions, setEmployeeOptions] = useState([])

    useEffect(() => {
        if (props.selectedProperties?.length > 0) {
            axios.get("api/propertyemployees/GetEmployeesForSite?siteIds=" + props.selectedProperties.map(x => x.siteId).join('&siteIds=') + "&daysInactive=60")
                .then((response) => {
                    let options = []
                    let employees = response.data.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1);
                    employees.forEach(emp =>
                    { options.push({ value: emp.propertyEmployeesId, label: getEmployeeNameLabel(emp) }); })
                    setEmployeeList(employees);
                    setEmployeeOptions(options);
                })
        }
    }, [props.selectedProperties])

    function getEmployeeNameLabel(emp) {
        if (emp.isActive) {
            return emp.employeeName + ' - ' + emp.position + ' (PrismId:' + emp.prismId + ', WebsiteId: ' + emp.propertyEmployeesId + ')';
        } else {
            return emp.employeeName + ' - ' + emp.position + ' (Inactive: ' + new Date(emp.employeeCard?.terminationDate).toLocaleDateString() + ') ';
        }
    }

    function handleChange(option) {
        setSelectedOption(option)
        props.onSelectEmployee && props.onSelectEmployee(employeeList.filter(x => x.propertyEmployeesId === option.value)[0]);
    }

    function handleCreate(inputValue) {
        setTimeout(() => {
            const newOption = { label: inputValue, value: inputValue }
            setEmployeeOptions((prev) => [...prev, newOption]);
            setSelectedOption(newOption)
        }, 300);
    };

    if (!employeeOptions)
        return <div>Loading...</div>

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
            
            <CreatableSelect
                isClearable
                placeholder={props.RequestTypeDetail.placeholder}
                options={employeeOptions}
                onChange={handleChange}
                value={selectedOption}
                name='employeePicker'
                onCreateOption={handleCreate}
            />
        </RequestDetail>
    );
}
