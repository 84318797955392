import React, { Component } from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons'
import styles from './linkStyles.module.scss';

export class BackToListLink extends Component {

    render() {
        return (
            <a href={this.props.linkToUrl}>
                <div className={styles.linkDiv}>
                    <FontAwesomeIcon icon={faList} />
                </div>
            </a>
        );
    }
}