import React, { useEffect, useState } from 'react';
import { axios, Button } from 'react-mimg';
import { Alert } from 'reactstrap';
import { itGroupOptions } from '../../../requestTypeConfig';
import EmailAddressExistanceChecker from './EmailAddressExistanceChecker';
import styles from './NetworkProcessing.module.scss';
import OutlookGroupSelection from "./OutlookGroupSelection";

export default function NetworkProcessing(props) {
    const [email, setEmail] = useState('')
    const [cellPhone, setCellPhone] = useState('');
    const [emp, setEmp] = useState();
    const [user, setUser] = useState();
    const [allEmailGroups, setAllEmailGroups] = useState([]);
    const [userExistingGroups, setUserExistingGroups] = useState([])
    const [mfaStepComment, setMfaStepComment] = useState(false)
    const [passwordStepComment, setPasswordStepComment] = useState(false)
    const [newPassword, setNewPassword] = useState();
    const [formAlert, setFormAlert] = useState();
    const [itGroupName, setItGroupName] = useState("--Select--")

    useEffect(() => {
        axios.get('api/ITSetup/GetAllOutlookGroups').then((response) => {
            if (response.data.isSuccessful) {
                setAllEmailGroups(response.data.data);
            }
        })

        let emp = props.genericRequestFormModel.genericRequestFormEmployees[0].propertyEmployees;
        if (emp) {
            setEmp(emp)
        }

    }, [])


    useEffect(() => {
        let empPosition = props.genericRequestFormModel.genericRequestFormEmployees[0]?.propertyEmployees?.position;
        itGroupOptions.forEach(x => {
            if (x.correspondingEmployeePositions.includes(empPosition)) {
                setItGroupName(x.value);
            }
        })

    }, [])

    useEffect(() => {
        let details = props.genericRequestFormModel.genericRequestFormDetails;
        setEmail(details.filter(x => x.detail.defaultDataPropName === "employeeEmail")[0]?.response);
        setCellPhone(details.filter(x => x.detail.defaultDataPropName === "employeeCellPhone")[0]?.response);
    }, [props.genericRequestFormModel.genericRequestFormDetails])

    useEffect(() => {
        let mfaComments = props.genericRequestFormModel.genericRequestFormComments.filter(x => x.comment.toLowerCase().includes(('marked mfa as having been ').toLowerCase()));
        let passwordComments = props.genericRequestFormModel.genericRequestFormComments.filter(x => x.comment.toLowerCase().includes(('the email password was successfully reset').toLowerCase()));
            
        mfaComments.sort(function (a, b) {
            return (a.dateTimeCreated - b.dateTimeCreated)
        })

        if (mfaComments.length > 0) {
            setMfaStepComment(mfaComments[0]);
        }

        passwordComments.sort(function (a, b) {
            return (a.dateTimeCreated - b.dateTimeCreated)
        })

        if (passwordComments.length > 0) {
            setPasswordStepComment(passwordComments[0]);
        }

    }, [props.genericRequestFormModel])

    function refreshUserExistingGroups() {
        axios.get('api/ItSetup/GetMicrosoftGraphUserGroups?email=' + email).then(response => {
            setUserExistingGroups(response.data.data)
        })
    }

    function onUpdateFormAlert(alert) {
        setFormAlert(alert);
    }

    function resetPassword() {
        axios.get('api/ITSetup/ResetOutlookPassword?email=' + email).then(response => {
            if (response.data.isSuccessful) {
                setNewPassword(response?.data?.data);
                
                let credential = {};
                credential.responseType = "Final Email Password";
                credential.responsePrompt = "Final Email Password";
                credential.responseValue = email;
                credential.responseValue2 = response?.data?.data;

                axios.post(`api/GenericRequestForm/AddGenericRequestResponse?formId=${props.genericRequestFormModel.id}`, credential).then((response) => {
                    let pageAlert = { AlertColor: "success", AlertText: "" };
                    props.onAddCredential(response.data, pageAlert, "Credential");
                    props.onAddComment(['The email password was successfully reset. The temporary password will be emailed to IT Helpdesk upon completion of this form.'], true)
                    setFormAlert({ color: "success", message: "Successfully reset email password. The password will be emailed to IT when the form is marked complete" })
                }).catch((error) => {
                    setModalAlert({ color: "danger", message: "Error saving just-reset email password. Error " + error })
                });
            } else {
                setFormAlert({ color: "danger", message: "Error Resetting the email password for this user " })
            }
        })
    }

    function enforceMfaOnAccount() {
        props.onAddComment([`Marked MFA as having been 'Enforced' for user ${email}`], true);
    }

    useEffect(() => {
        if (user && email) {
            refreshUserExistingGroups();
        }
    }, [email, user])

    function refreshUserExistingGroups() {
        axios.get('api/ItSetup/GetMicrosoftGraphUserGroups?email=' + email).then(response => {
            setUserExistingGroups(response.data.data)
        })
    }

    return (
        <div>
            {formAlert &&
                <Alert color={formAlert.color}>{formAlert.message}</Alert>
            }

            <EmailAddressExistanceChecker defaultEmail={email}
                setEmail={setEmail}
                cellPhone={cellPhone}
                emp={emp}
                setUser={setUser}
                genericRequestFormModel={props.genericRequestFormModel}
                onAddComment={props.onAddComment}
                onUpdateFormAlert={onUpdateFormAlert} />

            {user && <>
                <div className={styles.questionRow}>
                    <OutlookGroupSelection header="SSO Security Groups" user={user} emp={emp} onAddComment={props.onAddComment} itGroupName={itGroupName} allGroups={allEmailGroups} userExistingGroups={userExistingGroups} groupTypes={['Security Groups']} selectionType="Security Groups" onRefreshUserExistingGroups={refreshUserExistingGroups} genericRequestFormModel={props.genericRequestFormModel} propertyList={props.propertyList} requestPropertyInfo={props.requestPropertyInfo} />
                </div>
                <div className={styles.questionRow}>
                    <OutlookGroupSelection header={"Sharepoint Groups"} user={user} emp={emp} onAddComment={props.onAddComment} itGroupName={itGroupName} allGroups={allEmailGroups} userExistingGroups={userExistingGroups} groupTypes={['Sharepoint Groups']} selectionType="Sharepoint Groups" onRefreshUserExistingGroups={refreshUserExistingGroups} genericRequestFormModel={props.genericRequestFormModel} propertyList={props.propertyList} requestPropertyInfo={props.requestPropertyInfo} />
                </div>

                <div className={styles.questionRow}>
                    <div className={styles.wrapper}>
                        <div className={styles.flexRow}>
                            <div className={styles.flex3}>
                                <h5 className={styles.header}>Enable MFA on Account - <span className={styles.warning}>This must be done from the Microsoft Portal as of now!</span></h5>
                                {mfaStepComment
                                    ? <span className={`${styles.lastSyncedMsg} ${styles.flex1} ${styles.marginTop10}`}>Last Synced {new Date(mfaStepComment.dateTimeCreated).toLocaleDateString()} {new Date(mfaStepComment.dateTimeCreated).toLocaleTimeString()} by {mfaStepComment.commenterName}</span>
                                    : <span className={`${styles.lastSyncedMsg} ${styles.flex1} ${styles.marginTop10}`}>This function has not been used on this form!</span>
                                }
                            </div>
                            <div className={styles.flex1}>
                                <Button className={styles.floatRight} onClick={enforceMfaOnAccount}>Enforce MFA on Account</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.questionRow}>
                    <div className={styles.wrapper}>
                        <div className={styles.flexRow}>
                            <div className={styles.flex3}>
                                <h5 className={styles.header}>Reset User Password (a temporary password will generated and emailed to IT Helpdesk)</h5>
                                {passwordStepComment
                                    ? <span className={`${styles.lastSyncedMsg} ${styles.flex1} ${styles.marginTop10}`}>Last Synced {new Date(passwordStepComment.dateTimeCreated).toLocaleDateString()} {new Date(passwordStepComment.dateTimeCreated).toLocaleTimeString()} by {passwordStepComment.commenterName}</span>
                                    : <span className={`${styles.lastSyncedMsg} ${styles.flex1} ${styles.marginTop10}`}>This function has not been used on this form!</span>
                                }
                            </div>
                            <div className={styles.flex1}>
                                <Button className={styles.floatRight} onClick={resetPassword}>Reset Password Now</Button>
                                {newPassword &&
                                    <b>Password Reset To: {newPassword}</b>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>}

        </div>
    );
}