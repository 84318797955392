import React, { useState } from 'react';
import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Button, axios } from 'react-mimg';
import CreatableSelect from 'react-select/creatable';
import { useEffect } from 'react';
import styles from '../../../styles/flex.module.scss';
import validator from 'validator';

export default function AddRequestCredential(props) {

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([{ label: "Yardi Username", value: "Yardi Username" }, { label: "Email Username", value: "Email Username" }])
    const [credential, setCredential] = useState({responseValue:"", responseValue2: ""});
    const [value, setValue] = useState();
    const [alert, setAlert] = useState();
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        updateCredential("responsePrompt", value?.value);
    },[value])

    function handleCreate(inputValue) {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = {label: inputValue, value: inputValue}
            setIsLoading(false);
            setOptions((prev) => [...prev, newOption]);
            setValue(newOption)
        }, 300);
    };

    function updateCredential(property, value) {
        let cred = JSON.parse(JSON.stringify(credential));
        cred[property] = value;

        setCredential(cred);
    }

    function onCreateCredential() {
        if (isValid()) {
            setProcessing(true);
            axios.post(`api/GenericRequestForm/AddGenericRequestCredential?formId=${props.genericRequestFormModel.id}`, credential).then((response) => {
                let pageAlert = { AlertColor: "success", AlertText: "The credential was added successfully" };
                props.onSave(response.data, pageAlert, "Credential");
                setProcessing(false);
                setShowModal(false);
            }).catch((error) => {
                setAlert({ color: "danger", message: "Error saving request credential. Error " + error })
                setProcessing(false);
            })
        }
    }

    function isValid() {
        let msg = "";
        if (!credential.responsePrompt || !validator.isLength(credential.responsePrompt, { min: 1, max: 100})) 
            msg = msg + "There is no credential type selected or entered\n";

        if (!credential.responseValue || !validator.isLength(credential.responseValue, { min: 1, max: 100 }))
            msg = msg + "There is no username entered\n";

        if (!credential.responseValue2 || !validator.isLength(credential.responseValue2, { min: 1, max: 100 }))
            msg = msg + "There is no password entered\n";


        if (msg !== "") {
            setAlert({ color: "danger", message: msg });
            return false;
        } else {
            setAlert();
            return true;
        }
    }


    return (
        <div>
            <Button onClick={() => setShowModal(true)}>Add Credential</Button>
            <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Add Credential</ModalHeader>

                <ModalBody>
                    {alert &&
                        <Alert color={alert.color}>{alert.message.split('\n').map((m, index) => {
                            return <p key={index}>{m}</p>
                        })}</Alert>
                    }

                    <div className={styles.flexRow}>
                        <Label className={styles.flex1} for="selectType">Credential Type:</Label>
                        <CreatableSelect
                            className={styles.flex2}
                            isClearable
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            onChange={(newValue) => setValue(newValue)}
                            onCreateOption={handleCreate}
                            options={options}
                            value={value} />
                    </div>

                    <div className={styles.flexRow}>
                        <Label className={styles.flex1} for="selectType">Username:</Label>
                        <Input className={styles.flex2} type="text" value={credential.responseValue} onChange={(e) => updateCredential('responseValue', e.target.value)} placeholder="Username"/>
                    </div>

                    <div className={styles.flexRow}>
                        <Label className={styles.flex1} for="selectType">Password:</Label>
                        <Input className={styles.flex2} type="text" value={credential.responseValue2} onChange={(e) => updateCredential('responseValue2',e.target.value)} placeholder="Password" />
                    </div>

                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={() => setShowModal(false)}>Cancel</Button> {"  "}
                    <Button onClick={onCreateCredential} disabled={processing}>
                        {processing
                            ?<span>Processing</span>
                            :<span>Add Credential</span>
                        }
                    </Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}
