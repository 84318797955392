import React from 'react';
import { Input } from 'reactstrap';
import RequestDetail from './RequestDetail';

export default function RequestDetailListbox(props){

        let choices = props.RequestTypeDetail.requestTypeDetailChoices;
        let uniqueChoices = [];

        for (let i = 0; i < choices.length; i++) {
            let existingChoices = uniqueChoices.map(x => x.choiceValue);
            if (!choices[i].isActive)
                continue;
            if (!existingChoices.includes(choices[i].choiceValue)) {
                uniqueChoices.push(choices[i]);
            }
        }

        return (
            <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
                <Input type="select"
                    style={{height: (uniqueChoices.length * 22) + 'px'}}
                    multiple>
                    {uniqueChoices &&
                        uniqueChoices.map(p => {
                            return <option id={p.id} key={p.id}>{p.choiceValue}</option>
                        })
                    }
                </Input>
            </RequestDetail>
        );
    }
