import React, { useEffect, useState } from 'react';
import { axios, Button } from 'react-mimg';
import Select from 'react-select'
import styles from './ITHelpdeskProcessing.module.scss';

export default function YardiSpecifySystems(props) {
    const [systemOptions, setSystemOptions] = useState([]);
    const [selectedSystemOptions, setSelectedSystemOptions] = useState([]);
    const [existingResponseInfo, setExistingResponseInfo] = useState();

    useEffect(() => {
        let existingInfo = props.genericRequestFormModel.genericRequestResponses.filter(x => x.responsePrompt === 'Yardi Setup Systems List')[0];
        if (existingInfo) {
            setExistingResponseInfo(existingInfo);

            let selSystems = [];
            existingInfo.responseValue.split(', ').map(x =>
                selSystems.push({ label: x, value: x })
            );

            setSelectedSystemOptions(selSystems)
        } else {
            const systems = ['Yardi Voyager & CRM', 'Rent Cafe', 'Payscan', 'Client Central', 'Yardi Marketplace', 'Paylease', 'Yardi Voyager Test']
            let opts = [];
            systems.map(x => {
                opts.push({ label: x, value: x });
            })

            setSystemOptions(opts);

            let selectedSystems = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detail.questionLabel === "Which systems does the employee need access to (can select multiple)")[0]?.response.split('; ');
            let selOpts = [];
            selectedSystems.map(x => {
                selOpts.push({ label: x, value: x });
            })

            setSelectedSystemOptions(selOpts);
            props.setSelectedSystemOptions(selOpts);
        }
    }, [props.genericRequestFormModel])

    useEffect(() => {
        props.setSelectedSystemOptions(selectedSystemOptions)
    }, [selectedSystemOptions])

    function markSystemOptions() {
        let systems = ['User has been granted access for the following systems:']
        let systemsString = '';
        selectedSystemOptions.map(x => {
            systems.push(x.label)
            systemsString += x.label + ", "
        })

        let response = {};
        response.responseType = "Yardi Setup Systems List";
        response.responsePrompt = "Yardi Setup Systems List";
        response.responseValue = systemsString;

        axios.post(`api/GenericRequestForm/AddGenericRequestResponse?formId=${props.genericRequestFormModel.id}`, response).then((response) => {
            let pageAlert = { AlertColor: "success", AlertText: "" };
            props.onAddCredential(response.data, pageAlert, "Credential");
            props.onAddComment(systems, true);
        }).catch((error) => {
            console.log(error)
            setModalAlert({ color: "danger", message: "Error saving request credential. Error " + error })
        })
    }

    return (
        <div className={styles.questionWrapper}>
            <div className={styles.questionRow}>
                <h5 className={styles.header}>Step 2: Select the Systems that this user was granted access to</h5>
                {existingResponseInfo
                    ? <div className={styles.questionRow}>User was marked as having been granted access to the following systems:
                        {existingResponseInfo.responseValue.split(', ').map((r, index) => {
                            return <div key={index}>{r}</div>
                        })}
                    </div>
                    : <div className={`${styles.questionRow} ${styles.flexRow}`}>
                        <Select
                            className={`${styles.flex3} ${styles.marginBetween}`}
                            isMulti
                            isClearable
                            options={systemOptions}
                            onChange={setSelectedSystemOptions}
                            value={selectedSystemOptions}
                            name='systemOptionsPicker' />
                        <div className={`${styles.flex1} ${styles.marginBetween}`}>
                            <Button className={`${styles.responseButton}`} onClick={markSystemOptions}>Save Systems</Button>
                        </div>
                    </div>

                }
            </div>
        </div>

    );
}