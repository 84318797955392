export class GenericRequestFormModel {

    constructor(genericRequestForm) { //link is any object that gets passed in and has these properties

        if (genericRequestForm == null) {
            this.id = 0;
            this.relatedToFormName = "";
            this.requestDateTime = null;
            this.requesterEmpId = 0;
            this.requesterName = "";
            this.requesterPropertyName = "";
            this.requesterSiteId = 0;
            this.requesterEmail = "";
            this.requesterPhone = "";
            this.requesterPosition = "";
            this.needByDate = null;
            this.requestStatus = "";
            this.requestStatusUpdatedByEmpId = null;
            this.requestStatusUpdatedByEmpName = null;
            this.requestStatusUpdatedDateTime = null;
            this.requestStatusUpdateByPosition = null;
            this.requestTypeId = null;
            this.additionalDetails = null;
            this.assignedToEmpId = null;
            this.assignedToName = null;
            this.assignedToDateTime = null;
            this.assignedToByEmpId = null;
            this.assignedToByName = null;
            this.assignedToEmail = null;
            this.requestComplete = false;
            this.requestApproved = null;
            this.approvedByEmpId = null;
            this.approvedByEmpName = null;
            this.approvedDateTime = null;
            this.requestRejected = false;
            this.rejectedByEmpId = null;
            this.rejectedByEmpName = null;
            this.rejectedByEmpPosition = null;
            this.completedByEmpId = null;
            this.completedByEmpName = null;
            this.completedDateTime = null;
            this.completedByEmpPosition = null;
            this.costApprovedByEmpId = null;
            this.costApprovedByEmpName = null;
            this.costApprovedByPosition = null;
            this.costApprovedDateTime = null;
            this.currentWorkflowStep = null;
            this.workflowId = null;
            this.externalReferenceId = null;
            this.genericRequestFormProperties = [];
            this.genericRequestFormComments = [];
            this.genericRequestFormDetails = [];
            this.genericRequestFormDocuments = [];
            this.genericRequestFormEmployees = [];
            this.genericRequestResponses = [];
            this.emailsSents = [];

            this.genericRequestId = 0;
            

        } else {
            this.id = genericRequestForm.id
            this.relatedToFormName = genericRequestForm.relatedToFormName
            this.requestDateTime = genericRequestForm.requestDateTime
            this.requesterEmpId = genericRequestForm.requesterEmpId
            this.requesterName = genericRequestForm.requesterName
            this.requesterPropertyName = genericRequestForm.requesterPropertyName
            this.requesterSiteId = genericRequestForm.requesterSiteId
            this.requesterEmail = genericRequestForm.requesterEmail
            this.requesterPhone = genericRequestForm.requesterPhone
            this.requesterPosition = genericRequestForm.requesterPosition
            this.needByDate = genericRequestForm.needByDate
            this.requestStatus = genericRequestForm.requestStatus
            this.requestStatusUpdatedByEmpId = genericRequestForm.requestStatusUpdatedByEmpId
            this.requestStatusUpdatedByEmpName = genericRequestForm.requestStatusUpdatedByEmpName
            this.requestStatusUpdatedDateTime = genericRequestForm.requestStatusUpdatedDateTime
            this.requestStatusUpdateByPosition = genericRequestForm.requestStatusUpdateByPosition

            this.requestTypeId = genericRequestForm.requestTypeId
            this.additionalDetails = genericRequestForm.additionalDetails
            this.assignedToEmpId = genericRequestForm.assignedToEmpId
            this.assignedToName = genericRequestForm.assignedToName
            this.assignedToEmail = genericRequestForm.assignedToEmail
            this.assignedToDateTime = genericRequestForm.assignedToDateTime
            this.assignedToByEmpId = genericRequestForm.assignedToByEmpId
            this.assignedToByName = genericRequestForm.assignedToByName
            this.requestComplete = genericRequestForm.requestComplete
            this.requestApproved = genericRequestForm.requestApproved
            this.approvedByEmpId = genericRequestForm.approvedByEmpId
            this.approvedByEmpName = genericRequestForm.approvedByEmpName
            this.approvedDateTime = genericRequestForm.approvedDateTime
            this.requestRejected = genericRequestForm.requestRejected
            this.rejectedByEmpId = genericRequestForm.rejectedByEmpId
            this.rejectedByEmpName = genericRequestForm.rejectedByEmpName
            this.rejectedByEmpPosition = genericRequestForm.rejectedByEmpPosition
            this.completedByEmpId = genericRequestForm.completedByEmpId;
            this.completedByEmpName = genericRequestForm.completedByEmpName;
            this.completedDateTime = genericRequestForm.completedDateTime;
            this.completedByEmpPosition = genericRequestForm.completedByEmpPosition;
            this.costApprovedByEmpId = genericRequestForm.costApprovedByEmpId
            this.costApprovedByEmpName = genericRequestForm.costApprovedByEmpName
            this.costApprovedByPosition = genericRequestForm.costApprovedByPosition
            this.costApprovedDateTime = genericRequestForm.costApprovedDateTime
            this.currentWorkflowStep = genericRequestForm.currentWorkflowStep
            this.workflowId = genericRequestForm.workflowId
            this.externalReferenceId = genericRequestForm.externalReferenceId;
            this.genericRequestFormProperties = genericRequestForm.genericRequestFormProperties
            this.genericRequestFormComments = genericRequestForm.genericRequestFormComments
            this.genericRequestFormDetails = genericRequestForm.genericRequestFormDetails
            this.genericRequestFormDocuments = genericRequestForm.genericRequestFormDocuments
            this.genericRequestFormEmployees = genericRequestForm.genericRequestFormEmployees
            this.emailsSents = genericRequestForm.emailsSents
            this.genericRequestResponses = genericRequestForm.genericRequestResponses
            this.genericRequestId = genericRequestForm.genericRequestId

        }

      
    }

}
