import React, { Component } from 'react';
import { axios } from 'react-mimg';
import Select from 'react-select';
import RequestDetail from './RequestDetail';

export class RequestDetailStatesList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stateOptions: null,
            selectedOption: 0,
        }

    }

    componentDidMount() {
        axios.get("api/state/getstates").then((response) => {
            let defaultOption = { value: "No Selection", label: "No Selection" };
            let options = [defaultOption];
            response.data.forEach(s => {
                options.push({ value: s.stateAbbr, label: s.state1 + " - " + s.stateAbbr });
            })

            let selectedOption;
            if (this.props.defaultValue) {
                selectedOption = options.filter(x => x.value === this.props.defaultValue).length > 0 ? options.filter(x => x.value === this.props.defaultValue)[0] : defaultOption;
            } else {
                selectedOption = defaultOption;
            }

            this.setState({ stateOptions: options, selectedOption: selectedOption })
        })
    }

    onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    handleChange = (event) => {
        this.setState({
            selectedOption: event,
        })
    }

    render() {

        if (!this.state.stateOptions) {
            return <div>Loading...</div>
        }
        return (
            <RequestDetail RequestTypeDetail={this.props.RequestTypeDetail} >
                <Select
                    placeholder={this.props.RequestTypeDetail.placeholder}
                    options={this.state.stateOptions}
                    onChange={this.handleChange}
                    value={this.state.selectedOption}
                    name='statePicker'
                />
            </RequestDetail>
        );
    }
}
