import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';


export class RequestListItem extends Component {

    render() {

        let props = this.props.propertyList.filter(x => this.props.requestInfo.genericRequestFormProperties.map(x => x.siteId).includes(x.siteId));
        let propLabel;
        if (props.length === 1) {
            propLabel = props[0].propertyName;
        } else {
            propLabel = "Multiple Properties";
        }

        let empLabel = ""
        if (this.props.requestInfo.genericRequestFormEmployees?.length > 0) {
            if (this.props.requestInfo.genericRequestFormEmployees.filter(x => x.propertyEmployeesId !== null).length > 0) {
                var sortedEmpList = this.props.requestInfo.genericRequestFormEmployees.sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                })
                empLabel = "for " + sortedEmpList[0]?.employeeName
            } else {
                
            }
        }

        return (
            <>

                <div className="mimgPadTop10">
                    <Card>
                        <CardHeader tag="h5">
                            <a className="mimgLinks mimgBold" href={"requests/" + this.props.formType + "/" + this.props.requestInfo.id}>
                                {new Date(this.props.requestInfo.requestDateTime).toLocaleDateString()} - {this.props.requestInfo.requestType.requestType1} {empLabel} Request for {propLabel}
                            </a>
                        </CardHeader>
                        <CardBody>
                            <Row xs={1} sm={1} md={2}>
                                <Col>
                                    Requested By: {this.props.requestInfo.requesterName}<br />
                                    Requester Email: {this.props.requestInfo.requesterEmail}<br />
                                    Requester Position: {this.props.requestInfo.requesterPosition}<br />
                                    Requester Property: {this.props.requestInfo.requesterPropertyName}<br />
                                </Col>
                                <Col>
                                    Workflow Step: {this.props.stepName}<br />
                                    {this.props.requestInfo.requestStatus === "Assigned" &&
                                        <>
                                            Assigned To: {this.props.requestInfo.assignedToName}<br />
                                            Assigned On Date: {new Date(this.props.requestInfo.assignedToDateTime).toLocaleDateString() + " " + new Date(this.props.requestInfo.assignedToDateTime).toLocaleTimeString()}  <br />
                                        </>
                                    }

                                    Updated On: {new Date(this.props.requestInfo.requestStatusUpdatedDateTime).toLocaleDateString() + " " + new Date(this.props.requestInfo.requestStatusUpdatedDateTime).toLocaleTimeString()} <br />
                                    Updated By: {this.props.requestInfo.requestStatusUpdatedByEmpName}<br />

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </>

        );
    }
}
