import validator from 'validator';
import { axios } from 'react-mimg'


export function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
}

export function isEmailValid(emailAddress) {
    if (emailAddress && emailAddress !== null) {
        let regex = /^[-A-Za-z0-9_.+!#$%&'*/=?^`{|}~]+@([A-Za-z0-9][-A-Za-z0-9]*\.)+[A-Za-z]{2,4}$/;
        return regex.test(emailAddress);
    }
    return false;
}

export function stringToBoolean(string) {
    switch (string.toLowerCase().trim()) {
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
    }
}

// set for removal after final testing


//export async function checkIfFileExists(filePath, fileName) { //file path is where they are saved to
//    await axios.get("api/file/CheckIfFileExists?filePath=" + filePath + "&fileName=" + fileName).then((response) => {
//        if (response.data.data === true) {
//            return "";
//        } else {
//            return response.data.errorMessage + "\n";
//        }
//    }).catch((error) => {
//        return "Unable to check whether the file already exists or not. " + error.message;
//    });
//}

//export async function checkIfFileExists2(filePath, fileName) { //file path is where they are saved to
//    await axios.get("api/file/CheckIfFileExists?filePath=" + filePath + "&fileName=" + fileName).then((response) => {
//        if (response.data.data === false) {
//            return ""; //does not exist
//        } else {
//            return response.data.errorMessage + "\n";
//        }
//    }).catch((error) => {
//        return "Unable to check whether the file already exists or not. " + error.message;
//    });
//}

export async function getResourcesValidationMessage(form, isDocument, actionType, allLinksList) {
    let msg = ""
    if (isDocument) {
        if (form.fileUpload.files.length === 0 && actionType === "Add") { //no file is required if this is just an update
            msg = msg + 'No file was selected.\n';
        } else if (form.fileUpload.files.length === 0) {
            //this is an update action, so no file is required and we don't need to check if the file extension is valid or not since there is no file.
        } else {
            msg += await checkIfFileExtensionIsValid(form.fileUpload.files[0]);
            msg += checkIfFileNameIsValid(form.fileUpload.files[0]);
        }
    }
    else {
        if (!validator.isURL(form.linkURL.value)) {
            msg = msg + 'The url entered is not a valid url.\n';
        }
    }

    if (!validator.isLength(form.displayDesc.value , { min: 1, max: 150 })) {
        msg = msg + 'The display description is invalid.  Please enter a description between 1 & 150 characters.\n';
    }

    if (!validator.isLength(form.linkDesc.value, { min: 1, max: 1000 })) {
        msg = msg + 'The link description is invalid.  Please enter a description between 1 & 1000 characters.\n';
    }

    if (actionType === "Add") {
        var matchingLinks = allLinksList.filter(link => link.linkDesc === form.linkDesc.value && link.isActive);
        if (matchingLinks.length > 0) {
            msg = msg + 'There is already a link with the exact same link description in the ' + matchingLinks[0].category + ' category. Please edit the existing link or choose a new description.\n';
        }
    }

    if (!validator.isLength(form.keywords.value, { min: 0, max: 500 })) {
        msg = msg + 'The key words entered are invalid.  Please limit the keywords section to 500 characters.\n'
    }

    return msg;
}

export function getCategoryValidationMessage(category) {
    let msg = ""

    if (!validator.isLength(category.subCategoryDesc, { min: 3, max: 50 })) {
        msg = msg + 'The category description must be between 3 & 50 characters long\n';
    }

    if (!validator.isInt(category.sortOrder, { min: 0, max: 999 })) {
        msg = msg + 'The sort order must be a number between 1 and 999\n';
    }

    return msg;
}

function checkIfFileNameIsValid(file) {
    if (file.name.includes('+') || file.name.includes('&')) {
        return "The system does not allow for a file to have '+' or '&' in it's name. Please rename your file and try again. \n"
    }
    return "";
}
export async function checkIfFileExtensionIsValid(file) {
    var msg = "";
    const lastDot = file.name.lastIndexOf('.');
    const ext = file.name.substring(lastDot); //include the . in the check

    var allowedExtensions = ['.pdf', '.doc', '.docx', '.xlsx', '.xls', '.csv', '.jpg', '.jpeg', '.png', '.tiff', '.pptx', '.ppt']
    if (allowedExtensions.includes(ext.toLowerCase())) {
        msg = "";
    } else {
        msg = "The system does not allow for files of the type " + ext + '. Please convert the file to one of the allowed extension types and try again.  Allowed extension types are: ';
        msg = msg + "";
        var sep = "";
        for (var i = 0; i < allowedExtensions.length; i++) {
            msg = msg + sep + allowedExtensions[i];
            sep = ", "
        }

        msg = msg + "\n";
    }

    return msg;
}

export function isPicture(fileName) {
    if (!fileName)
        return null;
    var extension = fileName.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "tif", "tiff", "heicfile"];
    return allowedExtensions.includes(extension);
}

