import React from 'react';
import { Label, Input, FormGroup, Form } from 'reactstrap';
import { getISODate } from '../../../functions/numberFormatting';


export default function RequestAutoClose(props) {

    return (
        <Form className={props.rowClassName} onChange = {props.onHandleAutoCloseDate}>
            <Label className={props.labelClassName} for="autoCloseDate">Auto Close ticket unless a response is received by this date</Label>
            <Input className={props.inputClassName} id="autoCloseDate" name="autoCloseDate" type="date" defaultValue={props.autoCloseDate == '' ? '' : getISODate(new Date(props.autoCloseDate).toLocaleDateString('en-US', { timeZone: 'UTC' }))} />
        </Form>
        
    )

}
