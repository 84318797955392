import React from "react";
import styles from './spinner.module.scss'
import { TailSpin } from "react-loader-spinner";

export default function Spinner(props) {
    return <div className={styles.spinnerWrapper}>
        <div>
            <TailSpin
                heigth="50"
                width="50"
                color='#d59038'
                arialLabel={props.message} />
        </div>
        <p>{props.message}</p>
    </div>
}