import React, { useEffect, useState, useRef } from 'react';
import { axios, Button, ErrorLogModel, logError, getNowMountainTime } from 'react-mimg';
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Alert, ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import validator from 'validator';
import { createComment, getNextStepInfo } from '../../../functions/formHelpers/genericRequestFormHelper';
import { getEmployeeSecurity } from '../../../functions/permissions';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import styles from '../modals/RequestModals.module.scss';
import RequestRichInput from './RequestRichInput';
import { uploadImage } from './UploadImage';

export default function Assign(props) {
    const security = getEmployeeSecurity();
    const [modalAlert, setModalAlert] = useState();
    const [showModal, setShowModal] = useState(false);
    const [selectedAssignee, setSelectedAssignee] = useState();
    const [commentText, setCommentText] = useState('');
    const [processing, setProcessing] = useState(false);
    const quillRef = useRef();

    useEffect(() => {
        let currentUserRecord = props.availableAssignees.filter(x => x.propertyEmployeesId === parseInt(security.empID))[0]
        if (currentUserRecord) {
            setSelectedAssignee(currentUserRecord);
        }
    }, [props.availableAssignees])
    async function onAssignRequest(toSelf = false) {
        if (selectedAssignee) {
            setProcessing(true);
            let genericRequestFormModel = props.genericRequestFormModel;
            let pageAlert = new CustomAlertModel();

            genericRequestFormModel.requestStatus = "Assigned";
            genericRequestFormModel.assignedToByEmpId = security.empID;
            genericRequestFormModel.assignedToByName = security.employeeFullName;
            genericRequestFormModel.assignedToDateTime = getNowMountainTime();
            genericRequestFormModel.assignedToEmpId = selectedAssignee.propertyEmployeesId;
            genericRequestFormModel.assignedToEmail = selectedAssignee.email;
            genericRequestFormModel.assignedToName = selectedAssignee.firstName + ' ' + selectedAssignee.lastName;

            let requestTypeId = genericRequestFormModel.requestTypeId;
            let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Assign')[0].id;
            genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);

            let comments = genericRequestFormModel.genericRequestFormComments;
            let newComment = createComment(genericRequestFormModel.requesterEmpId, "<b>Request assigned to " + selectedAssignee.firstName + ' ' + selectedAssignee.lastName + ' (' + selectedAssignee.email + ') by ' + security.employeeFullName + "</b>");

            let updatedValue = "";
            if (toSelf === true) {
                updatedValue = "";
            } else {
                updatedValue = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : commentText;
            }
            
            if (validator.isLength(updatedValue, { min: 1 })) {
                updatedValue = updatedValue.replaceAll('<p><br></p>', '<br />')
                const imageRegex = /<img[^>]+src="([^">]+)"/g;
                const images = [];
                let match;
                while ((match = imageRegex.exec(updatedValue)) !== null) {
                    images.push(match[1]);
                }

                for (let i = 0; i < images.length; i++) {
                    const imageUrl = images[i];
                    const response = await uploadImage(imageUrl, genericRequestFormModel.id);
                    updatedValue = updatedValue.replace(imageUrl, response.data.url);
                }

                newComment.comment += "<br /><u>Assignment Notes: </u>" + updatedValue.replaceAll('\n', '<br />');
            }

            comments.push(newComment);

            axios.put("api/GenericRequestForm/UpdateRequest?updateAction=assign", genericRequestFormModel).then((response) => {
                genericRequestFormModel = response.data;

                pageAlert.AlertText = "The request was successfully assigned to " + genericRequestFormModel.assignedToName;
                pageAlert.AlertColor = "success";

                if (toSelf) {
                    props.updateGenericRequestFormModel(genericRequestFormModel, false);
                } else {
                    props.onSave(genericRequestFormModel, pageAlert, "Assign");
                }

                setShowModal(false);
                setProcessing(false);
            }).catch((error) => {
                setModalAlert({ color: "danger", message: "Error assigning request" })
                setProcessing(false);
                logError(new ErrorLogModel(error, "OnAssignRequestButtonPressed", security.employeeFullName + " experienced an error assigning a GenericRequestForm to " + selectControl.selectedOptions[0].value + ". FormId " + genericRequestFormModel.id));
            })
        } else {
            setModalAlert({ color: "danger", message: "No One is selected to assign the request to.  Please select someone" })
            setShowModal(true);//in case it is from the "self"
        }
    }

    return (
        <>
            {props.formPreferences?.allowAssignToSelf
                ? <ButtonGroup className={styles.buttonGroupWrapper}>
                    <UncontrolledDropdown direction="up">
                        <DropdownToggle className={styles.buttonGroup} caret>Assign</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => onAssignRequest(true)}>
                                Assign To Self
                            </DropdownItem>
                            <DropdownItem onClick={() => setShowModal(true)}>
                                Assign To Someone Else
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </ButtonGroup>
                : <Button onClick={() => setShowModal(true)}>Assign</Button>
            }

            <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)} size={props.formPreferences.richTextComments ? "lg" : undefined}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Assign Request</ModalHeader>

                {modalAlert &&
                    <Alert color={modalAlert.color}>{modalAlert.message}</Alert>
                }

                <ModalBody>
                    <Form>
                        <Label for="selectAssignee">Select someone to assign:</Label>
                        <Input type="select" id="selectAssignee" name="selectAssignee" value={selectedAssignee?.propertyEmployeesId} onChange={(e) => setSelectedAssignee(props.availableAssignees.filter(x => x.propertyEmployeesId === parseInt(e.target.value))[0])}>
                            <option key="--select--" id={0} value="0">--Select--</option>
                            {props.availableAssignees &&
                                props.availableAssignees.map(p => {
                                    return <option key={p.propertyEmployeesId} value={p.propertyEmployeesId}>{p.firstName + ' ' + p.lastName + ' (' + p.email + ')'}</option>
                                })}
                        </Input>
                        <br />
                        <Label for="txtAreaAssigmentComment">Assignment Notes (optional):</Label>
                        {props.formPreferences.richTextComments ? (
                            <RequestRichInput r={quillRef} /> 
                        ) : (
                            <Input className={styles.commentBox} type="textarea" id="txtAreaAssigmentComment" name="txtAreaAssigmentComment" value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                        )}
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={() => setShowModal(false)}>Cancel</Button> {"  "}
                    <Button onClick={() => onAssignRequest(false)} disabled={processing}>
                        {processing 
                            ? <span>Processing</span>
                            : <span>Assign Request</span>
                    }</Button> {"  "}
                </ModalFooter>
            </Modal>
        </>
    );
}
