import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import RequestDetail from './RequestDetail';

export default function RequestDetailNumber(props) {
    //needs to be a controlled component so it updates when defualt value updates (e.g. someone changes the property)
    const [value, setValue] = useState(props.defaultValue ? props.defaultValue : 0);

    useEffect(() => {
        setValue(props.defaultValue ? props.defaultValue : 0)
    }, [props.defaultValue])

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail} >
            <Input
                type="number"
                placeholder={props.RequestTypeDetail.placeHolderText}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </RequestDetail>
    );
}
