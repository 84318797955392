import React, { useState, useEffect } from 'react';
import RequestDetail from './RequestDetail';
import Select from 'react-select';
import { axios } from 'react-mimg';
import { Input, Label, FormGroup } from 'reactstrap';
import QuestionWrapper from "../formelements/QuestionWrapper";
import { getEmployeeSecurity } from '../../../functions/permissions';

export default function RequestDetailPropertySelectYardi(props) {
    const security = getEmployeeSecurity();
    const [needsAllProperties, setNeedsAllProperties] = useState('No');
    const [allPropertyOptions, setAllPropertyOptions] = useState([])
    const [propertyOptions, setPropertyOptions] = useState([])
    const [selectedPropertyOptions, setSelectedPropertyOptions] = useState([]);
    const [onlyMyProps, setOnlyMyProps] = useState(true);

    useEffect(() => {
        let opts = [];
        if (props.propertyList?.length > 0) {

            props.propertyList.map(x => {
                opts.push({ value: x.siteId, label: getPropertyNameFromId(x.siteId) });
            })
        }
        setAllPropertyOptions(opts);

        let usersSiteIds = []
        usersSiteIds.push(security.employeeSiteID);
        security.propertyAssociations.filter(x => x.siteId !== security.employeeSiteID).map(x => {
            usersSiteIds.push(x.siteId);
        })

        setPropertyOptions(opts.filter(x => usersSiteIds.includes(x.value)))

        let defaultSites = []
        defaultSites.push({ label: getPropertyNameFromId(security.employeeSiteID), value: security.employeeSiteID })
        setSelectedPropertyOptions(defaultSites)
    }, [props.propertyList])

    useEffect(() => {
        if (allPropertyOptions) {
            if (onlyMyProps) {
                let usersSiteIds = []
                usersSiteIds.push(security.employeeSiteID);
                security.propertyAssociations.filter(x => x.siteId !== security.employeeSiteID).map(x => {
                    usersSiteIds.push(x.siteId);
                })
                setPropertyOptions(allPropertyOptions.filter(x => usersSiteIds.includes(x.value)))
            } else {
                setPropertyOptions(allPropertyOptions)
            }
        }
    }, [onlyMyProps, allPropertyOptions])

    useEffect(() => {
        let sites = [];
        selectedPropertyOptions.map(x => {
            sites.push({ siteId: x.value, propertyName: x.label })
        })

        if (props.hideRequestPropertySelection === undefined || props.hideRequestPropertySelection === true) { //in some cases, like the payroll form, we want the property selection at the top, so that the submittor can choose properties to get the right list of employees. But we need the property selection to determine which properties will pay the bonus.  But we need to prevent these properties from posting back to change the list of employees as well, in the event that the list is different than who pays. In this case, the GenericRequestFormProperties saved for the form will be from the top selection, and will not necessarily match those for which the bonus is paid (that is stored within the specific RequestTypeDetail answer.)
            if (sites.length > 0) {
                props.onUpdateSelectedProperties(sites)
            }
        }

    }, [selectedPropertyOptions])
    
    function getPropertyNameFromId(siteId) {
        if (siteId === 99999)
            siteId = 176;

        let p = props.propertyList.filter(x => x.siteId === siteId)[0];
        if (p) {
            return p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')';
        } else {
            return "";
        }
    }

    if (!propertyOptions)
        return <div>Loading...</div>

    return (
        <div>
            {props.allowAllPropertiesOption &&
                <div>
                    <QuestionWrapper>
                        <Label
                            className='genericRequestDetailQuestionLabel'
                            for={"needsAllProperties"}>
                            Does this employee need access to all properties?
                        </Label>
                        <div className='genericRequestDetailInput'>
                            <Input type="select" name="needsAllProperties" onChange={(e) => setNeedsAllProperties(e.target.value)}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </Input>
                        </div>
                    </QuestionWrapper>
                </div>
            }

            <RequestDetail RequestTypeDetail={props.RequestTypeDetail} >
                {needsAllProperties === "Yes"
                    ? <Input type="text" value="Access To All Properties" disabled />
                    : <Select
                        isMulti
                        placeholder={props.RequestTypeDetail.placeholder}
                        options={propertyOptions}
                        value={selectedPropertyOptions}
                        onChange={setSelectedPropertyOptions}
                        name='propertyPicker' />
                }

                <div className='genericRequestDetailQuestionLabel'>
                    <div className='genericRequestDetailInput'>
                        <FormGroup switch>
                            <Input type="switch" checked={onlyMyProps} onChange={(e) => setOnlyMyProps(e.target.checked)}></Input>Show only my props
                        </FormGroup>
                    </div>
                </div>


            </RequestDetail>

        </div>
    );
}
