import React, { useEffect, useState } from 'react';
import { Button, axios, getNowMountainTime } from 'react-mimg';
import { Form, Modal, ModalBody, ModalHeader, Alert, Label, Input } from 'reactstrap';
import { createComment, getNextStepInfo } from '../../../../functions/formHelpers/genericRequestFormHelper';
import { getEmployeeSecurity } from '../../../../functions/permissions';
import styles from './UploadMvrModal.module.scss';
import ClipLoader from "react-spinners/ClipLoader";
import ToggleSwitch from '../../../profiles/PropertyProfile/Maintenance/ToggleSwitch';

var CryptoJS = require("crypto-js");

export default function ApproveDriversLicenseUpdate(props) {

    const security = getEmployeeSecurity();
    const [processing, setProcessing] = useState(false);
    const [mvrDate, setMvrDate] = useState();
    const [licenseExpDate, setLicenseExpDate] = useState();
    const [sendEmailToEpic, setSendEmailToEpic] = useState(true);
    const [defaultEmailBody, setDefaultEmailBody] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalAlert, setModalAlert] = useState({
        color: "info", message: "Approve this request after verifying that the information entered matches the information on the uploaded license document. If there are issues, use the commenting system, or reject the request and ask the requestor to submit again with the correct info."
    });

    useEffect(() => {
        if (showModal) {
            if (props.genericRequestFormModel.id > 0) {
                axios.get('api/PropertyEmployees/GetSingleEmployeeCard?PropertyEmployeesId=' + props.genericRequestFormModel?.genericRequestFormEmployees[0]?.propertyEmployeesId).then(response => {
                    setDefaultEmailBody(getDefaultEmailBody(response.data))
                })

                let response = props.genericRequestFormModel.genericRequestResponses[0];
                if (response) {//if there has been an MVR added to this form already, assume it is a new one and use that date.  If there is not an MVR date associated to this form, use the date already associated with that driver
                    setMvrDate(response.responseValue)
                } else {
                    let propertyEmployeesId = props.genericRequestFormModel?.genericRequestFormEmployees[0]?.propertyEmployeesId;
                    if (propertyEmployeesId) {
                        axios.get('api/DriversLicense/GetDriverByPropertyEmployeesId?propertyEmployeesId=' + propertyEmployeesId).then(res => {
                            setMvrDate((new Date(res.data.dateOfMvr)).toISOString().split('T')[0]);
                        })
                    }
                }

                let detail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_EXP_DATE_UPDATE))[0];
                if (detail) {
                    setLicenseExpDate((new Date(detail.response)).toISOString().split('T')[0]);
                }
            }
        }
    }, [showModal, props.genericRequestFormModel])

    function onCompleteRequest(e) {
        e.preventDefault();
        setProcessing(true);

        let genericRequestFormModel = JSON.parse(JSON.stringify(props.genericRequestFormModel));
        let updateLicenseModel = {};
        updateLicenseModel.requestForm = genericRequestFormModel;
        updateLicenseModel.notes = e.target.updateDlComment.value;
        updateLicenseModel.licenseNumber = getDecryptedDriversLicenseNumber(genericRequestFormModel);
        updateLicenseModel.dateOfMvr = e.target.updateDriverMvr.value;
        updateLicenseModel.licenseExpDate = e.target.updateDriverLicenseExp.value;
        updateLicenseModel.sendEmailToEpic = sendEmailToEpic;
        updateLicenseModel.emailToEpicBody = sendEmailToEpic ? e.target.updateDriverEmailToEpic.value : "";

        //api call to add the driver to the drivers table list, which should also copy the used MVR and Drivers License documents to the EmployeeDocuments table.
        axios.post('api/DriversLicense/UpdateDriversLicense', updateLicenseModel).then(response => {
            genericRequestFormModel.genericRequestFormComments.push(createComment(security.empID, e.target.updateDlComment.value));

            let requestTypeId = genericRequestFormModel.requestTypeId;
            let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Approve Drivers License Update')[0].id;
            genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);
            genericRequestFormModel.requestStatus = 'Completed'
            genericRequestFormModel.requestComplete = true;
            genericRequestFormModel.requestCompletedByEmpId = security.empID;
            genericRequestFormModel.requestCompletedByEmpName = security.employeeFullName;
            genericRequestFormModel.requestCompletedDateTime = getNowMountainTime();
            genericRequestFormModel.requestCompletedByEmpPosition = security.employeePosition;

            axios.put('api/GenericRequestForm/UpdateRequest?updateAction=updateDriversLicense', genericRequestFormModel).then((res) => {
                let genericRequestForm = res.data;
                let pageAlert = { AlertText: "The drivers license info has been updated successfully. A notification was sent to the requester.", AlertColor: "success" }

                props.onSave(genericRequestForm, pageAlert, "Approve Drivers License Update")
                setShowModal(false);
                setProcessing(false);
            })
        })
    }

    function getDecryptedDriversLicenseNumber(genericRequestFormModel) { //the grf system encrypts/decrypts the details at the client.  The drivers system does it on the backend, so as the Drivers system is the target system, we need to decrypt here (keys not shared)
        let detail = genericRequestFormModel.genericRequestFormDetails.filter(x => x.detail.questionLabel.toLowerCase() === "Drivers License Number" || x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_NUMBER_UPDATE))[0]
        let key = process.env.REACT_APP_GENERIC_REQUESTS_CLIENT_SIDE_KEY + detail.detailId

        var bytes = CryptoJS.AES.decrypt(detail?.response, key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    function getDefaultEmailBody(employeeCard) {
        let nameDetail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_NAME_UPDATE))[0];
        let stateDetail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_STATE_UPDATE))[0];
        let dobDetail = props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.detailId === parseInt(process.env.REACT_APP_REQUEST_DETAIL_DRIVERS_LICENSE_DOB_UPDATE))[0];

        return `Monarch Investment has updated the info for an already approved driver to drive company vehicles

Driver Name (Per License): ${nameDetail?.response} 
Driver Name (Per Employee Record): ${employeeCard?.employeeName} 
Job Title: ${employeeCard?.title}
Home Property: ${employeeCard.homeProperty}
License State: ${stateDetail?.response}
License Number: ${getDecryptedDriversLicenseNumber(props.genericRequestFormModel)}
DOB: ${dobDetail?.response}`
    }

    return (
        <div>
            <Button onClick={() => setShowModal(true)}>Approve License Update</Button>

            <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>Approve Driver's License Update</ModalHeader>

                <ModalBody>
                    {modalAlert &&
                        <Alert color={modalAlert.color}>{modalAlert.message}</Alert>
                    }
                    <Form onSubmit={onCompleteRequest}>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="updateDriverLicenseExp">Confirm License Exp Date</Label>
                            <Input type="date" required name="updateDriverLicenseExp" id="updateDriverLicenseExp" defaultValue={licenseExpDate} />
                        </div>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="updateDriverMvr">Confirm MVR Date</Label>
                            <Input type="date" name="updateDriverMvr" id="updateDriverMvr" defaultValue={mvrDate} />
                        </div>
                        <div className={styles.marginBetweenQuestions}>
                            <Label className={styles.prompt} for="updateDlComment">Comments (optional, any comments will be visible to anyone that can access the form)</Label>
                            <Input type="textarea" name="updateDlComment" id="updateDlComment" defaultValue="" placeholder="Add any comments about this update that should be visible to all users" />
                        </div>
                        <div className={styles.marginBetweenQuestions}>
                            <ToggleSwitch className={styles.prompt} label="Send Email To EPIC" checked={sendEmailToEpic} onChange={() => setSendEmailToEpic(!sendEmailToEpic)} />
                            {sendEmailToEpic &&
                                <Input type="textarea" rows="10" name="updateDriverEmailToEpic" id="updateDriverEmailToEpic" defaultValue={defaultEmailBody} placeholder="This text will be sent to Epic in an email" />
                            }
                        </div>
                        <div className={styles.buttonsOnRight}>
                            <Button transparent onClick={() => setShowModal(false)}>Close</Button> {"  "}
                            <Button type="submit" disabled={processing} className={styles.submitButton}>
                                {processing
                                    ? <div><ClipLoader size={25} /></div>
                                    : <>Approve Drivers License Update</>
                                }
                            </Button> {"  "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}