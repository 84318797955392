import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Button } from 'react-mimg';
import styles from './ImageModal.module.scss';

export default function ImageModal(props) {
    return (
        <Modal isOpen={props.isOpen} size="xl" toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                {props.modalTitle}
            </ModalHeader>
            <ModalBody>
                <div className={styles.modalBody} dangerouslySetInnerHTML={{ __html: props.modalContent }}></div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
}
