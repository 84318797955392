import React from "react";
import { Label } from "reactstrap";
import Required from "../../layout/Required";
import QuestionWrapper from "../formelements/QuestionWrapper";
import styles from './requestDetail.module.scss'

export default function RequestDetail(props) {

    return (
        <div
            className='genericDetailHolderDiv' /*genericDetailHolderDiv has no styling attached, it is used as a class selector to remove data*/
            id={props.RequestTypeDetail.id}
            isencrypted={props.RequestTypeDetail.isEncrypted ? "Yes" : "No"}
            validationtype={props.RequestTypeDetail.validationType} >
            <QuestionWrapper required={props.RequestTypeDetail.isRequired}>
                <Label
                    className='genericRequestDetailQuestionLabel'
                    for={"detail_input_" + props.RequestTypeDetail.id}>
                    <a href={props.RequestTypeDetail.linkUrl} target="_blank">{props.RequestTypeDetail.questionLabel}</a>
                    {props.RequestTypeDetail.isRequired && <Required />}

                    {props.isAdmin && props.RequestTypeDetail.responseControlType.toLowerCase().includes('property') &&
                        <>
                        <br />
                        <span className={styles.changeLabel} onClick={() => props.onShowChangePropertiesModal(true)}>Change Properties</span>
                        </>
                    }
                </Label>
                <div className='genericRequestDetailInput'
                    id={"detail_input_" + props.RequestTypeDetail.id}>
                    {props.children}
                </div>
            </QuestionWrapper >
        </div>
    )
}