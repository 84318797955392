import { isEmailValid } from './validation';
import { logError, ErrorLogModel, axios } from 'react-mimg';
import { CustomAlertModel } from '../models/CustomAlertModel';
import { CustomAlertLink } from '../models/CustomAlertLink';
import { Cookies } from 'react-cookie';
import jwt from 'jwt-decode';

const CryptoJS = require("crypto-js");

function getLoggedInUserId() {
    let cookies = new Cookies();
    let jwtCookie = cookies.get('jwt');
    if (!jwtCookie) {
        return null;
    }
    let cookie = jwt(jwtCookie)
    return cookie.nameid;
}

export function getEmployeeSecurity() {
    try {
        let encrypted = localStorage.getItem('employeeSecurity');
        if (!encrypted) {
            return null;
        }
        else {
            let bytes = CryptoJS.AES.decrypt(encrypted.toString(), getLoggedInUserId());
            let empSecurity = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return empSecurity;
        }
    }
    catch (error) {
        logError(new ErrorLogModel(error, "getEmployeeSecurity", "Cannot load employee Security from local storage."));
        localStorage.removeItem('employeeSecurity');
    }
}

export function getSearchObject() {
    let searchInfo = JSON.parse(localStorage.getItem("savedSearchResults"));
    if (!searchInfo) {
        return null;
    } else {
        return searchInfo;
    }
}

export function setPermissions(empSecurity) {
    let encrypted = CryptoJS.AES.encrypt(empSecurity, getLoggedInUserId()).toString();
    localStorage.setItem('employeeSecurity', encrypted);
}

export function isDeveloper() {
    let empSecurity = getEmployeeSecurity();
    let developerEmpIds = ['113', '11791', '13217', '9953', '11207']
    return developerEmpIds.includes(empSecurity.empID);
}

export function isHeadHoncho() {
    let empSecurity = getEmployeeSecurity();
    var execIds = [115, 130, 124, 136, 116] //bobby, Andy Miller, Chuck, Andy N, Quentin
    return execIds.includes(parseInt(empSecurity.empID))
}

export function getMenuPermissions() {
    try {
        let empSecurity = getEmployeeSecurity();

        if (!empSecurity)
            return null;

        return {
            //resources
            KnowledgeBase:
                empSecurity.blogPostSecurityLevel > 1,
            MonarchWiki:
                empSecurity.paePosSortOrder <= 3,
            //request Forms
            VendorSetupRequestForm:
                empSecurity.securityLevel === 3 ||
                empSecurity.canSubmitVendorSetupRequests,
            CorporateServicesRequestForm:
                empSecurity.securityLevel === 3 ||
                empSecurity.canSubmitCorporateServicesRequest,
            CreditCardRequestFormOld:
                isDeveloper() ||
                parseInt(empSecurity.empID) === 2444 || //cindy heeg
                parseInt(empSecurity.empID) === 5336 || //cheryl gurule
                parseInt(empSecurity.empID) === 9720, //lisa mcdufford
            CreditCardRequestForm:
                empSecurity.securityLevel === 3 ||
                empSecurity.canSubmitCreditCardRequests === true ||
                parseInt(empSecurity.empID) === 153 || //Joann Davis
                parseInt(empSecurity.empID) === 928 || //Tiffany Reitcheck
                parseInt(empSecurity.empID) === 2444, //Cindy Heeg
            ITPurchaseRequest:
                empSecurity.canSubmitHardwareRequests,
            ITAdmin:
                empSecurity.isITadmin,
            TransactionTracker:
                empSecurity.canViewTrans === true ||
                empSecurity.canCreateUpdateTrans === true,
            ContractRequestForm:
                empSecurity.canSubmitContractRequest === true ||
                empSecurity.canProcessContractRequest === true,
            MarketingRequestForm:
                empSecurity.securityLevel === 3 ||
                empSecurity.canSubmitMarketingDesignForms === true ||
                (empSecurity.marketingDesignGroup != null && empSecurity.marketingDesignGroup !== "") ||
                empSecurity.employeePosition.includes("IT"),
            MotivatorDataDownload:
                empSecurity.securityLevel === 3 ||
                empSecurity.canSubmitMarketingDesignForms === true ||
                (empSecurity.marketingDesignGroup != null && empSecurity.marketingDesignGroup !== "") ||
                empSecurity.employeePosition.includes("IT"),
            TrainingCoverageRequestForm:
                empSecurity.securityLevel === 3 ||
                parseInt(empSecurity.empID) === 113 || //Curtis Pottle
                empSecurity.isTrainingFormAdmin === true ||
                empSecurity.canSubmitTrainingCoverageForms === true,
            TrainingHelpDeskRequestForm:
                empSecurity.securityLevel === 3 ||
                (empSecurity.trainingHelpDeskGroup != null && empSecurity.trainingHelpDeskGroup !== "") ||
                isEmailValid(empSecurity.employeeEmail),
            NewHireRequestForm:
                empSecurity.securityLevel === 3 ||
                empSecurity.employeeSiteID === 99999 ||
                empSecurity.employeeSiteID === 176,
            RecruitingAssistanceRequestForm:
                empSecurity.canSubmitRecruitingForm === true,
            RecruitingAssistanceFormOld:
                empSecurity.employeePosition.includes("Recruit") ||
                empSecurity.SecurityLevel >= 3 ||
                empSecurity.isJobAdmin === true,
            RoverRequestForm:
                empSecurity.paePosSortOrder <= 3 ||
                empSecurity.employeeSiteID === 176,
            VendorRequestForm:
                empSecurity.canSubmitVendorForms === true ||
                empSecurity.isVendorAdmin === true ||
                empSecurity.isITadmin === true,
            RequestFormAnalytics:
                empSecurity.canEditForms.length > 0 ||
                isDeveloper() || isHeadHoncho(),
            //HR Payroll
            MyEmployeesPage:
                empSecurity.canViewMyEmployeesPage === true,
            EmployeeStatusChangeForm:
                empSecurity.canSubmitEmployeeStatusChanges === true,
            SeparationNoticeForm:
                empSecurity.securityLevel === 3 ||
                parseInt(empSecurity.empID) === 113 || //Curtis Pottle
                empSecurity.canSubmitEmpTerminationForms === true ||
                (empSecurity.empTerminationGroup != null && empSecurity.empTerminationGroup !== ""),
            CommissionBonusSubmission:
                empSecurity.canSubmitCommissionBonusForm === true ||
                empSecurity.canCreateCommissionBonusForm === true,
            PayrollAdmin:
                empSecurity.isBonusAdmin === true,
            ReferralBonusRequestForm:
                empSecurity.securityLevel === 3 ||
                parseInt(empSecurity.empID) === 113 || //Curtis Pottle
                empSecurity.canSubmitReferralBonusForms === true ||
                empSecurity.isBonusAdmin === true,
            PayrollReports: //'AMs and Analysts only for now. The page allows for RMs and ARMs to access but don't roll out yet (CP - 8/13/2021)
                empSecurity.payrollReportsSecLevel >= 1 ||
                empSecurity.paePosSortOrder <= 3 ||
                empSecurity.paePosSortOrder === 30, //add recruiters 2-9-2022
            //Admin
            AdminMenu:
                empSecurity.securityLevel === 3 ||
                empSecurity.directorySecurityLevel === 2 ||
                empSecurity.trainingSecurityLevel === 2 ||
                empSecurity.isTrainingFormAdmin === true ||
                empSecurity.isLegalAdmin === true ||
                empSecurity.isProfileAdmin === true ||
                empSecurity.propertyDocAdmin === true ||
                empSecurity.blogPostSecurityLevel > 2 ||
                empSecurity.canViewThumbsUpReport === true ||
                empSecurity.marketingDesignGroup.toLowerCase().includes("admin") ||
                empSecurity.employeePosition.includes("Team Relations") ||
                empSecurity.isJobAdmin === true ||
                empSecurity.employeePosition === "Marketing" ||
                empSecurity.employeePosition === "Marketing Senior" ||
                parseInt(empSecurity.empID) === 4945 || //Cal Lavezzi
                parseInt(empSecurity.empID) === 113 || //Curtis Pottle
                (empSecurity.canEditForms && empSecurity.canEditForms.length > 0),
            AdminEmployeeLinks:
                empSecurity.securityLevel === 3 ||
                empSecurity.isITadmin === true ||
                empSecurity.marketingDesignGroup.toLowerCase().includes("admin") ||
                empSecurity.employeePosition.includes("Team Relations") ||
                parseInt(empSecurity.empID) === 719, //Anthony (ATP) Petrone
            AdminLegal:
                empSecurity.securityLevel === 3 ||
                empSecurity.isLegalAdmin === true,
            AdminViewLogs:
                empSecurity.securityLevel === 3 ||
                empSecurity.isITadmin === true,
            AdminPropertyDocMaint:
                empSecurity.propertyDocAdmin === true,
            AdminVendorDocMaint:
                empSecurity.isVendorAdmin === true,
            AdminVendors:
                empSecurity.isVendorAdmin === true,
            AdminAllocationReview:
                empSecurity.isProfileAdmin === true,
            AdminJobDocMaint:
                empSecurity.securityLevel === 3 ||
                empSecurity.isJobAdmin === true,
            AdminJobTitleMaint:
                empSecurity.securityLevel === 3 ||
                empSecurity.isJobAdmin === true,
            AdminPendingSpAllocations:
                empSecurity.isProfileAdmin === true,
            AdminPendingAdjustmentReview:
                empSecurity.isProfileAdmin === true,
            AdminPendingCommissionBonus:
                empSecurity.isProfileAdmin === true,
            AdminStatusChangeReview:
                empSecurity.isProfileAdmin === true,
            AdminEmployeeDocMaint:
                empSecurity.isEmployeeStatusChangeAdmin === true,
            AdminEmployeeProfileMaint:
                empSecurity.directorySecurityLevel === 2 ||
                empSecurity.hasAdminAccessToOwnRegion === true ||
                empSecurity.isProfileAdmin === true ||
                empSecurity.isITadmin === true,
            AdminTrainingSessionsList:
                empSecurity.trainingSecurityLevel === 2,
            AdminTraining:
                empSecurity.trainingSecurityLevel === 2,
            AdminTrainingCoverageList:
                empSecurity.securityLevel === 3 ||
                empSecurity.isTrainingFormAdmin === true,
            AdminMonarchUniversity:
                empSecurity.trainingSecurityLevel === 2,
            AdminApproveKnowledgeBase:
                empSecurity.blogPostSecurityLevel > 2,
            AdminITPurchaseOrder:
                empSecurity.securityLevel === 3 ||
                empSecurity.isITadmin === true,
            AdminMarketingRequestTypeMaint:
                parseInt(empSecurity.empID) === 113 || //Curtis Pottle
                parseInt(empSecurity.empID) === 5421, //Erica Garrity
            //|| parseInt(empSecurity.empID) === 15858, //Sloan Shaffer
            AdminUpdatePhoneNumbers:
                empSecurity.canUpdatePhoneNumbers === true,
            AdminThumbsUpReport:
                empSecurity.canViewThumbsUpReport === true,
            AdminThumbsUpMaint:
                empSecurity.directorySecurityLevel === 2 ||
                empSecurity.hasAdminAccessToOwnRegion === true,
            AdminYelpReport:
                empSecurity.employeePosition === "Marketing" ||
                empSecurity.employeePosition === "Marketing Senior" ||
                parseInt(empSecurity.empID) === 4945, // Cal Lavezzi
            AdminRequestForms:
                (empSecurity.canEditForms && empSecurity.canEditForms.length > 0) ||
                isDeveloper(),
            AdminMyEmployees:
                empSecurity.isProfileAdmin ||
                empSecurity.isPerformanceReviewAdmin,
            AdminInvStaffMaintenance:
                empSecurity.isProfileAdmin === true,
            CanViewDriversForm:
                getFormAccess("Drivers") == true ||
                isHeadHoncho() ||
                empSecurity.isDriverAdmin === true

        };
    }
    catch (error) {
        // Do something else?? Send to login? Clear permissions from local storage? 
        logError(new ErrorLogModel(error, "getMenuPermissions", "Cannot load menu permissions from local storage"));
    }
}

/*return a boolean based on the current category and empSecurity object */
export function getIsCategoryAdmin(webpageCategory, empSecurity) {
    let authorizedToEdit = false;
    /*Admins can be set on the employee profile page.  For now, any other page uses other permissions to the best guess possible*/
    if (empSecurity.canEditAllResourceCategories === true) {
        authorizedToEdit = true;
    } else {
        switch (webpageCategory.toLowerCase()) {
            case 'Accounting'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Accounting Senior" ||
                    empSecurity.employeePosition === "Accounting Controller" ||
                    empSecurity.employeePosition === "Corporate Services" ||
                    empSecurity.employeePosition === "Corporate Services Senior";
                break;
            case 'Covid19'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "HR" ||
                    empSecurity.employeePosition === "HR Senior" ||
                    empSecurity.employeePosition === "Training Senior";
                break;
            case 'HR'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "HR" ||
                    empSecurity.employeePosition === "HR Senior";
                break;
            case 'IT'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "IT"
                    || empSecurity.employeePosition === "IT Senior";
                break;
            case 'JobDescriptions'.toLowerCase():
                authorizedToEdit =
                    empSecurity.isJobAdmin;
                break;
            case 'Legal'.toLowerCase():
                authorizedToEdit =
                    empSecurity.isLegalAdmin;
                break;
            case 'Marketing'.toLowerCase():
                authorizedToEdit =
                    empSecurity.marketingDesignGroup.toLowerCase().includes("admin")
                    || parseInt(empSecurity.empID) === 5421  //Erica Garrity
                    || empSecurity.employeePosition === "Regional Marketing Director" //seemed to not work
                    || parseInt(empSecurity.empID) === 2370  //Melissa Peterson
                    || parseInt(empSecurity.empID) === 599   //Melissa Winford
                    || parseInt(empSecurity.empID) === 1161; //Bailey Fillmon

                break;
            case 'OnSiteOps'.toLowerCase():
                authorizedToEdit = empSecurity.employeePosition === "Corporate Services" ||
                    empSecurity.employeePosition === "Corporate Services Senior" ||
                    empSecurity.employeePosition === "Training Senior" ||
                    empSecurity.employeePosition === "Other Corporate" ||
                    empSecurity.employeePosition === "HR Senior";
                break;
            case 'Recruiting'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Recruiting Senior" ||
                    parseInt(empSecurity.empID) === 15356 || //Leanna Merritt - per IT Request 107181
                    parseInt(empSecurity.empID) === 11500 || //Morgan Murphy - recruiting assistant
                    parseInt(empSecurity.empID) === 2659; //MEGAN LAMBERT - IT Request 134553
                break;
            case 'SOP'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Training Senior" ||
                    parseInt(empSecurity.empID) === 82 //Angela Faria, Lorraine did not want the entire training department to have this
                break;
            case 'News'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Team Relations" ||
                    empSecurity.employeePosition === "Team Relations Senior" //doesn't exist at 9/7/2021 but good chance it will in the future.
                break;
            case 'TeamRelations'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Team Relations" ||
                    empSecurity.employeePosition === "Team Relations Senior" || //doesn't exist at 9/7/2021 but good chance it will in the future.
                    empSecurity.employeePosition === "Corporate Services" ||
                    empSecurity.employeePosition === "Corporate Services Senior";
                break;
            case 'Training'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Training Senior" ||
                    parseInt(empSecurity.empID) === 82 //Angela Faria, Lorraine did not want the entire training department to have this
                break;
            case 'Bluemoon'.toLowerCase():
                authorizedToEdit =
                    parseInt(empSecurity.empID) === 1057 || //Lorraine Vega, Lorraine did not want the entire training department to have this
                    parseInt(empSecurity.empID) === 2374    //Ian Morris, Lorraine did not want the entire training department to have this
                break;
            default:
                authorizedToEdit = false;
                break;
        }
    }

    return authorizedToEdit;
}

export function getIsCorporateProfileAdmin(DepartmentName) {
    let empSecurity = getEmployeeSecurity();
    let authorizedToEdit = false;
    /*Admins can be set on the employee profile page.  For now, any other page uses other permissions to the best guess possible*/
    if (empSecurity.canEditAllResourceCategories === true) {
        authorizedToEdit = true;
    } else {
        switch (DepartmentName.toLowerCase()) {
            case 'Accounting'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Accounting Senior" ||
                    empSecurity.employeePosition === "Accounting Controller" ||
                    empSecurity.employeePosition === "Corporate Services" ||
                    empSecurity.employeePosition === "Corporate Services Senior";
                break;

            case 'Corporate Services'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Corporate Services" ||
                    empSecurity.employeePosition === "Corporate Services Senior";
                break;
            case 'Human Resources'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "HR" ||
                    empSecurity.employeePosition === "HR Senior";
                break;
            case 'IT'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "IT"
                    || empSecurity.employeePosition === "IT Senior";
                break;
            case 'Legal'.toLowerCase():
                authorizedToEdit =
                    empSecurity.isLegalAdmin;
                break;
            case 'Corp Marketing'.toLowerCase():
                authorizedToEdit =
                    parseInt(empSecurity.empID) === 5421;  //Erica Garrity
                break;

            case 'Leasing & Marketing Specialist'.toLowerCase():
                authorizedToEdit =
                    parseInt(empSecurity.empID) === 5421; //Erica Garrity
                break;

            case 'Recruiting'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Recruiting Senior" ||
                    parseInt(empSecurity.empID) === 11500; //Morgan Murphy - recruiting assistant
                break;

            case 'Team Relations'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Team Relations" ||
                    empSecurity.empSecurity === "Team Relations Senior"; //doesn't exist at 9/7/2021 but good chance it will in the future.
                break;
            case 'Training'.toLowerCase():
                authorizedToEdit =
                    empSecurity.employeePosition === "Training Senior" ||
                    parseInt(empSecurity.empID) === 82 //Angela Faria, Lorraine did not want the entire training department to have this
                break;
            default: //'Analyst','Asset Mgrs','MIMG General Contacts','N/A'
                authorizedToEdit = false;
                break;
        }
    }

    return authorizedToEdit;
}

export function getUnauthorizedToViewPageAlertMessage(additionalLinks) { //all requests will point to the home page and IT request list, but if there are any others specified they can be added (on top of the default links)
    let pageAlert = new CustomAlertModel();
    pageAlert.AlertColor = "danger";
    pageAlert.AlertText = "The url entered is valid, but you are not authorized to access this page or the referenced form id does not exist. Please see the links below for more options. This error message sometimes shows if you are trying to submit a request form, but there are not any currently active request types for that category";

    let alertLinks = [];

    if (additionalLinks !== null && additionalLinks !== undefined) {
        for (let i = 0; i < additionalLinks.length; i++) {
            alertLinks.push(additionalLinks[i]);
        }
    }

    var homeLink = new CustomAlertLink();
    homeLink.url = 'profiles/employee';
    homeLink.linkDesc = 'Return to the Home Page';
    alertLinks.push(homeLink);

    var itLink = new CustomAlertLink();
    itLink.url = 'https://mimg.us/ITRequestForm.aspx?Type=AddForm&on=0';
    itLink.linkDesc = 'Submit an IT Service Request if you believe you should have access to this page, but have been unable to gain access by resetting your securities';
    alertLinks.push(itLink);

    logPageView();
    pageAlert.AlertLinks = alertLinks;

    return pageAlert;

}

let canLog = true;

const logPageView = async () => {
    if (!canLog) return;
    canLog = false;

    const user = getEmployeeSecurity();
    const view = {
        userId: user?.userId,
        fullUrl: window?.location?.href,
        propertyEmployeesId: user?.empID,
        path: window?.location?.pathname,
        queryString: window?.location?.search,
    };

    await axios.post('api/PageViewLog/LogNoAccessView', view)
        .finally(() => {
            canLog = true; 
        });
};

export function getWebDeveloperOnlyMessage(additionalLinks) { //all requests will point to the home page and IT request list, but if there are any others specified they can be added (on top of the default links)
    let pageAlert = new CustomAlertModel();
    pageAlert.AlertColor = "danger";
    pageAlert.AlertText = "The url entered is valid, This page is only available to web developers. Please see the links below for more options.";

    let alertLinks = [];

    if (additionalLinks !== null && additionalLinks !== undefined) {
        for (let i = 0; i < additionalLinks.length; i++) {
            alertLinks.push(additionalLinks[i]);
        }
    }

    var homeLink = new CustomAlertLink();
    homeLink.url = 'profiles/employee';
    homeLink.linkDesc = 'Return to the Home Page';
    alertLinks.push(homeLink);

    var itLink = new CustomAlertLink();
    itLink.url = 'https://mimg.us/ITRequestForm.aspx?Type=AddForm&on=0';
    itLink.linkDesc = 'Submit an IT Service Request if you believe you should have access to this page';
    alertLinks.push(itLink);

    pageAlert.AlertLinks = alertLinks;

    return pageAlert;

}

export function getFormAccess(form) {
    
    try {
        let searchObject = getSearchObject()
        let availableRequestForms = searchObject?.requestTypes;

        if (isDeveloper() || !availableRequestForms || availableRequestForms.some(obj => obj.form.toLowerCase() === form.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    } catch {
        return true;
    }
}


