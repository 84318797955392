import React from "react";
import { FormGroup } from "reactstrap";
import styles from './questionWrapper.module.scss'

export default function QuestionWrapper (props) {


    if (props.children.length < 2) return <div />

    return (
        <FormGroup className={styles.wrapper}>
            <div className={styles.label}>
                {props.children[0]}
            </div>
            <div className={styles.question}>
                {props.children.slice(1)}
            </div>
        </FormGroup>
    )
}