import React, { useState } from 'react';
import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup } from 'reactstrap';
import { Button, axios, getNowMountainTime } from 'react-mimg';
import { useEffect } from 'react';
import styles from './changeRequestTypeModal.module.scss';
import validator from 'validator';
import { getEmployeeSecurity } from '../../../functions/permissions';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';
import ChangeRequestTypeEmail from './ChangeRequestTypeEmail';
import Select from 'react-select';

export default function ChangePropertiesModal(props) {
    const security = getEmployeeSecurity();
    const [alert, setAlert] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [processing, setProcessing] = useState(false)
    const [additionalComment, setAdditionalComment] = useState('');
    const [propertyOptions, setPropertyOptions] = useState([]);
    const [updatedPropertyList, setUpdatedPropertyList] = useState([]);
    const [requestType, setRequestType] = useState();
    const [originalRequestType, setOriginalRequestType] = useState(); //this is needed to find the proper request type detail in the event that the request type has been changed, we still need to be able to change the right request type detail.
    const [emailToRequester, setEmailToRequester] = useState({ send: false, to: props.genericRequestFormModel.requesterEmail, body: '', genericRequestFormId: props.genericRequestFormModel.id, subject: "Request Form Properties Changed - XRef: " + props.genericRequestFormModel.id });
    const [emailToAdmins, setEmailToAdmins] = useState({ send: false, to: '', body: '', genericRequestFormId: props.genericRequestFormModel.id, subject: "Request Form Properties Changed - XRef: " + props.genericRequestFormModel.id });
    const [newFormUrl, setNewFormUrl] = useState(window.location.href)
    const [currentlyForAllSites, setCurrentlyForAllSites] = useState(props.genericRequestFormModel.genericRequestFormDetails.filter(x => x.response === 'Access To All Properties').length > 0);
    const [needsToBeForAllProperties, setNeedsToBeForAllProperties] = useState(currentlyForAllSites);
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        let messages = ['Admins may use this functionality to change the properties associated with this form.  You may choose to send an email to inform other admins or the requester by selecting to do so below, if necessary.  A list of the previous properties will be saved as a comment for all form users to see.']

        setAlert({ color: "info", messages: messages })
    }, [])

    useEffect(() => {

        if (props.isOpen && props.genericRequestFormModel.requestTypeId) {
            axios.get("api/aptproperties/GetActiveAndPendingPropertiesWithManagersAndCityMetroAreas").then((response) => {
                setProperties(response.data)
            })

            axios.get('api/requesttypes/GetRequestTypeSummary/' + props.genericRequestFormModel.requestTypeId).then((response) => {
                setRequestType(response.data)
                updateEmailToAdmins('to', response.data.requestTypeAdmins.map(x => x.adminEmp.email).join(','))
            })

            if (props.genericRequestFormModel.originalRequestTypeId) {
                axios.get('api/requesttypes/GetRequestTypeSummary/' + props.genericRequestFormModel.originalRequestTypeId).then((response) => {
                    setOriginalRequestType(response.data)
                })
            }
        }
    }, [props.isOpen, props.genericRequestFormModel.requestTypeId])

    useEffect(() => {
        let opts = [];

        let sortedList = properties.sort((a, b) => {
            if (getPropertyNameFromId(a.siteId) < getPropertyNameFromId(b.siteId)) { return -1 }
            else if (getPropertyNameFromId(a.siteId) > getPropertyNameFromId(b.siteId)) { return 1 }
            else { return 0; }
        })

        if (sortedList?.length > 0) {
            sortedList?.map(x => {
                opts.push({ value: x.siteId, label: getPropertyNameFromId(x.siteId) });
            })
        }
        setPropertyOptions(opts);

        setPropertiesFromExistingGenericRequestFormProperties()

    }, [properties, props.genericRequestFormModel.genericRequestFormProperties])

    useEffect(() => {
        if (needsToBeForAllProperties) {
            setUpdatedPropertyList([])
        } else {
            setPropertiesFromExistingGenericRequestFormProperties()
        }

    }, [needsToBeForAllProperties])

    function setPropertiesFromExistingGenericRequestFormProperties() {
        let selOpts = [];
        props.genericRequestFormModel.genericRequestFormProperties.map(p => {
            selOpts.push({ value: p.siteId, label: getPropertyNameFromId(p.siteId) });
        })

        setUpdatedPropertyList(selOpts);
    }

    useEffect(() => {
        if (props.isOpen) {
            setIsLoading(false);
        }
    }, [props.isOpen])

    function getDefaultComment() {
        let newSiteIds = updatedPropertyList?.map(x => x.value)
        let existingSiteIds = props.genericRequestFormModel.genericRequestFormProperties?.map(x => x.siteId)

        let addedSites = newSiteIds.filter(x => !existingSiteIds.includes(x))
        let removedSites = existingSiteIds.filter(x => !newSiteIds.includes(x))

        if (currentlyForAllSites && !needsToBeForAllProperties && newSiteIds.includes(props.genericRequestFormModel.requesterSiteId)) { //add back the initial site, this is not necessary, but will help for the user experience. 
            addedSites.push(props.genericRequestFormModel.requesterSiteId);
        }

        let s = "<b><u>Form Properties Changed:</u></b><br />"

        s += "<u>Added Properties</u>:"
        if (existingSiteIds.length > 0 && needsToBeForAllProperties) {
            s += "<br />All Properties";
        } else if (addedSites.length === 0) {
            s += "<br />None";
        } else {
            addedSites.map(x => {
                s += `<br />${getPropertyNameFromId(x)}`;
            })
        }

        s += "<br /><u>Removed Properties</u>:"
        if (existingSiteIds.length > 0 && needsToBeForAllProperties) {
            let notHomeSiteIds = existingSiteIds.filter(x => x !== props.genericRequestFormModel.requesterSiteId);
            if (notHomeSiteIds.length > 0) {
                notHomeSiteIds.map(x => {
                    if (x !== props.genericRequestFormModel.requesterSiteId) {
                        s += `<br />${getPropertyNameFromId(x)}`;
                    }
                })
            } else {
                s += "<br />None"
            }
        } else if (removedSites.length === 0) {
            s += "<br />None"
        } else {
            removedSites.map(x => {
                s += `<br />${getPropertyNameFromId(x)}`;
            })
        }

        return s;
    }


    function getDefaultEmailBody() {

        let s = `An admin (${security.employeeFullName}) has made changes to the properties associated with a request form for the following request: [FormUrl].\n\n`

        s += "[DefaultCommentsFromAbove]"
        s += `\n\n[AdditionalCommentsFromAbove]`;

        return s;
    }

    function getSortedPropertyOptions(properties) {
        return properties.sort((a, b) => {
            return getPropertyNameFromId(a.siteId) - getPropertyNameFromId(b.siteId);
        })
    }

    function getNewPropertyNames() {
        let s = '';
        let sep = '';

        if (needsToBeForAllProperties) {
            s = "All Properties"
        } else {
            updatedPropertyList.map(x => {
                s += sep + getPropertyNameFromId(x.value);
                sep = ";"
            })
        }

        return s;
    }

    function updateEmailToAdmins(property, value) {
        let emailCopy = JSON.parse(JSON.stringify(emailToAdmins))
        emailCopy[property] = value;
        setEmailToAdmins(emailCopy);
    }

    function submitUpdate() {
        //validate that something has actually changed
        let validationMessages = validateUpdate();
        if (validationMessages.length === 0) {
            setProcessing(true)
            let genericRequestFormCopy = JSON.parse(JSON.stringify(props.genericRequestFormModel));

            let newProperties = []
            if (needsToBeForAllProperties) {
                newProperties.push({ formId: props.genericRequestFormModel.id, siteId: props.genericRequestFormModel.requesterSiteId });
            } else {
                updatedPropertyList.map(x => {
                    newProperties.push({ formId: props.genericRequestFormModel.id, siteId: x.value });
                })
            }

            genericRequestFormCopy.genericRequestFormProperties = newProperties;

            //add comment
            let commentText = getDefaultComment();
            if (additionalComment !== '')
                commentText += `<br /><b><u>Additional Comment</u></b><br />${additionalComment}`
            genericRequestFormCopy.genericRequestFormComments.push(createComment(security.empID, commentText))

            //look for a genericrequestformdetail that needs to be updated. Need to look at both the current and if applicable the original requesttypeid
            let reqTypeToUse = originalRequestType ? originalRequestType : requestType;
            let propertyDetails = reqTypeToUse.requestTypeDetails.filter(x => x.responseControlType.toLowerCase().includes('property'))
            for (let i = 0; i < propertyDetails.length; i++) {
                let matchingDetail = genericRequestFormCopy.genericRequestFormDetails.filter(x => x.detailId === propertyDetails[i].id)[0]
                if (matchingDetail) {
                    if (needsToBeForAllProperties) {
                        matchingDetail.response = 'Access To All Properties';
                    } else {
                        matchingDetail.response = getNewPropertyNames();
                    }

                }
            }

            //post here to update the GRF. Use existing function
            axios.put('api/GenericRequestForm/UpdateRequestProperties', genericRequestFormCopy).then((response) => {
                //SINCE I AM GOING TO SEND THE EMAIL AFTER UPDATING THE FORM, WE WANT TO BE SURE THAT THEY WILL WORK, SO WILL NEED TO UPDATE THE VALIDATION FUNCTION ACCORDINGLY TO MAKE SURE EVERYTHING SEEMS VALID
                setCurrentlyForAllSites(needsToBeForAllProperties);

                let emailsToSend = []
                if (emailToAdmins.send) {
                    let emailCopy = JSON.parse(JSON.stringify(emailToAdmins))
                    emailCopy.body = emailCopy.body.replace('[FormUrl]', newFormUrl);
                    emailCopy.body = emailCopy.body.replace('[DefaultCommentsFromAbove]', "<br /><br />" + getDefaultComment())
                    emailCopy.body = emailCopy.body.replace('[AdditionalCommentsFromAbove]', "<br /><br />" + additionalComment + "<br /><br />")
                    emailsToSend.push(emailCopy)
                }

                if (emailToRequester.send) {
                    let emailCopy = JSON.parse(JSON.stringify(emailToRequester))
                    emailCopy.body = emailCopy.body.replace('[FormUrl]', newFormUrl);
                    emailCopy.body = emailCopy.body.replace('[DefaultCommentsFromAbove]', "<br /><br />" + getDefaultComment())
                    emailCopy.body = emailCopy.body.replace('[AdditionalCommentsFromAbove]', "<br /><br />" + additionalComment + "<br /><br />")
                    emailsToSend.push(emailCopy)
                }

                if (emailsToSend.length > 0) {
                    axios.post('api/GenericRequestForm/SendGenericRequestFormReclassificationEmails', emailsToSend).then(res => {
                        if (res.data.isSuccessful) {
                            window.location = getRedirectUrl(true, true) //redirect form no matter what, either to a new url, or to the existing url.  This will force everything to reload which seems necessary to get it all to work properly. 
                        } else {
                            setAlert({ color: "danger", messages: ['Updating the ticket was successful, but there was an error sending one or more emails', res.data.errorMessage] })
                            setProcessing(false);
                        }
                    })
                } else {
                    window.location = getRedirectUrl(true, false) //redirect form no matter what, either to a new url, or to the existing url.  This will force everything to reload which seems necessary to get it all to work properly. 
                }
            })

        } else {
            setAlert({ color: "danger", messages: validationMessages })
            setProcessing(false)
        }
    }

    function getRedirectUrl(justChanged, emailsWereSent) {
        let baseUrl = `requests/${requestType?.relatedToFormName}/${props.genericRequestFormModel.id}`;
        if (justChanged) {
            if (emailsWereSent) {
                return baseUrl + "?justChanged=1&emailsWereSent=1";
            } else {
                return baseUrl + "?justChanged=1";
            }
        } else {
            return baseUrl;
        }
    }


    function getPropertyNameFromId(siteId) {
        let p = properties.filter(x => x.siteId === siteId)[0];
        if (p) {
            return p.propertyName + ' ' + p.city + ', ' + p.state + ' (' + p.yardiCode + ')';
        } else {
            return "";
        }
    }

    function validateUpdate() {
        let messages = [];

        if (updatedPropertyList.length === 0 && !needsToBeForAllProperties)
            messages.push("No properties are currently selected.  All requests must be associated with at least 1 property")

        let newSiteIds = updatedPropertyList?.map(x => x.value)
        let existingSiteIds = props.genericRequestFormModel.genericRequestFormProperties?.map(x => x.siteId)
        let addedSites = newSiteIds.filter(x => !existingSiteIds.includes(x))
        let removedSites = existingSiteIds.filter(x => !newSiteIds.includes(x))

        if (addedSites.length === 0 && removedSites.length === 0)
            messages.push("The list of properties has not changed.  You must add or remove at least 1 property to use this functionality")

        if (emailToAdmins.send) {
            let toEmails = emailToAdmins.to.split(',')
            if (toEmails.length === 0 || toEmails[0] === '') {
                messages.push('No email recipients are selected for the email to admins')
            } else {
                for (let i = 0; i < toEmails.length; i++) {
                    if (!validator.isEmail(toEmails[i]))
                        messages.push(toEmails[i] + ' is not a valid email address')
                }
            }
        }

        if (emailToRequester.send) {
            let toEmails = emailToRequester.to.split(',')
            if (toEmails.length === 0 || toEmails[0] === '') {
                messages.push('No email recipients are selected for the email to requester')
            } else {
                for (let i = 0; i < toEmails.length; i++) {
                    if (!validator.isEmail(toEmails[i]))
                        messages.push(toEmails[i] + ' is not a valid email address')
                }
            }
        }

        return messages;
    }

    function onUpdateEmail(emailType, emailObj) {
        if (emailType === "Admins")
            setEmailToAdmins(emailObj);

        if (emailType === "Requester")
            setEmailToRequester(emailObj);

    }

    return (
        <div>
            <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle}>

                <ModalBody>
                    {alert && alert.messages?.length > 0 &&
                        <Alert toggle={() => setAlert()} color={alert.color}>{alert.messages.map((m, index) => {
                            return <p key={index}><b>{m}</b></p>
                        })}
                            <a href="https://mimg.us/gotolink.aspx?id=12263" target="_blank" rel="noreferrer">Download Instructions Document</a>
                        </Alert>
                    }

                    <div className={styles.sectionWrapper}>
                        {isLoading ? <>Loading</>
                            : <><div className={`${styles.flexRow} ${styles.headers}`}>
                                <div className={styles.flex2}>Original Properties</div>
                                <div className={styles.flex1}>Revised Properties</div>
                                <div className={styles.flex1}>

                                    {props.formPreferences?.allowChangeToAllPropertiesOption &&
                                        <span className={styles.allPropertiesOption} onClick={() => setNeedsToBeForAllProperties(!needsToBeForAllProperties)}>
                                            {needsToBeForAllProperties
                                                ? <>Select Individual Properties</>
                                                : <>Click here for 'All Properties'</>}
                                        </span>
                                    }
                                </div>

                            </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        {props.genericRequestFormModel.genericRequestFormProperties.sort((a, b) => {
                                            if (getPropertyNameFromId(a.siteId) < getPropertyNameFromId(b.siteId)) { return -1; }
                                            else if (getPropertyNameFromId(a.siteId) < getPropertyNameFromId(b.siteId)) { return 1; }
                                            else { return 0 }
                                        }).map((p, i) => {

                                            return <div key={i}>{getPropertyNameFromId(p.siteId)}</div>
                                        })}
                                    </div>
                                    <div className={styles.flex1}>
                                        {!needsToBeForAllProperties &&
                                            <Select
                                                isMulti
                                                options={getSortedPropertyOptions(propertyOptions)}
                                                value={getSortedPropertyOptions(updatedPropertyList)}
                                                onChange={(e) => setUpdatedPropertyList(e)}
                                            />
                                        }

                                    </div>

                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        Default Comments
                                    </div>
                                    <div className={`${styles.flex5} ${styles.changesComment}`} dangerouslySetInnerHTML={{ __html: getDefaultComment() }}>

                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={styles.flex1}>
                                        Additional Comments (optional)
                                    </div>
                                    <div className={styles.flex5}>
                                        <Input type="textarea" rows={3} value={additionalComment} onChange={(e) => setAdditionalComment(e.target.value)} placeholder="Enter any additional comments regarding the change" />
                                    </div>
                                </div>

                                <div className={styles.flexRow}>
                                    <div className={`${styles.flex1} ${styles.sectionWrapper}`}>
                                        <ChangeRequestTypeEmail email={emailToAdmins} onUpdateEmail={onUpdateEmail} sendToLabel="Admins" defaultEmailBody={getDefaultEmailBody()} />
                                    </div>
                                    <div className={`${styles.flex1} ${styles.sectionWrapper}`}>
                                        <ChangeRequestTypeEmail email={emailToRequester} onUpdateEmail={onUpdateEmail} sendToLabel="Requester" defaultEmailBody={getDefaultEmailBody()} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={props.toggle}>Cancel</Button> {"  "}
                    <Button onClick={() => submitUpdate()} disabled={processing}>Update Request Properties</Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}
