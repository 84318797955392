import React, { useState, useEffect } from 'react';
import styles from './GenericRequestForm.module.scss';
import flexStyles from '../../../styles/flex.module.scss';
import ReopenRequestModal from '../modals/ReopenRequestModal';
import RequestReminderModal from '../modals/RequestReminderModal';
import { Tooltip } from 'reactstrap';

export default function RequestStatusInfo(props) {

    const requestAlreadyCompleted = props.genericRequestFormModel.requestComplete === true || props.genericRequestFormModel.requestRejected;
    const [showReopenRequestModal, setShowReopenRequestModal] = useState(false);
    const [showRequestReminderModal, setShowRequestReminderModal] = useState(false);
    const [showReminderInfoTooltip, setShowReminderInfoTooltip] = useState(false);
    const pendingAutoComplete = props.genericRequestFormModel.requestComplete === false && props.genericRequestFormModel.autoCloseTicketAfterDateTime !== null;
    const [currentStepLabel, setCurrentStepLabel] = useState(getCurrentStepLabel());

    useEffect(() => {
        setCurrentStepLabel(getCurrentStepLabel())
    }, [props.workflow])

    function getCurrentStepLabel() {

        if (props.genericRequestFormModel.requestComplete === true) {
            return "Request Completed"
        } else if (props.genericRequestFormModel.requestRejected === true) {
            return "Request Rejected Or Cancelled"
        } else {
            let currentWorkflowStep = props.workflow?.requestWorkflowSteps.filter(x => x.id === props.genericRequestFormModel.currentWorkflowStep)[0];
            return currentWorkflowStep?.stepName;
        }
    }

    return (
        <div className={flexStyles.paddingTop10}>
            <h4 className={styles.sectionHeader}>Request Status</h4>
            Request ID: {props.genericRequestFormModel.id}<br />
            Last Action: {props.genericRequestFormModel.requestStatus} by {props.genericRequestFormModel.requestStatusUpdatedByEmpName} on {new Date(props.genericRequestFormModel.requestStatusUpdatedDateTime).toLocaleDateString() + ' at ' + new Date(props.genericRequestFormModel.requestStatusUpdatedDateTime).toLocaleTimeString()}<br />
            Current Step: {getCurrentStepLabel()}
            {requestAlreadyCompleted && props.isAdmin &&
                <><span onClick={() => setShowReopenRequestModal(true)} className={styles.spanReopenRequest}>Reopen Request</span></>
            }
            {props.genericRequestFormModel.requestStatus.includes("Assign") &&
                <>
                    <br />
                    Assigned To: {props.genericRequestFormModel.assignedToName}
                </>
            }
            {props.genericRequestFormModel.externalReferenceId &&
                <>
                    <br />
                    External Reference ID: {props.genericRequestFormModel.externalReferenceId}
                </>
            }

            {props.formPreferences?.allowReminderEmails === true && props.isAdmin && !requestAlreadyCompleted &&
                <div>
                    <span className={styles.spanSendReminderEmail} onClick={() => setShowRequestReminderModal(true)}>Send Reminder Email</span>
                    <i> - {props.genericRequestFormModel.numberOfRemindersSent ? props.genericRequestFormModel.numberOfRemindersSent : 0 } reminder(s) sent. </i>
                    {props.genericRequestFormModel.mostRecentReminderSentDateTime
                        ? <> <span className={styles.spanSendReminderEmail} id="mostRecentReminderInfo">(info)</span>
                            <Tooltip isOpen={showReminderInfoTooltip} toggle={() => setShowReminderInfoTooltip(!showReminderInfoTooltip)} target="mostRecentReminderInfo">
                                Most recent reminder sent {new Date(props.genericRequestFormModel.mostRecentReminderSentDateTime)?.toLocaleDateString()} by {props.genericRequestFormModel.mostRecentReminderSentByEmpName}. 
                            </Tooltip></>
                        : <></>
                    }
                    <br />
                </div>
            }

            {pendingAutoComplete &&
                <div>
                    <b style={{ color: "red" }}>Auto Complete On: {new Date(props.genericRequestFormModel.autoCloseTicketAfterDateTime).toLocaleDateString('en-US', { timeZone: 'UTC' })} 8:00PM MT</b>
                </div>
            }

            <ReopenRequestModal isOpen={showReopenRequestModal} toggle={() => setShowReopenRequestModal(!showReopenRequestModal)} genericRequestFormModel={props.genericRequestFormModel} formPreferences={props.formPreferences} />
            <RequestReminderModal isOpen={showRequestReminderModal} toggle={() => setShowRequestReminderModal(!showRequestReminderModal)} genericRequestFormModel={props.genericRequestFormModel} formPreferences={props.formPreferences} propertyList={props.propertyList} onUpdatePageAlert={props.onUpdatePageAlert} onUpdateGenericRequestFormModel={props.onUpdateGenericRequestFormModel} />
        </div>
    );

}
