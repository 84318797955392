import React from 'react';
import RequestDetail from './RequestDetail'
import { Input } from 'reactstrap';

export default function RequestDetailDropdown(props) {
    let choices = props.RequestTypeDetail.requestTypeDetailChoices;
    let uniqueChoices = [];

    for (let i = 0; i < choices.length; i++) {
        if (!choices[i].isActive)
            continue;
        let existingChoices = uniqueChoices.map(x => x.choiceValue);
        if (!existingChoices.includes(choices[i].choiceValue)) {
            uniqueChoices.push(choices[i]);
        }
    }

    uniqueChoices.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail} >
            <Input type="select" >
                <option value=''>No Selection</option>
                {uniqueChoices &&
                    uniqueChoices.map(p => {
                        return <option id={p.id} key={p.id}>{p.choiceValue}</option>
                    })
                }
            </Input>
        </RequestDetail>
    );
}

