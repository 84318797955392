import React from 'react';
import { Input } from 'reactstrap';
import RequestDetail from './RequestDetail';

export default function RequestDetailCheckbox(props) {
    //couldn't figure out the styling of the checkboxes, so I decided to do this instead
    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail} >
            <Input type="select" >
                <option id="0">No</option>
                <option id="1">Yes</option>
            </Input>
        </RequestDetail>
    );
}

