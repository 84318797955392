import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { axios, Button } from 'react-mimg';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';

export default function SubmitOnBehalfOfModal(props) {
    const [allEmployeeOptions, setAllEmployeeOptions] = useState();
    const [selectedOverrideEmployee, setSelectedOverrideEmployee] = useState();

    useEffect(() => {
        if (props.isOpen) {
            if (props.formPreferences.allowSubmitOnBehalfOf) {
                axios.get('api/PropertyEmployees/GetAllActiveEmployeesForSelect').then(response => {
                    setAllEmployeeOptions(response.data);
                })
            }
        }
    }, [props.isOpen])
    
    function updateEmployee() {
        axios.get('api/PropertyEmployees/GetSingleEmployeeCard?PropertyEmployeesId=' + selectedOverrideEmployee.value).then(response => {
            props.onUpdateEmployee(response.data);
        });
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                Select the Employee You are Submitting the Ticket on Behalf Of
            </ModalHeader>
            <ModalBody>
                <Alert color="info">Use this functionality to select a user (other than yourself) for which this ticket is for.  That user will receive the further emails/communication related to this ticket.</Alert>
                Select Employee:<br />
                <Select
                    options={allEmployeeOptions}
                    onChange={setSelectedOverrideEmployee}
                />
            </ModalBody>
            <ModalFooter>
                <Button transparent onClick={() => props.toggle}>Close</Button>
                <Button onClick={() => updateEmployee(false)}>Update</Button>
            </ModalFooter>
        </Modal>
    )
}

