import React, { useState, useEffect } from 'react';
import { Tooltip } from "reactstrap";
import { axios } from "react-mimg";
import flexStyles from '../../../styles/flex.module.scss';
import styles from './GenericRequestForm.module.scss';
import { getEmployeeSecurity } from '../../../functions/permissions';
import WhoAreTheAdminsModal from '../modals/WhoAreTheAdminsModal';
import ImageModal from './ImageModal';

export default function RequestComments(props) {
    const [showAdminOnlyComments, setShowAdminOnlyComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [showWhoAreTheAdminsModal, setShowWhoAreTheAdminsModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('')
    const [hasImages, setHasImages] = useState(false);

    useEffect(() => {
        let comms = props.comments.sort(function (a, b) { // order to get newest comment first
            let x = a.dateTimeCreated;
            let y = b.dateTimeCreated;
            if (x > y) { return -1; }
            if (x < y) { return 1; }
            return 0;
        });

        setComments(comms);
        setHasImages(comms.some(comment => /<img[^>]+src="([^">]+)"/.test(comment.comment)));

        if (props.requestTypes?.length > 0) {
            let adminOnlyComments = comms.filter(x => x.adminOnly === true);
            if (adminOnlyComments.length > 0) {
                axios.get('api/RequestTypes/SeesAdminOnlyComments?requestTypeId=' + props.requestTypes[0]?.id).then(response => {
                    if (response.data === true) {
                        setShowAdminOnlyComments(true);
                    }
                });
            }
        }

        window.handleImageClick = handleImageClick;
    }, [props.comments, props.requestTypes]);

    function makeCommentRedAndBold(comment) {
        if (comment.toLowerCase().includes('updated total referral bonus amount'))
            return true;

        if (comment.toLowerCase().includes('updated total signing bonus amount'))
            return true;

        return false;
    }

    const handleImageClick = (imageUrl, commenterName, date, commentIndex) => {
        let content = document.getElementById(imageUrl).innerHTML.replaceAll('grfImageThumbnail', 'grfImageNonThumbnail');
        let modalTitle = `${commenterName} at ${date} - (Comment ${commentIndex + 1} of ${comments.length})`
        setModalContent(content);
        setModalTitle(modalTitle)
        setShowModal(true);
    };

    const getTextWithoutImages = (comment) => {
        return comment.replace(/<img[^>]*>/g, '').trim();
    };

    function linkify(text) {
        const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = text;

        const processNodes = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const matches = node.nodeValue.match(urlRegex);

                if (matches) {
                    const fragment = document.createDocumentFragment();
                    let lastIndex = 0;

                    matches.forEach(match => {
                        const index = node.nodeValue.indexOf(match, lastIndex);

                        if (index > lastIndex) {
                            fragment.appendChild(document.createTextNode(node.nodeValue.substring(lastIndex, index)));
                        }

                        const a = document.createElement('a');
                        const hyperlink = match.startsWith('http') ? match : 'http://' + match;
                        a.href = hyperlink;
                        a.target = "_blank";
                        a.rel = "noopener noreferrer";
                        a.textContent = match;
                        a.style.color = '#d59038';

                        fragment.appendChild(a);

                        lastIndex = index + match.length;
                    });

                    if (lastIndex < node.nodeValue.length) {
                        fragment.appendChild(document.createTextNode(node.nodeValue.substring(lastIndex)));
                    }

                    node.replaceWith(fragment);
                }
            } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'IMG') {
                Array.from(node.childNodes).forEach(processNodes);
            }
        };

        Array.from(tempDiv.childNodes).forEach(processNodes);

        return tempDiv.innerHTML;
    }


    function processCommentText(comment, index, commenterName, dateTimeCreated, commentIsArray) {
        let processedText = comment.replace(/<img([^>]+)>/g, (match, g1) => {
            return `<img ${g1} class="grfImageThumbnail" onclick="handleImageClick('comment_${index}', '${commenterName.replace(/'/g, "\\'")}', '${new Date(dateTimeCreated).toLocaleDateString()} ${new Date(dateTimeCreated).toLocaleTimeString()}', ${index})">`;
        });

        processedText = linkify(processedText);

        processedText = processedText.replaceAll('<p><br></p>', '<br />')
            .replaceAll(/<\/br><br>/g, '</br>')
            .replace(/^(<br\s*\/?>)+/, '');

        if (commentIsArray) {
            processedText = processedText.replaceAll('\\n', '<br />')
        }

        return processedText;
    }



    return (
        <div className={flexStyles.paddingTop10}>
            <div className={flexStyles.rowFlexContainer80}>
                <h4 className={`${styles.sectionHeader} ${styles.flex1}`}>Request Comments (Newest First)</h4>
            </div>
            {hasImages && (
                <div className={`${styles.imageBlurb} ${styles.flex1}`}>
                    Click on an image to view full size.
                </div>
            )}
            {showAdminOnlyComments &&
                <div>
                    <span className={styles.adminOnlyComment}>This form contains 1 or more comments that are only available to positions listed as Request Form Admins.</span><br />
                    <span id="spanWhoAreAdmins" className={`${styles.adminOnlyComment} ${styles.whoAreAdmins}`} onClick={() => setShowWhoAreTheAdminsModal(true)}>Who Can See These?</span>
                    <WhoAreTheAdminsModal isOpen={showWhoAreTheAdminsModal} toggle={() => setShowWhoAreTheAdminsModal(!showWhoAreTheAdminsModal)} requestType={props.requestTypes[0]} />
                    <br />
                </div>
            }

            <div className={flexStyles.rowFlexContainer80}>
                <div className="card card-body bg-light">
                    <div className="d-none d-sm-inline">
                        {comments &&
                            <div className={flexStyles.rowFlexContainer}>
                                <div className={flexStyles.flexColumnHeader1}>Comment Date/Time</div>
                                <div className={flexStyles.flexColumnHeader1}>Comment By</div>
                                <div className={flexStyles.flexColumnHeader3}>Comment</div>
                            </div>
                        }
                        {comments
                            ? comments.map((p, index) => {
                                return (
                                    <div key={p.id + '_mobile'} className={flexStyles.rowFlexContainer}>
                                        <div className={`${flexStyles.flex1}`}>
                                            <span className={p.adminOnly ? styles.adminOnlyComment : ""}>
                                                {new Date(p.dateTimeCreated).toLocaleDateString() + ' ' + new Date(p.dateTimeCreated).toLocaleTimeString()}
                                            </span>
                                        </div>
                                        <div className={flexStyles.flex1}>
                                            <span className={p.adminOnly ? styles.adminOnlyComment : ""}>
                                                {p.commenterName}
                                            </span>
                                        </div>
                                        <div id={"comment_" + index} className={flexStyles.flex3}>
                                            <span className={makeCommentRedAndBold(p.comment) ? styles.redAndBold : styles.commentContainer}>
                                                <span className={p.adminOnly ? styles.adminOnlyComment : ""} dangerouslySetInnerHTML={{
                                                    __html: processCommentText(p.comment, index, p.commenterName, p.dateTimeCreated, p.commentIsArray)
                                                }}></span>
                                            </span>
                                        </div>
                                    </div>
                                );
                            })
                            : <div>There are no comments for this request yet</div>
                        }
                    </div>
                    <div className="d-block d-sm-none">
                        {comments
                            ? comments.map((p, index) => {
                                return (
                                    <div key={p.id} className={`row ${flexStyles.marginTop10}`}>
                                        <span className={p.adminOnly ? styles.adminOnlyComment : ""}>
                                            <span className={styles.boldUnderline}>Commenter:</span> {p.commenterName}
                                        </span>
                                        <span className={p.adminOnly ? styles.adminOnlyComment : ""}>
                                            <span className={styles.bold}>Date:</span> {new Date(p.dateTimeCreated).toLocaleDateString() + ' ' + new Date(p.dateTimeCreated).toLocaleTimeString()}
                                        </span>
                                        <span className={p.adminOnly ? styles.adminOnlyComment : ""}>
                                            <span className={styles.bold}>Comment:</span>
                                            <span className={p.adminOnly ? styles.adminOnlyComment : ""} dangerouslySetInnerHTML={{
                                                __html: processCommentText(p.comment, index, p.commenterName, p.dateTimeCreated,p.commentIsArray)
                                            }}></span>
                                        </span>
                                    </div>
                                );
                            })
                            : <div>There are no comments for this request yet</div>
                        }
                    </div>
                </div>
            </div>
            <ImageModal
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
                modalContent={modalContent}
                modalTitle={modalTitle}
            />
        </div>
    );
}
