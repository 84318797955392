import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import { Alert } from 'reactstrap';
import { itGroupOptions } from '../../../requestTypeConfig';
import EmailAddressExistanceChecker from './EmailAddressExistanceChecker';
import styles from './ITHelpdeskProcessing.module.scss';
import OutlookGroupSelection from "./OutlookGroupSelection";

export default function ITHelpdeskProcessing(props) {
    const [email, setEmail] = useState('')
    const [cellPhone, setCellPhone] = useState('');
    const [emp, setEmp] = useState();
    const [user, setUser] = useState();
    const [allEmailGroups, setAllEmailGroups] = useState([]);
    const [userExistingGroups, setUserExistingGroups] = useState([])
    const [itGroupName, setItGroupName] = useState("--Select--")
    const [formAlert, setFormAlert] = useState();

    useEffect(() => {
        axios.get('api/ITSetup/GetAllOutlookGroups').then((response) => {
            if (response.data.isSuccessful) {
                setAllEmailGroups(response.data.data);
            }
        })

        let emp = props.genericRequestFormModel.genericRequestFormEmployees[0].propertyEmployees;
        if (emp) {
            setEmp(emp)
        }


    }, [])

    useEffect(() => {
        let details = props.genericRequestFormModel.genericRequestFormDetails;
        setEmail(details.filter(x => x.detail.defaultDataPropName === "employeeEmail")[0]?.response);
        setCellPhone(details.filter(x => x.detail.defaultDataPropName === "employeeCellPhone")[0]?.response);
    }, [props.genericRequestFormModel.genericRequestFormDetails])

    useEffect(() => {
        let empPosition = props.genericRequestFormModel.genericRequestFormEmployees[0]?.propertyEmployees?.position;
        itGroupOptions.forEach(x => {
            if (x.correspondingEmployeePositions.includes(empPosition)) {
                setItGroupName(x.value);
            }
        })

    }, [])

    function onUpdateFormAlert(alert) {
        setFormAlert(alert);
    }

    useEffect(() => {
        if (user && email) {
            refreshUserExistingGroups();
        }
    }, [email,user])

    function refreshUserExistingGroups() {
        axios.get('api/ItSetup/GetMicrosoftGraphUserGroups?email=' + email).then(response => {
            setUserExistingGroups(response.data.data)
        })
    }

    return (
        <div>
            {formAlert &&
                <Alert color={formAlert.color}>{formAlert.message}</Alert>
            }

            <EmailAddressExistanceChecker defaultEmail={email}
                setEmail={setEmail}
                cellPhone={cellPhone}
                emp={emp}
                setUser={setUser}
                genericRequestFormModel={props.genericRequestFormModel}
                onAddComment={props.onAddComment}
                onUpdateFormAlert={onUpdateFormAlert}
                requestPropertyInfo={props.requestPropertyInfo}
            />


            {user && userExistingGroups &&
                <div>
                    <div className={styles.questionRow}>
                        <OutlookGroupSelection header="SSO Security Groups" user={user} emp={emp} onAddComment={props.onAddComment} itGroupName={itGroupName} allGroups={allEmailGroups} userExistingGroups={userExistingGroups} groupTypes={['Security Groups']} selectionType="Security Groups" onRefreshUserExistingGroups={refreshUserExistingGroups} genericRequestFormModel={props.genericRequestFormModel} propertyList={props.propertyList} requestPropertyInfo={props.requestPropertyInfo} />
                    </div>
                    <div className={styles.questionRow}>
                        <OutlookGroupSelection header={"Distribution Lists - These must now be done within the Microsoft Portal"} user={user} emp={emp} onAddComment={props.onAddComment} itGroupName={itGroupName} allGroups={allEmailGroups} userExistingGroups={userExistingGroups} groupTypes={['Distribution groups']} selectionType="Email Groups" onRefreshUserExistingGroups={refreshUserExistingGroups} genericRequestFormModel={props.genericRequestFormModel} propertyList={props.propertyList} requestPropertyInfo={props.requestPropertyInfo} />
                    </div>
                    <div className={styles.questionRow}>
                        <OutlookGroupSelection header={"Sharepoint Groups"} user={user} emp={emp} onAddComment={props.onAddComment} itGroupName={itGroupName} allGroups={allEmailGroups} userExistingGroups={userExistingGroups} groupTypes={['Sharepoint Groups']} selectionType="Sharepoint Groups" onRefreshUserExistingGroups={refreshUserExistingGroups} genericRequestFormModel={props.genericRequestFormModel} propertyList={props.propertyList} requestPropertyInfo={props.requestPropertyInfo} />
                    </div>

                    <div className={styles.questionRow}>
                        <OutlookGroupSelection header={"Microsoft 365 Groups - These can be requested on the form, but there is no predetermination of groups"} user={user} emp={emp} onAddComment={props.onAddComment} itGroupName={itGroupName} allGroups={allEmailGroups} userExistingGroups={userExistingGroups} groupTypes={['Microsoft 365 Groups']} selectionType="Microsoft 365 Groups" onRefreshUserExistingGroups={refreshUserExistingGroups} genericRequestFormModel={props.genericRequestFormModel} propertyList={props.propertyList} requestPropertyInfo={props.requestPropertyInfo} />

                    </div>
                </div>
            }


        </div>


    );
}