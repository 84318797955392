import React, { useState } from 'react';
import { Button, GenericEmailHistoryForm } from 'react-mimg';

export default function ViewEmails(props) {

    const [showModal, setShowModal] = useState(false);
    const sortedEmails = props.genericRequestFormModel.emailsSents.slice().sort((a, b) => {
        const dateA = new Date(a.createDateTime);
        const dateB = new Date(b.createDateTime);

        return dateB - dateA;
    });

    return (
        <div>
            <Button onClick={() => setShowModal(true)}>View Emails</Button>
            <GenericEmailHistoryForm
                visible={showModal}
                toggle={() => setShowModal(!showModal)}
                emails={sortedEmails} 
                title="Email Detail" />
        </div>
    );
}