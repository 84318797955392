import { axios } from 'react-mimg';
import FileSaver from 'file-saver';
import { getEmployeeSecurity } from './permissions';




export function GetFranktownContacts(closeWindow = true) {
    let security = getEmployeeSecurity();
    if (security.userIsAuthorized) {
        axios.get('api/CsvExport/GetFranktownCSV').then((csv) => {

            let blob = new Blob([csv.data], { type: "text/csv" });

            FileSaver.saveAs(blob, "FranktownEmps.csv");

            if (closeWindow !== false) { window.close() }

        })

        return <div>Downloading File</div>
        
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }

}

export function GetExtensionList(closeWindow = true) {
    let security = getEmployeeSecurity();
    if (security.userIsAuthorized) {
        axios.get('api/CsvExport/GetExtensionList').then((csv) => {

            let blob = new Blob([csv.data], { type: "text/csv" });

            FileSaver.saveAs(blob, "ExtensionList.csv");

            if (closeWindow !== false) { window.close() }

        })
        return <div>Downloading File</div>
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }
}
export function GetVendorServiceContracts(closeWindow = true) {
    let security = getEmployeeSecurity();
    if (security.userIsAuthorized && security.employeePositionType === 'corporate') {
        axios.get('api/CsvExport/GetVendorServiceContracts', { responseType: 'arraybuffer' }).then((csv) => {

            let blob = new Blob([csv.data], { type: "text/csv" });

            FileSaver.saveAs(blob, "VendorServiceContracts.csv");

            if (closeWindow !== false) { window.close() }

        })

        return <div>Downloading File</div>
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }
}

export function GetEmployeesList(closeWindow = true) {
    let security = getEmployeeSecurity();
    if (security.userIsAuthorized && security.securityLevel >= 3) {
        axios.get('api/CsvExport/GetEmployeesList').then((csv) => {

            let blob = new Blob([csv.data], { type: "text/csv" });

            FileSaver.saveAs(blob, "EmployeeList.csv");

            if (closeWindow !== false) { window.close() }

        })

        return <div>Downloading File</div>
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }
}

export function GetTerminationsList(closeWindow = true) {
    let security = getEmployeeSecurity();
    if ((security.userIsAuthorized && security.securityLevel >= 3) || security.empTerminationGroup === 'Team Relations') {
        axios.get('api/CsvExport/GetTerminationsList').then((csv) => {

            let blob = new Blob([csv.data], { type: "text/csv" });

            FileSaver.saveAs(blob, "TerminationsList.csv");

            if (closeWindow !== false) { window.close() }

        })

        return <div>Downloading File</div>
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }
}

export function GetPropertyList(closeWindow = true) {
    let security = getEmployeeSecurity();
    if (security.userIsAuthorized) {
        axios.get('api/CsvExport/GetPropertyList', { responseType: 'arraybuffer' }).then((csv) => {

            let blob = new Blob([csv.data]);

            FileSaver.saveAs(blob, "PropertyList.xlsx");

            if (closeWindow !== false) { window.close() }

        })

        return <div>Downloading File</div>
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }
}

export function GetAMRMPropertySummary(closeWindow = true) {
    let security = getEmployeeSecurity();
    if (security.userIsAuthorized) {
        axios.get('api/CsvExport/GetAMRMPropertySummary', { responseType: 'arraybuffer' }).then((csv) => {

            let blob = new Blob([csv.data]);

            FileSaver.saveAs(blob, "AMRMPropertySummary.xlsx");

            if (closeWindow !== false) { window.close() }

        })

        return <div>Downloading File</div>
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }
}

export function GetFMLAEligibility(closeWindow = true) {
    let security = getEmployeeSecurity();
    if (security.userIsAuthorized) {
        axios.get('api/CsvExport/GetFMLAEligibility', { responseType: 'arraybuffer' }).then((csv) => {

            let blob = new Blob([csv.data]);

            FileSaver.saveAs(blob, "FMLAEligibilityByProperty.xlsx");

            if (closeWindow !== false) { window.close() }

        })

        return <div>Downloading File</div>
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }
}


export function GetMIMGTransactions(closeWindow = true) {
    let security = getEmployeeSecurity();
    if (security.userIsAuthorized && security.canViewTrans) {
        axios.get('api/CsvExport/GetMIMGTransactions').then((csv) => {

            let blob = new Blob([csv.data]);

            FileSaver.saveAs(blob, "GetMIMGTransactions.csv");

            if (closeWindow !== false) { window.close() }

        })

        return <div>Downloading File</div>
    }
    else {
        return window.alert("You do not have the required permissions to download this file")
    }
}

