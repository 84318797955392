import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import Select from 'react-select'
import RequestDetail from './RequestDetail';

export default function RequestDetailMultiEmployeeForSitePicker (props) {
    const [employeeOptions, setEmployeeOptions] = useState([])

    useEffect(() => {
        axios.get("api/propertyemployees/GetEmployeesForSite?siteIds=" + props.selectedProperties.map(x => x.siteId).join('&siteIds='))
            .then((response) => {
                let employees = response.data.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1);
                let options = employees.map(emp => ({ value: emp.propertyEmployeesId, label: emp.employeeName + ' (' + emp.prismId + '), ' + emp.position }))
                setEmployeeOptions(options)
            })
    }, [props.selectedProperties])

    if (!employeeOptions)
        return <div>Loading...</div>

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
            <Select
                isMulti
                placeholder={props.RequestTypeDetail.placeholder}
                options={employeeOptions}
                name='employeePicker'
            />
        </RequestDetail>
    );
}
