import React, { useState, useRef } from 'react';
import { axios, Button, ErrorLogModel, logError } from 'react-mimg';
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import validator from 'validator';
import { createComment, getNextStepInfo } from '../../../functions/formHelpers/genericRequestFormHelper';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { MimgAlert } from '../../layout/MimgAlert';
import styles from '../modals/RequestModals.module.scss';
import 'react-quill/dist/quill.snow.css';
import RequestRichInput from './RequestRichInput';
import { uploadImage } from './UploadImage';

export default function RequestRejection(props) {
    const [modalAlert, setModalAlert] = useState(new CustomAlertModel());
    const [showModal, setShowModal] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [processing, setProcessing] = useState('');
    const quillRef = useRef();

    function toggle() { setShowModal(false); }
    function open() { setShowModal(true); }

    async function onRejectRequest() {
        let genericRequestFormModel = props.genericRequestFormModel;
        let pageAlert = new CustomAlertModel();
        let alert = new CustomAlertModel();

        let validationMsg = validateGenericRequestFormRejectionComment();
        if (validationMsg === "") {
            setProcessing(true);
            let updatedValue = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : document.getElementById("textAreaNewComment").value;

            const imageRegex = /<img[^>]+src="([^">]+)"/g;
            const images = [];
            let match;
            while ((match = imageRegex.exec(updatedValue)) !== null) {
                images.push(match[1]);
            }

            for (let i = 0; i < images.length; i++) {
                const imageUrl = images[i];
                const response = await uploadImage(imageUrl, genericRequestFormModel.id);
                updatedValue = updatedValue.replace(imageUrl, response.data.url);
            }

            let newComment = createComment(genericRequestFormModel.requesterEmpId, "<b>Request Marked as Rejected</b><br />Reason: " + updatedValue);
            genericRequestFormModel.genericRequestFormComments.push(newComment);

            genericRequestFormModel.requestStatus = "Rejected";
            genericRequestFormModel.requestRejected = true;
            genericRequestFormModel.rejectedByEmpId = props.security.empID;
            genericRequestFormModel.rejectedByEmpName = props.security.employeeFullName;
            genericRequestFormModel.rejectedByEmpPosition = props.security.employeePosition;

            let requestTypeId = genericRequestFormModel.requestTypeId;
            let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Reject')[0].id;
            genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);

            if (genericRequestFormModel.currentWorkflowStep === null) {
                genericRequestFormModel.currentWorkflowStep = currentStepId;
            }

            axios.put("api/GenericRequestForm/UpdateRequest?updateAction=reject", genericRequestFormModel).then((response) => {
                genericRequestFormModel = response.data;

                pageAlert.AlertColor = "success";
                pageAlert.AlertText = "The request was rejected successfully";

                props.onSave(genericRequestFormModel, pageAlert, "Reject");
                setProcessing(false);
                toggle();
            }).catch((error) => {
                alert.AlertColor = "danger";
                alert.AlertText = "Error rejecting request.";
                setModalAlert(alert);
                setProcessing(false);
                logError(new ErrorLogModel(error, "OnRejectRequest", props.security.employeeFullName + " experienced an error rejecting a GenericRequestForm (CorporateServices). FormId " + genericRequestFormModel.id));
            });
        } else {
            alert.AlertColor = "danger";
            alert.AlertText = validationMsg;
            setModalAlert(alert);
        }
    }

    function validateGenericRequestFormRejectionComment() {
        // Comment is required
        let msg = "";
        let commentText = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : document.getElementById("textAreaNewComment").value;
        const textWithoutImages = commentText.replace(/<img[^>]*>/g, '').trim();

        if (!validator.isLength(textWithoutImages, { min: 1, max: 4000 }) || commentText === '<p><br></p>') {
            msg = msg + "A comment is required for all request rejections. Please enter a comment that is between 1 and 4000 characters in length.\n";
        }
        return msg;
    }

    return (
        <div>
            <Button onClick={open} red={true}>Reject</Button>
            <Modal isOpen={showModal} toggle={toggle} size={props.formPreferences.richTextComments ? "lg" : undefined}>
                <ModalHeader toggle={toggle}>Reject Request</ModalHeader>

                {modalAlert.AlertText !== "" &&
                    <MimgAlert alertInfo={modalAlert} />
                }

                <ModalBody>
                    <h5>Current Action: Reject Request</h5>
                    <Form>
                        <Label for="textAreaNewComment">Comment:</Label>
                        {props.formPreferences.richTextComments ? (
                            <RequestRichInput r={quillRef} />
                        ) : (
                            <Input className={styles.commentBox} type="textarea" id="textAreaNewComment" name="textAreaNewComment" value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                        )}
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={toggle}>Cancel</Button> {"  "}
                    <Button onClick={onRejectRequest} disabled={processing}>
                        {processing
                            ? <span>Processing</span>
                            : <span>Reject Request</span>
                        }
                        </Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>
    );
}
