import React, { useState } from 'react'
import { axios, Button, Link } from 'react-mimg';
import { Alert, Container, FormGroup, Input, Label } from 'reactstrap';
import { PageTitle } from '../../components/layout/PageTitle'
import { isEmailValid } from '../../functions/validation';
import styles from './register.module.scss'

export default function Register() {
    const [homeEmail, setHomeEmail] = useState('');
    const [empId, setEmpId] = useState(0);
    const [username, setUsername] = useState('');
    const [workEmail, setWorkEmail] = useState('');
    const [error, setError] = useState('');
    const [info, setInfo] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    function submitHomeEmail(e) {
        e.preventDefault();
        setError('');
        if (!isEmailValid(homeEmail))
            setError('Email is not valid. Please enter the email address used when communicating with the recruiters.');
        else
            axios.get('api/login/GetEmpIdbyHomeEmail?email=' + homeEmail)
                .then(res => setEmpId(res.data))
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        setError(error.response.data)
                    }
                    else if (error.response && error.response.status === 409) {
                        setInfo(error.response.data)
                    }
                    else {
                        setError('Unexpected error when submitting form.')
                    }
                })
    }

    function emailLoginInfo() {
        var data = {
            'empId': empId,
            'userName': username,
            'workEmail': workEmail + '@monarchinvestment.com'
        }
        axios.post('api/login/RegisterUser', data)
            .then(() => setShowSuccess(true))
    }

    return (
        <Container className={styles.container}>
            <PageTitle PageTitle='Create a New Account' />
            <h4>Welcome to Monarch Investment and Management Group&apos;s Employee Website.</h4>
            <h5>
                Please follow the workflow below to help us setup your account.
                Please note that we use personal email addresses for this step rather than @monarchinvestment.com addresses as not all
                employees have a @monarchinvestment.com email.
            </h5>
            <Alert color='danger' isOpen={error.length > 0} toggle={() => setError('')} >{error}</Alert >
            <Alert color='info' isOpen={info.length > 0} toggle={() => setInfo('')} >
                {info} <a href='https:mimginvestment.com/contact'>Please Submit this form</a> if you believe you are seeing this message in error.
            </Alert >
            <Alert color='success' isOpen={showSuccess} toggle={() => setShowSuccess(!showSuccess)} >
                Your username and password were sent to {homeEmail}. 
                The email comes from info@mimg.us. Please check any spam or junk folders if you did not receive the message. 
                <a href='/login'>Please click here to access the login page.</a></Alert>
            <div className={styles.wrapper}>
                <FormGroup>
                    <Label for='homeEmail'>Personal Email (used to communicate with recruiters)</Label>
                    <Input type='text' id='homeEmail' value={homeEmail} onChange={e => setHomeEmail(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Button disabled={empId > 0} onClick={submitHomeEmail}>Submit</Button>
                </FormGroup>
                {empId > 0 &&
                    <div>
                        <FormGroup>
                            If you would like to choose your own username, please enter it below; otherwise you will be assigned a new username.
                            After pressing submit, you will receive an email at the address entered with your username and a temporary password.
                            You may login at <Link href='/login' title='mimg.us/v2/login' /> and then will be asked to change your password.
                        </FormGroup>
                        <FormGroup>
                            <Label for='username'>Chosen Username</Label>
                            <Input type='text' id='username' value={username} onChange={e => setUsername(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            If you have been assigned an @monarchinvestment.com email address, please enter it below; otherwise you may leave this blank and edit your profile later.
                        </FormGroup>
                        <FormGroup>
                            <Label for='workEmail'>Work Email:</Label>
                            <div className={styles.workEmail}><Input type='text' id='workEmail' value={workEmail} onChange={e => setWorkEmail(e.target.value)} />@monarchinvestment.com</div>
                        </FormGroup>
                        <FormGroup>
                            <Button onClick={emailLoginInfo}>Email Login Info</Button>
                        </FormGroup>
                    </div>
                }
            </div>
        </Container>
    )
}
