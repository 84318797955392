import React, { useEffect, useState } from 'react';
import { axios, Button } from 'react-mimg';
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { createDepartmentSpecificComment } from '../../../../functions/formHelpers/genericRequestFormHelper';
import { MimgAlert } from '../../../layout/MimgAlert';
import styles from '../../modals/RequestModals.module.scss';
import ITHelpdeskProcessing from './sendtodepartmentinfo/ITHelpdeskProcessing';
import NetworkProcessing from './sendtodepartmentinfo/NetworkProcessing';
import RequestedPropertyInfo from './sendtodepartmentinfo/RequestedPropertyInfo';
import YardiProcessing from './sendtodepartmentinfo/YardiProcessing';

export default function SendToDepartment(props) {

    const [showModal, setShowModal] = useState(false);
    const [modalAlert, setModalAlert] = useState();
    const [commentText, setCommentText] = useState('');
    const [comments, setcomments] = useState();

    useEffect(() => {
        if (props.genericRequestFormModel.genericRequestFormComments?.length > 0 && props.assignToDepartment) {
            setcomments(props.genericRequestFormModel?.genericRequestFormComments.filter(x => x.specificDepartment === props.assignToDepartment));
        }    
    },[props.genericRequestFormModel?.genericRequestFormComments, props.assignToDepartment])


    function onUpdateComment(textToAdd) {
        setCommentText(commentText + '' + textToAdd);
    }

    function addComment(items, isArray) {
        let newComment = createDepartmentSpecificComment(genericRequestFormModel, items, props.assignToDepartment, isArray)
        let genericRequestFormModel = props.genericRequestFormModel;
        genericRequestFormModel.genericRequestFormComments.push(newComment);

        axios.put("api/GenericRequestForm/UpdateRequest?updateAction=comments", genericRequestFormModel).then((response) => {
            genericRequestFormModel = response.data;
            let pageAlert = { AlertText : "", AlertColor: "success"};
            props.onSave(genericRequestFormModel, pageAlert); //no emails are sent
        })
    }

    return (
        <div>
            <Button onClick={() => setShowModal(true)}>{props.assignToDepartment} Processing</Button>
            <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>{props.assignToDepartment} Processing</ModalHeader>
                {modalAlert && <MimgAlert alertInfo={modalAlert} /> }

                <ModalBody>
                    <Form>
                        <RequestedPropertyInfo genericRequestFormModel={props.genericRequestFormModel} requestPropertyInfo={props.requestProperties} />
                        
                        {props.assignToDepartment == "Helpdesk" &&
                            <ITHelpdeskProcessing genericRequestFormModel={props.genericRequestFormModel} onUpdateComment={onUpdateComment} onAddCredential={props.onSave} onAddComment={addComment} requestPropertyInfo={props.requestProperties} propertyList={props.propertyList} />
                        }

                        {props.assignToDepartment == "Yardi Support" &&
                            <YardiProcessing genericRequestFormModel={props.genericRequestFormModel} onUpdateComment={onUpdateComment} onAddCredential={props.onSave} onAddComment={addComment} requestPropertyInfo={props.requestProperties} setModalAlert={setModalAlert} />
                        }

                        {props.assignToDepartment == "Network Team" &&
                            <NetworkProcessing genericRequestFormModel={props.genericRequestFormModel} onUpdateComment={onUpdateComment} onAddCredential={props.onSave} onAddComment={addComment} requestPropertyInfo={props.requestProperties} propertyList={props.propertyList} />
                        }

                        <hr />
                        {comments?.length > 0 &&
                            <><br />
                            <h5><b>Comments</b></h5>
                            <div className={`${styles.flexRow} ${styles.headers}`}>
                                <div className={styles.flex1}>Action Date/Time</div>
                                <div className={styles.flex1}>Action By</div>
                                <div className={styles.flex3}>Action Notes</div>
                            </div>
                            {comments.map((h, index) => {
                                return <div key={index} className={`${styles.flexRow} ${styles.marginTop10}`}>
                                    <div className={styles.flex1}>{new Date(h.dateTimeCreated).toLocaleDateString() + ' ' + new Date(h.dateTimeCreated).toLocaleTimeString()}</div>
                                    <div className={styles.flex1}>{h.commenterName}</div>
                                    <div className={styles.flex3}>
                                        {h.commentIsArray
                                            ? <>
                                                {h.comment.split('\\n').map((x, index) => {
                                                    return <div key={index}>{x}</div>
                                                })}</>
                                            : <>{h.comment}</>
                                        }
                                    </div>
                                </div>
                            }) }
                            </>
                        }
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={() => setShowModal(!showModal)}>Done</Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}