import React, { useState, useRef } from 'react';
import { axios, Button, ErrorLogModel, logError, getNowMountainTime } from 'react-mimg';
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { MimgAlert } from '../../layout/MimgAlert';
import { createComment, getNextStepInfo } from '../../../functions/formHelpers/genericRequestFormHelper';
import validator from 'validator';
import 'react-quill/dist/quill.snow.css';
import styles from '../modals/RequestModals.module.scss';
import RequestRichInput from './RequestRichInput';
import { uploadImage } from './UploadImage';

export default function RequestCompletion(props) {
    const [modalAlert, setModalAlert] = useState(new CustomAlertModel());
    const [showModal, setShowModal] = useState(false);
    const [value, setValue] = useState('');
    const warning = props.workflow.id === 19 && props.genericRequestFormModel.requestStatus === 'Awaiting Approval' ? true : false
    const [processing, setProcessing] = useState(false);
    const quillRef = useRef();

    function toggle() { setShowModal(false) }
    function open() { setShowModal(true) }


    async function onCompleteRequest() {

        let genericRequestFormModel = props.genericRequestFormModel;
        let pageAlert = new CustomAlertModel();
        let alert = new CustomAlertModel();

        let validationMsg = validateGenericRequestFormCompletionComment();
        if (validationMsg === "") {
            setProcessing(true);
            let commentText = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : document.getElementById("textAreaNewComment").value;

            const imageRegex = /<img[^>]+src="([^">]+)"/g;
            const images = [];
            let match;
            while ((match = imageRegex.exec(commentText)) !== null) {
                images.push(match[1]);
            }

            for (let i = 0; i < images.length; i++) {
                const imageUrl = images[i];
                const response = await uploadImage(imageUrl, genericRequestFormModel.id);
                commentText = commentText.replace(imageUrl, response.data.url);
            }

            let newComment = createComment(genericRequestFormModel.requesterEmpId, "<b>Request Completed.</b><br />" + commentText);
            genericRequestFormModel.genericRequestFormComments.push(newComment);
            genericRequestFormModel.requestStatus = "Completed";
            genericRequestFormModel.requestComplete = true;
            genericRequestFormModel.requestCompletedByEmpId = props.security.empID;
            genericRequestFormModel.requestCompletedByEmpName = props.security.employeeFullName;
            genericRequestFormModel.requestCompletedDateTime = getNowMountainTime();
            genericRequestFormModel.requestCompletedByEmpPosition = props.security.employeePosition;

            if (props.workflow.showExternalReference) {
                genericRequestFormModel.externalReferenceId = document.getElementById("txtExternalReference").value;
            }

            let requestTypeId = genericRequestFormModel.requestTypeId;
            let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Complete')[0].id;
            genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);

            if (genericRequestFormModel.currentWorkflowStep === null) {
                genericRequestFormModel.currentWorkflowStep = workflow.requestWorkflowSteps.filter(x => x.stepName.includes('Complete'))[0]?.id;
            }

            axios.put("api/GenericRequestForm/UpdateRequest?updateAction=complete", genericRequestFormModel).then((response) => {
                genericRequestFormModel = response.data;

                pageAlert.AlertColor = "info";
                pageAlert.AlertText = "The request was successfully marked as complete and the applicable parties were notified via email.";

                props.onSave(genericRequestFormModel, pageAlert, "Complete");
                setProcessing(false);
                toggle();
            }).catch((error) => {
                alert.AlertColor = "danger";
                alert.AlertText = "Error completing request.";
                setModalAlert(alert)
                setProcessing(false);
                logError(new ErrorLogModel(error, "OnCompleteRequest", props.security.employeeFullName + " experienced an error completing a GenericRequestForm (CorporateServices). FormId " + genericRequestFormModel.id));
            })

        } else {
            alert.AlertColor = "danger";
            alert.AlertText = validationMsg
            setModalAlert(alert)
        }
    }

    function validateGenericRequestFormCompletionComment() {
        let msg = "";
        let commentText = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : document.getElementById("textAreaNewComment").value;
        const textWithoutImages = commentText.replace(/<img[^>]*>/g, '').trim();

        if (props.formPreferences.requireCommentsOnCompletion) {
            if (commentText === "" || commentText ===  "<p><br></p>") {
                msg = msg + "Comments are required to close out these forms\n";
            }

        } else {
            if (commentText !== "") {
                if (!validator.isLength(textWithoutImages, { min: 1, max: 4000 })) {
                    msg = msg + "The comment entered is invalid.  The comment should be between 1 and 4000 characters, but is not required for this form\n";
                }
            }

            if (props.workflow.showExternalReference) {
                let textContent = document.getElementById("txtExternalReference")?.value;
                if (!validator.isLength(textContent, { min: 1, max: 25 })) {
                    msg = `The external reference id ${props.workflow.externalReferenceLabel} is required and must be between 1 and 25 characters`;
                }
            }
        }


        return msg;

    }

    return (
        <div>
            <Button onClick={open} >Complete Request</Button>
            <Modal isOpen={showModal} toggle={toggle} size={props.formPreferences.richTextComments ? "lg" : undefined}>
                <ModalHeader toggle={toggle}>Complete Request</ModalHeader>
                {warning &&
                    <MimgAlert alertInfo={{ AlertColor: "warning", AlertText: "Note: this ticket has another step. Only complete request if you are sure you want to close out the ticket. If not, click the Approve button instead." }} />
                }
                {modalAlert.AlertText !== "" &&
                    <MimgAlert alertInfo={modalAlert} />
                }

                <ModalBody>
                    <h5>Current Action: Complete Request</h5>
                    <Form>
                        <Label for="textAreaNewComment">Comment: {props.formPreferences.requireCommentsOnCompletion && <span className={styles.warning}>*</span>}</Label>
                        {props.formPreferences.richTextComments ? <RequestRichInput r={quillRef} />
                            : <Input className={styles.commentBox} type="textarea" id="textAreaNewComment" name="textAreaNewComment" onChange={(e) => setValue(e.target.value)} />}
                        {props.workflow.showExternalReference &&
                            <div className={styles.externalReferenceBox}>
                                <Label for="txtExternalReference">External Reference ID: {props.workflow.externalReferenceLabel}:</Label>
                                <Input type="text" id="txtExternalReference" name="txtExternalReference" defaultValue={props.genericRequestFormModel.externalReferenceId} placeholder={props.workflow.externalReferencePlaceholder} />
                            </div>
                        }
                    </Form>

                </ModalBody>

                <ModalFooter>
                    <Button transparent onClick={toggle}>Cancel</Button> {"  "}
                    <Button onClick={onCompleteRequest} disabled={processing}>
                        {processing
                            ? <span>Processing</span>
                            : <span>Complete Request</span>
                        }
                        
                    </Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}

