import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import RequestDetail from './RequestDetail';
export default function RequestDetailKey1FromQuery(props) {
    const params = new URLSearchParams(window.location.search)
    const queryValue = params.get("requestDetailKey1");
    const [value, setValue] = useState(props.requestDetailKey1 ? props.requestDetailKey1 : queryValue);

    useEffect(() => {
        setValue(props.requestDetailKey1 ? props.requestDetailKey1 : queryValue)
    }, [props.requestDetailKey1])

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail} >
            <Input
                type="text"
                placeholder={props.RequestTypeDetail.placeHolderText}
                value={value}
                onChange={e => setValue(e.target.value)}
                disabled={true}
            />
        </RequestDetail>
    );
}

