import React, { useState, useEffect } from 'react';
import flexStyles from '../../../styles/flex.module.scss';
import styles from './GenericRequestForm.module.scss';
import { axios } from 'react-mimg';
import { getEmployeeSecurity } from '../../../functions/permissions';
import RequestHistory from '../modals/RequestHistory';
import SubmitOnBehalfOfModal from '../modals/SubmitOnBehalfOfModal';

export default function RequestorInfo(props) {

    const [requesterEmployeeCard, setRequesterEmployeeCard] = useState(null);
    const [showOverrideEmployeeModal, setShowOverrideEmployeeModal] = useState(false);
    const [allEmployeeOptions, setAllEmployeeOptions] = useState([])
    const [selectedOverrideEmployee, setSelectedOverrideEmployee] = useState()
    const security = getEmployeeSecurity();
    const showTelephoneHyperLink = security.employeePosition === "IT Senior" || security.employeePosition === "IT";
    const [showTicketHistoryCanvas, setShowTicketHistoryCanvas] = useState(false);

    useEffect(() => {
        if (props.genericRequestFormModel.id > 0) {
            axios.get(`api/PropertyEmployees/GetSingleEmployeeCard?PropertyEmployeesId=${props.genericRequestFormModel.requesterEmpId}`).then(response => {
                setRequesterEmployeeCard(response.data);
            })
        }
    }, [])

   

    function getEmployeePhoneNumber() {
        if (props.employeePhone) {
            return props.employeePhone
        } else if (requesterEmployeeCard !== null) {
            if (requesterEmployeeCard.phone?.length >= 10) {
                return requesterEmployeeCard.phone
            } else {
                if (requesterEmployeeCard.cellPhone?.length >= 10) {
                    return requesterEmployeeCard.cellPhone
                } else {
                    return props.genericRequestFormModel.requesterPhone;
                }
            }
        } else {
            return props.employeeSecurity.employeePhone;
        }
    }

    function getEmail() {
        if (requesterEmployeeCard !== null) {
            return requesterEmployeeCard.email;
        } else {
            return props.employeeSecurity.employeeEmail;
        }
    }

    function onUpdateEmployee(employeeCard) {
        setRequesterEmployeeCard(employeeCard);
        props.onUpdateActuallyForEmployeeCard(employeeCard);
        setShowOverrideEmployeeModal(false);
    }

    return (
        <div className={flexStyles.paddingBottom10}>
            <h4 className={styles.sectionHeader}>Requestor Info</h4>
            {props.formPreferences.allowSubmitOnBehalfOf && props.isAdmin && !props.genericRequestFormModel.id &&
                <span className={styles.spanViewEmployeeProfile} onClick={() => setShowOverrideEmployeeModal(true)}>Submit on behalf of someone else<br /></span>
            }
            Requestor Name: <span id="spanRequestorName">{requesterEmployeeCard !== null ? requesterEmployeeCard.employeeName : props.employeeSecurity.employeeFullName}</span><br />
            Requestor Position: <span id="spanRequestorPosition"> {requesterEmployeeCard !== null ? requesterEmployeeCard.officialJobTitle : props.employeeSecurity.employeePosition}</span><br />
            Requestor Email: <a className={styles.spanViewEmployeeProfile} id="spanRequestorEmail" href={'mailto:' + getEmail()}>{getEmail()}</a><br />
            Requestor Phone: &nbsp;
            {showTelephoneHyperLink
                ? <span><a className={styles.spanViewEmployeeProfile} id="spanRequestorEmail" href={'tel:' + getEmployeePhoneNumber()}>{getEmployeePhoneNumber()}</a><br /></span>
                : <span>{getEmployeePhoneNumber()}</span>}<br />
            {props.genericRequestFormModel.submittedOnBehalfOfUser &&
                <span><i>**Form submitted on above user's behalf by {props.genericRequestFormModel.submittedOnBehalfOfUserByEmpName}</i><br /></span>
            }
            {props.genericRequestFormModel.requesterEmpId
                ? <a className={styles.spanViewEmployeeProfile} href={`profiles/employee?id=${props.genericRequestFormModel.requesterEmpId}`} target="_blank" rel="noreferrer">View Profile</a>
                : <></>
            }
            {props.isAdmin && props.genericRequestFormModel.id
                ? <span className={styles.spanViewEmployeeProfile} onClick={() => setShowTicketHistoryCanvas(true)}>View Employee Ticket History</span>
                : <></>
            }

            <SubmitOnBehalfOfModal
                isOpen={showOverrideEmployeeModal}
                toggle={() => setShowOverrideEmployeeModal(!showOverrideEmployeeModal)}
                onUpdateEmployee={onUpdateEmployee}
                formPreferences={props.formPreferences}
            />

            <RequestHistory
                isOpen={showTicketHistoryCanvas}
                toggle={() => setShowTicketHistoryCanvas(!showTicketHistoryCanvas)}
                genericRequestFormModel={props.genericRequestFormModel}
                employee={requesterEmployeeCard !== null ? requesterEmployeeCard.employeeName : props.employeeSecurity.employeeFullName}
            />
        </div>
    );
}
