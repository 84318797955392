import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import Select from 'react-select'
import RequestDetail from '../RequestDetail';

export default function RequestDetailNewishHires(props) {
    const [employeeList, setEmployeeList] = useState([])
    const [selectedOption, setSelectedOption] = useState()
    const [employeeOptions, setEmployeeOptions] = useState([])

    useEffect(() => {
        axios.get(`api/propertyemployees/GetNewishEmployeesFromSystemAccessRequestForm`)
            .then((response) => {
                let options = []
                let employees = response.data.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1);
                employees.forEach(emp => { options.push({ value: emp.propertyEmployeesId, label: emp.employeeName + ' (' + (emp.prismId !== '' ? emp.prismId : 'Not Yet Mapped' ) + ', WebsiteId: )' + emp.propertyEmployeesId + ', ' + emp.position }); })
                setEmployeeList(employees);
                setEmployeeOptions(options);
            })
    }, [])

    function handleChange(option) {
        setSelectedOption(option)
        props.onSelectEmployee && props.onSelectEmployee(employeeList.filter(x => x.propertyEmployeesId === option.value)[0]);
    }

    if (!employeeOptions)
        return <div>Loading...</div>

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
            <Select
                isClearable
                placeholder={props.RequestTypeDetail.placeholder}
                options={employeeOptions}
                onChange={handleChange}
                value={selectedOption}
                name='employeePicker'
            />

        </RequestDetail>
    );
}
