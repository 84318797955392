import React, { useEffect, useState, useRef } from 'react';
import { axios, Button, ErrorLogModel, logError } from 'react-mimg';
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { MimgAlert } from '../../layout/MimgAlert';
import { createComment, getNextStepInfo } from '../../../functions/formHelpers/genericRequestFormHelper';
import validator from 'validator';
import styles from '../modals/RequestModals.module.scss';
import RequestAutoClose from '../formelements/RequestAutoClose';
import RequestRichInput from './RequestRichInput';
import { getEmployeeSecurity } from '../../../functions/permissions';
import { uploadImage } from './UploadImage';

export default function RequestInfoNeeded(props) {
    const [modalAlert, setModalAlert] = useState(new CustomAlertModel());
    const [showModal, setShowModal] = useState(false);
    const [autoCloseDate, setAutoCloseDate] = useState(props.genericRequestFormModel.autoCloseTicketAfterDateTime !== null ? props.genericRequestFormModel.autoCloseTicketAfterDateTime : '');
    const [processing, setProcessing] = useState(false);
    const quillRef = useRef();

    function toggle() { setShowModal(false) }
    function open() { setShowModal(true) }
    function onHandleAutoCloseDate(event) { setAutoCloseDate(event.target.value) }

    async function onRequestMoreInfo() {
        let genericRequestFormModel = props.genericRequestFormModel;
        let pageAlert = new CustomAlertModel();
        let alert = new CustomAlertModel();

        let validationMsg = validateGenericRequestFormComment();
        if (validationMsg === "") {
            setProcessing(true);
            let autoCloseText = autoCloseDate ? `<br/><b><u>Response Requested by ${new Date(autoCloseDate).toLocaleDateString('en-US', { timeZone: 'UTC' })} 8:00PM MT or this ticket will be automatically closed.</u></b>` : '';
            let commentText = (props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : document.getElementById("textAreaNewComment").value) + autoCloseText;
            commentText = commentText.replaceAll('<p><br></p>', '<br />')
            const imageRegex = /<img[^>]+src="([^">]+)"/g;
            const images = [];
            let match;
            while ((match = imageRegex.exec(commentText)) !== null) {
                images.push(match[1]);
            }

            for (let i = 0; i < images.length; i++) {
                const imageUrl = images[i];
                const response = await uploadImage(imageUrl, genericRequestFormModel.id);
                commentText= commentText.replace(imageUrl, response.data.url);
            }

            let newComment = createComment(genericRequestFormModel, "<b>Need more information:</b><br />" + commentText);
            
            genericRequestFormModel.genericRequestFormComments.push(newComment);
            genericRequestFormModel.requestStatus = "Needs More Info";

            if (autoCloseDate !== '') {
                genericRequestFormModel.autoCloseTicketAfterDateTime = autoCloseDate;
                genericRequestFormModel.autoCloseSetByEmpId = parseInt(getEmployeeSecurity().empID);
            }

            let requestTypeId = genericRequestFormModel.requestTypeId;
            let workflow = props.requestTypes.filter(x => x.id === requestTypeId)[0].approvalWorkflowNavigation;
            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Need More Info')[0].id;
            genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStepId);

            axios.put("api/GenericRequestForm/UpdateRequest?updateAction=needsMoreInfo", genericRequestFormModel).then((response) => {
                genericRequestFormModel = response.data;

                pageAlert.AlertColor = "info";
                pageAlert.AlertText = "The request was successfully marked as needing more information and the applicable parties were notified via email.";
                
                props.onSave(genericRequestFormModel, pageAlert, "Need More Info");
                setProcessing(false)
                toggle();
            }).catch((error) => {
                alert.AlertColor = "danger";
                alert.AlertText = "Error completing request.";
                setModalAlert(alert)
                setProcessing(false)
                logError(new ErrorLogModel(error, "onRequestMoreInfo", props.security.employeeFullName + " experienced an error requesting more info. FormId " + genericRequestFormModel.id));
            })

        } else {
            alert.AlertColor = "danger";
            alert.AlertText = validationMsg
            setModalAlert(alert)
        }
    }

    function validateGenericRequestFormComment() {
        //comment is not required, but should check the length if necessary
        let msg = "";
        let commentText = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : document.getElementById("textAreaNewComment").value;

        if (commentText !== "") {
            if (!validator.isLength(commentText, { min: 1 })) {
                msg = msg + "The comment entered is invalid.  The comment should be between 1 and 4000 characters, but is not required for this action\n";
            }
        }

        if (props.genericRequestFormModel.autoCloseTicketAfterDateTime === null) {
            let selectedDate = new Date(autoCloseDate);
            var userTimezoneOffset = selectedDate.getTimezoneOffset() * 60000;
            selectedDate = new Date(selectedDate.getTime() + userTimezoneOffset)
            let tomorrow = addDays(new Date(new Date().setHours(0, 0, 0, 0)), 1);

            if (selectedDate < tomorrow) {
                setAutoCloseDate('')
                msg = msg + "Auto Close Date must be at least one day in the future\n";
            }
        }
        return msg;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    return (
        <div>
            <Button onClick={open}>Request More Info</Button>
            <Modal isOpen={showModal} toggle={toggle} size={props.formPreferences.richTextComments ? "lg" : undefined}>
                <ModalHeader toggle={toggle}>Request More Info</ModalHeader>
                {modalAlert.AlertText !== "" &&
                    <MimgAlert alertInfo={modalAlert} />
                }
                <ModalBody>
                    <h5>Current Action: Request More Info</h5>
                    <Form>
                        {props.formPreferences.allowAutoClose &&
                            <FormGroup>
                                <div className={styles.adminOnlyWrapper}> <RequestAutoClose onHandleAutoCloseDate={onHandleAutoCloseDate} autoCloseDate={autoCloseDate} /></div>
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label for="textAreaNewComment">Comment:</Label>
                            {props.formPreferences.richTextComments ? <RequestRichInput r={quillRef} />
                                : <Input className={styles.commentBox} type="textarea" id="textAreaNewComment" name="textAreaNewComment" />
                            }</FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button transparent onClick={toggle}>Cancel</Button> {"  "}
                    <Button onClick={onRequestMoreInfo} disabled={processing}>
                        {processing
                            ?<span>Processing</span>
                            : <span>Request More Info</span>
                        }
                        </Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>
    );
}

