import {useMemo} from "react";
import { useLocation } from 'react-router-dom'

export function useQuery(keepInitialCase) {
    const { search } = useLocation()
    if (keepInitialCase) {
        return useMemo(() => new URLSearchParams(search), [search]);
    } else {
        return useMemo(() => new URLSearchParams(search.toLowerCase()), [search]);
    }
    
}