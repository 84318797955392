import React, { useState, useEffect } from 'react';
import { axios, Button } from 'react-mimg';
import { Form, Alert, Label, Input } from 'reactstrap';
import Select from 'react-select'
import styles from './referralBonusInfoModal.module.scss';
import { getEmployeeSecurity } from '../../../../functions/permissions';

export default function ReferralBonusDetail(props) {
    const security = getEmployeeSecurity();
    const isBonusAdmin = security.isBonusAdmin;
    const [bonus, setBonus] = useState(props.bonus);
    const [bonusDetails, setBonusDetails] = useState([])
    const [propertyOptions, setPropertyOptions] = useState([])
    const [detailAlert, setDetailAlert] = useState()
    const [totalDoesntMatch, setTotalDoesntMatch] = useState();
    const inputsDisabled = props.genericRequestFormModel.requestComplete === true || props.genericRequestFormModel.requestRejected === true || !isBonusAdmin;


    useEffect(() => {
        let options = [];
        props.propertyList.map((o) => {
            options.push({ label: o.propertyName + ' - ' + o.pelitonId, value: o.siteId })
        })

        setPropertyOptions(options);

    }, [props.propertyList])

    useEffect(() => {

        if (bonusDetails.length > 0) {
            //nothing, use what we have
        } else if (bonus.referralAndSigningBonusDetails.length === 0) { //if there are no bonus details, create the default one
            let empSiteId = props.employeeCard.siteId;
            if (empSiteId === 99999 || empSiteId === 99998 || empSiteId === 231) { //corporate exclusions
                empSiteId = 176
            }

            let property;
            if (props.header === "First Portion") { //default amount 1/3 is paid by Monarch 368100
                property = props.propertyList.filter(x => x.siteId === 176)[0]
            } else if (props.header === "Second Portion") { //default amount 2/3 paid by property
                property = props.propertyList.filter(x => x.siteId === empSiteId)[0];
            } else if (props.header === "Signing Bonus") {  //entire amount paid by ?
                property = props.propertyList.filter(x => x.siteId === empSiteId)[0];
            }

            let defaultDetail = {};
            defaultDetail.bonusId = 0; //added via EF
            defaultDetail.bonusAmount = bonus.amount;
            defaultDetail.siteId = property?.siteId;
            defaultDetail.propertyCode = property?.yardiCode;
            defaultDetail.propertyName = property?.propertyName;
            defaultDetail.propertyDivisionCode = property?.pelitonId;
            defaultDetail.portionName = bonus.bonusType;

            let details = [];
            details.push(defaultDetail);
            setBonusDetails(details);
        } else { //set the existing details to be the new details
            setBonusDetails(bonus.referralAndSigningBonusDetails);
        }

    }, [bonus])

    useEffect(() => {
        if (getTotal() !== parseFloat(bonus.amount)) {
            setTotalDoesntMatch(true)
        } else {
            setTotalDoesntMatch(false)
        }
    }, [bonus, bonusDetails])

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    function updateBonus(property, value) {
        let bonusCopy = JSON.parse(JSON.stringify(props.bonus));

        bonusCopy[property] = value;

        if (property === "status" && (value === "Not Applicable" || value === "Rejected"))
            bonusCopy["amount"] = 0;

        setBonus(bonusCopy)
        props.onUpdateBonus(bonusCopy)
    }

    function getSelectedOption(siteId) {
        return propertyOptions.filter(x => x.value === siteId)[0];
    }

    function updateBonusDetail(index, newValue) {
        let detailsCopy = JSON.parse(JSON.stringify(bonusDetails))
        let detailCopy = JSON.parse(JSON.stringify(detailsCopy[index]));
        detailCopy.bonusAmount = newValue;
        detailsCopy[index] = detailCopy;

        setBonusDetails(detailsCopy);
    }

    function updateSelectedProperty(index, e) {
        let detailsCopy = JSON.parse(JSON.stringify(bonusDetails))
        let detailCopy = JSON.parse(JSON.stringify(detailsCopy[index]));
        let matchingProp = props.propertyList.filter(x => x.siteId === e.value)[0];

        detailCopy.siteId = e.value;
        detailCopy.propertyName = matchingProp?.propertyName;
        detailCopy.propertyDivisionCode = matchingProp?.pelitonId;
        detailCopy.propertyCode = matchingProp?.yardiCode;

        detailsCopy[index] = detailCopy;
        setBonusDetails(detailsCopy);
    }

    function addNewDetail() {
        let newDetail = {};
        newDetail.bonusId = 0; //added via EF
        newDetail.bonusAmount = 0;
        newDetail.siteId = 0;
        newDetail.propertyCode = ""
        newDetail.propertyName = "";
        newDetail.propertyDivisionCode = "";

        let detailsCopy = JSON.parse(JSON.stringify(bonusDetails))
        detailsCopy.push(newDetail);
        setBonusDetails(detailsCopy);
    }

    function getTotal() {
        let total = 0;
        bonusDetails.map(d => {
            total += parseFloat(d.bonusAmount);
        })

        return total;
    }

    function submitBonus(e) {
        e.preventDefault();

        //cleanse the bonus details (must have a valid amount and property, then associate those to the 'bonus' which is sent to the controller to update)
        var validDetails = [];
        var detailsCopy = JSON.parse(JSON.stringify(bonusDetails));
        for (var i = 0; i < detailsCopy.length; i++) {
            var detail = detailsCopy[i];
            if (detail.siteId > 0 && detail.bonusAmount > 0) {
                validDetails.push(detail);
            }
        }

        let proceed = false;
        if (bonus.status === 'Not Applicable' || bonus.status === 'Rejected') {
            var bonusCopy = JSON.parse(JSON.stringify(bonus))
            bonusCopy.referralAndSigningBonusDetails = []
            proceed = true;
        } else if (validDetails.length >= 1 || bonus.status === 'Not Applicable' || bonus.status === 'Rejected') {
            var bonusCopy = JSON.parse(JSON.stringify(bonus))
            bonusCopy.referralAndSigningBonusDetails = validDetails;
            proceed = true;
        } else {
            proceed = false;
        }


        if (proceed) {
            axios.post('api/payroll/AddReferralOrSigningBonusDetail', bonusCopy).then(response => {
                if (response.data.isSuccessful) {
                    setBonus(response.data.data);
                    setDetailAlert({ color: "success", message: "Success" })
                    props.onUpdateBonus(response.data.data);
                } else {
                    setDetailAlert({ color: "danger", message: response.data.errorMessage })
                }
            })
        } else {
            setDetailAlert({ color: "danger", message: "Unable to save data with the given inputs. Check the dates and amounts listed" })
        }

    }

    return (
        <div className={`${styles.flex1} ${styles.bonusWrapper}`}>
            <Form onSubmit={submitBonus}>
                {detailAlert &&
                    <Alert color={detailAlert.color} toggle={() => setDetailAlert()}>{detailAlert.message}</Alert>
                }
                <div className={styles.flexRow}>
                    <div className={styles.flex1}>
                        <h5 className={styles.header}>{props.header}</h5>
                        <div className={styles.flexRow}>
                            <Label className={styles.flex1} for="status">Status: </Label>
                            <Input disabled={inputsDisabled} className={styles.flex1} id="status" name="status" type="select" value={bonus?.status} onChange={(e) => updateBonus('status', e.target.value)} >
                                <option value="Submitted">Submitted</option>
                                <option value="Not Applicable">Not Applicable</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Scheduled">Scheduled</option>
                                <option value="Paid">Paid</option>
                            </Input>
                        </div>

                        <div className={styles.flexRow}>
                            <Label className={styles.flex1} for="amountToPay">Amount to Pay: </Label>
                            <Input disabled={inputsDisabled} className={styles.flex1} id="amountToPay" name="amountToPay" type="number" value={bonus?.amount} onChange={(e) => updateBonus('amount', e.target.value)} />
                        </div>
                        <div className={styles.flexRow}>
                            <Label className={styles.flex1} for="payDate">Pay Date: </Label>
                            <Input disabled={inputsDisabled} className={styles.flex1} id="payDate" name="payDate" type="date" value={bonus.payDate ? new Date(bonus?.payDate)?.toISOString().split('T')[0] : ""} onChange={(e) => updateBonus('payDate', e.target.value)} />
                        </div>
                        <div className={`${styles.flexRow} ${styles.header}`}>
                            <div className={styles.flex3}>Property</div>
                            <div className={styles.flex1}>Amount</div>
                        </div>
                        {bonusDetails?.length > 0 && bonusDetails.map((d, i) => {
                            return <div key={i} className={`${styles.flexRow} ${styles.alignCenters}`}>
                                <div className={styles.flex3}>
                                    <Select
                                        isDisabled={inputsDisabled}
                                        options={propertyOptions}
                                        value={getSelectedOption(d.siteId)}
                                        onChange={(e) => updateSelectedProperty(i, e)}
                                    />
                                </div>
                                <div className={styles.flex1}>
                                    <Input disabled={inputsDisabled} type="number" value={d.bonusAmount} placeholder="0.00" min="0" max="1500" onChange={(e) => updateBonusDetail(i, e.target.value)} />
                                </div>
                            </div>
                        })}
                        {isBonusAdmin && !inputsDisabled && 
                            <div className={`${styles.flexRow}`}>
                                <span className={styles.fauxLink} onClick={() => addNewDetail()}>Add Additional Property</span>
                            </div>
                        }
                        <div className={`${styles.footer} ${styles.flexRow}`}>
                            <div className={styles.flex3}>
                                {totalDoesntMatch ?
                                    <span className={styles.warning}>Total Doesn't Match</span>
                                    : <span>Total</span>}
                            </div>
                            <div className={styles.flex1}>
                                {getTotal()}

                            </div>
                        </div>

                    </div>
                </div>
                {isBonusAdmin && !inputsDisabled && 
                    <div className={styles.floatRight}>
                        <Button type="submit" disabled={totalDoesntMatch}>Update {props.header}</Button>
                    </div>
                }
            </Form>
        </div>
    );
}
