import React, { useState } from "react";
import styles from '../modals/RequestModals.module.scss';
import { Label, Input, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createComment, getNextStepInfo } from '../../../functions/formHelpers/genericRequestFormHelper';
import { Button, axios, logError, ErrorLogModel } from 'react-mimg'
import { MimgAlert } from "../../layout/MimgAlert";
import { getEmployeeSecurity } from "../../../functions/permissions";

export default function RequestReadyForApproval(props) {
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [processing, setProcessing] = useState(false);

    function toggle() { setShowModal(false) }
    function open() { setShowModal(true) }

    function onSubmit(e) {
        e.preventDefault();
        setProcessing(true);
        let security = getEmployeeSecurity();
        let requestForm = props.genericRequestFormModel;
        let currentStepId = getNextStepInfo(props.workflow, requestForm.currentWorkflowStep);
        requestForm.currentWorkflowStep = currentStepId;
        let fullStepInfo = props.workflow?.requestWorkflowSteps?.filter(x => x.id === currentStepId)[0];
        let comment = e.target['comment'].value;
        let newComment = createComment(requestForm.requesterEmpId, "<b>Request Marked Ready for Approval by " + security.employeeFullName + "</b>");
        if (comment.length > 0) {
            newComment.comment += "<br />Notes: " + comment;
        }

        requestForm.genericRequestFormComments.push(newComment);
        requestForm.requestStatus = fullStepInfo?.stepName

        axios.put("api/GenericRequestForm/UpdateRequest?updateAction=ReadyForApproval", requestForm).then((response) => {
            props.onSave(
                response.data,
                { AlertText: "The request is now ready for approval!", AlertColor: "success" },
                "Ready for Approval");
            toggle();
            setProcessing(false)

        }).catch((error) => {
            setError('Error while saving the request. Please try again.');
            logError(new ErrorLogModel(error, "OnReadyForApproval", security.employeeFullName + " experienced an error when marking request as ready for approval. FormId " + requestForm.id));
            setProcessing(false);
        })

    }

    return (
        <>
            <Button onClick={open}>Mark Ready For Approval</Button>
            <Modal isOpen={showModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Mark as Ready for Approval</ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        {error.length > 0 && <MimgAlert alertInfo={{ AlertColor: "danger", AlertText: { error } }} />}
                        <Label for="comment">Comment (Optional):</Label>
                        <Input className={styles.commentBox} type="textarea" id="comment" name="comment" />
                    </ModalBody>
                    <ModalFooter>
                        <Button transparent onClick={toggle}>Cancel</Button> {"  "}
                        <Button type='submit' disabled={processing}>
                            {processing
                                ? <span>Processing</span>
                                : <span>Mark Ready For Approval</span>
                            }
                            
                        </Button> {"  "}
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}