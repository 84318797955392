import React, { useEffect, useState, useRef } from 'react';
import validator from 'validator';
import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Tooltip } from 'reactstrap';
import { axios, ErrorLogModel, logError, Button } from 'react-mimg';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { MimgAlert } from '../../layout/MimgAlert';
import styles from '../modals/RequestModals.module.scss';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';
import { getEmployeeSecurity } from '../../../functions/permissions';
import WhoAreTheAdminsModal from '../modals/WhoAreTheAdminsModal';
import RequestAutoClose from '../formelements/RequestAutoClose';
import RequestRichInput from './RequestRichInput';
import { uploadImage } from './UploadImage';

export default function RequestComment(props) {
    const [modalAlert, setModalAlert] = useState(new CustomAlertModel());
    const [adminOnly, setAdminOnly] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(true);
    const [showWhoIsAdminModal, setShowWhoIsAdminModal] = useState(false);
    const formAlreadyCompleted = (props.genericRequestFormModel.requestComplete === true || props.genericRequestFormModel.requestRejected === true);
    const [showModal, setShowModal] = useState(false);
    const [value, setValue] = useState('');
    const [autoCloseDate, setAutoCloseDate] = useState(props.genericRequestFormModel.autoCloseTicketAfterDateTime !== null ? props.genericRequestFormModel.autoCloseTicketAfterDateTime : '');
    const isRequestor = (props.genericRequestFormModel.requesterEmpId === parseInt(getEmployeeSecurity().empID));
    const [processing, setProcessing] = useState(false);
    const quillRef = useRef();

    useEffect(() => {
        axios.get('api/RequestTypes/SeesAdminOnlyComments?requestTypeId=' + props.requestTypes[0]?.id).then(response => {
            if (response.data === true) {
                setIsAdmin(response.data)
                setAdminOnly((props.genericRequestFormModel.requestComplete === true || props.genericRequestFormModel.requestRejected === true) && response.data)
            }
        })
        if (isRequestor && autoCloseDate !== "") {
            let alert = new CustomAlertModel();
            alert.AlertColor = "info";
            alert.AlertText = "Auto close will be cancelled when you submit this comment"
            setModalAlert(alert)
            setAllowSubmit(true)
        }
    }, [])

    function toggle() { setShowModal(false) }
    function open() { setShowModal(true) }
    function updateAdminOnly(checked) { setAdminOnly(checked) }
    function toggleShowAdminsModal(open) { setShowWhoIsAdminModal(open) }

    function onHandleAutoCloseDate(event) {
        setAutoCloseDate(event.target.value)
        if (adminOnly) {
            let alert = new CustomAlertModel();
            alert.AlertColor = "info";
            alert.AlertText = 'Comment cannot be "Admin Only" if you are setting the auto close date. ';
            setModalAlert(alert)
            setAdminOnly(false)
        }
    }

    async function onSaveComment() {
        let genericRequestFormModel = props.genericRequestFormModel;
        let pageAlert = new CustomAlertModel();
        let alert = new CustomAlertModel();

        let validationMsg = validateGenericRequestFormComment();
        if (validationMsg === "") {
            setProcessing(true);
            let updatedValue = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : value;

            const imageRegex = /<img[^>]+src="([^">]+)"/g;
            const images = [];
            let match;
            while ((match = imageRegex.exec(updatedValue)) !== null) {
                images.push(match[1]);
            }

            for (let i = 0; i < images.length; i++) {
                const imageUrl = images[i];
                const response = await uploadImage(imageUrl , genericRequestFormModel.id);
                updatedValue = updatedValue.replace(imageUrl, response.data.url);
            }

            let cancelAutoCloseText = isRequestor && autoCloseDate !== '' ? "<br><i>Response was received which cancelled the scheduled Auto Close</i>" : '';
            let autoCloseText = !isRequestor && autoCloseDate !== '' && !adminOnly ? `<br/><b><u>Response Requested by ${new Date(autoCloseDate).toLocaleDateString('en-US', { timeZone: 'UTC' })} 8:00PM MT or this ticket will be automatically closed.</u></b>` : '';
            let newComment = createComment(genericRequestFormModel.requesterEmpId, updatedValue + autoCloseText + cancelAutoCloseText);
            newComment.adminOnly = adminOnly;
            genericRequestFormModel.genericRequestFormComments.push(newComment);

            if (autoCloseDate !== '') {
                genericRequestFormModel.autoCloseTicketAfterDateTime = autoCloseDate;
                genericRequestFormModel.autoCloseSetByEmpId = parseInt(getEmployeeSecurity().empID);
                if (isRequestor && new Date().setHours(0, 0, 0, 0) <= new Date(genericRequestFormModel.autoCloseTicketAfterDateTime)) {
                    genericRequestFormModel.autoCloseTicketAfterDateTime = null;
                    setAutoCloseDate('')
                }
            }

            axios.put("api/GenericRequestForm/UpdateRequest?updateAction=comments", genericRequestFormModel).then((response) => {
                genericRequestFormModel = response.data;

                if (newComment.adminOnly) {
                    props.onSaveWithoutEmails(response.data);
                    toggle();
                    alert.AlertColor = "warning";
                    alert.AlertText = "The comment was added successfully.  No emails are sent when sending 'Admin Only' comments"
                    setModalAlert(alert)
                    setAllowSubmit(false)

                } else {
                    pageAlert.AlertText = "The comment was added successfully.";
                    pageAlert.AlertColor = "success";
                    props.onSave(genericRequestFormModel, pageAlert, "Comment");
                    setProcessing(false)
                    toggle();
                }

            }).catch((error) => {
                alert.AlertColor = "danger";
                alert.AlertText = "Error saving new comment";
                setProcessing(false)
                setModalAlert(alert)

                logError(new ErrorLogModel(error, "OnSaveComment", props.security.employeeFullName + " experienced an error adding a comment to a GenericRequestForm (CorporateServices). FormId " + genericRequestFormModel.id));
            })
        } else {
            alert.AlertColor = "danger";
            alert.AlertText = validationMsg;
            setModalAlert(alert)
            setAdminOnly(false)
            setProcessing(false)
        }
    }

    function validateGenericRequestFormComment() {
        let msg = "";
        let commentText = props.formPreferences.richTextComments ? quillRef.current.root.innerHTML : value;
        
        const textWithoutImages = commentText.replace(/<img[^>]*>/g, '').trim();

        if (textWithoutImages === '<p><br></p>' || !validator.isLength(textWithoutImages, { min: 1, max: 4000 })) {
            msg = msg + "The comment entered is invalid.  Please add a comment (max 4000 characters)\n";
        }

        if (props.genericRequestFormModel.autoCloseTicketAfterDateTime === null) {
            let selectedDate = new Date(autoCloseDate);
            var userTimezoneOffset = selectedDate.getTimezoneOffset() * 60000;
            selectedDate = new Date(selectedDate.getTime() + userTimezoneOffset)
            let tomorrow = addDays(new Date(new Date().setHours(0, 0, 0, 0)), 1);

            if (selectedDate < tomorrow) {
                setAutoCloseDate('')
                msg = msg + "Auto Close Date must be at least one day in the future\n";
            }
        }

        return msg;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }


    return (
        <div>
            <Button onClick={open} belongsToGroup={props.belongsToGroup}>Add Comment</Button>
            <Modal isOpen={showModal} toggle={toggle} size={props.formPreferences.richTextComments ? "lg" : undefined}>
                <ModalHeader toggle={toggle}>Add Comment</ModalHeader>
                {modalAlert.AlertText !== "" &&
                    <MimgAlert alertInfo={modalAlert} />
                }
                <ModalBody>
                    <h5>Current Action: Add Comment</h5>
                    {isAdmin &&
                        <div className={styles.adminOnlyWrapper}>
                            <FormGroup check className={styles.flexRow}>
                                <Input className={styles.checkbox} type="checkbox" checked={adminOnly} onChange={(e) => updateAdminOnly(e.target.checked)}></Input>
                                <Label className={styles.flex2} for="switchAdminOnly">Admin Only</Label>
                                <span id="spanShowAdmins" className={styles.whoAreAdmins} onClick={() => toggleShowAdminsModal(!showWhoIsAdminModal)}>Who are the admins?</span>
                                <WhoAreTheAdminsModal isOpen={showWhoIsAdminModal} toggle={() => toggleShowAdminsModal(!showWhoIsAdminModal)} requestType={props.requestTypes[0]} />
                            </FormGroup>
                            {formAlreadyCompleted && <span className={styles.warning}>This request has already been completed or rejected, all comments will default to Admin Only, but it is not required.  Comments added to closed forms will follow the notification behavior of comments on open forms</span>}
                            {props.formPreferences.allowAutoClose && !adminOnly && <div className={styles.flexRow}><RequestAutoClose onHandleAutoCloseDate={onHandleAutoCloseDate} autoCloseDate={autoCloseDate} /></div>}
                        </div>
                    }
                    <FormGroup>
                        <Label for="textAreaNewComment">Comment:</Label>
                        {props.formPreferences.richTextComments ? <RequestRichInput r={quillRef} /> 
                            : <Input className={styles.commentBox} type="textarea" id="textAreaNewComment" name="textAreaNewComment" onChange={(e) => setValue(e.target.value)} /> }
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button transparent onClick={toggle}>Cancel</Button> {"  "}
                    <Button className={styles.tailspinButtonWrapper} onClick={onSaveComment} disabled={!allowSubmit || processing}>
                        {processing
                            ? <span>Processing...</span>
                            : <>Add Comment</>}
                    </Button> {"  "}
                </ModalFooter>
            </Modal>
        </div>

    );
}
