import { useEffect } from "react";
import { logPageView } from 'react-mimg'

export default function PageView() {

    useEffect(() => {
        logPageView();
    }, []);

    return null;
}