import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import Select from 'react-select'
import RequestDetail from './RequestDetail';
import styles from './RequestDetailEmailGroupPicker.module.scss';

export default function RequestDetailEmailGroupPicker(props) {
    const [selectedEmailGroups, setSelectedEmailGroups] = useState([]);
    const [allEmailGroups, setAllEmailGroups] = useState([]);

    useEffect(() => {
        axios.get('api/ITSetup/GetAllOutlookGroups').then(response => {
            let okTypes = ['Distribution groups'];
            let groups = response.data.data.filter(x => okTypes.includes(x.groupTypeName)).sort((a, b) => a.displayName > b.displayName ? 1 : -1)
            let allOptions = [];
            groups.map(o => {
                allOptions.push({ label: o.displayName, value: o.id })
            });

            setAllEmailGroups(allOptions);
        })

    }, [])

    useEffect(() => {
        if (parseInt(props.propertyEmployeesId) > 0) {
            axios.get('api/ITSetup/GetCurrentAndDefaultOutlookGroupsByPosition?propertyEmployeesId=' + props.propertyEmployeesId).then(response => {
                let groups = response.data.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
                let options = [];
                groups.map(o => {
                    options.push({label: o.displayName, value: o.id})
                })

                setSelectedEmailGroups(options);
            })
            
        } else {
            setSelectedEmailGroups([]);
        }
    }, [props.propertyEmployeesId])


    function handleChange(options) {
        setSelectedEmailGroups(options)
    }

    if (!selectedEmailGroups || !allEmailGroups)
        return <div>Loading...</div>

    return (
        <div>
            <h6 className={styles.warning}>If you do not see a group you want add, add that to the Additional Information box at the bottom of the form.</h6>
            <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
                <Select
                    isClearable
                    isMulti
                    placeholder={props.RequestTypeDetail.placeholder}
                    options={allEmailGroups}
                    onChange={handleChange}
                    value={selectedEmailGroups}
                    name='emailGroupPicker' />
            </RequestDetail>
            
        </div>
    );
}
