import React, { useEffect } from 'react'
import { NotificationPage, axios } from 'react-mimg';
import logo from '../../images/mimgLogo3.png'
import { useLocation } from 'react-router-dom';
import { getEmployeeSecurity } from '../../functions/permissions';

export default function ErrorPage(props) {
    const user = getEmployeeSecurity();
    const location = useLocation();

    useEffect(() => {

            const view = {
                userId: user?.userId,
                fullUrl: window.location.href,
                propertyEmployeesId: user?.empID,
                path: location?.pathname,
                errorMessage: props?.error?.message,
                stackTrace: props?.error?.stack,
                source: 'Front End'
            };

            axios.post('api/PageViewLog/LogError', view);
    }, []);



        return (
            <NotificationPage
                header="Something went wrong."
                logo={logo}
                text={
                    <p style={{ color: '#354442', textAlign: 'left', display: 'inline-block' }}><strong>
                        Please try refresh this page to refresh your session. <br/>
                        If that does not work, please submit a <a href="https://mimg.us/ITRequestForm.aspx?Type=AddForm&on=0" target="_self">support ticket</a>. 

                        In your ticket, explain what you tried to do before you got this error page. <br />
                        Letting us know what date and time you got the error will also help us identify the proper logs.
                    </strong></p>} />
        )
    }