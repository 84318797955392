export const controlOptions = [
    { value: "RequestDetailShortText", label: "Short Text (One Line)" },
    { value: 'RequestDetailTextArea', label: "Long Text (Multi Line)" },
    { value: 'RequestDetailNumber', label: "Number (Integer)" },
    { value: 'RequestDetailDropdown', label: "Dropdown (Select Single Option)" },
    { value: 'RequestDetailListbox', label: "Listbox (Select Multiple Options)" },
    { value: 'RequestDetailCheckbox', label: "Checkbox (Yes/No)" },
    { value: 'RequestDetailAssociatedDocuments', label: 'Associated Documents Control' },
    { value: 'RequestDetailStatesList', label: 'List of States' },
    { value: 'RequestDetailDatePicker', label: 'Date Picker' },
    { value: 'RequestDetailEmployeeForSitePicker', label: 'Employee Picker (Single Employee)' },
    { value: 'RequestDetailEmployeeForSitePicker2', label: 'Second Employee Picker (Single Employee)' },
    { value: 'RequestDetailMultiEmployeeForSitePicker', label: 'Employee Picker (Multiple Employees)' },
    { value: 'RequestDetailMyEmployeePicker', label: 'Employee Picker (My Employees)'},
    { value: 'RequestDetailEmployeeNameFromId', label: 'Employee Info (From Query String)' },
    { value: 'RequestDetailJobTitlePicker', label: 'Job Title Picker' },
    { value: 'RequestDetailJobDisplayNamePicker', label: 'Job Display Name Picker' },
    { value: 'RequestDetailEmployeePositionGroupPicker', label: 'Employee Position Group' },
    { value: 'RequestDetailEmailGroupPicker', label: 'Email Groups Picker' },
    { value: 'RequestDetailPropertySelect', label: 'Property Selection' },
    { value: 'RequestDetailPropertySelectWithNoSelectAllOption', label: 'Property Selection (no select all option)' },
    { value: 'RequestDetailPropertySelectYardi', label: 'Property Selection (Yardi Form)' },
    { value: 'RequestDetailUnitAvailability', label: 'Property Unit Availability' },
    { value: 'RequestDetailKey1FromQuery', label: 'Request Detail From Query (Key 1)'}

];

export const validationTypes = [
    { value: "", label: "" },
    { value: "Phone", label: "Phone Number" },
    { value: "Email", label: "Email Address" },
    { value: "Monarch Email", label: "Monarch Email" },
    { value: "Date", label: "Date" },
    { value: "ZipCode", label: "Zip Code" },
    { value: "Integer", label: "Integer (at least 1)" }
];

export const autofillOptions = [
    { value: "propertyName", label: "Property Name" },
    { value: "propertyUnitCount", label: "Property Unit Count" },
    { value: "propertyAddress", label: "Property Street Address" },
    { value: "propertyFullAddress", label: "Property Full Address" },
    { value: "propertyCity", label: "Property City" },
    { value: "propertyState", label: "Property State" },
    { value: "propertyMetro", label: "Property Metro Area" },
    { value: "propertyZip", label: "Property Zip" },
    { value: "propertyUrl", label: "Property Url" },
    { value: "propertyAM", label: "Property Asset Manager" },
    { value: "propertyRM", label: "Property Regional Manager" },
    { value: "propertyPM", label: "Property Manager" },
    { value: "propertyAqcuisitionDate", label: "Property Acquisition Date" },
    { value: "vendorName", label: "Vendor Name" },
    { value: "vendorCode", label: "Vendor Code" },
    { value: "vendorAddress", label: "Vendor Address" },
    { value: "vendorCity", label: "Vendor City" },
    { value: "vendorState", label: "Vendor State" },
    { value: "vendorZip", label: "Vendor Zip" },
    { value: "vendorPhone", label: "Vendor Phone" },
    { value: "vendorEmail", label: "Vendor Email" },
    { value: "vendorein", label: "Vendor EIN" },
    { value: "employeeName", label: "Employee Name" },
    { value: "employeePosition", label: "Employee Position" },
    { value: "employeeHireDate", label: "Employee Hire Date" },
    { value: "employeeCellPhone", label: "Employee Cell Phone" },
    { value: "employeeEmail", label: "Employee Email" },
    { value: "employeeDateofBirth", label: "Employee Date of Birth" },
    { value: "employeeJobTitle", label: "Employee Job Title" },
    { value: "employeePrismId", label: "Employee Prism ID" },
    
    { value: "employeeName2", label: "Employee 2 Name" },
    { value: "employeePosition2", label: "Employee 2 Position" },
    { value: "employeeHireDate2", label: "Employee 2 Hire Date" },
    { value: "employeeJobTitle2", label: "Employee 2 Job Title" },
    { value: "employeeEmail2", label: "Employee 2 Email" },
    { value: "employeeCellPhone2", label: "Employee 2 Cell Phone" },
    { value: "employee2PrismId", label: "Employee 2 Prism ID" },
    { value: "propertyEmployeesId", label: "Employee Id" },
    { value: "referralBonus", label: "Referral Bonus" },
    { value: "signingBonus", label: "Signing Bonus" }

];

export const itGroupOptions = [
    {
        label: "Accounting", value: "Accounting", defaultSsoGroupName: "Accounting_SG", defaultSsoGroupId: "33930f5c-8385-4c81-b8d6-47e875101731", corpFolders: true, correspondingEmployeePositions: ['Accounting', 'Accounting Controller', 'Accounts Payable', 'Accounts Receivable', 'Accounting Senior', 'Accounting Manager']
    },
    {
        label: "Analyst", value: "Analyst", defaultSsoGroupName: "Analyst_SG", defaultSsoGroupId: "15f168cf-b9b6-4681-8375-d9881c405f4d", corpFolders: true, correspondingEmployeePositions: ['Analyst']
    },
    {
        label: "Asset Manager", value: "Asset Manager", defaultSsoGroupName: "Asset_Manager_SG", defaultSsoGroupId: "bd346d45-4921-4db4-9e9c-71911a326fd9", corpFolders: true, correspondingEmployeePositions: ['Asset Manager']
    },
    {
        label: "Corporate", value: "Corporate", defaultSsoGroupName: "Corporate_SG", defaultSsoGroupId: "1f7e3cee-5dfc-46c8-a1d2-ec955318f6d2", corpFolders: true, correspondingEmployeePositions: ['Other Corporate']
    },
    {
        label: "Corporate Services", value: "Corporate Services", defaultSsoGroupName: "Corporate_Services_SG", defaultSsoGroupId: "10f048c7-799f-48ff-9760-c2412ba7096d", corpFolders: true, correspondingEmployeePositions: ['Corporate Services', 'Corporate Services Senior']
    },
    {
        label: "Executive", value: "Executive", defaultSsoGroupName: "Executive_SG", defaultSsoGroupId: "832e901e-176c-4f6f-b52a-52864b2605f3", corpFolders: true, correspondingEmployeePositions: []
    },
    {
        label: "HR", value: "HR", defaultSsoGroupName: "HR_SG", defaultSsoGroupId: "c0c64f90-e902-4fb2-a9f1-e1584ea75016", corpFolders: true, correspondingEmployeePositions: ['HR', 'HR Senior']
    },
    {
        label: "IT", value: "IT", defaultSsoGroupName: "IT_SG", defaultSsoGroupId: "86252a9b-1dd8-40a8-9856-248af346ac6e", corpFolders: true, correspondingEmployeePositions: ['IT', 'IT Senior']
    },
    {
        label: "Legal", value: "Legal", defaultSsoGroupName: "Legal_SG", defaultSsoGroupId: "19146abf-5616-4452-bf46-46a4d34ce353", corpFolders: true, correspondingEmployeePositions: ['Legal', 'Legal Senior']
    },
    {
        label: "Maintenance Rover", value: "Maintenance Rover", defaultSsoGroupName: "Maintenance_Rover_SG", defaultSsoGroupId: "ad9f67ee-c830-4dd2-9df0-f42e03a6f8c9", corpFolders: false, correspondingEmployeePositions: ['Maintenance Rover', 'Junior Maintenance Rover']
    },
    {
        label: "Marketing", value: "Marketing", defaultSsoGroupName: "Marketing_SG", defaultSsoGroupId: "50b9f5ea-0484-4481-bd02-b2224eab6aa7", corpFolders: false, correspondingEmployeePositions: ['Marketing', 'Marketing Senior', 'Marketing - LMS', 'Regional Marketing Senior']
    },
    {
        label: "Michigan Corp", value: "Michigan Corp", defaultSsoGroupName: "Michigan_Corp_SG", defaultSsoGroupId: "fdf7f1db-26cd-430e-81c1-692b8b7f3dff", corpFolders: true, correspondingEmployeePositions: []
    },
    {
        label: "Property", value: "Property", defaultSsoGroupName: "Property_SG", defaultSsoGroupId: "56373999-6fbf-4022-b389-c957b965433c", corpFolders: false, correspondingEmployeePositions: ['Assistant Property Manager', 'Leasing/Other Office Staff', 'Maintenance Supervisor', 'Other Maintenance Staff', 'Property Manager', 'Maintenance Tech']
    },
    {
        label: "Recruiting", value: "Recruiting", defaultSsoGroupName: "Recruiting_SG", defaultSsoGroupId: "bcf4023a-7ff5-4ac1-9242-59f38c5ad2cc", corpFolders: false, correspondingEmployeePositions: ['Recruiting', 'Recruiting Senior']
    },
    {
        label: "Regionals", value: "Regionals", defaultSsoGroupName: "Regionals_SG", defaultSsoGroupId: "fcc95028-e6b6-4720-b1f0-44f0c74ece85", corpFolders: false, correspondingEmployeePositions: ['Assistant Regional Manager', 'Regional Manager']
    },
    {
        label: "Team Relations", value: "Team Relations", defaultSsoGroupName: "Team_Relations_SG", defaultSsoGroupId: "5aa7d397-cc37-4b7b-8cfd-81c53db3f419", corpFolders: true, correspondingEmployeePositions: ['Team Relations']
    },
    {
        label: "Training", value: "Training", defaultSsoGroupName: "Training_SG", defaultSsoGroupId: "3875b69e-80c8-458c-951b-d2d2c3b5644a", corpFolders: true, correspondingEmployeePositions: ['Training', 'Training Senior']
    }
];

export const officeLicenseTypes = [
    { label: "EXCHANGESTANDARD", displayName: "Exchange Online (Plan 1)", show: true },
    { label: "EXCHANGEENTERPRISE", displayName: "Exchange Online (Plan 2)", show: true },
    { label: "M365_F1_COMM", displayName: "Microsoft 365 F1", show: true },
    { label: "SPE_E3", displayName: "Microsoft 365 E3", show: true },
    { label: "SPB", displayName: "Microsoft 365 Business Premium", show: true },
    { label: "WACONEDRIVESTANDARD", displayName: "OneDrive for Business", show: false },
    { label: "FLOW_FREE", displayName: "Microsoft Power Automate Free", show: true },
    { label: "VISIOCLIENT", displayName: "Visio Plan 2", show: false },
    { label: "STREAM", displayName: "Microsoft Stream Trial", show: false },
    { label: "POWER_BI_PRO", displayName: "Power BI Pro", show: false },
    { label: "WINDOWS_STORE", displayName: "Windows Store Store", show: false },
    { label: "MCOEV", displayName: "Microsoft Teams Phone Standard", show: false },
    { label: "PHONESYSTEM_VIRTUALUSER", displayName: "Microsoft Teams Phone Resource Account", show: false },
    { label: "POWERAPPS_VIRAL", displayName: "Microsoft Power Apps Plan 2 Trial", show: false },
    { label: "MEETING_ROOM", displayName: "Meeting Room", show: false },
    { label: "POWER_BI_STANDARD", displayName: "Power BI (free)", show: false },
    { label: "MCOPSTNC", displayName: "", show: false },
    { label: "MCOTEAMS_ESSENTIALS", displayName: "Teams Phone with Calling Plan", show: false },
    { label: "PBI_PREMIUM_PER_USER", displayName: "Power BI Premium Per User", show: false },
    { label: "SPE_E5", displayName: "Microsoft 3685 E5", show: false },
    { label: "MCOMEETADV", displayName: "Microsoft 365 Audio Confrencing", show: false },
    { label: "PBI_PREMIUM_PER_USER_ADDON", displayName: "Power BI Premium Per User Add-On", show: false },
    { label: "", displayName: "", show: false }
];

export const marketingRequestTypeGroups = [
    { value: "", label: "--Select--"},
    { value: "J Turner", label: "J Turner" },
    { value: "Print Collateral", label: "Print Collateral" },
    { value: "Social Media", label: "Social Media" },
    { value: "Websites", label: "Websites" }
];