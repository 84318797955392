import React, { useState, useEffect } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Label } from 'reactstrap';
import RequestCardForHistory from "../../profiles/EmployeeProfile/Requests/RequestCardForHistory";
import styles from "./requestHistory.module.scss";
import Spinner from "../../../components/layout/Spinner";
import Select from 'react-select';
import { axios } from 'react-mimg';

export default function RequestHistory(props) {
    const [selectedType, setSelectedType] = useState();
    const [ticketHistory, setTicketHistory] = useState();
    const [ticketTypes, setTicketTypes] = useState();
    const [filteredTickets, setFilteredTickets] = useState();

    useEffect(() => {
        if (props.isOpen) {
            axios.get(`api/request/getTicketHistory?empId=${props.genericRequestFormModel.requesterEmpId}&formName=${props.genericRequestFormModel.relatedToFormName}`).then(res => {
                setTicketHistory(res.data.sort((a, b) => b.requestDateTime.localeCompare(a.requestDateTime)))
                let types = [...new Set(res.data.map(x => x.title.split('| ')[1]))]
                setTicketTypes(types)
            })
        }
    }, [props.isOpen])

    useEffect(() => {
        if (ticketHistory) {
            setFilteredTickets(ticketHistory)
        }
    }, [ticketHistory])

    function onChangeType(option) {
        setSelectedType(option)
        if (option === null) {
            setFilteredTickets(ticketHistory)
        } else {
           setFilteredTickets(ticketHistory.filter(x => x.title.split('| ')[1] === option.value))
        }
    }

    return (
        <div >
            <Offcanvas className={styles.wrapper} isOpen={props.isOpen} toggle={props.toggle} scrollable={true} direction="end">
                <OffcanvasHeader toggle={props.toggle}>{`${props.employee} Ticket History`}</OffcanvasHeader>
                <OffcanvasBody>
                    <div className={styles.bodyWrapper}>
                        {ticketTypes &&
                            <div className={styles.filter}>
                            <Label className={styles.label }>Filter By Request Type</Label>
                                <Select
                                    name="filterTypes"
                                    options={ticketTypes.map(x => ({ label: x, value: x }))}
                                    onChange={onChangeType}
                                    value={selectedType ? selectedType : null}
                                    isClearable
                                />
                            </div>
                        }
                        {filteredTickets
                            ?
                            <>
                                {filteredTickets.length > 0
                                    ? <div>{filteredTickets.map((x, idx) => <RequestCardForHistory key={idx} request={x} genericRequestFormModel={props.genericRequestFormModel} />)}</div>
                                    : <div>Employee has no ticket history for the selected request type</div>
                                }
                            </>
                            : <Spinner />
                        }
                    </div>
                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

