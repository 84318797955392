

export class RequestWorkflowModel {

    constructor() { //link is any object that gets passed in and has these properties
        this.id = 0;
        this.workflowName = "";
        this.workflowNotes  = "";
        this.createdByEmpId = null;
        this.createdByEmpName = null;
        this.createdDateTime = null;
        this.modifiedByEmpId = null;
        this.modifiedByEmpName = null;
        this.modifiedDateTime = null;
        this.showExternalReference = false;
        this.externalReferenceLabel = "";
        this.externalReferencePlaceholder = "";
        this.requestWorkflowSteps = [];

    }

}
