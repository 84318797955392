import React, { useEffect, useState } from 'react';
import styles from './UserMaint.module.scss';
import { axios, Button } from 'react-mimg';
import { ModalHeader, ModalFooter, ModalBody, Alert, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Modal } from 'reactstrap';
import { PageTitle } from '../../components/layout/PageTitle';
import { getEmployeeSecurity } from "../../functions/permissions";
import UserMaintFilters from '../../components/admin/UserMaintFilters';
import { isMobile } from 'react-device-detect'
import NoAccessPage from './NoAccessPage';

export default function UserMaint() {
    const [users, setUsers] = useState();
    const [displayUsers, setDisplayUsers] = useState();
    const [properties, setProperties] = useState();
    const [filtersOpen, setFiltersOpen] = useState();
    const [positions, setPositions] = useState();
    const [searchFilter, setSearchFilter] = useState();
    const [propertyFilter, setPropertyFilter] = useState();
    const [phoneFilter, setPhoneFilter] = useState();
    const [positionFilter, setPositionFilter] = useState();
    const [alert, setAlert] = useState({ color: "", message: "", isOpen: false });
    const security = getEmployeeSecurity();
    const mobileWidth = window.innerWidth <= 993;

    async function fetchData() {
        const responseEmployees = await axios.get('api/PropertyEmployees/GetPropertyEmployees');
        const propertyEmployeesData = responseEmployees.data;

        const propertiesData = await axios.get('api/Property/GetAll');
        setProperties(propertiesData.data.map((property) => ({
            label: property.name,
            value: property.siteId,
        })));


        setUsers(propertyEmployeesData);
        setDisplayUsers(propertyEmployeesData);
        const positions = [...new Set(propertyEmployeesData.map(item => item["position"]))];

        setPositions(positions);
    }
    function getProperty(id) {
        var property = properties.find(property => property.value === id);

        return property?.label;
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        var filteredUsers = users;
        if (searchFilter) {
            filteredUsers = filteredUsers?.filter(x => (x.firstName + ' ' + x.lastName)?.toLowerCase().includes(searchFilter?.toLowerCase()) || x.email?.toLowerCase().includes(searchFilter?.toLowerCase()) || x.userId === parseInt(searchFilter) || x.propertyEmployeesId === parseInt(searchFilter))
        };
        if (propertyFilter) {
            filteredUsers = filteredUsers?.filter(x => (x.siteId === propertyFilter.value))
        }
        if (positionFilter) {
            filteredUsers = filteredUsers?.filter(x => (x.position === positionFilter.value))
        }
        if (phoneFilter) {
            const cleanedPhoneFilter = phoneFilter.replace(/\D/g, '');
            filteredUsers = filteredUsers?.filter(x => {
                const numericPhone = x.phone.replace(/\D/g, '');
                return numericPhone.includes(cleanedPhoneFilter);
            });
        }

        setDisplayUsers(filteredUsers);

    }, [searchFilter, propertyFilter, positionFilter, phoneFilter]);

    function actionRouter(option, user) {
        if (option == 'Profile') {
            const url = `profiles/employee?tab=it%20admin&id=${user.propertyEmployeesId}`;
            window.location.href = url;
        }
        if (option == 'Admin') {
            const url = `profiles/employee?tab=it%20admin&id=${user.propertyEmployeesId}`;
            window.location.href = url;
        }
        if (option == 'EditProfile') {
            const url = `profiles/employee?tab=edit%20profile&id=${user.propertyEmployeesId}`;
            window.location.href = url;
        }
    }

    function setFilters() {
        if (searchFilter) {
            setSearchFilter(searchFilter)
        }
        if (propertyFilter) {
            setPropertyFilter(propertyFilter)
        }
        if (phoneFilter) {
            setPhoneFilter(phoneFilter)
        }
        if (positionFilter) {
            setPositionFilter(positionFilter)
        }
    }

    const positionOptions = positions
        ?.map((position) => ({ label: position, value: position }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            <PageTitle PageTitle={"User Maintenance"} />
            { (security?.isITadmin || security.isProfileAdmin )? (
                <div>
                    {!isMobile ? (
                        <UserMaintFilters
                            users={users}
                            properties={properties}
                            setDisplayUsers={setDisplayUsers}
                            setFilters={setFilters}
                            positionOptions={positionOptions}></UserMaintFilters>) :
                        (<>
                            <Button onClick={() => setFiltersOpen(true)}>Filters</Button>
                            <Modal
                                className={styles.customModal}
                                isOpen={filtersOpen}
                                toggle={() => setFiltersOpen(!filtersOpen)}
                            >
                                <ModalHeader>Filters</ModalHeader>
                                <ModalBody>
                                    <UserMaintFilters
                                        users={users}
                                        properties={properties}
                                        setDisplayUsers={setDisplayUsers}
                                        setFilters={setFilters}
                                        positionOptions={positionOptions}
                                    ></UserMaintFilters>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => setFiltersOpen(false)}>Close</Button>
                                </ModalFooter>
                            </Modal>
                        </>
                    )}
                    <Alert isOpen={alert?.isOpen} color={alert?.color}>
                        {alert?.message}
                    </Alert>
                    <div className={styles.table}>
                        <div className={styles.headerRow}>
                            <div className={styles.columnxsm}>User ID</div>
                            <div className={styles.columnsm}>Property Emp ID</div>
                            <div className={styles.column}>Name</div>
                            <div className={styles.column2}>Property</div>
                            <div className={styles.column2}>Email</div>
                            <div className={styles.columnxsm}>Last Accessed</div>
                            <div className={styles.columnEnd}></div>
                        </div>
                        {displayUsers ? (
                            displayUsers.slice(0, 25).map((user) => (
                                <div key={user?.userId}>
                                    <div className={styles.row}>
                                        {!isMobile ? (<div className={styles.columnxsm}>{user?.userId}</div>) : (<div className={styles.mobileRow}><div className={styles.mobileLabel}>UserId :</div>{" "}{user?.userId}</div>)}
                                        {!isMobile ? (<div className={styles.columnsm}>{user?.propertyEmployeesId}</div>) : (<div className={styles.mobileRow}><div className={styles.mobileLabel}>Property Emp ID :</div>{user?.propertyEmployeesId}</div>)}
                                        {!isMobile ? (<div className={styles.column}>{user?.firstName + " " + user.lastName}</div>) : (<div className={styles.mobileRow}><div className={styles.mobileLabel}>Name :</div>{user?.firstName + " " + user?.lastName}</div>)}
                                        {!isMobile ? (<div className={styles.column2}>{getProperty(user.siteId)}</div>) : (<div className={styles.mobileRow}><div className={styles.mobileLabel}>Property:</div>{getProperty(user.siteId)}</div>)}
                                        {!isMobile ? (<div className={styles.column2}>{user?.email}</div>) : (<div className={styles.mobileRow}><div className={styles.mobileLabel}>Email:</div>{user?.email}</div>)}
                                        {!isMobile ? (<div className={styles.columnxsm}>{new Date(user?.myProfileLastAccessDateTime).toLocaleDateString("en-US")}</div>) : (<div className={styles.mobileRow}><div className={styles.mobileLabel}>Last Accessed:</div>{new Date(user?.user?.lastAccessDateTime).toLocaleDateString("en-US")}</div>)}
                                        <div className={styles.columnEnd}>
                                            <UncontrolledDropdown
                                                key={user?.userId}
                                                direction="down"
                                                className={styles.dropDown}
                                            >
                                                <DropdownToggle caret className={styles.dropDown}>
                                                    Actions
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => actionRouter('Profile', user)}>
                                                        View Profile
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => actionRouter('Admin', user)}>
                                                        IT Admin
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => actionRouter('EditProfile', user)}>
                                                        Edit Profile
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>Loading Users</div>
                        )}
                    </div>
                </div>
            ) : (
                <NoAccessPage/>
            )}
        </>
    );
}