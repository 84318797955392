import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import { useQuery } from '../../../functions/routing';
import RequestDetail from './RequestDetail';
import RequestDetailEmployeeForSitePicker from './RequestDetailEmployeeForSitePicker';
import RequestDetailNewishHires from './SystemAccess/RequestDetailNewishHires';
import Select from 'react-select'
import styles from './requestDetailEmployeeNameFromId.module.scss';

export default function RequestDetailEmployeeNameFromId(props) {
    const [employee, setEmployee] = useState()
    const [singleEmpOptions, setSingleEmpOptions] = useState([]);
    const [employeeIsInactive, setEmployeeIsInactive] = useState(false);
    const query = useQuery();
    const predefinedId = parseInt(query.get("propertyemployeesid")) //this just helps check if there is a query string

    useEffect(() => {
        let id = props.propertyEmployeesId ? props.propertyEmployeesId : predefinedId;
        if (id > 0 && !employee) {
            axios.get(`api/propertyemployees/GetSinglePropertyEmployeeBasics?PropertyEmployeesId=${id}&activesOnly=false`)
                .then((response) => {
                    setEmployee(response.data)

                    let employee = response.data;
                    let options = [];
                    options.push({ label: employee.employeeName + ' - ' + employee.position + ' (PrismId: '  + (employee.prismId ? employee.prismId : 'Not yet mapped') + ', WebsiteId: ' + employee.propertyEmployeesId + ')', value: employee.propertyEmployeesId })
                    setSingleEmpOptions(options);

                    if (!employee.isActive)
                        setEmployeeIsInactive(true);

                })
        }
    }, [props.propertyEmployeesId,predefinedId])

    useEffect(() => {
        props.onSelectEmployee && props.onSelectEmployee(employee);
    }, [employee])

    if (predefinedId > 0 && employee && singleEmpOptions.length > 0) {
        return (
            <div>
                {employeeIsInactive &&
                    <h6 className={styles.warning}>This EE is inactive, meanng they may have not passed background yet, but you may still submit the form</h6>
                }
                <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
                    <Select
                        readOnly
                        isClearable
                        placeholder={props.RequestTypeDetail.placeholder}
                        options={singleEmpOptions}
                        value={singleEmpOptions[0]}
                        name='employeePicker' />
                </RequestDetail>
            </div>

        )
    }

    if (props.defaultToComponent === 'RequestDetailEmployeeForSitePicker') {
        return (<RequestDetailEmployeeForSitePicker
            selectedProperties={props.selectedProperties}
            RequestTypeDetail={props.RequestTypeDetail}
            isAdmin={props.isAdmin}
            onSelectEmployee={props.onSelectEmployee} />);
    }

    if (props.defaultToComponent === 'RequestDetailNewishHires') {
        return (<RequestDetailNewishHires
            RequestTypeDetail={props.RequestTypeDetail}
            isAdmin={props.isAdmin}
            selectedRequestType={props.selectedRequestType}
            onSelectEmployee={props.onSelectEmployee} />)
    }

    return (
        <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>
            <Select
                readOnly
                isClearable
                placeholder={props.RequestTypeDetail.placeholder}
                options={singleEmpOptions}
                value={singleEmpOptions[0]}
                name='employeePicker'
            />
        </RequestDetail>
    );
}
